import CollapsibleProperty from '../CollapsibleProperty';
import React from 'react';
import {observer} from "mobx-react";
import {action, observable, ObservableSet} from "mobx";
import { getErrorMessage } from '../Map/ServerValidation';

export class ErrorsAndWarningsObject {
	errors: ObservableSet<string> = observable.set([], { deep: false});
	warnings: ObservableSet<string> = observable.set([], { deep: false});

	@action
	public addError(error: string) {
		this.errors.add(error);
	}

	@action
	public addErrors(errors: string[]) {
		errors.forEach(error => this.addError(error));
	}

	@action
	public removeError(error: string) {
		this.errors.delete(error);
	}

	@action
	public addWarning(warning: string) {
		this.warnings.add(warning);
	}

	@action
	public addWarnings(warnings: string[]) {
		warnings.forEach(warning => this.addWarning(warning));
	}

	@action
	public removeWarning(warning: string) {
		this.warnings.delete(warning);
	}

	@action
	public clearErrors() {
		this.errors.clear();
	}

	@action
	public clearWarnings() {
		this.warnings.clear();
	}

	@action
	public clear() {
		if (this.errors.size === 0 && this.warnings.size === 0) {
			return;
		}

		 this.clearErrors();
		 this.clearWarnings();
	}
}


interface IErrorsAndWarningProps {
	errorsAndWarnings: ErrorsAndWarningsObject;
}

const ErrorsAndWarningsProperties = observer((props: IErrorsAndWarningProps) => {
	const { errorsAndWarnings } = props;

	const { errors, warnings } = errorsAndWarnings;

	return (
		<CollapsibleProperty
			propertyTitle={`Errors and Warnings (${errors.size + warnings.size})`}
			displayProperty
		>
			{
				((errors.size > 0 || warnings.size > 0)
					&& (
						<div className="errors-and-warnings">
							{
								Array.from(errors).map(item => {
									return (
										<div className="errors-and-warnings-item"
											 key={item}>
											<span className="error" />
											<p>
												{getErrorMessage(item)}
											</p>
										</div>
									);
								})
							}
							{
								Array.from(warnings).map(item => {
									return (
										<div className="errors-and-warnings-item"
											 key={item}>
											<span className="warning" />
											<p>
												{item}
											</p>
										</div>
									);
								})
							}
						</div>
					))
				|| (
					<div className="errors-and-warnings">
						No errors
					</div>
				)
			}
		</CollapsibleProperty>
	);
});

export default ErrorsAndWarningsProperties;
