// This module contains data structures and helpers related to object validation that takes place on the server
export type MapErrorFlags =
	'NoError' |
	'NodeCurvatureError' |
	'NodeDistanceShortError' |
	'NodeDistanceLongError' |
	'NodeDistanceLongForCurveError' |
	'NodeOverSpeedError' |
	'NodeUnderSpeedError' |
	'NodeSpecialPositionError' |
	'NodeTurnbackPathStraightDistanceError' |
	'SublinkSelfIntersectionError' |
	'SublinkTooManyNodesError' |
	'SublinkMixedSpeedError' |
	'SublinksInconsistentSpeedError' |
	'SublinkDrivingBoundaryInterferenceError' |
	'LinkTooManySublinksError' |
	'NodeGradientError' |
	'NodeLateralAccelerationLoadingError' |
	'NodeLateralAccelerationEmptyError' |
	'BayWrongTypeError' |
	'BayWrongLocationError' |
	'BayWrongNodeError' |
	'AreaParkingNodeMustBeInnerLinkWithFullyContainedDrivingZoneError' |
	'AreaTooManyIOError' |
	'AreaTooFewIOError' |
	'AreaNoLinkBreakError' |
	'AreaDirectConnectionError' |
	'AreaIncorrectInnerLinksError' |
	'AreaIncorrectBayLinksError' |
	'AreaIsolatedInnerLinksNotAllowed' |
	'AParkingNodeMustBeLocatedInsideAParkingArea' |
	'AreaLoadingDumpingAreasMoreThanOneInnerLink' |
	'AreaLoadingDumpingInnerLinkMustBeConnected' |
    'AreaInnerLinksDrivingZoneIntersectionError' |
	'AreaParkingEvenNumberLinksAllowedExceptTransition' |
	'AreaParkingIncorrectNumberOfParkingNodes';

const errorMessages: { [key in MapErrorFlags]: string } = {
	NoError: "No Error",
	NodeCurvatureError: "Node curvature error.",
	NodeDistanceShortError: "Node distance is too short.",
	NodeDistanceLongError: "Node distance is too long.",
	NodeDistanceLongForCurveError: "Node distance is too long for the curve.",
	NodeOverSpeedError: "Node Speed exceeds the limit.",
	NodeUnderSpeedError: "Node Speed is below the minimum.",
	NodeSpecialPositionError: "Node Special position error.",
	NodeTurnbackPathStraightDistanceError: "Node Straight distance error in turnback path.",
	SublinkSelfIntersectionError: "Sublink self-intersection error.",
	SublinkTooManyNodesError: "Too many nodes in sublink.",
	SublinkMixedSpeedError: "Mixed speed error in sublinks.",
	SublinksInconsistentSpeedError: "Inconsistent speed error in sublinks.",
	SublinkDrivingBoundaryInterferenceError: "Driving boundary interference in sublink.",
	LinkTooManySublinksError: "Too many sublinks in the link.",
	NodeGradientError: "Gradient error in the node.",
	NodeLateralAccelerationLoadingError: "Lateral acceleration loading error in node.",
	NodeLateralAccelerationEmptyError: "Empty lateral acceleration error in node.",
	BayWrongTypeError: "Incorrect bay type.",
	BayWrongLocationError: "Incorrect bay location.",
	BayWrongNodeError: "Incorrect node in bay.",
	AreaParkingNodeMustBeInnerLinkWithFullyContainedDrivingZoneError: "A parking node must be part of an inner link with its driving zone fully contained within the parking area boundary.",
	AreaTooManyIOError: "Only a single entry and exit link is allowed.",
	AreaTooFewIOError: "The entry and/or exit link is missing.",
	AreaNoLinkBreakError: "Area No link break error",
	AreaDirectConnectionError: "Direct connection in area is not allowed.", 
	AreaIncorrectInnerLinksError: "Incorrect inner links configuration.",
	AreaIncorrectBayLinksError: "Incorrect bay links configuration.",
	AreaIsolatedInnerLinksNotAllowed: "Isolated inner links are not allowed",
	AParkingNodeMustBeLocatedInsideAParkingArea: "A parking node must be located inside a parking area.",
	AreaLoadingDumpingAreasMoreThanOneInnerLink: "Loading and dumping areas cannot contain more than one inner link",
	AreaLoadingDumpingInnerLinkMustBeConnected: "The inner link must be connected to the entry and exit link in a loading or dumping area",
    AreaInnerLinksDrivingZoneIntersectionError: "Inner links and their driving zone must be fully contained within the autonomous area boundary.",
	AreaParkingEvenNumberLinksAllowedExceptTransition: "A parking area must have an even number of inner links (transition area excepted).",
	AreaParkingIncorrectNumberOfParkingNodes: "Incorrect number of parking node(s)",
};

export function getErrorMessage(errorCode: string): string {
	return errorMessages[errorCode] || "Error "+errorCode;
}

export interface LinkErrorResult
{
	error: MapErrorFlags;
}

export interface AnotherLinkNodeErrorResult
{
	errors: MapErrorFlags[];
	nodeId: string;
}

export interface NodeErrorResult
{
	errors: MapErrorFlags[];
	sublinkIndex: number;
	index: number;
}

export interface SublinkErrorResult
{
	errors: MapErrorFlags[];
	index: number;
}
