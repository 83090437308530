import NodeGraphic from '../MapObjects/Node/NodeGraphic';
import alertToast from '../../../../Util/ToastifyUtils';
import {LinkEntity, LinkFromLinkTo} from '../../../../Models/Entities';
import MapStateHandler from './MapStateHandler';
import LinkOperationsHelper, {
	updateLinkSublinkConnectivityState
} from '../MapStateHandlerHelpers/LinkOperationsHelper';
import MapStore from '../MapStore';
import ConnectivityValidator, {CONNECTIVITY_INVALID_DIRECTION} from '../MapValidators/ConnectivityValidator';
import PathToolHelper from '../MapStateHandlerHelpers/PathToolHelper';
import { setCustomTag } from '../Helpers/MapUtils';
import CreateConnectivityCommand from "../../ChangeTracker/ChangeTypes/CreateConnectivityCommand";

export const CONNECTIVITY_INVALID_NODE = 'Not a valid connectivity node.';
export const CONNECTIVITY_PAIR = 'Links can not share connectivity pair.';

export default class ConnectivityToolHandler extends MapStateHandler {
	public selectedConnectivityItem: string | undefined;

	onInit() {
		// show in properties panel
		this.getEventHandler().emit('onPropertiesPanel', 'connection');
	}

	/**
	 * Check that a start or end waypoint has been clicked and
	 * call handleConnectivityClick
	 * Ignore clicks that are not nodes, and show error toast if
	 * a node which isn't a stard/end waypoint has been clicked
	 * @param event
	 * @returns
	 */
	onClick(event: L.LeafletMouseEvent) {
		const entity = this.getController().getMapObjectAtCoordinates(event.latlng);
		if (!entity) {
			return;
		}

		const type = entity.getType();

		// check that it's a node
		if (type === 'node') {
			if (entity instanceof NodeGraphic) {
				if (entity.isStartWithHaulingTaskOrEndWithTask()) {
					let previouslySelectedItemId: string | undefined;
					if (this.selectedConnectivityItem) {
						previouslySelectedItemId = this.selectedConnectivityItem;
					}
					let firstNode: NodeGraphic = entity;
					let endNode: NodeGraphic | undefined;
					if (previouslySelectedItemId) {
						firstNode = this.getRenderer().getObjectById(previouslySelectedItemId) as NodeGraphic;
						endNode = entity;
						this.selectedConnectivityItem = undefined;
					} else {
						this.selectedConnectivityItem = entity.getId();
					}

					this.handleConnectivityClick(firstNode, endNode);
				} else {
					alertToast(CONNECTIVITY_INVALID_NODE, 'error');
				}
			}
		}
	}

	onMove(event: L.LeafletMouseEvent) {
	}

	/**
	 * Cancel and connectivity operation in progress
	 * Change to Selector tool if there is no connectivity operation
	 * @param event
	 */
	onEscapePressed(event: KeyboardEvent) {
		if (this.selectedConnectivityItem) {
			const entity = this.getRenderer().getObjectById(this.selectedConnectivityItem);
			if (entity instanceof NodeGraphic) {
				this.handleConnectionCancel(entity);
				this.selectedConnectivityItem = undefined;
			}
		} else {
			this.getEventHandler().setActiveTool('selector');
		}
	}

	dispose() {
		if (this.selectedConnectivityItem !== undefined) {
			const entity = this.getRenderer().getObjectById(this.selectedConnectivityItem);
			if (entity instanceof NodeGraphic) {
				this.selectedConnectivityItem = undefined;

				entity.clearConnectivity();
			}
		}
	}

	/**
	 * Handle connectivity click of map controller when manual connectivity mode is set.
	 * Includes validation such as checking node type and whether connectivity already exists
	 * Error toast shown when validation fails, and info toast for successful creation of connectivity
	 * @param start
	 * @param end
	 * @returns
	 */
	private handleConnectivityClick = async (start: NodeGraphic, end?: NodeGraphic) => {
		// attempt to connect start/end nodes or start new connection from node
		if (end) {
			// clear the graphic
			start.clearConnectivity();

			// 1. Check they are start and end nodes
			if (start.isStart() === end.isStart()) {
				const nodeTypeString = start.isStart() ? 'start' : 'end';
				alertToast(`Cannot connect two ${nodeTypeString} nodes`, 'error');
				return;
			}

			// 2. Ensure order is correct
			if (!start.isStart()) {
				const tmp = start;
				start = end;
				end = tmp;
			}

			// 3. Check connectity doesn't already exist
			const startEntity = start.getEntity();
			const endEntity = end.getEntity();
			if (startEntity.linkIdNumber === endEntity.linkIdNumber) {
				alertToast('Cannot connect to the same link', 'error');
				return;
			}

			/**
			 * The links containing the start and end node. The direction of the links look like:
			 * endNodeLink -> startNodeLink
			 */
			const startNodeLink = this.getLookup().getLinkByIdNumber(startEntity.linkIdNumber);
			const endNodeLink = this.getLookup().getLinkByIdNumber(endEntity.linkIdNumber);
			
			if (!startNodeLink || !endNodeLink) {
				return;
			}

			const _startNodeLink = LinkOperationsHelper.copyLink(startNodeLink);
			const _endNodeLink = LinkOperationsHelper.copyLink(endNodeLink);

			const hasConnection = startNodeLink.linkFroms.some(lflt => lflt.linkFromId === endNodeLink.id);
			if (hasConnection) {
				alertToast('Links already connected', 'error');
				return;
			}

			const hasConnectivityPair = ConnectivityToolHandler
				.hasConnectivityPair(startNodeLink, endNodeLink, this.getLookup(), 'add');
			if (hasConnectivityPair) {
				alertToast(CONNECTIVITY_PAIR, 'error');
				return;
			}

			const isValidDistance = ConnectivityValidator.validateConnectivityDistance(
				startEntity, endEntity, this.getController(), true,
			);
			if (!isValidDistance) {
				console.log('Invalid distance');
				return;
			}

			const isConnectivityDirectionValid = ConnectivityValidator.validateConnectivityDirections(endEntity, startEntity, this.getLookup());
			if (!isConnectivityDirectionValid) {
				alertToast(CONNECTIVITY_INVALID_DIRECTION, 'error');
				return;
			}

			const newLinkFrom = new LinkFromLinkTo({
				linkFrom: endNodeLink, linkFromId: endNodeLink.id, linkTo: startNodeLink, linkToId: startNodeLink.id,
			});
			newLinkFrom.id = newLinkFrom._clientId;
			startNodeLink.linkFroms.push(newLinkFrom);
			endNodeLink.linkTos.push(newLinkFrom);
			alertToast('Added connectivity', 'info');

			updateLinkSublinkConnectivityState(startNodeLink, true);
			updateLinkSublinkConnectivityState(endNodeLink, false);

			const importVersion = this.getController().getImportVersion();

			await PathToolHelper.calculateDrivingZone(this.getLookup(), importVersion.id, undefined, newLinkFrom, 'CTH.handleConnectivityClick');

			setCustomTag('map-interface', 'create-a-connectivity');
			// this.getEventHandler().emitPathEditedEvent();
			// this.getEventHandler().emit('onTrackAddConnectivity', newLinkFrom, _startNodeLink, startNodeLink, _endNodeLink, endNodeLink);
			this.getController().getTracker1().addChange(new CreateConnectivityCommand(endNodeLink.id, startNodeLink.id));
		} else {
			start.startConnectivity();
		}
	}

	/**
	 * Used to cancel the current manual connectivity operation.
	 * e.g. when the user presses escape
	 * @param ng
	 */
	private handleConnectionCancel = (ng: NodeGraphic) => {
		ng.clearConnectivity();
	}

	/**
	 * Check if the same connectivity pair already exists.
	 * A connectivity pair exists if there are two links that look like the following:
	 * A -> B -> C
	 * A -> D -> C
	 * B and D are both connected to A and C and therefore have the same to and from connectivity
	 *
	 * These connectivity pairs are checked by traversing two links forward, or two links back and checking for
	 * a matching connectivity to one of the links currently being connected to. Traversing forward will check
	 * if there is a connectivity pair when connecting A -> B or A -> D. Traversing back will check
	 * when connecting B -> C or D -> C
	 *
	 * @param startNodeLink: The link containing the start node of the connectivity (not the first link in the path)
	 * @param endNodeLink: The link containing the end node of the connectivity (not the final link in the path)
	 * @param lookup: A reference to the map lookup table
	 * @param action: Add or Edit
	 * @returns true if the two links hava a connectivity pair
	 */
	public static hasConnectivityPair(
		startNodeLink: LinkEntity,
		endNodeLink: LinkEntity,
		lookup: MapStore,
		action: string,
	): boolean {
		let startLinkConnectivityPair = true;
		let endLinkConnectivityPair = true;

		if (action === 'add') {
			startLinkConnectivityPair = endNodeLink?.linkTos
				.some(x => lookup.getEntity(x.linkToId, LinkEntity)?.linkTos
					.some(y => lookup.getEntity(y.linkToId, LinkEntity)?.linkFroms
						.some(z => z.linkFromId === startNodeLink.id)));
	
			endLinkConnectivityPair = startNodeLink?.linkFroms
				.some(x => lookup.getEntity(x.linkFromId, LinkEntity)?.linkFroms
					.some(y => lookup.getEntity(y.linkFromId, LinkEntity)?.linkTos
						.some(z => z.linkToId === endNodeLink.id)));
		} else {
			startLinkConnectivityPair = endNodeLink?.linkTos
				.some(x => lookup.getEntity(x.linkToId, LinkEntity)?.linkTos
					.some(y => lookup.getEntity(y.linkToId, LinkEntity)?.linkFroms
						.some(z => z.linkFromId !== startNodeLink.id && lookup.getEntity(z.linkFromId, LinkEntity)?.linkFroms
							.some(w => w.linkFromId === endNodeLink.id))));

			endLinkConnectivityPair = startNodeLink?.linkFroms
				.some(x => lookup.getEntity(x.linkFromId, LinkEntity)?.linkFroms
					.some(y => lookup.getEntity(y.linkFromId, LinkEntity)?.linkTos
						.some(z => z.linkToId !== endNodeLink.id && lookup.getEntity(z.linkToId, LinkEntity)?.linkTos
							.some(w => w.linkToId === startNodeLink.id))));
		}

		return startLinkConnectivityPair || endLinkConnectivityPair;
	}

	// Checks if a link LinkN exists where startLink is connected to endlink by linkN (i.e. startLink -> linkN -> endLink) or the reverse.
	public static isConnectivityPair(startLink: LinkEntity, endLink: LinkEntity, lookup: MapStore) {
		// console.log(`checkConnectivityPair: ${startLink.linkId} -> ${endLink.linkId}}`)
		const canTraverseFromStartLink = startLink.linkTos.some(x => {
			const nextLink = lookup.getLinkById(x.linkToId);
			return nextLink.linkTos.some(y => {
				return y.linkToId === endLink.id;
			});
		});
		
		if (canTraverseFromStartLink) {
			return true;
		}

		const canTraverseFromEndLink = endLink.linkFroms.some(x => {
			const prevLink = lookup.getLinkById(x.linkFromId);
			return prevLink.linkFroms.some(y => {
				return y.linkFromId === startLink.id
			});
		});
		
		if (canTraverseFromEndLink) {
			return true;
		}
		return false;
	}

	/**
	 *
	 * NOTE: It is expected the new link contains the to/from links
	 *
	 * @param startNodeLink
	 * @param newLink
	 * @param lookup
	 */
	public static newLinkHasConnectivityPair(
		newLink: LinkEntity,
		lookup: MapStore,
	): boolean {
		return newLink.linkFroms.some(({ linkFromId }) => newLink.linkTos
			.some(({ linkToId }) => {
				if (!linkFromId || !linkToId) {
					return false; // The link is not connected on either side
				}

				const nextLink = lookup.getEntity(linkToId, LinkEntity);
				const previousLink = lookup.getEntity(linkFromId, LinkEntity);

				if (!nextLink || !previousLink) {
					throw new Error('Link contains invalid connectivities');
				}

				// Check if the previousLink can be reached by traversing the next link's link from connectivities
				const checkByTraversingFromNext = nextLink.linkFroms
					.some(x => lookup.getEntity(x.linkFromId, LinkEntity)?.linkFroms
						.some(y => y.linkFromId === previousLink.id));

				const checkByTraversingFromPrevious = previousLink.linkTos
					.some(x => lookup.getEntity(x.linkToId, LinkEntity)?.linkTos
						.some(y => y.linkToId === nextLink.id));

				return checkByTraversingFromNext || checkByTraversingFromPrevious;
			}));
	}
}
