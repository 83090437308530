import { ImportVersionEntity, NodeEntity } from 'Models/Entities';
import L, { LatLng, LatLngBounds, LatLngExpression } from 'leaflet';
import { SmoothWheelZoom } from './Helpers/SmoothScroll';
import * as PIXI from 'pixi.js';
import 'leaflet-pixi-overlay';
import MapObject, { MapObjectType } from './MapObjects/MapObject';
import MapController from './MapController';
import { NodeGraphic, Path } from '../index';
import {
	isRealWorldCoordinates, leafletCoordinates,
	LeafletCoordinates,
	PixiCoordinates, realWorldCoordinates,
	RealWorldCoordinates,
} from './Helpers/Coordinates';
import { OBJECT_SCALE_THRESHOLD, RULER_SCALE_THRESHOLD } from 'Constants';
import { trc_caller, trc_disable } from './Helpers/MapUtils';
export const MINIMUM_ZOOM = -3;
export const MAXIMUM_ZOOM = 9;
const ZOOM_SNAP = 0.1;
const ZOOM_DELTA = 1;
const WHEEL_PIXELS_PER_ZOOM_LEVEL = 300;

const PIXI_PANE_NAME = 'pixi-pane';
const PIXI_PANE_ZINDEX = 410;

/**
 * Specify the zindex of the containers
 * DO NOT CHANGE THE ORDER OF THE CONTAINER_Z_INDEX
 * Select event loops through map object containers in the order of CONTAINER_Z_INDEX
 * Order should be: node -> link -> sublink -> area
 */
export const CONTAINER_Z_INDEX: { [key in MapObjectType]: number } = {
	speed: 160,
	segment: 150,
	beacon: 140,
	location: 130,
	node: 100,
	link: 90,
	bay: 120,
	sublink: 70,
	signal: 85,
	area: 110,
	driving_area: 10,
	driving_zone: 80,
	path: 20,
	ruler: 170,
	ahs_area: 11,
};

/**
 * Renderer class which handles initialising and rendering
 * all of the map objects including the background image
 */
export default class MapRenderer {
	private map: L.Map;

	private controller: MapController;

	// All the containers needed
	private pixiContainer: PIXI.Container;
	private containers: { [key: string]: PIXI.Container } = {};

	public pixiOverlay: any;
	// private interactionManager: PIXI.InteractionManager;
	public renderer: PIXI.Renderer;

	public mousePosition: RealWorldCoordinates;
	
	// These two variables are helpers only (for testing)
	public prevClickPos: RealWorldCoordinates = realWorldCoordinates(0, 0);
	public clickPos: RealWorldCoordinates = realWorldCoordinates(0, 0);

	private readonly version: ImportVersionEntity;

	private zoomLevel: number;
	private halfImageHeight: number;
	private halfImageWidth: number;
	private center: LatLngExpression;
	private imageBounds: LatLngBounds;
	private mapInitViewBounds: L.LatLngBounds;
	private mapAHSAreaBounds: L.LatLngBounds;
	private halfMapHeight: number;
	private halfMapWidth: number;
	private mapOffsetX: number;
	private mapOffsetY: number;

	// related to dynamic scaling (currently only connectivity endpoints)
	public pixiZoom: number = 0;
	public pixiScale: number = 0;
	public pixiCurrentAppliedZoom: number | undefined;

	// List of objects that need rerendering
	private objectsToRender: string[] = [];
	private renderObjects: Map<string, MapObject<unknown>> = new Map<string, MapObject<unknown>>();

	private backgroundImageOverlay: L.ImageOverlay;

	private trc = trc_disable;

	constructor(version: ImportVersionEntity, controller: MapController) {
		this.version = version;
		this.controller = controller;
	}

	/**
	 * Get leaflet map
	 */
	public getMap() {
		return this.map;
	}

	/**
	 * Image bounds are the actual bounds of the map
	 */
	public getImageBounds() {
		return this.imageBounds;
	}

	/**
	 * Get pixi root container.
	 * All map object pixi containers are children of this root container.
	 */
	public getRootContainer() {
		return this.pixiContainer;
	}

	/**
	 * Gets specific map object container
	 * @param type map object type (node, sublink, link, area, drivingzone)
	 */
	public getContainer(type: string) {
		return this.containers[type];
	}

	/**
	 * Perform hittest on specified contains @ coords
	 * @param pixiCoords 
	 * @param container 
	 * @returns 
	 */
	public hitTest(pixiCoords: PixiCoordinates, container: PIXI.Container): PIXI.DisplayObject | undefined  {
		const { name } = container; 
		if (!name) {
			console.log('Container name not found!');
			return undefined;
		}
		const { x, y } = this.getRootContainer().toGlobal(pixiCoords);
		return new PIXI.EventBoundary(container).hitTest(x, y);
	}


	/**
	 * Get pixi renderer
	 */
	public getRenderer(): PIXI.Renderer {
		return this.renderer;
	}

	public getController(): MapController {
		return this.controller;
	}

	/**
	 * Get pixi map object by its GUID
	 * @param id map object GUID
	 */
	public getObjectById(id: string): MapObject<unknown> {
		return this.renderObjects[id];
	}

	/**
	 * Add mapObject to be rendered on next call to render
	 * @param id
	 */
	public markObjectToRerender(id: string) {
		this.objectsToRender.push(id);
	}

	/**
	 * Make specific pixi container invisible and re render.
	 * This method is currently only used in selenium tests.
	 * @param type map object type
	 * @param visible should the container be visible
	 */
	public setContainerDisplay(type: string, visible: boolean) {
		// Hide all labels for container
		Object.values(this.renderObjects).forEach((x: MapObject<any>) => {
			if (x.getType() === type) {
				x.displayGraphic(visible);
			}
		});

		this.rerender();
	}

	/**
	 * Make specific pixi container invisible and re render.
	 * This method is currently only used in selenium tests.
	 * @param type map object type
	 */
	public hideContainer(type: string) {
		this.getContainer(type).visible = false;
		this.render(false);
	}

	public setAhsMapDisplay(visible: boolean) {
		Object.values(this.renderObjects).forEach((x: MapObject<any>) => {
			x.displayGraphic(visible);
		});

		this.rerender();
	}

	/**
	 * Get map bounds offset values
	 */
	public getMapOffset() {
		return {
			offsetX: this.mapOffsetX,
			offsetY: this.mapOffsetY,
		};
	}

	public getHighlightedMapObjectId() {
		return this.getController().getHighlightedMapObject()?.getId();
	}

	/**
	 * Add mapObject to be rendered (including children)
	 * Adds all associated graphics to Pixi container
	 * @param mapObject
	 * @param [addToRerender]
	 * @returns object
	 */
	public addObject(mapObject: MapObject<unknown>, addToRerender?: boolean): string {
		const toAdd = addToRerender !== false;

		// if (toAdd) {
		// If statement commented out to avoid issues with selection of path entities
		// Right now this is a necessary tradeoff to avoid other issues
		this.renderObjects[mapObject.getId()] = mapObject;
		// }

		mapObject.getChildren().forEach(child => this.addObject(child, false));

		// Add the graphics to this container
		mapObject.addToContainer();

		if (toAdd) {
			this.markObjectToRerender(mapObject.getId());
		}

		return mapObject.getId();
	}

	/**
	 * Remove mapObject from all containers and from the list of objects to render.
	 * @param id
	 */
	public removeObject(id: string) {
		const mapObject = this.renderObjects[id];
		if (mapObject === undefined) {
			return;
		}

		mapObject.removeFromContainer();

		mapObject.getChildren().forEach((child: MapObject<any>) => this.removeObject(child.getId()));

		delete this.renderObjects[mapObject.getId()];
	}

	/**
	 * Generates projected coordinates from latitutde and longitude
	 * @returns
	 */
	public project(coords: RealWorldCoordinates | LeafletCoordinates): PixiCoordinates {
		const { lat, lng } = isRealWorldCoordinates(coords)
			? this.getLeafletCoords(coords as RealWorldCoordinates)
			: coords as LeafletCoordinates;

		// Latitude is northing (y) and longitude is easting (x)
		return this.map.project([lat, lng], // Takes leaflet coordinates
			(this.map.getMaxZoom() + this.map.getMinZoom()) / 2);
	}

	/**
	 * Unprojects pixel coordinates to leaflet coords
	 *
	 * @param x
	 * @param y
	 * @returns
	 */
	public unproject({ x, y }: PixiCoordinates): LeafletCoordinates {
		return this.map.unproject([x, y],
			(this.map.getMaxZoom() + this.map.getMinZoom()) / 2);
	}

	/**
	 * Get leaflet map coordinates from the real map object position
	 * @param coordinates
	 */
	public getLeafletCoords(coordinates: RealWorldCoordinates): LeafletCoordinates {
		return {
			lat: coordinates.northing - this.mapOffsetY,
			lng: coordinates.easting - this.mapOffsetX,
		};
	}

	/**
	 * Get real world coordinates from mouse event latitude longtitude position
	 * @param coordinates
	 */
	public getRealWorldCoords(coordinates: LeafletCoordinates): RealWorldCoordinates {
		return {
			northing: coordinates.lat + this.mapOffsetY,
			easting: coordinates.lng + this.mapOffsetX,
		};
	}

	public isPointInMapBounds(coordinates: RealWorldCoordinates): boolean {
		const { maptoolparam } = this.version;

		if (!maptoolparam) {
			return false;
		}

		const { northing, easting } = coordinates;
		const northWest = realWorldCoordinates(maptoolparam.ahsAreaStartingPointY + maptoolparam.ahsAreaLengthMax,
												maptoolparam.ahsAreaStartingPointX);
		const southEast = realWorldCoordinates(maptoolparam.ahsAreaStartingPointY,
												maptoolparam.ahsAreaStartingPointX + maptoolparam.ahsAreaWidthMax);

		return northing <= northWest.northing && northing >= southEast.northing
			&& easting >= northWest.easting && easting <= southEast.easting;
	}

	/**
	 * Calls all initialisation methods associated with rendering of map
	 * but does not begin actual Pixi rendering
	 */
	public initRenderer() {
		console.time('initRender');
		this.calculateMapConstants();
		this.initLeaflet();
		this.renderer = PIXI.autoDetectRenderer({
			antialias: true,
			autoDensity: true,
			powerPreference: 'high-performance',
			hello: true, // show pixi welcome msg in console (incl version)
		}) as PIXI.Renderer;
		// Prepare to use pixi
		this.createPixiContainers();
		console.timeEnd('initRender');
	}

	/**
	 * Calculate initial dimensions and bounds of map based on
	 * information in the ImportEntityVersion
	 */
	private calculateMapConstants() {
		const { backgroundImage, maptoolparam } = this.version;
		if (backgroundImage == null) {
			console.error('The background image entity is null');
		}

		if (maptoolparam == null) {
			console.error('The maptoolparam entity is null');
		}

		this.mapOffsetX = backgroundImage?.xCoordinateNW ?? 0;
		this.mapOffsetY = backgroundImage?.yCoordinateNW ?? 0;
		const x1 = 0;
		const y1 = 0;
		const x2 = (backgroundImage?.xCoordinateSE ?? 0) - this.mapOffsetX;
		const y2 = (backgroundImage?.yCoordinateSE ?? 0) - this.mapOffsetY;

		// half size of the bg image
		this.halfImageWidth = (x2 - x1) / 2;
		this.halfImageHeight = (y2 - y1) / 2;

		// center of image
		this.center = [(y1 + y2) / 2, (x1 + x2) / 2];
		this.imageBounds = new LatLngBounds([[y1, x1], [y2, x2]]);

		// half size of the AHS area
		this.halfMapHeight = (maptoolparam?.ahsAreaLengthMax ?? 0) / 2;
		this.halfMapWidth = (maptoolparam?.ahsAreaWidthMax ?? 0) / 2;
	}

	/**
	 * Calculates map bounds based on current zoom level
	 * This alleviates navigational difficulties where a
	 * user may pan too far outside of actual map bounds
	 * TODO: after set AHS area to setMaxBounds, we may not need this method.
	 */
	public calculateMapBounds() {
		const zoomOffset = this.map.getZoom() - this.zoomLevel;

		// For each zoom level past the minimum reduce the lat/long extension by a power of 2
		// This ensures extra panning space is consistent at each zoom level
		const reduceFactor = 2 ** zoomOffset; // TODO: this needs tweaking
		if (this.map) {
			const nw = this.mapInitViewBounds.getNorthWest();
			const se = this.mapInitViewBounds.getSouthEast();
			const newBounds = new LatLngBounds([
				[nw.lat - this.halfImageHeight / reduceFactor, nw.lng - this.halfImageWidth / reduceFactor],
				[se.lat + this.halfImageHeight / reduceFactor, se.lng + this.halfImageWidth / reduceFactor],
			]);

			this.map.setMaxBounds(newBounds);
		}
	}

	/**
	 * Initialise and mount the leaflet map
	 */
	private initLeaflet() {
		this.trc('start initLeaflet');
		console.time('initLeaflet');
		// Add smooth scrolling
		L.Map.addInitHook('addHandler', 'smoothWheelZoom', SmoothWheelZoom);

		this.map = L.map('leaflet-container', {
			center: this.center,
			minZoom: MINIMUM_ZOOM,
			maxZoom: MAXIMUM_ZOOM,
			zoomSnap: ZOOM_SNAP,
			zoomDelta: ZOOM_DELTA,

			// @ts-ignore ignore custom added attribute
			smoothWheelZoom: true,
			scrollWheelZoom: false, // Make sure to disable the default scroll
			doubleClickZoom: false,
			wheelPxPerZoomLevel: WHEEL_PIXELS_PER_ZOOM_LEVEL,
			zoomControl: false,
			boxZoom: false,

			attributionControl: false,

			preferCanvas: true,
			crs: L.CRS.Simple,
		});

		// Required for selenium tests to expose leaflet
		document['leaflet'] = this.map;
		document['mapRenderer'] = this;

		this.map.dragging.disable();

		// Set a map view that contains the given geographical bounds
		// Map view should be set to image bounds for a user to see the image when the map is open
		this.map.fitBounds(this.imageBounds);
		this.mapInitViewBounds = this.map.getBounds();

		// Set map bound to AHS params
		const { maptoolparam } = this.version;
		const x1 = (maptoolparam?.ahsAreaStartingPointX ?? 0) - this.mapOffsetX;
		const y1 = (maptoolparam?.ahsAreaStartingPointY ?? 0) - this.mapOffsetY + (maptoolparam?.ahsAreaLengthMax ?? 0);
		const x2 = (maptoolparam?.ahsAreaStartingPointX ?? 0) - this.mapOffsetX + (maptoolparam?.ahsAreaWidthMax ?? 0);
		const y2 = (maptoolparam?.ahsAreaStartingPointY ?? 0) - this.mapOffsetY;

		this.mapAHSAreaBounds = new LatLngBounds([
			[y1 + this.halfMapHeight, x1 - this.halfMapWidth],
			[y2 - this.halfMapHeight, x2 + this.halfMapWidth]]);
		this.map.setMaxBounds(this.mapAHSAreaBounds);

		// Zoom is rounded as round number needed for the menu zoom
		const zoom = this.getMap().getZoom();
		const roundedZoom = Math.round(zoom);
		console.log(`Original zoom (${zoom}) rounded to ${roundedZoom}`);
		this.getMap().setZoom(roundedZoom);
		console.timeEnd('initLeaflet');
		// for debugging
		//this.getMap().setZoom(4);
		//this.getMap().panTo(this.getLeafletCoords({ easting: 225282.43, northing: 6615912.37 }));
	}

	/**
	 * Mount Pixi and start rendering
	 * At this point all data must already be initialised
	 */
	public mountPixiAndStartRendering() {
		this.trc('start mountPixiAndStartRendering');
		console.time('mountPixiAndStartRendering');
		// this.renderer = PIXI.autoDetectRenderer({
		// 	antialias: true,
		// 	autoDensity: true,
		// 	powerPreference: 'high-performance',
		// 	hello: true,
		// }) as PIXI.Renderer;
		// this.interactionManager = new PIXI.InteractionManager(this.renderer, {
		// 	autoPreventDefault: true,
		// });

		// Set up a pane for this container
		this.map.createPane(PIXI_PANE_NAME);
		const pane = this.map.getPane(PIXI_PANE_NAME);
		if (pane) {
			pane.style.zIndex = PIXI_PANE_ZINDEX.toString();
		}

		let firstDraw = true;

		// Set up the the overlay
		this.pixiOverlay = (L as any).pixiOverlay((utils: any) => {
			const container = utils.getContainer();
			this.renderer = utils.getRenderer();

			this.pixiScale = utils.getScale();
			this.pixiZoom = utils.getMap().getZoom();

			// Render the graphics (this will run every event that happens)
			// There will need to be a requestAnimationFrame if we want to add any animations
			if (firstDraw) {
				this.initialRender();
				firstDraw = false;
			} else {
				// For objects that require fixed size regardless of zoom level
				this.updateDynamicScaleObjects();

				this.render(false);
			}
		}, this.pixiContainer, { pane: PIXI_PANE_NAME });
		
		// Add the pixi overlay to the map
		this.pixiOverlay.addTo(this.map);
		console.timeEnd('mountPixiAndStartRendering');
		//globalThis['__PIXI_APP__'] = this.pixiOverlay;
	}

	public updateMapLabels() {
		const isShow = this.getController().getMapLookup().getLabelViewStatus().allLabels;
		// TODO: in future tickets, the classlist will represent subsets of labels to show/hide
		const classList = ".leaflet-tooltip";
		document.querySelectorAll(classList).forEach(el => {
			if (el instanceof HTMLElement) {
				el.hidden = !isShow;
			}
		});
	}

	/**
	 * Whether or not zoom level of container is at threshold at which dynamic adjustment of
	 * connectivity endpoint nodes is required. Do not update if it's applying same zoom that
	 * was previously applied
	 *  
	 * @returns true when dynamic scale adjustment is necessary
	 */
	public isUpdateDynamicScaleObjects(_scaleThreshold?: number) { // TODO: rename and ensure it works for general cases
		const scaleThreshold = _scaleThreshold?? OBJECT_SCALE_THRESHOLD;
		return (this.pixiZoom < scaleThreshold) && (!!this.pixiCurrentAppliedZoom || (this.pixiCurrentAppliedZoom !== this.pixiZoom));
	}

	/**
	 * Dynamiclly adjust scale for objects that do not change with container
	 * Supported nodegraphic and ruler only - if other objects are needed update this method
	 * 
	 */
	public updateDynamicScaleObjects() {
		const lookup = this.getController().getMapLookup();
		let scaleParams = this.getScaleParams(OBJECT_SCALE_THRESHOLD);
		let objIds = lookup.getDynamicScaleObjects();
		let prevType: string | undefined;

		let updatedObjIds: string[] = [];
		objIds.forEach(id => {
			const obj = this.getObjectById(id);
			if (!obj) {
				return;
			}

			const currentType = obj.getType();

			if (!prevType || prevType !== currentType) {
				switch(currentType) {
					case "node":
					case "link":
						scaleParams = this.getScaleParams(OBJECT_SCALE_THRESHOLD);
						break;
					case "ruler":
						scaleParams = this.getScaleParams(RULER_SCALE_THRESHOLD);
						break;
					default:
						scaleParams = this.getScaleParams(OBJECT_SCALE_THRESHOLD);
						break;
				}
			}
			
			if (!!obj) {
				// Graphics objects may have changed, so update array accordingly
				updatedObjIds.push(id);
				if (scaleParams.isUpdate) {
					obj.updateScale();
				} else if (scaleParams.isReset) {
					obj.resetScale();
				}
			}

			prevType = currentType;
		});
		lookup.setDynamicScaleObjects(updatedObjIds);
	}

	private getScaleParams(_scaleThreshold?: number) {
		const scaleThreshold = _scaleThreshold ?? OBJECT_SCALE_THRESHOLD;
		const scaleParams = {
			isUpdate: this.isUpdateDynamicScaleObjects(scaleThreshold),
			isReset: false,
		}

		const prevZoom = this.pixiCurrentAppliedZoom;
		if (!scaleParams.isUpdate && !!prevZoom) {
			if (prevZoom < scaleThreshold && (prevZoom !== this.pixiZoom)) {
				// Reset scale when transitioning above threshold
				scaleParams.isReset = true;
			}
		}

		return scaleParams;
	}

	/**
	 * Creates pixi containers by Z index given by CONTAINER_Z_INDEX
	 */
	private createPixiContainers() {
		this.trc('start createPixiContainers');
		console.time('createPixiContainers');
		this.pixiContainer = new PIXI.Container();
		this.pixiContainer.sortableChildren = true;
		this.pixiContainer.interactive = true;
		this.pixiContainer.interactiveChildren = false;
		this.pixiContainer.hitArea = this.createHitArea();

		Object.entries(CONTAINER_Z_INDEX).forEach(([key, value]) => {
			const container = new PIXI.Container();
			container.name = key;
			container.zIndex = value;
			// container.eventMode = 'none';
			container.interactive = false;
			// makes graphics within container sortable by zIndex
			container.sortableChildren = true;

			if (key === 'driving_zone') {
				container.interactive = false;
				container.interactiveChildren = false;
				// container.eventMode = 'none';
			}
			if (key === 'speed') {
				container.interactive = false;
				container.interactiveChildren = false;
				// container.eventMode = 'none';
			}
			this.getRootContainer().addChild(container);

			this.containers[key] = container;
			// this.eventBoundaries[key] = new PIXI.EventBoundary(container);
		});
		console.timeEnd('createPixiContainers');
	}

	private createHitArea(): PIXI.Polygon {
		const nw = this.mapAHSAreaBounds.getNorthWest();
		const se = this.mapAHSAreaBounds.getSouthEast();

		const coords = [
			this.getRealWorldCoords(new LatLng(nw.lat, nw.lng,)),
			this.getRealWorldCoords(new LatLng(se.lat, nw.lng,)),
			this.getRealWorldCoords(new LatLng(se.lat, se.lng,)),
			this.getRealWorldCoords(new LatLng(nw.lat, se.lng,)),
		].map(x => this.project(x));

		return new PIXI.Polygon(coords);
	}

	public rerender() {
		this.render(false);
	}

	/**
	 * Initital render (pixi and leaflet), called by mountPixi
	 */
	private initialRender() {
		this.zoomLevel = this.map.getZoom();
		this.backgroundImageOverlay = L.imageOverlay(`/api/files/${this.version.backgroundImage?.compressedImageId}`, this.imageBounds)
										.addTo(this.map);

		this.render(true);
	}

	public setBackgroundImageOpacity(opacity: number) {
		this.backgroundImageOverlay.setOpacity(opacity);
	}

	private render(firstDraw: boolean) {
		const objsToDraw = firstDraw ? Object.keys(this.renderObjects) : this.objectsToRender;

		this.trc(`firstDraw: ${firstDraw} objCount: ${objsToDraw.length}`);

		// Stop rendering objects that are outside the bounds viewable
		this.cullMapObjects();

		objsToDraw.forEach(objId => {
			const o = this.getObjectById(objId);
			// this check is for debugging
			if (!!o) {
				this.getObjectById(objId).render();
			} else {
				console.log(`Render object: ${objId} not found`)
			}
		});

		// Uncomment logging to debug low frame rate
		// console.time('render');
		// Render the root container
		this.renderer.render(this.getRootContainer());
		//console.timeEnd('render');
		this.objectsToRender = [];
	}

	public cullMapObjects() {
		// Calculate the ranges of the screen
		const mapInitViewBounds = this.map.getBounds();

		const padding = 0.2;
		const mapHeightPadding = Math.abs(mapInitViewBounds.getNorth() - mapInitViewBounds.getSouth()) * padding;
		const mapWidthPadding = Math.abs(mapInitViewBounds.getEast() - mapInitViewBounds.getWest()) * padding;

		const eastBounds = mapInitViewBounds.getEast() + mapWidthPadding;
		const westBounds = mapInitViewBounds.getWest() - mapWidthPadding;

		const northBounds = mapInitViewBounds.getNorth() + mapHeightPadding;
		const southBounds = mapInitViewBounds.getSouth() - mapHeightPadding;

		const northwest = this.project(leafletCoordinates(northBounds, westBounds));
		const southeast = this.project(leafletCoordinates(southBounds, eastBounds));

		const checkNode = (obj: MapObject<any>) => {
			const node = obj as NodeGraphic;

			const nodeEntity = node.getEntity();
			const { x, y } = this.project(nodeEntity);

			if (node.shouldAlwaysRender() || (y >= northwest.y && y <= southeast.y && x >= northwest.x && x <= southeast.x)) {
				node.setCulled(false);
			} else {
				node.setCulled(true);
			}
		};

		// Check each rendered object
		// NOTE: For now just check all nodes because they are the simplest to check, and also have the largest
		// performance hit
		Object.values(this.renderObjects).forEach((obj: MapObject<any>) => {
			// Cull && its children
			if (obj.getType() === 'node') {
				checkNode(obj);
			} else if (obj.getType() === 'path') {
				const path = obj as Path;
				path.getChildren().forEach(child => {
					if (child.getType() === 'node') {
						checkNode(child);
					}
				});
			}
		});
	}

	/**
	 * Unmounts leaflet map. Resets leaflet events and DOM element
	 */
	public deInitLeaflet() {
		this.renderer.destroy();

		this.map.off();
		this.map.remove();
		const leafletContainer = L.DomUtil.get('leaflet-container');
		if (leafletContainer) {
			// eslint-disable-next-line dot-notation
			leafletContainer['_leaflet_id'] = null;
		}
	}
}
