import MapStateHandler from './MapStateHandler';
import {
	AreaEntity,
	NodeEntity,
} from '../../../../Models/Entities';
import { LatLng, LeafletMouseEvent } from 'leaflet';
import {
	LeafletCoordinates, PixiCoordinates, realWorldCoordinates, RealWorldCoordinates,
} from '../Helpers/Coordinates';
import {
	action,
	autorun, IReactionDisposer, observable, runInAction,
} from 'mobx';
import Area, { AREA_LINE_WIDTH } from '../MapObjects/Area/Area';
import alertToast from '../../../../Util/ToastifyUtils';

import AreaValidator, {
	AREA_ERROR_MAXIMUM_NODES,
	AREA_ERROR_MINIMUM_NODES_DELETE,
	AREA_ERROR_ORIGINAL_DATA,
	MAXIMUM_AREA_NODES,
} from '../MapValidators/AreaValidator';

import * as PIXI from 'pixi.js';
import MapController from '../MapController';
import NodeGraphic from '../MapObjects/Node/NodeGraphic';
import { Polygon } from 'pixi.js';
import { setCustomTag } from '../Helpers/MapUtils';
import {store} from "../../../../Models/Store";
import {isSameShape} from "../Helpers/GeoJSON";
import CreateAreaCommand from "../../ChangeTracker/ChangeTypes/CreateAreaCommand";
import UpdateAreaCommand from "../../ChangeTracker/ChangeTypes/UpdateAreaCommand";
import DeleteAreaCommand from "../../ChangeTracker/ChangeTypes/DeleteAreaCommand";

interface IAreaEditHandler {
	mapObject?: Area;
	isEditMode?: boolean;
}

// Used to store info about edges (e.g. for the purpose of hit tests on each individual edge)
interface IEdgeData {
	polygon: PIXI.Polygon;
	index: number;
}

export enum areaMode {
	SELECTED,
	EDIT,
	AREA_NODE,
}

export interface IAreaNodeLocation {
	northing: number | undefined;
	easting: number | undefined;
}

export default class AreaEditHandler extends MapStateHandler {
	private isNewArea: boolean;
	private isAreaConfirmed = false;
	private areaEntity: AreaEntity;
	private areaMapObject: Area;

	private autorunDisposers: IReactionDisposer[] = [];

	private _mode: areaMode;

	private areaValidator: AreaValidator;

	@observable
	private isConfirmable: boolean = false;

	@observable
	private isValid: boolean = false;

	@observable
	private areaNodeLocation: IAreaNodeLocation = { northing: undefined, easting: undefined };

	private selectedNode: {Coords: PixiCoordinates | undefined, index: number};

	private originalSelectedPoint: PixiCoordinates;

	private isDeleted: boolean = false;

	// array of each edge (for edge hittest)
	private edgeData: IEdgeData[];

	private originalAreaNodes: PIXI.Point[];

	// @ts-ignore
	onInit({ mapObject, isEditMode }: IAreaEditHandler) {
		if (!mapObject) {
			this.getEventHandler().setActiveTool('selector');
			return;
		}

		this.autorunDisposers.push(autorun(() => {
			this.getEventHandler().emit('toggleConfirmCreation', this.isConfirmable, !this.isValid);
		}));

		this.autorunDisposers.push(autorun(() => {
			this.getEventHandler().emit('onAreaStateChange', this.areaNodeLocation, this.isConfirmable);
		}));

		this.getEventHandler().addListener('onConfirmMapObjectCreation', this.onConfirmArea);

		this.getEventHandler().addListener('onPropertiesUpdate', this.onPropertiesUpdate);

		this.mode = areaMode.SELECTED;

		this.areaMapObject = mapObject;
		this.areaEntity = this.areaMapObject.getEntity();

		if (isEditMode) {
			this.mode = areaMode.EDIT;
			this.areaMapObject.enableEditMode();
			this.isNewArea = true;
			this.updateArea();
		}

		// check properties
		this.checkProperties();
		this.areaValidator = new AreaValidator(this.getController());
		this.generateEdgeData();
	}

	isAreaValid() {
		if (!this.getController().isActionConfirmAllowed()) {
			return false;
		}

		return this.isValid;
	}

	/**
	 * Generates hit for each edge
	 * IMPORTANT: must be called when any changes to edges are made (e.g. insert/delete node)
	 */
	generateEdgeData() {
		this.edgeData = [];
		const points = this.areaMapObject.getPoints();
		for (let i = 0; i < points.length - 1; i++) {
			const nextIndex = i + 1;
			const polygon = new PIXI.Polygon(this.areaMapObject.generateHitAreaFromLine([points[i],
				points[nextIndex]], AREA_LINE_WIDTH));
			this.edgeData.push({ polygon: polygon, index: i });
		}
	}

	checkProperties() {
		const { areaName } = this.areaEntity;
		// TODO: more validation
		runInAction(() => {
			this.isValid = areaName !== undefined && areaName.length > 0;
		});

		if (this.areaEntity.areaId === undefined || this.areaEntity.areaVersion === undefined) {
			runInAction(() => {
				this.areaEntity.areaId = 0;
				this.areaEntity.areaVersion = '0';
			});
		}
	}

	private set mode(newMode: areaMode) {
		console.log(`areaMode: ${areaMode[this.mode]} -> ${areaMode[newMode]}`);
		runInAction(() => {
			this.isConfirmable = newMode !== areaMode.SELECTED;
			if (newMode === areaMode.AREA_NODE) {
				if (this.areaMapObject.selectPointIndex > -1) {
					const renderer = this.getRenderer();
					const selectedPoint = this.areaMapObject.getSelectedPoint();
					if (selectedPoint) {
						this.areaNodeLocation = renderer.getRealWorldCoords(renderer.unproject(selectedPoint));
					} else {
						console.warn('No selected point found');
					}
				}
			} else {
				this.areaNodeLocation = { northing: undefined, easting: undefined };
			}
		});
		this._mode = newMode;
	}

	private get mode() {
		return this._mode;
	}

	getSelectedNode(m: PixiCoordinates): number {
		const points = this.areaMapObject.getPoints();
		return points.findIndex(p => {
			const distance = Math.sqrt((p.x - m.x) ** 2 + (p.y - m.y) ** 2);
			return distance <= Area.areaNodeRadius;
		});
	}

	/**
	 * On click event
	 * @param event
	 */
	onClick(event: LeafletMouseEvent) {
		if (this.mode === areaMode.SELECTED) {
			// In selected mode, allow selection of other objects on map as per usual
			const mapObject = this.getController().getMapObjectAtCoordinates(event.latlng);
			if (!mapObject || mapObject?.getId() !== this.areaMapObject.getId()) {
				this.getEventHandler().setMapEventState('selector', { mapObject });
			}
		} else if (this.mode === areaMode.EDIT || this.mode === areaMode.AREA_NODE) {
			const pixiCoords = this.getRenderer().project(event.latlng);
			this.areaMapObject.selectPointIndex = this.getSelectedNode(pixiCoords);
			let isNodeSelected = this.areaMapObject.selectPointIndex !== -1;
			if (isNodeSelected) {
				// Set the selected node to handle the delete functionality
				this.selectedNode = {
					Coords: this.areaMapObject.getSelectedPoint(),
					index: this.areaMapObject.selectPointIndex,
				};
			} else {
				// Node wasn't clicked. See if edge was clicked.
				const edgeData = this.getSelectedEdgeData(pixiCoords);
				if (!!edgeData) {
					// edge was clicked, insert area node
					isNodeSelected = this.insertNode(edgeData);
				}
			}
			this.mode = isNodeSelected ? areaMode.AREA_NODE : areaMode.EDIT;

			this.updateArea();
			this.updateCursor();
		} else {
			console.log('Unhandled click');
		}
	}

	/**
	 * Insert node at midpoint and regenerate edge data.
	 * Note that this method don't save updated edge data until confirm button is clicked
	 * @param edgeData
	 */
	insertNode(edgeData: IEdgeData): boolean {
		const points = this.areaMapObject.getPoints();
		const currentNumberOfNodes = points.length; // exclude closing node
		if (currentNumberOfNodes + 1 > MAXIMUM_AREA_NODES) {
			alertToast(AREA_ERROR_MAXIMUM_NODES, 'error');
			return false;
		}
		setCustomTag('map-interface', 'insert-an-area-node (edit mode)');
		this.areaMapObject.insertPoint(edgeData.index);
		// since the edges have been modified, edge data must be regenereated
		this.generateEdgeData();
		if (this.areaEntity.isImported) {
			this.areaEntity.state = 'MODIFIED';
			console.log('%c Inserting an area node: state change to Modified', 'background: #222; color: #bada55');
		}
		return true;
	}

	/**
	 * Delete unconfirmed bay when Delete or Backspace is pressed
	 * Confirm bay when Enter is pressed (same as double click)
	 * @param event
	 */
	onKeyPress(event: KeyboardEvent) {
		const eventHandler = this.getEventHandler();
		if (event.key === 'Enter' && this.isConfirmable && this.isAreaValid()) {
			this.onConfirmArea();
		}
		if (['Delete', 'Backspace'].includes(event.key)) {
			if (this.mode === areaMode.SELECTED) {
				const { areaType, isImported, id } = this.areaEntity;
				const isAreaAhs = areaType === 'AREAAHS';

				if (isImported && !isAreaAhs) {
					// For imported areas, only aoz (areaaahs) can be deleted
					alertToast(AREA_ERROR_ORIGINAL_DATA, 'error');
					return;
				}

				const tool = this.getController().getSelectedToolType();
				this.isDeleted = true;

				if (isImported && isAreaAhs) {
					console.log('AOZ area: state changed to Deleted');
					this.areaEntity.state = 'DELETED';
				}

				this.getController().getTracker1().addChange(new DeleteAreaCommand(this.areaEntity.id));

				setCustomTag('map-interface', 'delete-an-area');

				const newState = (tool === 'selector' || tool === undefined) ? 'selector' : 'area';
				eventHandler.setMapEventState(newState);
			}

			if (this.mode === areaMode.AREA_NODE) {
				this.handleDeleteAreaNode();
			}
		}
	}

	/**
	 * Handle delete operation for the area node
	 */
	handleDeleteAreaNode = () => {
		this.originalAreaNodes = this.areaMapObject.getCopyPoints();

		let totalAreaNodes = this.originalAreaNodes.length;
		if (!(this.selectedNode.index >= 0
			&& this.selectedNode.index < totalAreaNodes - 1)) {
			return;
		}

		/*
		* Minimum 4 nodes need to exist in the area
		* Start and end node is at the same location but
		* exist separately and hence counts
		* */
		const totalPointsCount = this.originalAreaNodes.length;
		if (totalPointsCount < 5) {
			alertToast(AREA_ERROR_MINIMUM_NODES_DELETE, 'error');
			return;
		}

		/*
		* Delete the node from area points
		* */
		const deletedAreaNode = this.areaMapObject.deleteDesiredPoint(this.areaMapObject.selectPointIndex);

		if (deletedAreaNode) {
			/*
			* Get the updated count
			* */
			totalAreaNodes = this.areaMapObject.getPoints().length;
			/*
			* Define params with handling special case of 0th index
			* */
			const calculatedStartIndex = (this.selectedNode.index === 0 || this.selectedNode.index === totalAreaNodes)
				? totalAreaNodes - 2
				: this.selectedNode.index - 1;

			// take into account case where endindex rolls around to node 0
			const calculatedEndIndex = this.selectedNode.index % (totalAreaNodes - 1);
			console.log(`${this.selectedNode.index} calculatedStartIndex: ${calculatedStartIndex} 
			calculatedEndIndex: ${calculatedEndIndex} totalAreaNodes ${totalAreaNodes}`);

			const deleteNodeParams = {
				startIndex: calculatedStartIndex,
				endIndex: calculatedEndIndex,
			};
			/*
			* Validate the delete area node operation
			* */
			const isDeleteAreaNodeValid = this.areaValidator
				.validateDeleteNode(this.areaMapObject, this.originalAreaNodes, deleteNodeParams);
			if (!isDeleteAreaNodeValid) {
				// set back to original array of points on validation failure
				this.areaMapObject.setPoints(this.originalAreaNodes);
				this.selectedNode = {
					Coords: this.areaMapObject.getSelectedPoint(),
					index: this.areaMapObject.selectPointIndex,
				};
			} else {
				/*
				* Set the mode back to area_edit to unselect
				* any area nodes selected after successful
				* deletion of the area node
				* */
				setCustomTag('map-interface', 'delete-an-area-node (edit mode)');
				this.areaMapObject.resetSelectPoint();
				this.mode = areaMode.EDIT;
				if (this.areaEntity.isImported) {
					console.log('%c Area node: state changed to Modified',
						'background: #222; color: #bada55');
					this.areaEntity.state = 'MODIFIED';
				}
			}
			this.generateEdgeData();
			this.updateArea();
			AreaValidator.checkDrivingZoneInterferenceForArea(this.areaEntity, this.getController());
		}
	};

	/**
	 * On properties update
	 * @param areNodeLocation
	 */
	onPropertiesUpdate = (areNodeLocation?: any) => {
		if (this.mode === areaMode.AREA_NODE && !!areNodeLocation) {
			const selectedPoint = this.areaMapObject.getSelectedPoint();
			if (selectedPoint) {
				// save original point
				this.originalSelectedPoint = { ...selectedPoint };
				// place updated point
				const location = this.areaNodeLocation as RealWorldCoordinates;
				const pixiCoords = this.getRenderer().project(location);
				this.areaMapObject.updateSelectedPoint(pixiCoords);
				this.updateArea();
				// validate
				const coords = this.getRenderer().getLeafletCoords(location) as LatLng;
				this.validateAreaNode(coords);
			}
		}
		this.checkProperties();
	}

	onConfirmArea = () => {
		if (this.mode === areaMode.EDIT || this.mode === areaMode.AREA_NODE) {
			// confirm and go back to select
			console.log('Confirming area');
			this.mode = areaMode.SELECTED;
			this.areaMapObject.disableEditMode();
			const originalPolygon = this.areaEntity.polygon;
			const isOriginalPolygonEmpty = originalPolygon != undefined && originalPolygon !== '';
			this.areaEntity.polygon = this.areaMapObject.getPointsAsGeoJSONString();
			const importVersion = this.getController().getImportVersion();

			const hasAreaChanged = isOriginalPolygonEmpty
				&& !isSameShape(this.areaEntity.polygon, originalPolygon);

			this.isAreaConfirmed = this.isNewArea || hasAreaChanged;
			this.updateArea();

			if (this.isNewArea) {
				// TODO: this should be handled by createEntity
				// Confirm new area
				// Add it to the layers panel
				this.getLookup().createEntity(this.areaEntity);
				this.getEventHandler().emit('onMapObjectCreateConfirm', this.areaEntity);
				// Go back to Creation
				this.getEventHandler().setMapEventState('area');
				this.areaEntity.state = 'NEW_OBJECT';

				// TODO: For Undo/Redo, update of areaEntity.polygon must happen each time points are updated and confirmed
				this.areaEntity.importVersionId = importVersion.id;
				this.areaEntity.crusherFlag = 0;

				setCustomTag('map-interface', 'create-an-area');

				this.getController().getTracker1().addChange(new CreateAreaCommand(this.areaEntity))

				AreaValidator.checkAreaErrors(this.getEventHandler().getController());
			} else {
				// Edit existing area if polygon is different
				if (hasAreaChanged) {
					const originalArea = new AreaEntity({ ...this.areaEntity });
					originalArea.polygon = originalPolygon;
					originalArea.bayss = this.getLookup().getBaysByAreaId(this.areaEntity.id)!; // HITMAT-1174					
					this.areaEntity.state = this.areaEntity.isImported ? 'MODIFIED' : 'NEW_OBJECT';
					runInAction(() => {
						this.areaEntity.areaCreator = store.email?.split('@')[0] ?? '';
					});
					if (this.areaEntity.isImported) {
						setCustomTag('map-interface', 'edit-a-new-area-shape');
					} else {
						setCustomTag('map-interface', 'edit-an-imported-area-shape');
					}

					this.getController().getTracker1().addChange(new UpdateAreaCommand(this.areaEntity))

					originalArea.bayss.map(b => this.getLookup().addBay(b)); // HITMAT-1174
				}
				// Reset isAreaConfirmed
				this.isAreaConfirmed = false;
			}
		} else {
			console.log('onConfirmArea: Ignored');
		}
	}

	onEscapePressed(event: KeyboardEvent) {
		if (this.isNewArea && !this.isAreaConfirmed) {
			this.getEventHandler().setMapEventState('area');
		} else {
			switch (this.mode) {
				case areaMode.EDIT:
					this.areaMapObject.setPointsFromEntity();
					this.areaMapObject.disableEditMode();
					this.updateArea();
					this.mode = areaMode.SELECTED;
					break;
				case areaMode.AREA_NODE:
					this.areaMapObject.resetSelectPoint();
					this.updateArea();
					this.mode = areaMode.EDIT;
					break;
				default:
					this.getEventHandler().setActiveTool('selector');
					break;
			}
			AreaValidator.checkDrivingZoneInterferenceForArea(this.areaMapObject.getEntity(), this.getController());
		}
	}

	dispose() {
		const areaMapObject = this.areaMapObject;
		const areaEntity = areaMapObject.getEntity();
		const mapController = this.getController();
		const eventHandler = this.getEventHandler();
		
		if(!this.isAreaConfirmed && this.isNewArea) {
			mapController.getMapLookup().deleteEntity(areaEntity);
		}
		runInAction(() => {
			this.isConfirmable = false;
		});
		this.autorunDisposers.forEach(disposerFn => disposerFn());

		const isAreaObjectExist = !!areaMapObject && !!mapController.getMapRenderer().getObjectById(areaMapObject.getId());

		if (!eventHandler.getToSelector()) {
			mapController.unhighlightObject();
		}

		if (isAreaObjectExist) {
			areaMapObject.disableEditMode();
			areaMapObject.setPointsFromEntity();
			this.updateArea();
			if ((this.isNewArea && !this.isAreaConfirmed) || this.isDeleted) {
				// TODO: when isDeleted is true, should be handled by deleteEntity
				mapController.removeMapObject(areaEntity, areaMapObject);
				this.getRenderer().rerender();
				AreaValidator.checkDrivingZonesAreasInterference(mapController);
			}
		} else {
			// TODO: this occurs due to undo redo. Consider tidying up logic
			console.log(`AreaEditHandler dispose: AreaMapObject ${areaMapObject.getId()} already removed`);
		}

		eventHandler.removeListener('onConfirmMapObjectCreation', this.onConfirmArea);
		eventHandler.removeListener('onPropertiesUpdate', this.onPropertiesUpdate);
		mapController.setDefaultCursor();
	}

	onRequestUpdate() {
		this.updateArea();
		this.getEventHandler().emit('onMapObjectUpdate', this.areaEntity);
	}

	updateArea() {
		runInAction(() => {
			this.areaEntity.perimeterCount = this.areaMapObject.getPoints().length - 1;
		});
		const renderer = this.getRenderer();
		renderer.markObjectToRerender(this.areaMapObject.getId());
		renderer.rerender();
		if (this.isAreaConfirmed) {
			this.getEventHandler().emitAreaEditedEvent();
		}
	}

	/**
	* Determine which type of drag operation to initiate (rotate or change location)
	* by doing hittest and store original heading/location values
	* @param event
	* @param originalCoordinates
	*/
	onDragStart(event: LeafletMouseEvent, originalCoordinates: LeafletCoordinates) {
		if (this.areaMapObject && (this.mode === areaMode.AREA_NODE || this.mode === areaMode.EDIT)) {
			const pixiCoords = this.getRenderer().project(originalCoordinates);
			this.areaMapObject.selectPointIndex = this.getSelectedNode(pixiCoords);
			const selectedPoint = this.areaMapObject.getSelectedPoint();
			if (selectedPoint) {
				if (this.mode === areaMode.EDIT) {
					this.mode = areaMode.AREA_NODE;
				}
				// Set the selected node to handle the delete functionality
				this.selectedNode = {
					Coords: this.areaMapObject.getSelectedPoint(),
					index: this.areaMapObject.selectPointIndex,
				};
				this.originalSelectedPoint = { ...selectedPoint };
			} else {
				console.error('There is no selected point!!');
			}
			this.updateArea();
		}
	}

	/**
	 * On drag end event
	 * @param event
	 */
	onDragEnd(event: L.LeafletMouseEvent): Promise<void> | void {
		if (!this.originalSelectedPoint) {
			console.log("Ignoring onDragEnd as there's no originalSelectedPoint");
			return;
		}
		if (this.validateAreaNode(event.latlng)) {
			// If node is shifted, update edge data
			this.generateEdgeData();
		}
	}

	/**
	 * Validate if new area node location is valid.
	 * On failure, revert the location
	 * @param coords
	 * @returns true if validation is successful
	 */
	private validateAreaNode(coords: LatLng) {
		const errorMessage = this.areaValidator.validateLocation(coords, this.areaMapObject,
			this.originalSelectedPoint);
		const isValidLocation = errorMessage === '';
		if (isValidLocation) {
			return true;
		}
		alertToast(errorMessage, 'error');
		this.revertAreaNode();
		return false;
	}

	/**
	 * Revert the area node
	 */
	revertAreaNode() {
		const renderer = this.getRenderer();
		const selectedPoint = this.areaMapObject.getSelectedPoint();
		if (selectedPoint) {
			this.areaMapObject.updateSelectedPoint(this.originalSelectedPoint);
			runInAction(() => {
				this.areaNodeLocation = renderer.getRealWorldCoords(renderer.unproject(this.originalSelectedPoint));
			});
			this.updateArea();
		}
	}

	/**
	* Dynamically update heading/location values as per current mouse coordinates
	* @param event
	*/
	onDragMove(event: LeafletMouseEvent) {
		const selectedPoint = this.areaMapObject.getSelectedPoint();
		if (selectedPoint) {
			const pixiCoords = this.getRenderer().project(event.latlng);
			this.areaMapObject.updateSelectedPoint(pixiCoords);
			const renderer = this.getRenderer();
			runInAction(() => {
				this.areaNodeLocation = renderer.getRealWorldCoords(event.latlng);
			});
			this.updateArea();
			AreaValidator.checkDrivingZoneInterferenceForArea(this.areaEntity, this.getController());
		}
	}

	/**
	 * Check if an edge is selected and return associated data
	 * 
	 * @param pixiCoords mouse coords of user action
	 * @returns if coords are on an edge, generate IEdgeData of selected edge (otherwise undefined)
	 */
	getSelectedEdgeData(pixiCoords: PixiCoordinates): IEdgeData | undefined {
		return this.edgeData.find(edgeData => edgeData.polygon.contains(pixiCoords.x, pixiCoords.y));
	}

	/**
	 * Change cursor depending on whether the user is hovering over
	 * area node, edge, or something else
	 * @param event
	 */
	onMove(event: LeafletMouseEvent) {
		this.updateCursor(this.getRenderer().project(event.latlng));
	}

	/**
	 * Update the cursor depending on current hover and state
	 * If coords are unknown just use current mouse position
	 * @param pixiCoords
	 */
	updateCursor(pixiCoords?: PixiCoordinates): void {
		const coords = pixiCoords ?? this.getRenderer().project(this.getRenderer().mousePosition);
		const controller = this.getController();
		let cursorType: string | undefined;
		if (this.mode === areaMode.EDIT || this.mode === areaMode.AREA_NODE) {
			if (this.getSelectedNode(coords) !== -1) {
				cursorType = 'move';
			} else if (!!this.getSelectedEdgeData(coords)) {
				cursorType = 'crosshair';
			}
		}
		return !!cursorType ? controller.setCursor(cursorType) : controller.setDefaultCursor();
	}

	/**
	 * Confirms changes to area
	 * @param event
	 */
	onDoubleClick(event: LeafletMouseEvent) {
		const mapObject = this.getController().getMapObjectAtCoordinates(event.latlng);

		const sameObject = mapObject && mapObject?.getId() === this.areaMapObject.getId();

		if (sameObject) {
			if (this.mode === areaMode.SELECTED) {
				// Editing the autonomous area shape is not allowed in JANUS MAT v1
				// area obstacle and loctype dump is a special case (can't edit such areas)
				if (this.areaEntity.areaType !== 'AREAAUTONOMOUS' && 
					!(this.areaEntity.areaType === 'AREAOBSTACLE' && this.areaEntity.locType === 'DUMP')) {
					this.mode = areaMode.EDIT;
					this.areaMapObject.enableEditMode();
					this.updateArea();
				}
			} else {
				console.log('Ignoring double click of selected object (already in Edit)');
			}
		} else {
			// eslint-disable-next-line no-lonely-if
			if (this.isConfirmable && this.isAreaValid()) { // implies mode !== areaMode.SELECTED
				this.onConfirmArea();
			} else {
				console.warn('Ignoring double click (not in confirmable state');
			}
		}
		this.updateCursor();
	}

	/**
	 * Returns the parking node in the current area
	 * @param areaEntity
	 * @param controller - controller to get the map renderer
	 */
	public static getAreaParkingNodes = (areaEntity: AreaEntity, controller: MapController): NodeEntity[] | undefined => {
		const lookup = controller.getMapLookup();
		const renderer = controller.getMapRenderer();

		const areaObjectId = lookup.getMapObjectId(areaEntity.id, 'area');
		const areaObject = renderer.getObjectById(areaObjectId) as Area;
		const polygon = new Polygon(areaObject.getPoints());

		return lookup.getAllEntities(NodeEntity)
			.filter(node => node.task === 'PARKING')
			.filter(node => {
				const nodeObjectId = lookup.getMapObjectId(node.id, 'node');
				if (!nodeObjectId) {
					return false;
				}

				const nodeObject = renderer.getObjectById(nodeObjectId) as NodeGraphic;
				const { x, y } = nodeObject.coordinates;

				return polygon.contains(x, y);
			});
	}
}
