/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
import MapStateHandler from './MapStateHandler';
import { LeafletMouseEvent } from 'leaflet';
import Ruler from '../MapObjects/Ruler/Ruler';
import { PixiCoordinates, RealWorldCoordinates } from '../Helpers/Coordinates';
import { calcDistanceBetweenCoords } from '../Helpers/MapUtils';
import DynamicScaleObjectHelper from '../MapStateHandlerHelpers/DynamicScaleObjectHelper';

export default class RulerToolHandler extends MapStateHandler {
	private rulerMapObject: Ruler | undefined;
	private isExitNormally: boolean = false;
	private realCoordsList: RealWorldCoordinates[] = [];
	private timeoutId: NodeJS.Timeout | undefined;

	onInit() {
		this.getController().setCursor('crosshair');
		// show in properties panel
		this.getEventHandler().emit('onPropertiesPanel', 'ruler', this.realCoordsList);
	}

	onMove(event: LeafletMouseEvent) {
	}

	onClick(event: LeafletMouseEvent) {
		// Don't fire a click event when double click
		if (this.timeoutId) {
			return;
		}

		// The double-click event does trigger the single click.
		// Case1: if there are one or more nodes, double-click will 1) place a new node and 2) exit the draw mode.
		// Case2: if there is no node, double-click will 1) place a new node but not exit the draw mode

		// 1) place a new node
		this._onClick(event);

		// To not show up text and background when double-clicking to exit draw mode, use setTimeout
		this.timeoutId = setTimeout(() => {
			if (!!this.rulerMapObject) {
				// Draw dynamic line, text and its background
				this.rulerMapObject.startLine();
				this.rulerMapObject.startTextAndBackground();
				this.getEventHandler().emit('onUpdateMeasuredLength', this.realCoordsList);
				this.timeoutId = undefined;
			}
		}, 70);
	}

	_onClick(event: LeafletMouseEvent) {
		const pixiCoords = this.getRenderer().project(event.latlng);
		const realCoords = this.getRenderer().getRealWorldCoords(event.latlng);

		if (this.rulerMapObject === undefined) {
			this.rulerMapObject = new Ruler([], this.getRenderer());
			console.log(`Added object ${this.rulerMapObject.getId()}`);
			this.getRenderer().addObject(this.rulerMapObject);
		}
		
		// The measurement shape will have its displayed adapted to the zoom level
		DynamicScaleObjectHelper.updateDynamicScaleObjects(true, [this.rulerMapObject.getId()], this.getController(), true);
		this.addNodeTextAndBackground(pixiCoords, realCoords);

		this.rulerMapObject.clearLine();
		this.rulerMapObject.clearTextAndBackground();
		this.updateRuler();
	}

	onDoubleClick(event: LeafletMouseEvent) {
		// 2) exit the draw mode (if there are one or more nodes)
		if (this.rulerMapObject !== undefined && this.rulerMapObject.getPoints().length > 1) {	
			if (this.timeoutId) {
				clearTimeout(this.timeoutId);
				this.timeoutId = undefined;
			}			

			this.getEventHandler().emit('onUpdateMeasuredLength', this.realCoordsList);
			this.exitDrawMode();
		}
	}

	addNodeTextAndBackground(pixiCoords: PixiCoordinates, realCoords: RealWorldCoordinates) {
		if (!!this.rulerMapObject) {
			// Add a node.
			this.rulerMapObject.addPoint(pixiCoords);
			this.realCoordsList.push(realCoords);
			// Add length text if there are two or more nodes
			if (this.realCoordsList.length > 1) {
				const previousNode = this.realCoordsList[this.realCoordsList.length - 2];
				const currentNode = realCoords;
				const distance = calcDistanceBetweenCoords(previousNode.easting, previousNode.northing, currentNode.easting, currentNode.northing);
				this.rulerMapObject.addLengthTextAndBackground(distance.toFixed(2));
			}
		}
	}

	deleteRulerNode() {
		if (!!this.rulerMapObject) {
			const points = this.rulerMapObject.getPoints();
			const numOfPoints = points.length;
			if (numOfPoints > 0) {
				const isLastPoint = numOfPoints === 1;
				this.rulerMapObject.clearLine();
				this.rulerMapObject.clearTextAndBackground();
				this.rulerMapObject.deletePreviousPoint();
				// Update Properties panel
				this.realCoordsList.pop();
				this.getEventHandler().emit('onUpdateMeasuredLength', this.realCoordsList);

				if (!isLastPoint) {
					this.rulerMapObject.removePreviousLengthTextAndBackground();
					this.rulerMapObject.startLine();
					this.rulerMapObject.startTextAndBackground();
				}
				this.updateRuler();
			}
		}
	}

	updateRuler() {
		if (!!this.rulerMapObject) {
			const renderer = this.getRenderer();
			renderer.markObjectToRerender(this.rulerMapObject.getId());
			renderer.rerender();
		}
	}

	/**
	 * Delete latest ruler node when Delete or Backspace is pressed
	 * @param event
	 */
	onKeyPress(event: KeyboardEvent) {
		if (['Delete', 'Backspace'].includes(event.key)) {
			this.deleteRulerNode();
		}
		if (event.key === 'Enter') {
			// Press enter when ruler object is not undefined and there are at least one line segment (more than one node)
			if (this.rulerMapObject !== undefined && this.rulerMapObject.getPoints().length > 1) {				
				this.exitDrawMode();
			}
		}
	}

	onEscapePressed(event: KeyboardEvent) {
		if (this.rulerMapObject !== undefined && this.rulerMapObject.getPoints().length > 1) {
			// Press escape when ruler object is not undefined and there are at least one line segment (more than one node)
			this.exitDrawMode();
		} else if (this.rulerMapObject !== undefined && this.rulerMapObject.getPoints().length === 1) {
			// Press escape when ruler object is not undefined and there is only one node
			// Delete the node and stay in this tool handler
			this.deleteRulerNode();
		} else {
			// Press escape when no ruler object is being drawn goes to selector tool
			this.getEventHandler().setActiveTool('selector');
		}
	}

	/**
	 * Exit measuring tool draw mode normally instead of clicking item in layers panel or other buttons
	 */
	exitDrawMode() {
		if (this.rulerMapObject) {
			this.isExitNormally = true;
			this.rulerMapObject.clearLine();
			this.rulerMapObject.clearTextAndBackground();
			this.getEventHandler().setMapEventState('edit_ruler', { mapObject: this.rulerMapObject });
		}
	}

	deleteRuler() {
		if (!!this.rulerMapObject) {
			DynamicScaleObjectHelper.updateDynamicScaleObjects(false, [this.rulerMapObject.getId()], this.getController(), true);
			this.rulerMapObject.clearLine();
			this.rulerMapObject.clearTextAndBackground();
			this.getRenderer().removeObject(this.rulerMapObject.getId());
			this.getRenderer().rerender();
		} else {
			console.log('No rulerMapObject. Something wrong.');
		}
	}

	dispose() {
		// Handle scenario when user clicks item in layers panel or other buttons
		if (!this.isExitNormally) {
			this.deleteRuler();
		}
		this.getController().setDefaultCursor();
	}

}