import {LeafletMouseEvent} from 'leaflet';
import {LeafletCoordinates} from '../Helpers/Coordinates';
import ClothoidStateHandler, {
	IClothoidRequestUpdate,
	IClothoidStateHandlerAttrs,
	LinkSegmentDirection,
	MAX_HAULING_WAYPOINTS,
	Waypoint
} from './ClothoidStateHandler';
import {Link, NodeGraphic, Path} from 'Views/MapComponents';
import {LinkEntity, NodeEntity} from 'Models/Entities';
import PathToolHelper, {HoverState} from '../MapStateHandlerHelpers/PathToolHelper';
import {action, runInAction} from 'mobx';
import {isCtrlKeyHeld} from './MapGlobalEventHandler';
import {calcHeading, setCustomTag} from '../Helpers/MapUtils';
import {IPathRequestResponse, pathRequestError} from '../MapStateHandlerHelpers/PathRequestHelper';
import {MAX_EDITABLE_MID_REVERSE_POINTS, MID_WAYPOINT_CHANGE_RESULT} from 'Constants';
import _, {cloneDeep} from "lodash";

export interface IClothoidEditHandler {
	linkEntity?: LinkEntity;
	attr?: IClothoidStateHandlerAttrs;
}

// isDrawMode = false
export default class ClothoidEditHandler extends ClothoidStateHandler {
	private isNew: boolean = false;
	private originalWaypoint: NodeEntity | undefined;
	private selectedWaypoint: NodeEntity | undefined;
	private isDragEnd: boolean = false;
	private onEnterDebounced = _.debounce(this.onEnter, 2000, { leading: true });


	/**
	 * init edit handler in one of two ways
	 * 1. Transition from DRAW mode -> continue with same set of attributes (init with attr only)
	 * 2. Transition from any other mode -> freshly init with existing link (init with linkEntity only)
	 * 
	 * @param _initialState 
	 */
	onInit(_initialState: unknown) {
		// console.log('onInit: Entered clothoid EDIT handler');
		const initialState = _initialState as IClothoidEditHandler;
		this.getEventHandler().addListener('onConfirmMapObjectCreation', this.onClickConfirmButton);
		this.getEventHandler().addListener('onActivateHCMPG', this.onActivateHCMPG);
		const attr = initialState.attr;
		let direction: string | undefined;
		if (!!attr) { // implies isNew
			this.isNew = true;
			if (!!initialState.linkEntity) {
				console.warn('Unexpected linkEntity param. Ignoring.');
			}
			// For transition from Create mode where state should be maintained (e.g. for snapping etc...)
			// restore the clothoidstatehandler attributes
			console.log(`onInit: Restoring attributes on EDIT handler`);
			this.assignClothoidAttributes(attr);
			if (!!attr.clothoidPathObjectId) {
				const path = this.getRenderer().getObjectById(attr.clothoidPathObjectId) as Path;
				if (!!path) {
					const tmpLinkEntity = path.getEntity();
					direction = PathToolHelper.getClothoidDirectionProperty(tmpLinkEntity);
					console.log(`NEW CLOTHOID DIRECTION ${direction}`);
				}
			}
		}

		this.initHelpers();

		// In EDIT mode, waypointCreation ALWAYS false
		this.waypointCreation = false;
		runInAction(() => {
			this.isDrawMode = false;
		});
		console.log(`onInit: Enter EDIT mode for ${this.isNew ? 'UNCONFIRMED' : 'CONFIRMED'} path`);
		if (!this.isNew) {
			if (!!initialState.linkEntity) {
				this.clothoidLinkEntity = cloneDeep(initialState.linkEntity);
				this.tempLinkFroms = initialState.linkEntity.linkFroms;
				this.tempLinkTos = initialState.linkEntity.linkTos;
				// initialise a confirmed link (for editing)
				this.initExistingLink(); // direction set here
			} else {
				throw Error('Edit existing link requires linkEntity param');
			}
		}
		this.isClothoidValid = true;
		this.initEvents(direction); // direction is set here for new links only
	}

	/* Used for initialisation of existing link with reverse waypoints */
	getStartWaypointDirection(nodes: NodeEntity[], firstNode: NodeEntity) {
		let startWaypointDirection: LinkSegmentDirection = "forward";
		if (firstNode.speed === 0) {
			// First node has special task
			let nextNode = firstNode.getNextNode();
			if (!nextNode) {
				if (nodes.length > 0) {
					console.warn('Next node not found. Using original nodes array');
					nextNode = nodes[1];
				}
			}
			if (!!nextNode) {
				startWaypointDirection = this.getDirectionFromSpeed(nextNode.speed)
			}
		} else {
			// first node does NOT have special task
			startWaypointDirection = this.getDirectionFromSpeed(firstNode.speed);
		}
		return startWaypointDirection;
	}

	/**
	 * The purpose is so the original entities are not deleted if editing is cancelled (revert back to original)
	 */
	assignWaypointEntityIds() {
		this.waypoints.forEach(waypoint => {
			waypoint.node.id = waypoint.node._clientId;
		});
	}

	/**
	 * Finds midwaypoints and reverse points and sets associated headings (required for heading handle)
	 * Used in initialisation
	 * 
	 * @param nodes 
	 * @param firstNode 
	 * @param startWaypointDirection 
	 * @returns array of midwaypoints and reverse points
	 */
	getAllMidWaypointsPointsAndSetHeadings(nodes: NodeEntity[] , firstNode: NodeEntity, startWaypointDirection: LinkSegmentDirection) {
		const waypoints: Waypoint[] = [];
		let reverseWaypointCount = 0;
		const middleNodes = nodes.slice(1, -1);
		let prevNode = firstNode;

		const setHeading = (prevNode: NodeEntity, targetNode: NodeEntity, isReverse: boolean) => {
			const heading = PathToolHelper.calcWaypointHeading(prevNode, targetNode);
			runInAction(() => {	
				targetNode.heading = isReverse ? this.calcReverseHeading(heading) : heading;
			});
		}

		if (middleNodes.length > 1) {
			let isReverse = !this.isForwardDirection(startWaypointDirection);; // calculated in an alternate manner for multiple consecutive reverse points
			middleNodes.forEach(n => {
				if (n.task === 'REVERSEPOINT') {
					if (n.isMidWaypoint) {
						console.warn(`Reversepoint should not be marked as midwaypoint!! node.id = ${n.id} This may cause unexpected results.`);
					}
					setHeading(prevNode, n, isReverse);
					reverseWaypointCount++; // do before push due to comparison
					waypoints.push({
						node: new NodeEntity({ ...n }),
						isReverse: true,
						isReadOnly: reverseWaypointCount > MAX_EDITABLE_MID_REVERSE_POINTS,
					});
					isReverse = !isReverse;
					// this.trc(`Reverse point at node ${n.nodeId} index ${i + 1}`);
				} else if (n.isMidWaypoint) {
					if (n.task === 'HAULING') {
						setHeading(prevNode, n, n.speed < 0);
						waypoints.push({
							node: new NodeEntity({ ...n }),
						});
					} else {
						console.error(`ismidwaypoint should only be HAULING task. Got ${n.task} (id ${n.id}). This may cause unexpected results.`);
					}
				}
				prevNode = n;
			});
		}
		return waypoints;
	}

	/** 
	 * Initialise waypoint data and send clothoid request
	 * 
	 * @param lastNode 
	 * @param startWaypoint 
	 * @param endWaypoint 
	 * @param midAndReversePoints 
	 * @param startWaypointDirection 
	 * @returns 
	 */
	initExistingLinkWithMidWaypoints(
		lastNode: NodeEntity,
		startWaypoint: NodeEntity,
		endWaypoint: NodeEntity,
		midAndReversePoints: Waypoint[],
		startWaypointDirection: LinkSegmentDirection
	) {
		const renderer = this.getRenderer();
		const lookup = this.getLookup();

		const link = this.clothoidLinkEntity;

		this.waypointCreation = false; // TODO: can be deleted later
		runInAction(() => {
			this.isDrawMode = false; // TODO: can be deleted later
			// start/end waypoint headings (other alread calculated)
			startWaypoint.heading = PathToolHelper.calcStartWaypointHeading(link, lookup) ?? 0;
			endWaypoint.heading = PathToolHelper.calcEndWaypointHeading(link, lookup) ?? 0;
			
		});

		// init waypoints using initialised data
		this.waypoints = [
			{ node: startWaypoint },
			...midAndReversePoints,
			{ node: endWaypoint }
		];

		// important. prevents modification of original entites until confirm
		this.assignWaypointEntityIds();

		if (!this.originalLinkId) {
			console.error('Unexpected originalLinkId not found');
			return false;
		}

		const linkMapObjectId = lookup.getMapObjectId(this.originalLinkId, 'link');
		const pathObject = this.getRenderer().getObjectById(linkMapObjectId).getParent();
		this.originalPathId = pathObject?.getId();
		this.trc(`Saving originalPathId ${this.originalPathId}`);
		pathObject?.displayGraphic(false);
		renderer.rerender();

		// Set defaults
		if (!this.endWaypoint.task) {
			this.endWaypoint.task = 'HAULING';
			this.trc("Setting default task to HAULING");
		}


		if (!this.endWaypoint.direction) {
			let endWaypointDirection: LinkSegmentDirection = "forward";
			if (lastNode.speed === 0) {
				this.trc(`End node speed is zero task ${lastNode.task}). Using prevNode to calculate direction`);
				const secondLastNode = lastNode.getPreviousNode();
				if (!!secondLastNode) {
					endWaypointDirection = secondLastNode.speed >= 0 ? 'forward' : 'reverse';
				} else {
					this.trc(`Previous node undefined. Setting to forward`, 'warn');
				}
			} else {
				endWaypointDirection = lastNode.speed >= 0 ? 'forward' : 'reverse';
			}

			runInAction(() => {
				if (!this.isForwardDirection(endWaypointDirection)) {
					this.endWaypoint.heading = this.calcReverseHeading(this.endWaypoint.heading);
				}
				if (!this.isForwardDirection(startWaypointDirection)) {
					this.startWaypoint.heading = this.calcReverseHeading(this.startWaypoint.heading);
				}
				this.endWaypoint.direction = endWaypointDirection;
			});
		}
		const direction = PathToolHelper.getClothoidDirectionProperty(this.clothoidLinkEntity);
		this.createNewLinkAndResetProperties(this.startWaypoint,
			this.endWaypoint,
			true,
			true,
			true,
			true,
			direction);
		// TODO: address async
		this.sendClothoidPathRequest();
		return true;
	}

	/**
	 * Init link with no midwaypoints/mid reverse points
	 * Does not send a clothoid requests - shows edit handles only
	 * 
	 * @param firstNode 
	 * @param lastNode 
	 * @param startWaypoint 
	 * @param endWaypoint 
	 * @returns 
	 */
	initExistingLinkNoMidWaypoints(firstNode: NodeEntity, lastNode: NodeEntity, startWaypoint: NodeEntity, endWaypoint: NodeEntity) {
		const lookup = this.getLookup();
		const renderer = this.getRenderer();
		const link = this.clothoidLinkEntity;
		if (!this.originalLinkId) {
			return false;
		}
		// Make sure nodes are shown initially when starting editing a link shape under Nodes hidden status
		const linkMapObjectId = lookup.getMapObjectId(this.originalLinkId, 'link');
		const pathObject = this.getRenderer().getObjectById(linkMapObjectId).getParent();
		pathObject?.displayGraphic(true);

		// lookup.orderSublinksAndNodesByPreviousIds(link);
		this.waypoints = [
			{
				node: startWaypoint,
			},
			{
				node: endWaypoint,
			},
		];

		this.assignWaypointEntityIds();

		let isReverse = this.endWaypoint.speed < 0; 
		if (this.endWaypoint.speed === 0) {
			this.trc(`Endwaypoint speed is zero. Special task of ${this.endWaypoint.task}. Getting previous node to determine direction`);
			const prevNode = this.endWaypoint.getPreviousNode();
			if (!!prevNode) {
				isReverse = prevNode.speed < 0;
				this.trc(`Found previous node with speed ${prevNode.speed}. isReverse = ${isReverse}`);
			} else {
				this.trc("Previous node not found. Setting direction to forward by default", 'warn');
			}
		}
		this.endWaypoint.direction = isReverse ? 'reverse' : 'forward';
		const heading1 = PathToolHelper.calcStartWaypointHeading(link, lookup) ?? 0;
		const heading2 = PathToolHelper.calcEndWaypointHeading(link, lookup) ?? 0;
		startWaypoint.heading = (isReverse && heading1 !== 0) ? this.calcReverseHeading(heading1) : heading1;
		endWaypoint.heading = (isReverse && heading2 !== 0) ? this.calcReverseHeading(heading2) : heading2;
		// Keep the original path but just hide it. If user cancels operation, it will be re-displayed (see handleClothoidCancel)

		this.trc('Hide original start/end waypoints');
		// Instead of hiding the path at this point, instead show the handles
		// If any change is detected, THEN hide the path and send a new request
		// Another way is to super-impose the new waypoints over the existing ones.
		lookup.getMapObjectByEntity(firstNode, 'node')?.displayGraphic(false);
		lookup.getMapObjectByEntity(lastNode, 'node')?.displayGraphic(false);

		this.startObj = new NodeGraphic(renderer, this.startWaypoint, { isSelected: true }, undefined);
		renderer.addObject(this.startObj, true);
		this.endObj = new NodeGraphic(renderer, this.endWaypoint, { isSelected: true }, undefined);
		renderer.addObject(this.endObj, true);
		renderer.rerender();

		// this.isDrawMode / this.waypointCreation should already be false  
		this.isDrawMode = false;
		if (this.waypointCreation) {
			this.trc("Setting waypointCreation = false");
			this.waypointCreation = false;
		}

		const useExistingTaskAndDirection = true;
		const setReadOnlyDirection = true;
		const setReadOnlyTask = true;
		const direction = PathToolHelper.getClothoidDirectionProperty(this.clothoidLinkEntity);
		console.log(`EXISTING CLOTHOID DIRECTION: ${direction}`);
		this.createNewLinkAndResetProperties(this.startWaypoint,
			this.endWaypoint,
			useExistingTaskAndDirection,
			setReadOnlyDirection,
			setReadOnlyTask,
			undefined,
			direction);
		return true;
	}

	/**
	 * Initialise edit mode for an existing link (as opposed to new link)
	 * The concept is to:
	 * Show the original path until the user actually performs an edit operation (e.g. change heading or node location).
	 *
	 * This is achieved by:
	 * 1) Upon entering edit mode, show the original link but hide the original start/end waypoint NodeGraphics.
	 * 2) Display placeholder graphics to replace the hidden NodeGraphics (serving the purpose of detecting whether the
	 * user initiated editing of the Clothoid shape)
	 * 3) When an edit occurs: 
	 * 		i) the original path is hidden
	 * 		ii) the placeholder NodeGraphics are removed
	 * 		iii) and a fresh clothoid path is generated
	 */
	initExistingLink() {
		// this only needs to happen for imported links, i think
		const link = this.clothoidLinkEntity;
		this.originalLinkId = link.id;

		if (!this.originalLinkId) {
			console.error('originalLinkId not found');
			return;
		}

		// START: code for edit link with reverse points
		const firstNode = link.firstNode();
		const lastNode = link.lastNode();

		if (!firstNode || !lastNode) {
			return;
		}

		const startWaypoint = new NodeEntity({ ...firstNode });
		const endWaypoint = new NodeEntity({ ...lastNode });

		// For initalisation, determine if there are reverse waypoints
		const nodes = this.clothoidLinkEntity.getNodes();
		const startWaypointDirection = this.getStartWaypointDirection(nodes, firstNode);

		// Find and initialise any midwaypoints
		const allMidAndReversePoints = this.getAllMidWaypointsPointsAndSetHeadings(nodes, firstNode, startWaypointDirection);
		const hasMidAndReversePoints = allMidAndReversePoints.length > 0; // does not include reversepoints

		// Initialisation is different depending on whether link contains mid/reverse points or not
		if (hasMidAndReversePoints) {
			// sends clothoid request with waypoint data (as per requirements)
			this.initExistingLinkWithMidWaypoints(lastNode, startWaypoint, endWaypoint, allMidAndReversePoints, startWaypointDirection);
		} else {
			// does not send clothoid request (as per requirements)
			this.initExistingLinkNoMidWaypoints(firstNode, lastNode, startWaypoint, endWaypoint);
		}
	}

	/**
	 * If it's an existing link, return to it's original state 
	 * 
	 * @returns 
	 */
	@action
	resetUneditedLink(): Link | undefined {
		let result: Link | undefined;
		if (!this.originalLinkId) {
			// will reach here when link already cancelled before dispose runs (expected)
			console.log('resetUneditedLink: Link already removed or does not exist');
			return result;
		}
		// Pressed escape or delete while editing existing like (tool = selector)
		const renderer = this.getRenderer();
		const linkMapObjectId = this.getLookup().getMapObjectId(this.originalLinkId, 'link');
		const linkObject = renderer.getObjectById(linkMapObjectId) as Link;
		const pathObject = linkObject.getParent();
		
		// display original path and remove temp objects
		pathObject?.displayGraphic(true);
		this.originalLinkId = undefined;
		if (!!this.startObj) {
			renderer.removeObject(this.startObj.getId());
			this.startObj = undefined;
		}
		if (!!this.endObj) {
			renderer.removeObject(this.endObj.getId());
			this.endObj = undefined;
		}
		renderer.rerender();
		return linkObject;
	}

	/**
	 * Case 1: from SELECTOR tool (edit existing path) - remove unconfirmed changes (display original) and switch to SELECTOR handler
	 * Case 2: from CLOTHOID tool (new path) - remove unconfirmed path and switch to CREATE handler
	 * @param event 
	 * @returns 
	 */
	async onEscapePressed(event: KeyboardEvent) {
		this.cancelEdit();
	}

	/**
	 * Cancel edit and transition to next state (e.g. Delete/Backspace/Escape)
	 */
	private cancelEdit() {
		const eventHandler = this.getEventHandler();
		this.restoreLinks();
		this.processTSDErrorsForCancel();
		// TODO:  check interaction with dispose
		this.getController().setDefaultCursor(); //TODO: check edge cases

		// Removing link here is more robust (even though this also happens in dispose)
		this.removeCurrentPathObj();
		// Remove confirm button
		eventHandler.emit('toggleConfirmCreation', false);

		const tool = this.getController().getSelectedToolType();
		// Determine correct state transition:
		if (tool === 'selector') {
			// Restore previously confirmed link
			this.resetUneditedLink();
			// Already got the correct tool, just change event state
			eventHandler.setMapEventState('selector');
		} else if (tool === 'clothoid') { // implies isNew
			// Nothing to restore (removed unconfirmed link and go back to create mode)
			eventHandler.setMapEventState('clothoid');
		} else {
			console.warn(`Unexpected tool ${tool}`);
		}
	}

	async onRequestUpdate(requestUpdateParams?: IClothoidRequestUpdate) {
		super.onRequestUpdate(requestUpdateParams);
		await this.sendClothoidPathRequest();
	}

	onMove(event: LeafletMouseEvent) {
		super.onMove(event);
		let hover: HoverState = HoverState.DEFAULT;
		let hoverWaypoint: NodeEntity | undefined;
		for (const waypoint of this.waypoints.filter(x => !x.isReadOnly)) {
			const result = this.checkHoverState(event, waypoint.node);
			if (result !== HoverState.DEFAULT) {
				// heading front/back or waypoint itself
				hover = result;
				hoverWaypoint = waypoint.node;
				// this.trc(`onMove: waypoint hover: ${HoverState[hover]}`);
				break;
			}
		}

		this.hover = hover;
		this.hoverWaypoint = hoverWaypoint;

		switch (this.hover) {
			case HoverState.HEADING_FRONT:
			case HoverState.HEADING_BACK:
				// rotating heading graphic
				this.getController().setRotateCursor(true);
				break;
			case HoverState.WAYPOINT:
				// dragging waypoint
				this.getController().setCursor('move');
				break;
			default:
				this.getController().setDefaultCursor();
		}
		//
	}

	/**
	 * If SELECTOR tool (i.e. edit of a confirmed path): Reverts pending changes and goes into SELECTOR mode (with bg image)
	 * If CLOTHOID tool (i.e. edit of unconfirmed path): Transitions back into DRAW mode (fresh path)
	 * @returns 
	 */
	async onDeleteOrBackspace() {
		this.cancelEdit();
	}

	private onEnter() {
		const isConfirmable = this.isConfirmable();
		this.getLookup().confirmButtonConfirming = true;
		if (!isConfirmable) {
			console.log(`Not confirmable`);
			return;
		}
		// Either confirm new path or confirm edit of existing path
		setCustomTag('map-interface', 'confirm-a-path-with-confirm-btn (edit mode)');
		this.getEventHandler().emit('toggleConfirmCreation', false);
		this.onConfirmEditClothoid();
	}

	private onClickConfirmButton = () => {
		setCustomTag('map-interface', 'confirm-a-path-with-enter-key (edit mode)');
		this.onConfirmEditClothoid();
	}

	dispose() {
		super.dispose();
		// Remove whatever has been drawn
		this.removeCurrentPathObj();
		if (!this.isNew) {
			this.resetUneditedLink();
		}
		this.getEventHandler().removeListener('onConfirmMapObjectCreation', this.onClickConfirmButton);
		this.getEventHandler().removeListener('onActivateHCMPG', this.onActivateHCMPG);
	}

	private onConfirmEditClothoid = async() => {
		// an existing link snapping to another link that includes existing TSD errors
		// action: edit the existing link and unsnap the link -> confirm it
		if (!!this.originalLinkId) {
			// Add TSD errors back but not update node style.
			// Full map validation will update it correctly. Otherwise, lookup won't update automatically.
			this.nodeErrorsToAddBack.forEach(nodeErrors => {
				const nodeEntity = this.getLookup().getEntity(nodeErrors.nodeId, NodeEntity);
				nodeErrors.errors.forEach(errorFlag => {
					this.validator.addNodeError(errorFlag, nodeEntity);
				});
			});
		}
		const confirmedLinkEntity = await this.confirmClothoidPath();
		const tool = this.getController().getSelectedToolType();
		if (!confirmedLinkEntity) {
			if (!!this.originalLinkId) { // TODO: use !this.isNew
				// assumption: can only enter edit mode for existing link via selector tool
				this.verifyAndUpdateTool('selector');
				this.getEventHandler().setMapEventState('selector');
				console.log("Pressed confirm with no changes. Set to selector.");
				const linkObject = this.resetUneditedLink();
				if (!!linkObject) {
					setTimeout(() => {
						this.getEventHandler().setMapEventState('selector', { mapObject: linkObject });
					}, 1);
				}
			}
		} else {
			// TODO: handle this logic for create handler to. updateClothoidState had original logic
			await this.processConfirmedPath(confirmedLinkEntity);
			console.log(`Confirming ${this.isNew ? 'NEW' : 'EXISTING'} link`);
			if (this.isNew) {
				this.verifyAndUpdateTool('clothoid');
				this.getEventHandler().setMapEventState('clothoid');
			} else {
				this.verifyAndUpdateTool('selector');
				this.getEventHandler().setMapEventState('selector');
				this.getEventHandler().emit('onPropertiesPanel', 'map');
			}

		}
	}

	async onKeyPress(event: KeyboardEvent) {
		const key = event.key;
		if (['Delete', 'Backspace'].includes(key)) {
			this.onDeleteOrBackspace();
		} else if (key === 'Enter') {
			this.onEnterDebounced();
		}
	}

	async removeMidwaypoint(mapObject: NodeGraphic, nodeEntity: NodeEntity) {
		if (mapObject.isStartOrEnd() || nodeEntity.task !== 'HAULING') {
			console.log('start/end waypoint or not Hauling task. Ignoring.');
			return false;
		} else {
			console.log('double click on midwaypoint. remove it.');
			const deleteIndex = this.waypoints.findIndex(w => w.node.id === nodeEntity.id);
			if (deleteIndex === -1) {
				console.log('waypoint not found in waypoints array. ignoring.', 'warn');
				return false;
			}
			console.log(`Removing waypoint from position ${deleteIndex}`);
			runInAction(() => {
				nodeEntity.isMidWaypoint = false;
			});
			this.waypoints.splice(deleteIndex, 1);
			await this.sendClothoidPathRequest();
			return true;
		}
	}

	// Process response to pending request
	public onAsyncRequestResponse(result: IPathRequestResponse) {
		super.onAsyncRequestResponse(result);
		if (result.isIgnore) {
			return;
		}
		// logic after processing of final update can be handled in if statement below
		if (result.isSuccess) {
			const currentWaypoint = this.getWaypointToRestore(result);
			// console.log(`currentWaypoint: ${currentWaypoint.northing} selectedWaypoint (invalid): ${this.selectedWaypoint?.northing}`);
			this.originalWaypoint = new NodeEntity({ ...currentWaypoint });
			// console.log(`onAsyncRequestResponse original waypoint: ${this.originalWaypoint.northing} | ${this.originalWaypoint.easting} | ${this.originalWaypoint.heading}`);
		}
		if (this.isAsyncRequestComplete()) {
			if (this.isDragEnd) {
				// console.log(`onAsyncRequestResponse: isAsyncRequestComplete = true`);
				this.processAsyncRequestErrors();
			}
		}
	}

	/**
	 * Processes any error on async req.
	 * in case of error, restore waypoint to valid state and show user msg
	 */
	processAsyncRequestErrors() {
		// custom error processing on async req (e.g. dragging heading / location)
		const err = this.getLastRequestErrorAndClear();
		if (err === pathRequestError.NONE) {
			return err;
		}
		if (!!this.originalWaypoint && !!this.selectedWaypoint) {
			this.showPathErrorToast(err);
			this.restoreWaypoint(this.originalWaypoint, this.selectedWaypoint);
			console.log(`onAsyncRequestResponse: Restored`);
		} else {
			console.log('onAsyncRequestResponse: Failed to restore');
		}
		return err;
	}

	processNormalRequestErrors(result: IPathRequestResponse) {
		const err = this.getLastRequestErrorAndClear();
		if (result.isSuccess || err === pathRequestError.NONE) {
			return pathRequestError.NONE;
		}
		// activating edit mode on an existing link may cause an error,
		// as path re-generation can yield a different path to the original
		this.resetUneditedLink();
		this.cancelEdit();
		this.showPathErrorToast(err);
		return err;
	}

	getWaypointToRestore(result: IPathRequestResponse) {
		const link = result.pathReponseData!.link;
		const { originalEndWaypoint, originalStartWaypoint } = result;
		const originalId = this.originalWaypoint!.id;
		if (originalEndWaypoint.id === originalId) {
			// console.log(`RETURN END WAYPOINT`);
			return originalEndWaypoint;
		} else if (originalStartWaypoint.id === originalId) {
			// console.log(`RETURN START WAYPOINT`);
			return originalStartWaypoint
		} else {
			// console.log(`RETURN mid WAYPOINT`);
			return  PathToolHelper.getAllNodesOfLink(link).find(x => x.id === originalId)!;
		}
	}

	/**
	 * Invoked from within addMidwaypoint to update nodeEntity data and add to waypoints array
	 * @param link 
	 * @param nodeEntity 
	 * @param heading 
	 * @param _isReverse 
	 * @returns 
	 */
	async _addMidwaypoint(link: LinkEntity, nodeEntity: NodeEntity, heading: number) {
		let isReverse = !this.isForwardDirection(this.endWaypoint.direction);
		if (this.waypoints.some(w => w.isReverse === true)) {
			console.log(`Edit mode with reverse points. Check whether reverse by speed sign: speed ${nodeEntity.speed}`);
			isReverse = nodeEntity.speed < 0;
		}
		let insertPosition = this.waypoints.length - 1; // position within waypoint array
		if (this.waypoints.length > 2) {
			// customise insert position
			const allNodes = PathToolHelper.getAllNodesOfLink(link);
			const targetIndex = allNodes.findIndex(n => n.id === nodeEntity.id);
			// find all other waypoints that are further foward in the array and decrement insert position accordingly
			this.waypoints.slice(1, -1).forEach(x => {
				const index = allNodes.findIndex(n => n.id === x.node.id);
				if (targetIndex < index) {
					insertPosition--;
				}
			});
		} 
		runInAction(() => {
			nodeEntity.isMidWaypoint = true;
			nodeEntity.heading = isReverse ? this.calcReverseHeading(heading) : heading;
			const newNode = new NodeEntity({ ...nodeEntity });
			this.waypoints.splice(insertPosition, 0, {
				node: newNode,
			});
			newNode.id = newNode._clientId;
		});
		// Two possible case: 
		// i. No update has been made to path (no new request has been made). In this case activateEdit will remove
		// the original path and generate a new one (returning true)
		// ii. Path already had at least one update (new request already made), activateEdit will return false and then we send request
		try {
			const isEditActivated = await this.activateEdit();
			if (!isEditActivated) {
				const result = await this.sendClothoidPathRequest();
				const err = this.processNormalRequestErrors(result);
				if (err !== pathRequestError.NONE) {
					throw Error(pathRequestError[err]);
				}
			}
		} catch (e: any) {
			console.log(`Activate edit failed: ${e.message}`);
			return false;
		}
		return true;
	}

	/**
	 * Method used to add midwaypoint. Includes validation.
	 * 
	 * @param nodeEntity node to turn into midwaypoint
	 * @returns true if success
	 */
	async addMidwaypoint(nodeEntity: NodeEntity) {
		// Step 2: User clicked on a node that isn't a midwaypoint
		// Perform validation and convert to waypoint
		const { haulingMidWaypoints } = this.getMidWaypointCountOnMap();
		if (haulingMidWaypoints >= MAX_HAULING_WAYPOINTS) {
			console.log(`Max 3 hauling mid-waypoints reached (value: ${this.waypoints.length}). Ignoring.`);
			return false;
		}
		
		if (nodeEntity.task !== 'HAULING') {
			console.log("Not a hauling node. Ignoring");
			return false;
		}

		let pathId: string | undefined;
		let link: LinkEntity | undefined;
		if (!!this.clothoidPathObjectId) {
			pathId = this.clothoidPathObjectId;
			link = this.clothoidLinkEntity;
			console.log("pathId is clothoidPathObjectId. link = this.clothoidLinkEntity");
		} else if (!!this.originalLinkId) {
			const linkObjId = this.getLookup().getMapObjectId(this.originalLinkId, 'link') ?? '';
			const linkObj = this.getRenderer().getObjectById(linkObjId) as Link;
			link = linkObj.getLinkEntity();
			pathId = this.getRenderer().getObjectById(linkObjId)?.getParent()?.getId();
			console.log("pathId is originalLinkId parent. link = linkObj.getLinkEntity()");
		}
		if (!pathId) {
			return false;
		}
		// let link = this.getLookup().getLinkByIdNumber(nodeEntity.linkIdNumber);
		if (!link) {
			console.log("Link not found");
			return false;
		}

		const nodeGraphics = this.getRenderer().getObjectById(pathId).getChildren().filter(g => g.getType() == 'node') as NodeGraphic[];
		const isConvertible = link!.getSublinks().every(sl => {
			return sl.getNodes().every(n => {
				// const g = this.nodeGraphicById(n);
				const g = nodeGraphics.find(g => g.getEntity().id === n.id) as NodeGraphic;
				if (!!g) {
					if (g.isWaypoint()) {
						return true;
					} else {
						// must all be hauling or reverse point
						return n.task === 'HAULING' || n.task === 'REVERSEPOINT' || n.task === 'PARKING';
					}
				} else {
					console.error('onDoubleClick(path): Node graphic not found');
					return false;
				}
			})
		});

		if (!isConvertible) {
			console.log("Node not convertible to midwaypoint");
			return false;
		}

		console.log(`this.endWaypoint.direction ${this.endWaypoint.direction}`);
		console.log(`Node ${nodeEntity.nodeId} is convertible to waypoint`);
		let nextNodeGraphic = nodeGraphics.find(g => g.getEntity().previousNodeId === nodeEntity.id);
		if (!nextNodeGraphic) {
			console.log(`nextNodeGraphic is at sublink boundary. get next graphic`);
			const i = nodeGraphics.findIndex(g => g.getEntity().id === nodeEntity.id);
			if (i === -1) {
				console.log(`nextNodeGraphic not found`);
				return false;
			}
			nextNodeGraphic = nodeGraphics[i + 1];
		}
		if (!nextNodeGraphic) {
			console.log('Unable to find nextNodeGraphic. Ignoring.', 'warn');
			return false;
		}
		const heading = PathToolHelper.calcWaypointHeading(nodeEntity, nextNodeGraphic!.getEntity());
		return this._addMidwaypoint(link, nodeEntity, heading);
	}

	/**
	 * Create or remove midwaypoint
	 * @param event 
	 * @returns 
	 */
	async onDoubleClick(event: LeafletMouseEvent) {
		if (await this.tryMidWaypointChange(event) === MID_WAYPOINT_CHANGE_RESULT.Confirmable) {
			this.onEnter();
		}
	}

	/**
	 * Takes a double click mouse event and tries to modify the links mid-waypoints. If it successfully edits any
	 * mid-waypoints it will return MID_WAYPOINT_CHANGE_RESULT.Success or MID_WAYPOINT_CHANGE_RESULT.Ignore
	 * if no change was made, or MID_WAYPOINT_CHANGE_RESULT.Confirmable if the dbl click event was on something other 
	 * than the nodes in the link being edited
	 *
	 * @param event
	 * @returns whether a midwaypoint was changed for this event
	 */
	async tryMidWaypointChange(event: LeafletMouseEvent): Promise<MID_WAYPOINT_CHANGE_RESULT> {
		const mapObject = this.getController().getMapObjectAtCoordinates(event.latlng);
		if (!mapObject || !(mapObject instanceof NodeGraphic)) {
			this.trc(`mapObject type ${mapObject?.getType()} Ignoring.`);
			return MID_WAYPOINT_CHANGE_RESULT.Confirmable;
		}

		this.trc("Double click on node");
		const storeNodeEntity = mapObject.getEntity();
		const nodeEntity = this.clothoidLinkEntity.sublinkss.map(x => x.nodess)
			.flat(1).find(x => x.getModelId() == storeNodeEntity.getModelId());

		if (nodeEntity == null) {
			return MID_WAYPOINT_CHANGE_RESULT.Ignore;
		}

		//ignore if start, end or reverse waypoints
		if (!nodeEntity.isMidWaypoint && mapObject.headingGraphic) {
			return MID_WAYPOINT_CHANGE_RESULT.Ignore;
		}
		
		// check if dbl clicked node is part of the link being edited
		if (!this.getAllNodes().some(n => n === nodeEntity))
		{
			return MID_WAYPOINT_CHANGE_RESULT.Confirmable;
		}
		
		// The only double click operation in this handler is add/remove waypoint.
		// If this node is not a waypoint, attempt to perform add operation.
		// It it's a waypoint, attempt to perform remove operation.
		// Each operation has its own validation contained within (operations only succeed if all validation conditions are met)
		let isSuccess;
		const isAddWaypoint = !mapObject.isWaypoint();
		if (isAddWaypoint) {
			isSuccess = await this.addMidwaypoint(nodeEntity);
			if (isSuccess) {
				setCustomTag('map-interface', 'turn-a-node-to-a-midwaypoint (edit mode)');
			}
		} else {
			isSuccess = await this.removeMidwaypoint(mapObject, nodeEntity);
			if (isSuccess) {
				setCustomTag('map-interface', 'turn-a-midwaypoint-to-a-node (edit mode)');
			} else {
				return MID_WAYPOINT_CHANGE_RESULT.Confirmable;
			}
		}

		if (isSuccess) {
			return MID_WAYPOINT_CHANGE_RESULT.Success;	
		} else {
			return MID_WAYPOINT_CHANGE_RESULT.Ignore;
		}
	}

	/**
	 * For case where an existing link is put into edit mode but no changes have yet been made
	 * This method will "activate" the edit mode, remove the placeholder, and generate new path
	 * @returns 
	 */
	async activateEdit() {
		if (!!this.startObj && !!this.endObj && !!this.originalLinkId) {
			// Existing link is in edit mode, and the user is now making some edits.
			// Hide the original path and generate a new path.
			this.trc(`Existing link is in edit mode!!!!!!`);
			const lookup = this.getLookup();
			const renderer = this.getRenderer();
			renderer.removeObject(this.startObj.getId());
			renderer.removeObject(this.endObj.getId());
			this.startObj = undefined;
			this.endObj = undefined;
			const linkMapObjectId = lookup.getMapObjectId(this.originalLinkId, 'link');
			const pathObject = this.getRenderer().getObjectById(linkMapObjectId).getParent();
			// TODO: remove path object??
			pathObject?.displayGraphic(false);
			renderer.rerender();
			this.trc('Hiding original path and sending request to updated clothoid');
			const result = await this.sendClothoidPathRequest();
			const err = this.processNormalRequestErrors(result);
			if (err !== pathRequestError.NONE) {
				throw Error(pathRequestError[err]);
			}
			return true;
		}
		return false;
	}

	async onDragStart(event: LeafletMouseEvent, originalCoordinates: LeafletCoordinates): Promise<void> {
		this.trc(`Edit mode. originalLinkId is ${this.originalLinkId}`);
		this.isDragEnd = false;
		try {
			await this.activateEdit(); // For case where edit mode is active but no request has been made
		} catch (e: any) {
			console.log(`Activate edit failed: ${e.message}`);
		}
		
		this.hoverGraphic = undefined;

		const isHeadingDrag = !(this.hover === HoverState.WAYPOINT);

		this.trc(`hover = ${HoverState[this.hover]} isHeadingDrag = ${isHeadingDrag}`);

		const waypoint = this.whichWaypointHover();

		if (waypoint === undefined) {
			this.trc(`Waypoint undefined. Ignore.`);
			return;
		}

		this.originalWaypoint = new NodeEntity({ ...waypoint });
		console.log(`onDragStart original waypoint: ${this.originalWaypoint.northing} | ${this.originalWaypoint.easting} | ${this.originalWaypoint.heading}`);
		this.selectedWaypoint = waypoint;

		// const waypoint = isStartWaypoint ? this.startWaypoint : this.endWaypoint;
		// if path is edited on map, remove connectivity (if any) associated with
		// the waypoint being edited
		if (waypoint.id === this.startWaypoint.id) {
			this.getNonEditedConnectedLinkTSDErrors(true);
			this.trc(`Detected start waypoint`);
			// TODO: Check which issues fixed by change of (targetEndLinkIdNum === undefined) to (targetEndLinkIdNum !== undefined)
			if (this.isStartWaypointSnapped || this.targetEndLinkIdNum !== undefined) {
				// The above condition is not strictly necessary but helps for debugging
				this.trc('Unsnap startWaypoint');
				this.isStartWaypointSnapped = false;
				this.targetEndLinkIdNum = undefined;
				if (isHeadingDrag) {
					setCustomTag('map-interface', 'rotate-a-snapped-start-waypoint (edit mode)');
				} else {
					setCustomTag('map-interface', 'move-a-snapped-start-waypoint (edit mode)');
				}
			} else {
				if (isHeadingDrag) {
					setCustomTag('map-interface', 'rotate-a-start-waypoint (edit mode)');
				} else {
					setCustomTag('map-interface', 'move-a-start-waypoint (edit mode)');
				}

				console.log('onDragStart: not restoring links. linksToRestore count: ', this.linksToRestore?.length);
			}
		} else if (waypoint.id === this.endWaypoint.id) {
			this.getNonEditedConnectedLinkTSDErrors(false);
			// endWaypoint
			this.trc(`Detected end waypoint`);
			// eslint-disable-next-line no-lonely-if
			if (this.isEndWaypointSnapped || this.targetStartLinkIdNum !== undefined) {
				// The above condition is not strictly necessary but helps for debugging
				this.trc('Unsnap endWaypoint');
				this.isEndWaypointSnapped = false;
				this.targetStartLinkIdNum = undefined;
				if (isHeadingDrag) {
					setCustomTag('map-interface', 'rotate-a-snapped-end-waypoint (edit mode)');
				} else {
					setCustomTag('map-interface', 'move-a-snapped-end-waypoint (edit mode)');
				}
			} else {
				if (isHeadingDrag) {
					setCustomTag('map-interface', 'rotate-a-end-waypoint (edit mode)');
				} else {
					setCustomTag('map-interface', 'move-a-end-waypoint (edit mode)');
				}

				console.log('onDragStart: not restoring links. linksToRestore count: ', this.linksToRestore?.length);
			}
		} else {
			this.trc("Detected Mid-waypoint")
		}
		this.trc(`Getting hoverGraphic for waypoint ${waypoint.id}`);
		this.hoverGraphic = this.nodeGraphicById(waypoint);
		if (isHeadingDrag && !!this.hoverGraphic) {
			this.hoverGraphic.startDynamicHeading();
		}
	}

	public onDragMove(event: LeafletMouseEvent): void | Promise<void> {
		// this.trc(`onDragMove`);
		const coords = this.getRenderer().mousePosition;
		// *** START end mode logic ***
		// this.trc(`onDragMove: Edit mode logic`);
		// Edit mode logic
		// TODO: Put edit mode in seperate handler
		// TODO: this needs to be consolidated into updateDynamicHeading
		let updatePath = false;
		if (this.hoverGraphic === undefined || this.hoverWaypoint === undefined) {
			return;
		}
		this.hoverGraphic.isWaypoint()
		const waypoint = this.hoverWaypoint; // why not?
		if (this.hover === HoverState.WAYPOINT) {
			// Drag waypoint graphic
			const { northing, easting } = coords;
			runInAction(() => {
				waypoint.northing = northing;
				waypoint.easting = easting;
				updatePath = true;
			});
			if (event.originalEvent.shiftKey && !isCtrlKeyHeld(event.originalEvent)) {
				// drag and shift key
				const ix = this.waypoints.findIndex(w => w.node.id === waypoint.id);
				if (ix !== -1) {
					this.trc(`straight line: found ix: ${ix}`);
					const isStartWaypoint = ix === 0;
					const node1 = isStartWaypoint ? this.waypoints[1].node : this.waypoints[ix - 1].node; 
					const node2 = waypoint;
					const hasReverseSegments = this.waypoints.some(x => x.isReverse === true);
					let isForward = true;
					if (hasReverseSegments) {
						// Special processing for multi-direction paths (e.g. has reverse points)
						const directions = this.getDirectionFromWaypoints();
						// Target segment is between the waypoint being dragged and the waypoint before it.
						// Exception if target waypoint is start waypoint (use first segment direction)
						const segmentDirection = ix > 0 ? directions[ix - 1] : directions[0];
						isForward = this.isForwardDirection(segmentDirection);
					} else {
						// Original processing
						isForward = this.isForwardDirection(this.endWaypoint.direction);
					}
					if (isStartWaypoint) {
						isForward = !isForward;
					}
					this.pathAlongHeadingMulti(node1, node2, node1.heading, isForward);
				}       
			}
		} else {
			// Drag heading graphic
			this.trc("Drag headging graphic");
			const isReverse = (this.hover === HoverState.HEADING_BACK);
			const heading = calcHeading(
				{ northing: waypoint.northing, easting: waypoint.easting }, coords, isReverse,
			);
			updatePath = true;
			runInAction(() => {
				waypoint.heading = heading;
			});
		}
		if (updatePath) {
			// TODO: address async
			this.asyncClothoidPathRequest();
		}
		// *** END end mode logic ***
	}

	async onDragEnd(event: LeafletMouseEvent): Promise<void> {
		console.log(`onDragEnd: ${HoverState[this.hover]}`);
		this.isDragEnd = true;
		if (!!this.hoverGraphic) { // implies this.hover !== hoverState.DEFAULT
			this.hoverGraphic.clearDynamicHeading();
			// Snap functionality
			if (isCtrlKeyHeld(event.originalEvent) && !event.originalEvent.shiftKey) {
				console.log(`Snap operation in EDIT mode`);
				let isSuccess = false;
				let isSnapEnd = false;
				const coords = this.getRenderer().getRealWorldCoords(event.latlng);
				if (this.hover === HoverState.WAYPOINT && this.hoverWaypoint?.id === this.startWaypoint.id) {
					isSuccess = !!this.snapStartwaypoint(coords);
				} else if (this.hover === HoverState.WAYPOINT && this.hoverWaypoint?.id === this.endWaypoint.id) {
					isSuccess = !!this.snapEndwaypoint(coords);
					isSnapEnd = true;
				} else {
					// console.error('Hover state is default');
					console.error('Hover state is default');
				}
				if (isSuccess) {
					setCustomTag('map-interface', 'snap-a-waypoint (edit mode)');
					const result = await this.sendClothoidPathRequest();
					const err = this.processNormalRequestErrors(result);
					const isError = err !== pathRequestError.NONE;
					if (isError) {
						console.log(`Error snapping path: ${err}`);
					} else {
						this.trc(`Successful snap operation. Set task and direction to readonly`);
						if (isSnapEnd) {
							this.trc(`End waypoint snapped. Send task/direction to readonly`);
							const direction = PathToolHelper.getClothoidDirectionProperty(this.clothoidLinkEntity);
							this.getEventHandler().emit('onSetTaskAndDirectionReadOnly', true, direction);	
						}
					}
				} else {
					this.trc('Snap failed: no eligible waypoint in vicinity');
				}
			}
			// Reset
			this.hover = HoverState.DEFAULT;
			this.hoverGraphic = undefined;
			if (this.isAsyncRequestComplete()) {
				console.log(`onDragEnd: isAsyncRequestComplete = true.`);
				this.processAsyncRequestErrors();
			}
		}
	}
}
