import { SERVER_URL } from 'Constants';
import { AreaEntity, BayEntity, LinkEntity, NodeEntity, SublinkEntity } from 'Models/Entities';
import { Model } from 'Models/Model';
import alertToast from 'Util/ToastifyUtils';
import { Area, Bay, Link, MapEventHandler, MapRenderer, NodeGraphic } from 'Views/MapComponents';
import axios from 'axios';
import MapController from "../MapController";
import SubLink from '../MapObjects/SubLink/SubLink';
import MapStore from '../MapStore';
import AreaValidator from "../MapValidators/AreaValidator";
import BayValidator from "../MapValidators/BayValidator";
import MapValidator from '../MapValidators/MapValidator';
import NodeValidator from '../MapValidators/NodeValidator';
import PathValidator from "../MapValidators/PathValidator";

/**
 * Validate full map on client side and server side.
 */
export async function validateFullMap(map: MapController, showErrorsWarnings: boolean, entity?: Model, isRunFullMapCheck: boolean = false) {
	map.getMapLookup().resetMapErrors();
	validateOnClientSide(map, entity);
	await validateOnServerSide(map);

	const errorCount = map.getMapLookup().getMapErrorCount();
	const warningCount = map.getMapLookup().getMapWarningCount();
	map.getEventHandler().emit('onErrorCountUpdate', errorCount);
	console.log(`Full map validation completed: ${errorCount} error(s) and ${warningCount} warning(s) found.`);

	// map.getEventHandler().emit('onErrorCountUpdate', errorCount);
	if (showErrorsWarnings && errorCount > 0) {
		const errorMessage = `Full map validation completed: ${errorCount} error(s) and ${warningCount} warning(s) found.`
		alertToast(errorMessage, 'error');
	}

	if (showErrorsWarnings && (errorCount === 0 && warningCount > 0)) {
		const errorMessage = `Full map validation completed: ${errorCount} error(s) and ${warningCount} warning(s) found.`
		alertToast(errorMessage, 'warning');
	}

	if (showErrorsWarnings && isRunFullMapCheck && errorCount === 0 && warningCount === 0) {
		const errorMessage = `Map validation completed: No error or warning found.`
		alertToast(errorMessage, 'success');
	}

	return;
}

export function validateOnClientSide(map: MapController, entity?: Model) {
	validateInMapBounds(map, entity);
	validateMapObjectValidity(map, entity);
	// console.log("Client side validation completed");
	return;
}

/**
 * Do server side validation by hitting fullMapValidation and validateMultiBayErrors endpoints.
 * Remove fixed server side errors and warnings from mapObjectErrorss, mapObjectWarningss,
 * mapErrors table, mapWarnings table, and styling.
 */
export async function validateOnServerSide(map: MapController): Promise<void> {
	const importVersionId = map.getImportVersion().id;
	const lookup = map.getMapLookup();
	const eventHandler = map.getEventHandler();
	const renderer = map.getMapRenderer();
	// The errors/warnings from the server side validation are
	// 1) kept in mapObjectErrorss, mapObjectWarningss, the error table and the warning table.
	// 2) saved in mapErrorsServerSide and mapWarningsServerSide, which is used to remove fixed errors/warnings.
	// Note that errors/warnings are not saved in the database.
	await axios
			.post(`${SERVER_URL}/api/entity/ImportVersionEntity/fullMapValidation`, // fullMapValidation only validate Link, Sublink, Node, and Area
				{ ImportVersionId: importVersionId })
			.then(result => {
				if (!result.data.success) {
					const mapErrors = result.data.errors as any;
					const mapWarnings = { ...lookup.getMapWarningsServerSide() }; // No warnings returned from fullMapValidation currently
					// record new errors and warnings
					map.getMapLookup().setMapErrorsServerSide(mapErrors);
					map.getMapLookup().setMapWarningsServerSide(mapWarnings);
					console.log(mapErrors);

					Object.entries(mapErrors as any as object)
					.map(([entityType, object]) => {						
						// console.log(entityType, object);						
						if (entityType === 'AreaEntity') {
							Object.entries(object).map(([id, errors]) => {								
								lookup.addNewErrorsForObject(id, AreaEntity, errors as string[]);
								setOrClearAreaErrorWarningStyle(lookup, eventHandler, id);
							});
						} else if (entityType === 'LinkEntity') {
							Object.entries(object).map(([id, errors]) => {
								lookup.addNewErrorsForObject(id, LinkEntity, errors as string[]);
								setOrClearLinkErrorWarningStyle(lookup, eventHandler, renderer, id);
							});
						} else if (entityType === 'SublinkEntity') {
							Object.entries(object).map(([id, errors]) => {
								lookup.addNewErrorsForObject(id, SublinkEntity, errors as string[]);
								setOrClearSublinkErrorWarningStyle(lookup, eventHandler, renderer, id);
							});
						} else if (entityType === 'NodeEntity') {
							Object.entries(object).map(([id, errors]) => {
								lookup.addNewErrorsForObject(id, NodeEntity, errors as string[]);
								setOrClearNodeErrorWarningStyle(lookup, eventHandler, renderer, id);
							});
						}
					});
				}

				// IMPORTANT: Sending all required data (incl. area polygon) to avoid
				// performance hit on serverside
				const refPath = {
					bayLocation: {},
					area: {
						polygon: {}
					}
				};
				const bays = map.getImportVersion().bayss.map(b => {
					const tempBay = new BayEntity(b);
					const areaId = tempBay.areaId;
					if (!!areaId) {
						const area = map.getMapLookup().getEntity(areaId, AreaEntity);
						if (!!area) {
							tempBay.area = area;
						}
					}
					const json = tempBay.toJSON(refPath);
					return json;
				});
					
				// TODO: refactor. validateMultiBayErrors had better to be put with fullMapValidation together
				return axios.post(`${SERVER_URL}/api/entity/BayEntity/validateMultiBayErrors`, {
					Bays: bays,
					ImportVersionId: importVersionId,
				});
			})
			.then(result => {
				if (result.data) {
					const bayErrors = result.data.bayErrors as any;
					const bayWarnings = result.data.bayWarnings as any;

					const mapErrors = lookup.getMapErrorsServerSide();
					const mapWarnings = lookup.getMapWarningsServerSide();

					// fullMapValidation above doesn't include BayEntity, so it can be set directly
					mapErrors['BayEntity'] = bayErrors;
					mapWarnings['BayEntity'] = bayWarnings;

					Object.entries(bayErrors as any as object)
					.map(([id, errors]) => {
						lookup.addNewErrorsForObject(id, BayEntity, errors as string[]);
						setOrClearBayErrorWarningStyle(lookup, eventHandler, id);
					});

					Object.entries(bayWarnings as any as object)
					.map(([id, warnings]) => {
						lookup.addNewWarningsForObject(id, BayEntity, warnings as string[]);
						setOrClearBayErrorWarningStyle(lookup, eventHandler, id);
					});
				}
			})
			.catch((e) => {
				alertToast(`fullMapValidation or validateMultiBayErrors request failed. Message: ${e.message}`, 'error');
			})
			.finally(() => {
				// TODO: removeFixedServerSideErrors/removeFixedServerSideWarnings should have own error handling 
				try {
					removeFixedServerSideErrors(lookup, eventHandler, renderer);
					removeFixedServerSideWarnings(lookup, eventHandler, renderer);
				} catch (e) {
					console.warn('removal of errors failed');
				}

				map.getMapRenderer().rerender(); // render all objects at once
				console.log("Server side validation completed");
			});
	return;
}

function validateMapObjectValidity(map: MapController, entity?: Model) {
	const pathValidator = new PathValidator(map);
	// Validate all entities when running validateFullMap
	if (!entity) {
		BayValidator.validateBaysDistance(map);
		pathValidator.validateSublinksDrivingZones();
		NodeValidator.validateStartParkingNodes(map);
		AreaValidator.checkDrivingZonesAreasInterference(map);
	// Validate a specific entity when running saveChanges
	} else if (!!entity && entity.getModelName() === 'AreaEntity') {
		const areaEntity = entity as AreaEntity;
		// Validate start parking node if the autonomous parking area is edited
		if (areaEntity.areaType === 'AREAAUTONOMOUS' && areaEntity.locType === 'PARKING') {
			NodeValidator.validateStartParkingNodes(map);
		}
	} else if (!!entity && entity.getModelName() === 'BayEntity') {
		const areaId = (entity as BayEntity).areaId;
		if (!!areaId) {
			const getBayList = map.getMapLookup().getBaysByAreaId(areaId);
			getBayList?.map(bayEntity => {
				BayValidator.validateBayDistance(bayEntity, map);
			});
		} else {
			BayValidator.validateBayDistance(entity as BayEntity, map);
		}
	} else if (!!entity && entity.getModelName() === 'SublinkEntity') {
		pathValidator.validateSublinkDrivingZone(entity as SublinkEntity);
	} else if (!!entity && entity.getModelName() === 'NodeEntity') {
		const node = entity as NodeEntity;
		if (node.task === 'PARKING' && node.isFirstNode()) {
			NodeValidator.validateStartParkingNode(entity as NodeEntity, map);
		}
	}

	return;
}

function validateInMapBounds(map: MapController, entity?: Model) {
	// Validate all entities when running validateFullMap
	if (!entity) {
		AreaValidator.validateAreasInMapBounds(map);
		BayValidator.validateBaysInMapBounds(map);
		NodeValidator.validateNodesInMapBounds(map);

	// Validate a specific entity when running saveChanges
	} else if (!!entity && entity.getModelName() === 'AreaEntity') {
		AreaValidator.validateAreaInMapBounds(entity as AreaEntity, map);
	} else if (!!entity && entity.getModelName() === 'BayEntity') {
		BayValidator.validateBayInMapBounds(entity as BayEntity, map);
	} else if (!!entity && entity.getModelName() === 'NodeEntity') {
		NodeValidator.validateNodeInMapBounds(entity as NodeEntity, map);
	}

	return;
}

export function validateFullMapPathsInterference(map: MapController) {
	const pathValidator = new PathValidator(map);
	pathValidator.validatePathsInterference();
	return;
}

/**
 * Remove fixed server side errors by comparing oldMapErrorsServerSide and mapErrorsServerSide tables
 */
function removeFixedServerSideErrors(lookup: MapStore, eventHandler: MapEventHandler, renderer: MapRenderer) {
	const oldMapErrorsServerSide = lookup.getOldMapErrorsServerSide();
	const mapErrorsServerSide = lookup.getMapErrorsServerSide();

	Object.entries(oldMapErrorsServerSide)
	.map(([entityType, object]) => {
		// console.log(entityType);
		const mapErrorsServerSideIds = Object.keys(mapErrorsServerSide[entityType]);
		Object.entries(object).map(([id, errors]) => {
			let fixErrors = false;
			let _errors = errors;
			const isIdExisted = mapErrorsServerSideIds.includes(id);
			if (isIdExisted) {
				const newErrors = mapErrorsServerSide[entityType][id];
				const errorsNotInNewErrors = errors.filter(e => !newErrors.includes(e));

				// At least one error of an entity has been fixed.
				if (errorsNotInNewErrors.length > 0) {
					fixErrors = true;
					_errors = errorsNotInNewErrors;
				}
			} else { // All errors of an entity have been fixed.
				fixErrors = true;
			}
			if (fixErrors) {
				switch(entityType) {
					case "AreaEntity":
						_errors.forEach(error => lookup.removeObjectError(id, AreaEntity, error));
						setOrClearAreaErrorWarningStyle(lookup, eventHandler, id);
						break;
					case 'BayEntity':
						_errors.forEach(error => lookup.removeObjectError(id, BayEntity, error));
						setOrClearBayErrorWarningStyle(lookup, eventHandler, id);
						break;
					case 'LinkEntity':
						_errors.forEach(error => lookup.removeObjectError(id, LinkEntity, error));
						setOrClearLinkErrorWarningStyle(lookup, eventHandler, renderer, id);
						break;
					case 'SublinkEntity':
						_errors.forEach(error => lookup.removeObjectError(id, SublinkEntity, error));
						setOrClearSublinkErrorWarningStyle(lookup, eventHandler, renderer, id);
						break;
					case 'NodeEntity':
						_errors.forEach(error => lookup.removeObjectError(id, NodeEntity, error));
						setOrClearNodeErrorWarningStyle(lookup, eventHandler, renderer, id);
						break;
					default:
						throw new Error('Unable to find correct entity type')
				}
			}
		});
	});
	lookup.setNewMapErrorsServerSideToOldOne();
}

/**
 * Remove fixed server side warnings by comparing oldMapWarningsServerSide and mapWarningsServerSide tables
 */
function removeFixedServerSideWarnings(lookup: MapStore, eventHandler: MapEventHandler, renderer: MapRenderer) {
	const oldMapWarningsServerSide = lookup.getOldMapWarningsServerSide();
	const mapWarningsServerSide = lookup.getMapWarningsServerSide();

	Object.entries(oldMapWarningsServerSide)
	.map(([entityType, object]) => {
		const mapWarningsServerSideIds = Object.keys(mapWarningsServerSide[entityType]);
		Object.entries(object).map(([id, warnings]) => {
			let fixWarnings = false;
			let _warnings = warnings;
			const isIdExisted = mapWarningsServerSideIds.includes(id);
			if (isIdExisted) {
				const newWarnings = mapWarningsServerSide[entityType][id];
				const errorsNotInNewErrors = warnings.filter(w => !newWarnings.includes(w));

				// At least one warning of an entity has been fixed.
				if (errorsNotInNewErrors.length > 0) {
					fixWarnings = true;
					_warnings = errorsNotInNewErrors;
				}
			} else { // All warnings of an entity have been fixed.
				fixWarnings = true;
			}
			if (fixWarnings) {
				switch(entityType) {
					case "AreaEntity":
						_warnings.forEach(warning => lookup.removeObjectWarning(id, AreaEntity, warning));
						setOrClearAreaErrorWarningStyle(lookup, eventHandler, id);
						break;
					case 'BayEntity':
						_warnings.forEach(warning => lookup.removeObjectWarning(id, BayEntity, warning));
						setOrClearBayErrorWarningStyle(lookup, eventHandler, id);
						break;
					case 'LinkEntity':
						_warnings.forEach(warning => lookup.removeObjectWarning(id, LinkEntity, warning));
						setOrClearLinkErrorWarningStyle(lookup, eventHandler, renderer, id);
						break;
					case 'SublinkEntity':
						_warnings.forEach(warning => lookup.removeObjectWarning(id, SublinkEntity, warning));
						setOrClearSublinkErrorWarningStyle(lookup, eventHandler, renderer, id);
						break;
					case 'NodeEntity':
						_warnings.forEach(warning => lookup.removeObjectWarning(id, NodeEntity, warning));
						setOrClearNodeErrorWarningStyle(lookup, eventHandler, renderer, id);
						break;
					default:
						throw new Error('Unable to find correct entity type')
				}
			}
		});
	});
	lookup.setNewMapWarningsServerSideToOldOne();
}

/**
 * Update the area tooltip on the map and the label style in the layers panel
 */
function setOrClearAreaErrorWarningStyle(lookup: MapStore, eventHandler: MapEventHandler, id: string) {
	const areaEntity = lookup.getEntity(id, AreaEntity);
	if (!!areaEntity) {
		const areaMapObject = lookup.getMapObjectByEntity(areaEntity, 'area') as Area;
		const hasError = areaEntity.getErrorCount() > 0;
		const hasWarning = areaEntity.getWarningCount() > 0;
		MapValidator.setMapObjectTooltipErrorWarning(areaMapObject, hasError, hasWarning);
		eventHandler.emit('onMapObjectUpdate', areaEntity, id);
	} else {
		console.warn(`validateOnServerSide: AreaEntity ${id} not found in lookup. Ignoring.`);
	}
}

/**
 * Update the bay tooltip on the map and the label style in the layers panel
 */
function setOrClearBayErrorWarningStyle(lookup: MapStore, eventHandler: MapEventHandler, id: string) {
	const bayEntity = lookup.getEntity(id, BayEntity);
	if (!!bayEntity) {
		const bayMapObject = lookup.getMapObjectByEntity(bayEntity, 'bay') as Bay;
		const hasError = bayEntity.getErrorCount() > 0;
		const hasWarning = bayEntity.getWarningCount() > 0;
		MapValidator.setMapObjectTooltipErrorWarning(bayMapObject, hasError, hasWarning);
		eventHandler.emit('onMapObjectUpdate', bayEntity, id);
	} else {
		console.warn(`validateOnServerSide: BayEntity ${id} not found in lookup. Ignoring.`);
	}
}

/**
 * Update the link mapObject style on the map and the label style in the layers panel
 */
function setOrClearLinkErrorWarningStyle(lookup: MapStore, eventHandler: MapEventHandler, renderer: MapRenderer, id: string) {
	const isRerender = false;
	const linkEntity = lookup.getEntity(id, LinkEntity);
	if (!!linkEntity) {
		const linkMapObject = lookup.getMapObjectByEntity(linkEntity, 'link') as Link;
		const hasError = linkEntity.getErrorCount() > 0;
		const hasWarning = linkEntity.getWarningCount() > 0;
		MapValidator.setMapObjectError(renderer, linkMapObject, isRerender, hasError);
		MapValidator.setMapObjectWarning(renderer, linkMapObject, isRerender, hasWarning);
		eventHandler.emit('onMapObjectUpdate', linkEntity, id);
	} else {
		console.warn(`validateOnServerSide: LinkEntity ${id} not found in lookup. Ignoring.`);
	}
}

/**
 * Update the sublink mapObject style on the map and the label style in the layers panel
 */
function setOrClearSublinkErrorWarningStyle(lookup: MapStore, eventHandler: MapEventHandler, renderer: MapRenderer, id: string) {
	const isRerender = false;
	const sublinkEntity = lookup.getEntity(id, SublinkEntity);
	if (!!sublinkEntity) {
		const sublinkMapObject = lookup.getMapObjectByEntity(sublinkEntity, 'sublink') as SubLink;
		const dz = sublinkMapObject.getDrivingZoneObject();
		if (!!dz) {
			const hasError = sublinkEntity.getErrorCount() > 0;
			const hasWarning = sublinkEntity.getWarningCount() > 0;
			MapValidator.setMapObjectError(renderer, dz, isRerender, hasError);
			MapValidator.setMapObjectWarning(renderer, dz, isRerender, hasWarning);
			eventHandler.emit('onMapObjectUpdate', sublinkEntity, id);
		}
	} else {
		console.warn(`validateOnServerSide: SublinkEntity ${id} not found in lookup. Ignoring.`);
	}
}

/**
 * Update the node mapObject style on the map and the label style in the layers panel
 */
function setOrClearNodeErrorWarningStyle(lookup: MapStore, eventHandler: MapEventHandler, renderer: MapRenderer, id: string) {
	const isRerender = false;
	const nodeEntity = lookup.getEntity(id, NodeEntity);
	if (!!nodeEntity) {
		const nodeMapObject = lookup.getMapObjectByEntity(nodeEntity, 'node') as NodeGraphic;
		const hasError = nodeEntity.getErrorCount() > 0;
		const hasWarning = nodeEntity.getWarningCount() > 0;
		MapValidator.setMapObjectError(renderer, nodeMapObject, isRerender, hasError);
		MapValidator.setMapObjectWarning(renderer, nodeMapObject, isRerender, hasWarning);
		eventHandler.emit('onMapObjectUpdate', nodeEntity, id);
	} else {
		console.warn(`validateOnServerSide: NodeEntity ${id} not found in lookup. Ignoring.`);
	}
}