import { runInAction } from 'mobx';
import {
	LinkEntity, LinkFromLinkTo, SublinkEntity,
} from 'Models/Entities';
import * as uuid from 'uuid';
import { Model, ReferencePath } from '../../../Models/Model';
import {
	MapLookup
} from '../index';

import { Action, ActionGroup, deepCopy } from './UndoRedoTracker';
import { store } from '../../../Models/Store';
import UndoRedoHelper from './UndoRedoHelper';
import LinkOperationsHelper from '../Map/MapStateHandlerHelpers/LinkOperationsHelper';

type ActionType = 'create' | 'modify' | 'delete';
type SaveActionType = 'apply' | 'unapply';

export interface IUndoRedoResult {
	entityId: string;
	actionType: ActionType;
	actionId: string;
	entityDetail: string;
	entityType: string;
}

export class UndoRedoAction<T> {
	public actionId = uuid.v4();
	public actionType: ActionType;

	private _parentActionGroup: ActionGroup;

	public entityId: string;
	public entityType: string;

	public oldValue: T;
	// TODO: this was changed to public to apply save patch for break sublink id constraint. ideally it should be protected
	public newValue: T;

	lookup: MapLookup;

	public isUpdateAndRerenderMapObject?: boolean;

	constructor(actionType: ActionType, lookup: MapLookup, entityId: string, entityType: string) {
		this.actionType = actionType;
		this.lookup = lookup;

		this.entityId = entityId;
		this.entityType = entityType;
	}

	public set parentActionGroup(v: ActionGroup) {
		this._parentActionGroup = v;
	}

	public get parentActionGroup() {
		return this._parentActionGroup;
	}

	// IMPORTANT: isLast has been added to denote the last action of an action group to be executed.
	// This is needed for complicated operations where re-rendering / adjustments to lookups need to
	// happen on the final step. e.g. if an undo operation results in the actionGroup with actions
	// A -> B -> C -> D being invoked in that order, isLast will be true for D
	public undo(isLast: boolean): IUndoRedoResult { throw new Error('Unimplemented'); }
	public redo(isLast: boolean): IUndoRedoResult { throw new Error('Unimplemented'); }

	/**
	 * Convert it into an action the server can parse
	 * @param mode
	 */
	public save(mode: SaveActionType): Action {
		return {
			actionId: this.actionId,
			actionType: this.actionType,
			entityType: this.entityType,
			entityId: this.entityId,
			delta: undefined,
		};
	}

	public hasChange(): boolean {
		return this.oldValue !== this.newValue;
	}
}

export class PropertyUpdateAction<T extends Model, U> extends UndoRedoAction<U> {
	protected attribute: string;

	private readonly commitAction: boolean | undefined;

	constructor(originalModel: T, updatedModel: T, attribute: string, lookup: MapLookup, isUpdateAndRerenderMapObject?: boolean) {
		super('modify', lookup, updatedModel.getModelId(), updatedModel.getModelName());

		this.attribute = attribute;
		this.oldValue = deepCopy(originalModel[attribute]);
		this.newValue = deepCopy(updatedModel[attribute]);
		this.isUpdateAndRerenderMapObject = isUpdateAndRerenderMapObject;
	}

	// determine if value is an entity or array of entities
	hasEntity(obj: any) {
		if (!obj) return false;

		if (obj instanceof Array) {
			return obj.length > 0 && !!obj[0]._clientId;
		}
		if (typeof obj === 'object') {
			return !!obj._clientId;
		}
		return false;
	}

	public undo(isLast: boolean): IUndoRedoResult {
		const obj = this.lookup.getEntity(this.entityId, this.entityType);
		if (this.attribute === 'drivingZone') {
			console.log(`Updating driving zone for ${this.entityId}`);
		}
		runInAction(() => {
			// avoid corrupting type information of entities
			const attrVal = obj[this.attribute];
			if (!this.hasEntity(attrVal)) {
				if (this.attribute !== 'state') {
					console.log(`PropertyUpdateAction.undo: [(${this.entityId}/${this.entityType}/${this.attribute}) ${attrVal}] -> [${this.oldValue}]`);
				}
				obj[this.attribute] = this.oldValue;
			}
		});

		// Potentially add logic to rerender here
		const entityDetail = this.updateAndRerenderMapObject(obj, true, isLast, this.commitAction);

		return {
			entityId: this.entityId,
			actionType: this.actionType,
			actionId: this.actionId,
			entityDetail: entityDetail as any,
			entityType: this.entityType,
		};
	}

	public redo(isLast: boolean): IUndoRedoResult {
		const obj = this.lookup.getEntity(this.entityId, this.entityType);
		runInAction(() => {
			const attrVal = obj[this.attribute];
			// avoid corrupting type information of entities
			if (!this.hasEntity(attrVal)) {
				if (this.attribute !== 'state') {
					console.log(`PropertyUpdateAction.redo: [(${this.entityId}/${this.entityType}/${this.attribute}) ${attrVal}] -> [${this.newValue}]`);
				}
				obj[this.attribute] = this.newValue;
			}
		});

		const entityDetail = this.updateAndRerenderMapObject(obj, false, isLast, this.commitAction);
		return {
			entityId: this.entityId,
			actionType: this.actionType,
			actionId: this.actionId,
			entityDetail: entityDetail as any,
			entityType: this.entityType,
		};
	}



	// TODO: put this elsewhere
	private updateAndRerenderMapObject(obj: Model, isUndo: boolean, isLast: boolean, commitAction?: boolean): string {
		// console.log(`updateAndRerenderMapObject: ${obj.getModelName()} commitAction ${commitAction}`);
		const { opType, actionGroupId } = this.parentActionGroup; 
		
		if (isLast) {
			console.log('updateAndRerenderMapObject: got last action');
			console.log(`PROCESSING LAST ${isUndo ? 'UNDO' : 'REDO'} ACTION (via Property): ${this.entityType}/${this.entityId}/${this.attribute}`);
		}
		if (!store.mapController) {
			return 'controller not defined';
		}

		/**
		 * There is a case that null value and non-null value before or after an operation causing incorrect lookup issue.
		 * e.g. newValue is null and oldValue has a value in a break operation
		 * we use markEntityToUpdate(link) to delete related data in the lookup. However, link with null-refs causes the residue in the lookup
		 * Use updateLookupRef to correctly remove the residue in the lookup
		 */
		const helper = new UndoRedoHelper(store.mapController, this, isUndo, isLast, this.attribute);
		if (this.oldValue === null && !!this.newValue && isUndo) { // Undo join
			helper.updateLookupRef(this.newValue, this.attribute);
		}
		if (this.newValue === null && !!this.oldValue && !isUndo) { // Redo break
			helper.updateLookupRef(this.oldValue, this.attribute);
		}
		const result =  helper.processEntity(obj);

		return result;
	}

	public save(mode: SaveActionType) {
		const saveObject = super.save(mode);
		saveObject.delta = {
			attribute: this.attribute,
			value: mode === 'apply' ? this.newValue : this.oldValue,
		};
		return saveObject;
	}
}

export class CreateEntityAction<T extends Model> extends UndoRedoAction<T> {
	private readonly referencePath: ReferencePath;
	constructor(newModel: T, modelType: { new(m: Partial<T>): T }, lookup: MapLookup, referencePath?: ReferencePath) {
		super('create', lookup, newModel.getModelId(), newModel.getModelName());
		this.newValue = new modelType(deepCopy(newModel));
		if (!!referencePath) {
			this.referencePath = referencePath;
		}
	}

	public undo(isLast: boolean): IUndoRedoResult {
		if (isLast) {
			console.log('CreateEntityAction (undo): LAST ACTION');
		}
		console.log(`CreateEntityAction (undo): ${this.entityType}/${this.entityId} isLast: ${isLast}`);
		const entity = this.lookup.getEntity(this.entityId, this.entityType);
		const { opType, actionGroupId } = this.parentActionGroup;
		this.lookup.deleteEntity(this.entityId, this.entityType);
		store.mapController?.deleteEntity(entity, undefined, opType);
		console.log(`CreateEntityAction.undo: ${this.entityType} ${this.entityId} `);
		if (isLast && opType === 'break_sublink') {
			if (entity instanceof SublinkEntity) {
				const isUndo = false;
				if (!!store.mapController) {
					const helper = new UndoRedoHelper(store.mapController, this, isUndo, isLast);
					const isJoin = false; // why break_sublink and hitting undo button need to call processBreakSublink?
					helper.processFinalAction(opType, isJoin, entity);
				}
			} else {
				console.log('Wrong entity type. NOT processing final action');
			}
		} else if (isLast && opType === 'break_link') {
			if (entity instanceof LinkEntity) {
				if (!!store.mapController) {
					const isUndo = true;
					const isJoin = true;
					const helper = new UndoRedoHelper(store.mapController, this, isUndo, isLast);
					helper.processFinalAction(opType, isJoin, entity);
				}
			} else {
				console.log('Wrong entity type. NOT processing final action');
			}
		}
		return {
			entityId: this.entityId,
			actionType: this.actionType,
			actionId: this.actionId,
			entityDetail: '',
			entityType: this.entityType,
		};
	}

	public redo(isLast: boolean): IUndoRedoResult {
		if (isLast) {
			console.log('CreateEntityAction (redo): LAST ACTION');
		}
		console.log(`CreateEntityAction (redo): ${this.entityType}/${this.entityId} isLast: ${isLast}`);
		let _entity = this.newValue;

		/**
		 * Do not create link by using this.newValue, otherwise the correct data of this.newValue will be changed !!!
		 */
		if (this.newValue instanceof LinkEntity) {
			(_entity as any) = LinkOperationsHelper.copyLink(this.newValue);
		} else if (this.newValue instanceof SublinkEntity) {
			(_entity as any) = LinkOperationsHelper.copySublink(this.newValue);
		}
		const { opType } = this.parentActionGroup;

		this.lookup.createEntity(_entity);
		store.mapController?.createEntity(_entity, undefined, opType);
		if (isLast && opType === 'break_sublink') {
			if (_entity instanceof SublinkEntity) {
				const isUndo = false;
				if (!!store.mapController) {
					const isJoin = false;
					const helper = new UndoRedoHelper(store.mapController, this, isUndo, isLast);
					helper.processFinalAction(opType, isJoin, _entity);
				}
			} else {
				console.log('Wrong entity type. NOT processing final action');
			}
		} else if (isLast && opType === 'break_link') {
			if (_entity instanceof LinkFromLinkTo) {
				if (!!store.mapController) {
					const isUndo = false;
					const isJoin = false;
					const helper = new UndoRedoHelper(store.mapController, this, isUndo, isLast);
					helper.processFinalAction(opType, isJoin, _entity);
				}
			} else {
				console.log('Wrong entity type. NOT processing final action');
			}
		}
		return {
			entityId: this.entityId,
			actionType: this.actionType,
			actionId: this.actionId,
			entityDetail: '',
			entityType: this.entityType,
		};
	}

	public save(mode: SaveActionType) {
		const saveObject = super.save(mode);

		saveObject.actionType = mode === 'apply' ? 'create' : 'delete';

		saveObject.delta = {
			value: mode === 'apply' ? this.newValue.toJSON(this.referencePath) : undefined,
		};

		saveObject.actionType = mode === 'apply' ? 'create' : 'delete';

		return saveObject;
	}
}

export class DeleteEntityAction<T extends Model> extends UndoRedoAction<T> {
	private readonly referencePath: ReferencePath;
	// eslint-disable-next-line max-len
	constructor(deletedModel: T, modelType: { new(m: Partial<T>): T }, lookup: MapLookup, referencePath?: ReferencePath) {
		super('delete', lookup, deletedModel.getModelId(), deletedModel.getModelName());
		this.oldValue = new modelType(deepCopy(deletedModel));
		if (!!referencePath) {
			this.referencePath = referencePath;
		}
	}

	public undo(isLast: boolean): IUndoRedoResult {
		if (isLast) {
			console.log('DeleteEntityAction (undo): LAST ACTION');
		}
		console.log(`DeleteEntityAction (undo): ${this.entityType}/${this.entityId} isLast: ${isLast}`);
		let _entity = this.oldValue;

		/**
		 * Do not create link/sublink by using this.oldValue, otherwise the correct data of this.oldValue will be changed !!!
		 */
		if (this.oldValue instanceof LinkEntity) {
			(_entity as any) = LinkOperationsHelper.copyLink(this.oldValue);
		} else if (this.oldValue instanceof SublinkEntity) {
			(_entity as any) = LinkOperationsHelper.copySublink(this.oldValue);
		}
		const { opType } = this.parentActionGroup;
		this.lookup.createEntity(_entity);
		store.mapController?.createEntity(_entity, undefined, opType);
		if (isLast) {
			const isUndo = true;
			if (opType === 'join_link') {
				if (_entity instanceof LinkFromLinkTo) {
					if (!!store.mapController) {
						const isJoin = false;
						const helper = new UndoRedoHelper(store.mapController, this, isUndo, isLast);
						helper.processFinalAction(opType, isJoin, _entity);
					}
				} else {
					console.log('Wrong entity type. NOT processing final action');
				}
			}
		}
		return {
			entityId: this.entityId,
			actionType: this.actionType,
			actionId: this.actionId,
			entityDetail: '',
			entityType: this.entityType,
		};
	}

	public redo(isLast: boolean): IUndoRedoResult {
		if (isLast) {
			console.log('DeleteEntityAction (redo): LAST ACTION');
		}
		console.log(`DeleteEntityAction (redo): ${this.entityType}/${this.entityId} isLast: ${isLast}`);
		const entity = this.lookup.getEntity(this.entityId, this.entityType);
		const { opType, actionGroupId } = this.parentActionGroup;
		this.lookup.deleteEntity(this.entityId, this.entityType);
		store.mapController?.deleteEntity(entity, undefined, opType);

		if (isLast) {
			const isUndo = false;
			if (opType === 'join_sublink') {
				if (entity instanceof SublinkEntity) {
					if (!!store.mapController) {
						const isJoin = true;
						const helper = new UndoRedoHelper(store.mapController, this, isUndo, isLast);
						helper.processFinalAction(opType, isJoin, entity);
					}
				} else {
					console.log('Wrong entity type. NOT processing final action');
				}
			} else if (opType === 'join_link') {
				if (entity instanceof LinkEntity) {
					if (!!store.mapController) {
						const isJoin = true;
						const helper = new UndoRedoHelper(store.mapController, this, isUndo, isLast);
						helper.processFinalAction(opType, isJoin, entity);
					}
				} else {
					console.log('Wrong entity type. NOT processing final action');
				}
			}
		}

		return {
			entityId: this.entityId,
			actionType: this.actionType,
			actionId: this.actionId,
			entityDetail: '',
			entityType: this.entityType,
		};
	}

	public save(mode: SaveActionType) {
		const saveObject = super.save(mode);

		saveObject.actionType = mode === 'apply' ? 'delete' : 'create';

		saveObject.delta = {
			value: mode === 'apply' ? undefined : this.oldValue.toJSON(this.referencePath),
		};

		saveObject.actionType = mode === 'apply' ? 'delete' : 'create';

		return saveObject;
	}
}
