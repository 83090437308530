import MapStateHandler from './MapStateHandler';
import { LeafletMouseEvent } from 'leaflet';
import Link from '../MapObjects/Link/Link';
import { LinkEntity, SignalSetEntity } from '../../../../Models/Entities';
import LinkConnectivityDisplayHelper from '../MapStateHandlerHelpers/LinkConnectivityDisplayHelper';
import { defaultContextMenuOptions } from 'Views/MapComponents/RightClickContextMenu';
import LinkOperationsHelper from '../MapStateHandlerHelpers/LinkOperationsHelper';
import LinkConnectivityEditHelper from '../MapStateHandlerHelpers/LinkConnectivityEditHelper';
import Signal from '../MapObjects/Signal/Signal';
import { setCustomTag } from '../Helpers/MapUtils';

interface ILinkEditHandler {
	mapObject?: Link;
}

export default class PathSelectHandler extends MapStateHandler {
	private linkEntity: LinkEntity;
	private linkMapObject: Link;
	private isLinkDeleted: boolean = false;
	private linkConnectivityDisplayHelper: LinkConnectivityDisplayHelper;
	private signalSetMapObject: Signal;
	private displaySignalTimeout: any;
	private signalViewMenuStatus: boolean;

	onInit({ mapObject }: ILinkEditHandler) {
		if (!mapObject) {
			this.getEventHandler().setActiveTool('selector');
			return;
		}
		this.linkMapObject = mapObject;
		this.linkEntity = this.linkMapObject.getLinkEntity();
		this.signalViewMenuStatus = this.getLookup().getIsViewMenuShown().signalSet;
		/**
		 * Display turn siganl
		 */
		const signalSetEntity = this.getLookup().getSignalSetByLinkId(this.linkEntity.id);
		if (!!signalSetEntity) {
			this.signalSetMapObject = this.getLookup().getMapObjectByEntity(signalSetEntity, 'signal') as Signal;
			
			const isLinkShownInLayersPanel = this.getLookup().getLinkLayersPanelShownStatus(this.linkEntity.id);
			// If a Link status in layers panel is shown and the link is selected,
			// the signal should be shown (don't care signal view menu status)
			if (!!this.signalSetMapObject && (isLinkShownInLayersPanel ?? true)) {
				const _this = this;
				// When a user double clicks on a link, the logic flow is going to select mode (PathSelectHandler) and then going to edit mode (ClothoidEditHandler),
				// which means it must show signal for a fraction of a second (before going to edit mode).
				// To fix this issue, set a small timeout 0.1s to delay rendering.
				// If it doesn’t change to edit mode over 0.1s, render signal and if it changes to edit mode within 0.1s, do not render signal.
				this.displaySignalTimeout = setTimeout(() => {
					_this.signalSetMapObject.displayGraphic(true);
					_this.getRenderer().rerender();
				}, 100);
			}
		}
		/**
		 * Display link connectivity
		 */
		this.linkConnectivityDisplayHelper = new LinkConnectivityDisplayHelper(
			this.getRenderer(),
			this.getLookup(),
			this.linkEntity,
		);
		this.linkConnectivityDisplayHelper.renderConnectivity();

		this.getEventHandler().addListener('onUpdateSignal', this.onUpdateSignal);
	}

	/**
	 * Set the updated Link Entity
	 * Also updates the mapObject
	 * @param updatedLinkEntity
	 */
	public setLinkEntityAndUpdateMapObject(updatedLinkEntity: LinkEntity) {
		this.linkEntity = updatedLinkEntity;
		const mapObjectID = this.getLookup().getMapObjectId(this.linkEntity.id, 'link');
		this.linkMapObject = this.getRenderer().getObjectById(mapObjectID) as Link;
	}

	/**
	 * Returns the instance of link connectivity display helper
	 */
	getLinkConnectivityDisplayHelper() {
		return this.linkConnectivityDisplayHelper;
	}

	/**
	 * Re-renders the connectivity graphic on emitting the event
	 * @param entity
	 */
	onRequestUpdate(entity?: unknown) {
		if (entity) {
			const _entity = entity as LinkEntity;
			this.linkConnectivityDisplayHelper.renderUpdatedConnectivity(_entity);
		}
	}

	/**
	 * On click event
	 * @param event
	 */
	onClick(event: LeafletMouseEvent) {
		/**
		 * Allow other map objects to be selected
		 */
		const mapObject = this.getController().getMapObjectAtCoordinates(event.latlng);
		if (!mapObject || mapObject?.getId() !== this.linkMapObject.getId()) {
			// hide turn signal when click away if signal view menu status is hidden
			this.signalViewMenuStatus = this.getLookup().getIsViewMenuShown().signalSet;
			if (!!this.signalSetMapObject && !this.signalViewMenuStatus) {
				this.signalSetMapObject.displayGraphic(false); 
			}
			this.getEventHandler().setMapEventState('selector', { mapObject });
		}
	}

	onMove(event: LeafletMouseEvent) {
	}

	public onDoubleClick(event: LeafletMouseEvent): void | Promise<void> {
		// hide turn signal
		if (!!this.signalSetMapObject) {
			this.signalSetMapObject.displayGraphic(false);
		}
		// Take link directly into EDIT mode. Remain on selector tool.
		this.getEventHandler().setMapEventState('edit_clothoid', {
			linkEntity: this.linkEntity
		});
	}

	/**
	 * On right click event
	 * @param event
	 */
	onRightClick(event: LeafletMouseEvent) {
		/**
		 * Allow other map objects to be selected
		 */
		const mapObject = this.getController().getMapObjectAtCoordinates(event.latlng);
		if (!!mapObject) {
			if (mapObject instanceof Link) {
				mapObject?.getId() !== this.linkMapObject.getId()
					? this.getEventHandler().setMapEventState('selector', { mapObject })
					: null;
				LinkOperationsHelper.linkRightClickMenu(event, this.getEventHandler(), mapObject);
			} else {
				LinkOperationsHelper.handleConnectivityRightClick(event,
					mapObject,
					this.linkConnectivityDisplayHelper,
					this.getEventHandler());
			}
		} else {
			this.getEventHandler().emit('onCustomContextMenu', defaultContextMenuOptions, event);
		}
	}

	/**
	 * Delete unconfirmed bay when Delete or Backspace is pressed
	 * Confirm bay when Enter is pressed (same as double click)
	 * @param event
	 */
	onKeyPress(event: KeyboardEvent) {
		if (['Delete', 'Backspace'].includes(event.key)) {
			this.handleLinkDelete();
		}

		if (event.key === 'N' && event.shiftKey) {
			this.getEventHandler().setMapEventState('select_confirmed_path', this.linkMapObject);
		}
	}

	/**
	 * Deletes the link and its associated entities (sublinks and nodes)
	 */
	private handleLinkDelete = () => {
		setCustomTag('map-interface', 'delete/cancel-a-waypoint (edit mode)');
		console.log('Deleting selected link');

		const eventHandler = this.getEventHandler();

		const _sublink = {
			drivingZone: {},
			nodess: {},
		};

		const linkReferencePath = {
			sublinkss: _sublink,
			linkTos: {},
			linkFroms: {},
		};

		// LOOKUP ordered nodes and sublinks
		this.getEventHandler().emit('onTrackDelete', this.linkEntity, linkReferencePath);
		// TODO: should be handled by deleteEntity
		/**
		/* Nullify all the link froms and linktos
		*/
		this.linkConnectivityDisplayHelper.hideConnectivity();
		LinkConnectivityEditHelper.removeConnectivityFromAssociatedLinks(this.linkEntity, this.getLookup());

		eventHandler.getController().removeMapObject(this.linkEntity, this.linkMapObject, true);
		eventHandler.getRenderer().removeObject(this.linkMapObject.getParent()?.getId() ?? '');
		this.getRenderer().rerender();

		/**
		 * Handle layers panel change
		 */
		 eventHandler.emit('onMapObjectDelete', this.linkEntity);
		/**
		 * Set the isLinkDeleted flag to true to help the connectivity disposal
		 */
		this.isLinkDeleted = true;
		console.log('Selected link deleted');
		eventHandler.emit('onPropertiesPanel', 'map');
		eventHandler.setMapEventState('selector'); 
		eventHandler.emitPathEditedEvent();
	}

	/**
	 * Used for updating a turn signal from properties panel or hitting undo/redo
	 */
	private onUpdateSignal = (signalSetMapObject: Signal) => {
		this.signalSetMapObject = signalSetMapObject;
	}

	/**
	 * On escape key press
	 * @param event
	 */
	onEscapePressed(event: KeyboardEvent) {
		// hide turn signal if signal view menu status is hidden (don't care a Link status in layers panel)
		this.signalViewMenuStatus = this.getLookup().getIsViewMenuShown().signalSet;
		if (!!this.signalSetMapObject && !this.signalViewMenuStatus) {
			this.signalSetMapObject.displayGraphic(false);
		}
		this.getEventHandler().setActiveTool('selector');
	}

	public getLinkEntity() {
		return this.linkEntity;
	}

	public setIsLinkDeleted(isDeleted: boolean) {
		this.isLinkDeleted = isDeleted;
	}

	dispose() {
		clearTimeout(this.displaySignalTimeout);
		/**
		 * Hide and rerender the connectivity only if
		 * the link is not deleted
		 */
		if (!this.isLinkDeleted && !!this.linkConnectivityDisplayHelper) {
			// TODO: check there's no bug on undo/redo when a link is
			// removed but this method is called
			this.linkConnectivityDisplayHelper.hideConnectivity();

			// Hide any signals
			this.signalSetMapObject?.displayGraphic(false);

		}
		this.getEventHandler().removeListener('onUpdateSignal', this.onUpdateSignal);
		console.log('LinkToolHandler disposer');
	}
}
