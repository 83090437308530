import { LinkEntity, SublinkEntity } from 'Models/Entities';
import * as uuid from 'uuid';
import axios from 'axios';
import {
	CreateEntityAction, DeleteEntityAction, IUndoRedoResult, PropertyUpdateAction, UndoRedoAction,
} from './UndoRedoAction';
import MapStore, { IDrivingZoneUpdate } from '../Map/MapStore';
import { Model, ReferencePath } from '../../../Models/Model';
import { MOMENT_FORMAT_STRING, SERVER_URL } from '../../../Constants';
import moment from 'moment-timezone';
import alertToast from 'Util/ToastifyUtils';
import { getJsonObject } from '../Map/Helpers/GeoJSON';
import { store } from '../../../Models/Store';
import DynamicScaleObjectHelper from '../Map/MapStateHandlerHelpers/DynamicScaleObjectHelper';
import { validateFullMapPathsInterference, validateOnClientSide, validateOnServerSide } from '../Map/Helpers/FullMapValidation';
import MapController from '../Map/MapController';
import NodeValidator from '../Map/MapValidators/NodeValidator';

export type ActionGroupType = 'generic_delete' | 'generic_create' | 'generic_update' | 'join_link' | 'break_link' | 'break_sublink' | 'join_sublink' | 'clothoid_update' | 'unknown' | 'props_update'
								| 'connectivity_create' | 'connectivity_update' | 'connectivity_delete';
export interface ActionGroup {
	actionGroupId: string; // Used to track changes actions which have not been applied to the server yet
	actions: UndoRedoAction<unknown>[];
	opType?: ActionGroupType; // Set on commitAction
}

export interface Action {
	actionId: string; // Used to reference values coming back from the server (eg. id of an object is updated)

	entityId: string;
	entityType: string;

	actionType: 'create' | 'modify' | 'delete',
	delta: Delta<any> | undefined;
}

interface Delta<T> {
	attribute?: string;
	value?: T;
}

interface SaveAction {
	mode: 'apply' | 'unapply';
	action: ActionGroup;
}

interface ISaveDrivingZonesResult
{
	succeededIds: string[];
	failedIds: string[];
	returnMessage: string;
}

export default class UndoRedoTracker {
	public undo: ActionGroup[] = [];
	public redo: ActionGroup[] = [];

	public unsavedActions: SaveAction[] = [];

	private currentActionGroup: ActionGroup | undefined;

	// DO NOT USE DIRECTLY
	private isBayTrackingDisabled: boolean = false;

	private readonly maxStackSize;
	private readonly lookup: MapStore;
	private readonly map: MapController;

	private _savingInProgress = false;
	private _blockingUndoRedo = false;

	constructor(lookup: MapStore, map: MapController, maxStackSize = 10) {
		this.maxStackSize = maxStackSize;
		this.lookup = lookup;
		this.map = map;
	}

	public getObjectAndTrackChanges = <T extends Model>(
		id: string,
		objectType: { new(): Model }): T | undefined => {
		const getProxy = <F extends Model>(entity: F) => new Proxy(
			entity,
			new ModelHandler(this, entity),
		) as F;

		const obj = this.lookup.getEntity(id, objectType) as T;
		if (!obj) {
			return undefined;
		}

		const group: ActionGroup = {
			actionGroupId: uuid.v4(),
			actions: [],
		};

		return getProxy<T>(obj);
	}

	public get savingInProgress() {
		return this._savingInProgress;
	}

	private set savingInProgress(v: boolean) {
		this._savingInProgress = v;
	}

	public get blockingUndoRedo() {
		return this._blockingUndoRedo;
	}

	private set blockingUndoRedo(v: boolean) {
		this._blockingUndoRedo = v;
	}

	// properties panel undo/redo actions
	public trackChangesOnObject = <T extends Model>(obj: T, attributeGroups?: AttributeGroup[]): T => {
		const getProxy = <F extends Model>(entity: F) => new Proxy(
			entity,
			new ModelHandler(this, entity, attributeGroups),
		) as F;

		return getProxy<T>(obj);
	}

	/**
	 * Simple create of entity. Sets up undo/redo and saves
	 * @param newObject 
	 * @param modelType 
	 * @param referencePath 
	 */
	public genericCreateGroup = async<T extends Model>(
		newObject: T,
		modelType: { new(m: Partial<T>): T },
		referencePath?: ReferencePath
	) => {
		this.createEntity(newObject, modelType, true, referencePath);
		await this.saveChanges();
	}
	
	/**
	 * Simple delete of entity. Sets up undo/redo and saves
	 * @param deletedObject 
	 * @param modelType 
	 * @param referencePath 
	 */
	public genericDeleteGroup = async<T extends Model>(
		deletedObject: T,
		modelType: { new(m: Partial<T>): T },
		referencePath?: ReferencePath
	) => {
		this.deleteEntity(deletedObject, modelType, true, referencePath);
		await this.saveChanges()
	};

	public createEntity = <T extends Model>(
		newObject: T,
		modelType: { new(m: Partial<T>): T },
		commitAction: boolean = true,
		referencePath?: ReferencePath) => {
		this.doChange(
			new CreateEntityAction(newObject, modelType, this.lookup, referencePath),
		);
		if (commitAction) {
			this.commitAction('generic_create');
		}
	};

	public deleteEntity = <T extends Model>(
		deletedObject: T,
		modelType: { new(m: Partial<T>): T },
		commitAction: boolean = true,
		referencePath?: ReferencePath) => {
		this.doChange(
			new DeleteEntityAction(deletedObject, modelType, this.lookup, referencePath),
		);
		if (commitAction) {
			this.commitAction('generic_delete');
		}
	};

	public propertyUpdateChange = <T extends Model>(
		originalObject: T,
		newObject: T,
		attribute: string,
	) => {
		const newAction = new PropertyUpdateAction(originalObject,
			newObject,
			attribute,
			this.lookup,
		);
		this.doChange(
			newAction,
		);
		// if (commitAction) {
		// IMPORTANT: moved to postCommit
		// this.lookup.updateEntity(newObject);
		// 	store.eventHandler.emit('onUpdateToolbarUndoRedo');
		// }
	}

	public doChange = (action: UndoRedoAction<unknown>) => {
		if (this.getIsBayTrackingDisabled()) {
			console.log(`*** Change Ignored due to getIsBayTrackingDisabled ***`);
			return;
		}

		if (action.hasChange()) {
			this.currentActionGroup = this.currentActionGroup ?? { actions: [], actionGroupId: uuid.v4() };
			// parent only set if action will be used
			action.parentActionGroup = this.currentActionGroup;
			this.currentActionGroup.actions.push(action);
		}
	};

	public postCommit = <T extends Model>(newObject: T) => {
		// this code was run upon commit of prop. TODO: check if necessary
		this.lookup.updateEntity(newObject);
		store.eventHandler.emit('onUpdateToolbarUndoRedo');	
	}

	public commitAction = (actionGroupType?: ActionGroupType) => {
		if (!this.currentActionGroup) {
			return;
		}

		this.currentActionGroup.opType = actionGroupType;

		this.undo.push(this.currentActionGroup);

		this.addToUnsavedList({
			mode: 'apply',
			action: this.currentActionGroup,
		});

		// Remove the first item of the list (can no longer undo this change)
		if (this.undo.length > this.maxStackSize) {
			this.undo.splice(0, 1);
		}

		this.currentActionGroup = undefined;

		// Because the redo stack is reset afterwards,
		// if the redo stack involves the first area/bay/path status changes
		// reset it
		this.redo.forEach(redoActions => {
			const backToInitialAreaActionId = this.map.getBackToInitialAreaActionId();
			const backToInitialBayActionId = this.map.getBackToInitialBayActionId();
			const backToInitialPathActionId = this.map.getBackToInitialPathActionId();
			redoActions.actions.forEach((action: UndoRedoAction<unknown>) => {
				switch (action.actionId) {
					case backToInitialAreaActionId:
						this.map.setBackToInitialAreaActionId(undefined);
						break;
					case backToInitialBayActionId:
						this.map.setBackToInitialBayActionId(undefined);
						break;
					case backToInitialPathActionId:
						this.map.setBackToInitialPathActionId(undefined);
						break;
				}
			});
		});

		// Set backToInitialXXXXActionId here instead of waiting for 15 seconds later auto saving
		// because import version status is updated immediately as well instead of waiting for 15 seconds
		this.undo[this.undo.length - 1].actions.forEach(action => this.recordBackToInitialActionId(action));

		// Reset the redo stack
		this.redo = [];
	}

	public undoAction = () => {
		return; // [HITMAT-2230] disable undo/redo for version 1
		if (this.undo.length < 1) {
			return;
		}

		const actionGroup = this.undo.pop();

		if (!!actionGroup) {
			this.redo.push(actionGroup);

			const { opType } = actionGroup;
			if (!!opType && this.blockUndoRedoButton(opType)) {
				// Block undo/redo button immediately
				// Otherwise, users can still hit buttons if saveChanges has not been called yet
				// Which causes saving issue
				this.blockingUndoRedo = true;
				this.map.getEventHandler().emit('onUpdateToolbarUndoRedo');
			}

			/**
			 * Reversing the order in which the operations should be performed.
			 * This is important precisely for the undo action of the break/join
			 * links/sublinks
			 */
			actionGroup.actions.reverse();
			// Undo each change
			const validUndoActions: UndoRedoAction<unknown>[] = [];
			const allUndoActions: IUndoRedoResult[] = [];
			actionGroup.actions.forEach((action: UndoRedoAction<unknown>, index, arr) => {
				const isLastActionOfGroup = index === (arr.length - 1);
				const undoAction = action.undo(isLastActionOfGroup);
				allUndoActions.push(undoAction);
				if (action.isUpdateAndRerenderMapObject !== false) {
					validUndoActions.push(action);
				}
			});

			actionGroup.actions = validUndoActions;

			this.addToUnsavedList({
				mode: 'unapply',
				action: actionGroup,
			});

			// TODO Rerender map based on updates

			validateOnClientSide(this.map);
			
			return allUndoActions;
		}

		return [];
	}

	public redoAction = () => {
		return; // [HITMAT-2230] disable undo/redo for version 1
		if (this.redo.length < 1) {
			return;
		}

		const actionGroup = this.redo.pop();
		if (!!actionGroup) {
			this.undo.push(actionGroup);

			const { opType } = actionGroup;
			if (!!opType && this.blockUndoRedoButton(opType)) {
				// Block undo/redo button immediately
				// Otherwise, users can still hit buttons if saveChanges has not been called yet
				// Which causes saving issue
				this.blockingUndoRedo = true;
				this.map.getEventHandler().emit('onUpdateToolbarUndoRedo');
			}

			this.addToUnsavedList({
				mode: 'apply',
				action: actionGroup,
			});
			/**
			 * Reversing the order in which the operations should be performed.
			 * This is important precisely for the redo action of the break/join
			 * links/sublinks
			 */
			actionGroup.actions.reverse();
			// Re-apply each change
			const allRedoneActions = actionGroup.actions.map((action: UndoRedoAction<unknown>, index, arr) => action.redo(index === (arr.length - 1)));

			// TODO Rerender map based on updates

			return allRedoneActions;
		}

		return undefined;
	}

	private blockUndoRedoButton(opType: ActionGroupType) {
		if (opType === 'join_link' || opType === 'break_link'
				|| opType === 'break_sublink' || opType === 'join_sublink') {
			return true;
		}
		return false;
	}

	public saveChanges = async (isAutoSave = false, isClickOnHomeButton = false) => {
		// TODO: reanable save!!
		// console.log("Ignoring save");
		// return;
		const isPendingDrivingZoneUpdate = this.lookup.hasPendingDrivingZoneUpdate();
		const isUnsavedActions = this.unsavedActions.length > 0;
		if (this.savingInProgress) {
			console.log(`saveChanges: Not saving - save already in progress. isAutoSave: ${isAutoSave}`);
			return;
		}
		const isNothingToSave = !isUnsavedActions && !isPendingDrivingZoneUpdate;
		if (isNothingToSave) {
			return;
		}
		if (isPendingDrivingZoneUpdate && !isUnsavedActions && isAutoSave) {
			console.log(`saveChanges: Not saving (pendingDrivingZoneUpdate). isAutoSave: ${isAutoSave}`);
			// Edit a path and make it be with error (Validation is called as usual)
			// -> undo (Validation is called as usual)
			// -> redo (Validation is not called because saveChanges() stop here, so add validation here for this edge case)
			// const showErrorsWarnings = false;
			// validateFullMap(this.map, showErrorsWarnings);
			// TODO: After HIT-931 has been fixed, the full map vallidation here should be able to be removed
			return;
		}

		this.savingInProgress = true;
		this.map.getEventHandler().emit('onUpdateToolbarUndoRedo');

		const { eventHandler } = store;
		eventHandler.emit('onAutoSave', false);

		let returnEarly = false;

		if (isUnsavedActions) {
			const saveActions = this.unsavedActions;
			const actionsSaved: string[] = [];

			const actionGroups = saveActions.map(saveAction => {
				actionsSaved.push(saveAction.action.actionGroupId);
				// savePatch associated with onTrackBreakSublink/onTrackJoinSublink
				// where by ref must be set to undefined to avoid dup key issue.
				const savePatches = this.lookup.getSavePatches();
				return {
					actions: saveAction.action.actions.map(action => {
						let result: Action | undefined;
						const { parentActionGroup, entityId, actionType, entityType } = action;
						const patch = savePatches.find(p => (p.actionGroupId === parentActionGroup.actionGroupId
							&& p.entityId === entityId
							&& p.entityType === entityType
							&& p.actionType === actionType)
						);
						if (!!patch) {
							let actionValue: any = undefined;
							if (action instanceof DeleteEntityAction) {
								actionValue = action.oldValue;
							}
							else if (action instanceof CreateEntityAction) {
								actionValue = action.newValue;
							} else {
								console.log('Unexpected action type. Skipping save patch');
							}

							if (!!actionValue){
								// Revert the value just for delta, after which it can be restored
								const { attribute, revertToValue } = patch
								console.log(`Applying save patch ${entityId} ${entityType} ${actionType}`);
								console.log(`Changing ${attribute} from ${actionValue[attribute]} to ${revertToValue}`);
								const attributeValueToRestore = actionValue[attribute];
								actionValue[attribute] = revertToValue;
								result = action.save(saveAction.mode);
								actionValue[attribute] = attributeValueToRestore;
							}
						}
						return result ?? action.save(saveAction.mode);
					}),
				};
			});

			const importVersionId = this.lookup.getImportVersion().id;
			const lockKey = store.getMapLockSession(importVersionId)?.lockKey;

			await axios.post(`${SERVER_URL}/api/entity/MapEntity/save`, {
				importVersionId,
				lockKey,
				actionGroups,
			}).then(result => {
				this.unsavedActions = this.unsavedActions.filter(saveAction => !actionsSaved
					.includes(saveAction.action.actionGroupId));
				eventHandler.emit('onAutoSave', true, result.data);
				const { mapStore, mapController } = store;
				if (mapStore.isPendingDynamicConnectionUpdate) {
					mapStore.isPendingDynamicConnectionUpdate = false;
					console.log(`Processing pending dynamic connection update.`);
					if (!!mapController) {
						mapStore.setCommitDynamicConnections(false);
						DynamicScaleObjectHelper.updateDynamicObjectDisplay(mapController);
					}
				}
				// Clear save patches only after success
				this.lookup.clearSavePatches(actionsSaved);
			}).catch(e => {
				console.log('Failed saving the entity', e);
				returnEarly = true;

				if (e.message === 'Network Error') {
					eventHandler.emit('onAutoSave', true, true);
					return;
				} else if (e.message === 'Request failed with status code 401') {
					alertToast('Unable to save. The current map session is invalid. Please refresh and try again', 'error');
				} else {
					alertToast('Save failed. Page refresh required.', 'error');
				}
				eventHandler.emit('onAutoSave', true);
			}).finally(async () => {
				if (returnEarly) {
					return;
				}

				console.log(actionGroups);
				// If go back to Map Import Overview page by clicking on Home button
				// Don't need to do full map validation
				if (!isClickOnHomeButton) {
					actionGroups.forEach(actionGroup => {
						actionGroup.actions.forEach(action => {
							this.recordBackToInitialActionId(action);
							if (action.actionType !== 'delete') {
								const type = action.entityType;
								if (type === 'LinkEntity') {
									const sublinks = action.delta?.value?.sublinkss as SublinkEntity[];
									if (!!sublinks) {
										sublinks.forEach((sublink) => {
											sublink.nodess.forEach(node => {
												const nodeEntity = this.lookup.getEntity(node.id, 'NodeEntity');
												validateOnClientSide(this.map, nodeEntity);
											});
											const sublinkEntity = this.lookup.getEntity(sublink.id, 'SublinkEntity');
											validateOnClientSide(this.map, sublinkEntity);
										});
									}
									if (action.delta?.attribute === 'state') {
										// LinkEntity cannot update the layers panel label by calling validateOnClientSide
										// Hence, update here
										const linkEntity = this.lookup.getEntity(action.entityId, LinkEntity);
										eventHandler.emit('onMapObjectUpdate', linkEntity, action.entityId);
									}
								} else {
									const id = action.entityId;
									const entity = this.lookup.getEntity(id, type);
									// AreaEntity, BayEntity, SublinkEntity, and NodeEntity update the layers panel labels
									// after calling validateOnClientSide
									validateOnClientSide(this.map, entity);
								}
							} else {
								if (action.entityType === 'BayEntity' // Validate other existing bays' distances
								|| action.entityType === 'AreaEntity' // Validate start parking node
								) {
									validateOnClientSide(this.map);
								}
							}

							if (action.entityType === 'LinkEntity' && this.lookup.isCheckPathInterference) {
								validateFullMapPathsInterference(this.map);
							}

							if (action.entityType === 'LinkEntity' || action.entityType === 'LinkFromLinkTo' ) {
								NodeValidator.validateStartParkingNodes(this.map);
							}
						});
					});
					if (!isPendingDrivingZoneUpdate) {
						console.log(`isPendingDrivingZoneUpdate = false. Do serverside validation later.`);
						await validateOnServerSide(this.map);
					}
					this.map.getEventHandler().emit('onErrorCountUpdate', this.map.getMapLookup().getMapErrorCount());
				}
			});
		}

		if (isPendingDrivingZoneUpdate && !returnEarly) {
			// LOOKUP
			const toUpdate: IDrivingZoneUpdate[] = this.lookup.getDrivingZoneUpdateEntries();
			await axios.post(`${SERVER_URL}/api/entity/LinkEntity/saveDrivingZones`, {
				dzParams: toUpdate,
			}).then(({ data }) => {
				/*
				* Note that it's expected that not all will save (but this will not result in an exception)
				* Entities that are not saved have not yet been created, and their driving zone information
				* will be saved upon creation of the entity
				*/
				const result = data as ISaveDrivingZonesResult;
				console.log('saveDrivingZones result:');
				result.failedIds.forEach(id => {
					console.warn(`saveDrivingZones: ${id} not saved`);
				});
				result.succeededIds.forEach(id => {
					console.log(`saveDrivingZones: ${id} saved`);
					this.lookup.deleteDrivingZoneUpdateEntry(id);
				});
				console.log('Doing serverside validation');
				return validateOnServerSide(this.map);
			}).then(result => {
				this.map.getEventHandler().emit('onErrorCountUpdate', this.map.getMapLookup().getMapErrorCount());
			}).catch(e => {
				// should not reach here
				console.warn('saveDrivingZoneData failed (shouldt reach here)');
				console.log(e.message);
				return null;
			});
		}

		this.blockingUndoRedo = false;
		this.savingInProgress = false;
		this.map.getEventHandler().emit('onUpdateToolbarUndoRedo');
	};

	/**
	 * Set the first area/bay/path status changes when saving or running commitAction
	 */
	private recordBackToInitialActionId = (action: Action | UndoRedoAction<unknown>) => {
		switch(action.entityType) {
			case 'AreaEntity':
				if (!this.map.getBackToInitialAreaActionId()) {
					this.map.setBackToInitialAreaActionId(action.actionId);
				}
				break;
			case 'BayEntity':
				if (!this.map.getBackToInitialBayActionId()) {
					this.map.setBackToInitialBayActionId(action.actionId);
				}
				break;
			case 'LinkEntity':
				if (!this.map.getBackToInitialPathActionId()) {
					this.map.setBackToInitialPathActionId(action.actionId);
				}
				break;
		}
	};

	private addToUnsavedList = (saveAction: SaveAction) => {
		if (this.unsavedActions.length === 0) {
			this.unsavedActions.push(saveAction);
			return;
		}

		const { action, mode } = this.unsavedActions[this.unsavedActions.length - 1];
		if (saveAction.action.actionGroupId === action.actionGroupId && saveAction.mode !== mode) {
			// If it is an undo change, remove the previous from the list
			this.unsavedActions.pop();
		} else {
			this.unsavedActions.push(saveAction);
		}
	}

	// TODO: refactor these three methods. Currently only used for bays.
	public getIsBayTrackingDisabled = () => {
		const currentMode = this.map.getEventHandler().getEventMode();
		const isBayMode = (currentMode == 'bay' || currentMode == 'edit_bay');
		return isBayMode && this.isBayTrackingDisabled;
	}

	public disableBayTrackingChanges = () => {
		this.isBayTrackingDisabled = true;
	};

	public enableBayTrackingChanges = () => {
		this.isBayTrackingDisabled = false;
	};

	public undoActionAvailable = () => this.undo.length > 0;

	public redoActionAvailable = () => this.redo.length > 0;

	public getActionCount = () => this.undo.length + this.redo.length;

	public getMaxStackSize = () => this.maxStackSize;

	public mergeLastTwoActions = () => {
		if (this.undo.length < 2) {
			return;
		}

		const lastAction = this.undo.pop();
		const actionToMergeWith = this.undo[this.undo.length - 1];

		actionToMergeWith.actions = actionToMergeWith.actions?.concat(lastAction?.actions ?? []) ?? [];
	};
}

interface AttributeGroup {
	modifiedProperty: string[];
	dependentProps?: string[];
	disableCommitWhenPropertyIs?: string[];
	ignore?: string[];
}

class ModelHandler<T extends Model> implements ProxyHandler<T> {
	private tracker: UndoRedoTracker;

	private originalEntity: T;

	private attributeGroups?: AttributeGroup[];
	private attributeGroupTracker = {};

	constructor(tracker: UndoRedoTracker, target: T, attributeGroups?: AttributeGroup[]) {
		this.tracker = tracker;
		this.attributeGroups = attributeGroups;
		this.originalEntity = deepCopy(target);
	}

	public set(target: Model, prop: string | symbol, value: any, receiver: any): boolean {
		if (prop === 'bayLocation') {
			const { coordinates } = getJsonObject(target[prop]);
			const _value = typeof value === 'string' ? JSON.parse(value) : value;
			if (coordinates[0] === _value.coordinates[0]
			&& coordinates[1] === _value.coordinates[1]) {
				return true;
			}
		}
		if (target[prop] === value) {
			return true;
		}
		target[prop] = value;
		this.attributeGroupTracker[prop] = true;

		let commitAction = true;
		if (!!this.attributeGroups) {
			if (this.attributeGroups.find(x => x.ignore?.includes(prop as string))) {
				return true;
			}
			const attributeGroup = this.attributeGroups.find(x => x.modifiedProperty.includes(prop as string));
			commitAction = (attributeGroup?.dependentProps?.every(x => this.attributeGroupTracker[x] === true) ?? true)
				// eslint-disable-next-line max-len
				&& (attributeGroup?.disableCommitWhenPropertyIs?.every(x => this.attributeGroupTracker[x] !== true) ?? true);
		}

		if (commitAction) {
			this.attributeGroupTracker = {};
		}

		this.tracker.propertyUpdateChange(this.originalEntity, target, prop.toString());

		if (commitAction) {
			// typically invoked by trackChangesOnObject
			this.tracker.commitAction('props_update');
			this.tracker.postCommit(target);
		}

		this.originalEntity[prop] = value;

		return true;
	}
}

export function deepCopy(obj: any): any {
	let copy;
	// console.log('deepCopy');
	// Handle the 3 simple types, and null or undefined
	if (!obj || typeof obj !== 'object') return obj;

	// Handle Date
	if (obj instanceof Date) {
		copy = new Date();
		copy.setTime(obj.getTime());
		return copy;
	}

	// Handle Array
	if (obj instanceof Array) {
		copy = [];
		for (let i = 0, len = obj.length; i < len; i++) {
			copy[i] = deepCopy(obj[i]);
		}
		return copy;
	}

	// Handle Object
	if (obj instanceof Object) {
		copy = {};
		for (const attr in obj) {
			if (obj.hasOwnProperty(attr)) {
				// TODO: Find a better way to avoid circular references
				// eslint-disable-next-line max-len
				const ignoreRefs = ['sublink', 'nextNode', 'previousNode', 'nextSublink', 'previousSublink', 'linkFrom', 'linkTo', 'link', 'mapObjectErrorss'];
				if (!ignoreRefs.includes(attr)) {
					copy[attr] = deepCopy(obj[attr]);
				} else {
					copy[attr] = undefined;
				}
			}
		}
		return copy;
	}

	throw new Error("Unable to copy obj! Its type isn't supported.");
}
