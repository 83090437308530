import MapStateHandler from './MapStateHandler';
import Ruler from '../MapObjects/Ruler/Ruler';
import DynamicScaleObjectHelper from '../MapStateHandlerHelpers/DynamicScaleObjectHelper';

interface IRulerEditHandler {
	mapObject?: Ruler;
}

export default class RulerEditHandler extends MapStateHandler {
	private rulerMapObject: Ruler;

	onInit({ mapObject }: IRulerEditHandler) {
		if (!mapObject) {
			this.getEventHandler().setActiveTool('selector');
			return;
		}
		this.rulerMapObject = mapObject;
	}

	onKeyPress(event: KeyboardEvent) {
		if (['Delete', 'Backspace'].includes(event.key)) {
			this.getEventHandler().setMapEventState('ruler');
		}
	}

	onEscapePressed(event: KeyboardEvent) {
		this.getEventHandler().setMapEventState('ruler');
	}

	deleteRuler() {
		if (!!this.rulerMapObject) {
			DynamicScaleObjectHelper.updateDynamicScaleObjects(false, [this.rulerMapObject.getId()], this.getController(), true);
			this.getRenderer().removeObject(this.rulerMapObject.getId());
			this.getRenderer().rerender();
		} else {
			console.log('No rulerMapObject. Something wrong.');
		}
	}

	dispose() {
		this.deleteRuler();
	}
}
