import { LeafletMouseEvent } from 'leaflet';
import type { LeafletCoordinates, RealWorldCoordinates } from '../Helpers/Coordinates';
import ClothoidStateHandler, {
	IClothoidRequestUpdate,
	IClothoidStateHandlerAttrs,
	MAX_HAULING_WAYPOINTS, MAX_PARKING_WAYPOINTS, MAX_REVERSE_WAYPOINTS
} from './ClothoidStateHandler';
import { action, runInAction } from 'mobx';
import { nodetask } from 'Models/Enums';
import { LinkEntity, NodeEntity } from 'Models/Entities';
import { isCtrlKeyHeld } from './MapGlobalEventHandler';
import alertToast from 'Util/ToastifyUtils';
import PathToolHelper from '../MapStateHandlerHelpers/PathToolHelper';
import { calcDistanceBetweenCoords, calcDistanceBetweenNodes, calcHeading, offsetAlongHeadingRealWorld, setCustomTag } from '../Helpers/MapUtils';
import { NodeGraphic } from 'Views/MapComponents';
import { MAX_PATH_DISTANCE } from 'Constants';
import { MINIMUM_DISTANCE_BETWEEN_WAYPOINTS } from 'Constants';
import { IPathRequestResponse, IPathResponseData, pathRequestError } from '../MapStateHandlerHelpers/PathRequestHelper';

export default class ClothoidCreateHandler extends ClothoidStateHandler {

	// used to prevent dispose from removing path from renderer during transition to EDIT handler
	private isTransitionToEdit: boolean = false;

	// Used to handle case where user attempts to set last waypoint but there are still pending requests
	private isSetLastWaitpoint = false;

	// Click corresponding to placement of waypoint other than the very first waypoint
	private isNextWaypointClick: boolean;

	// Used to restore start waypoint heading value when placement of second waypoint results in serverside error
	protected savedStartWaypointHeading: number = 0;

	
	private isSnapOperation: boolean = false;

	private isFirstWaypointShortclick: boolean = false;

	private isWaypointLimit: boolean = false;

	private isDynamicHeadingFinialised: boolean = false;

	private isBlockAddNextWaypoint: boolean = false;

	onInit(initialState: unknown) {
		// console.log('onInit: Entered clothoid CREATE handler');
		this.isDrawMode = true;
		this.waypointCreation = true;
		this.getEventHandler().addListener('onConfirmMapObjectCreation', this.onConfirmCreateClothoid);
		this.getEventHandler().addListener('onActivateHCMPG', this.onActivateHCMPG);
		this.initHelpers();
		if (!!initialState) {
			console.log('Existing link found on new clothoid creation');
		}
		this.initEvents();
		this.initNewLink();
	}

	initNewLink() {
		this.createNewLinkAndResetProperties();
	}

	dispose() {
		// console.log('DISPOSE create handler');
		super.dispose();
		this.getEventHandler().removeListener('onConfirmMapObjectCreation', this.onConfirmCreateClothoid);
		this.getEventHandler().removeListener('onActivateHCMPG', this.onActivateHCMPG);
		if (!this.isTransitionToEdit) {
			// anything drawn can be removed unless transitioning to EDIT handler
			this.removeCurrentPathObj();
		} else {
			console.log('Transition to EDIT handler. Do not remove path.');
		}
	}

	onConfirmCreateClothoid = async() => {
		if (this.isBusy()) {
			console.log(`onConfirmCreateClothoid: Ignoring due to incomplete operations`);
			return;
		}
		// debugger;
		const confirmedLinkEntity = await this.confirmClothoidPath();
		console.log('onConfirmCreateClothoid');
		if (!confirmedLinkEntity) {
			console.log(`Clothoid could not be confirmed`);
		} else {
			setCustomTag('map-interface', 'confirm-a-path-with-confirm-btn (draw mode)');
			await this.processConfirmedPath(confirmedLinkEntity);
		}
	}

	protected isBusy() {
		if (this.isTransitionToEdit) {
			console.log(`isBusy: isTransitionToEdit = true`);
			return true;
		}
		return super.isBusy();
	}

	async onKeyPress(event: KeyboardEvent) {
		if (this.isBusy())
			return;
			
		if (['Delete', 'Backspace'].includes(event.key)) {
			await this.onDeleteOrBackspace();
		}
		if (event.key === 'Enter') {
			this.onEnter();
		}
	}

	onEnter() {
		if (!this.isNextWaypointClick) {
			console.log(`Ignoring. isNextWaypointClick ${this.isNextWaypointClick}`);
			return;
		}
		if (this.waypoints.length < 3) {
			console.log(`Ignoring. Only ${this.waypoints.length} incl. dummy waypoint`);
			return;
		}
		this.waypointCreation = false;
		runInAction(() => {
			this.isDrawMode = false; // TODO: REMOVE
		});
		console.log('onEnter: Transition to EDIT handler');
		this.transitionToEditHandler(true);
	}

	/**
	 * Case 1: Over two waypoints placed - Remove waypoint
	 * Case 2: Two or less waypoints placed - Reinit handler/ properties
	 */
	async onDeleteOrBackspace() {
		const threeOrMoreWaypoints = this.waypoints.length > 3;
		if (!threeOrMoreWaypoints) {
			// If less than 3 waypoints just delete / revert path
			console.log(`onKeyPress: threeOrMoreWaypoints = ${threeOrMoreWaypoints}. Don't delete individual waypoint`);
		}
		if (this.waypointCreation && threeOrMoreWaypoints) {
			setCustomTag('map-interface', 'delete-a-waypoint (draw mode)');
			console.log('Delete individual waypoint');
			// TODO: Check that direction logic is correct
			let direction = this.endWaypoint.direction ?? this.startWaypoint.direction;
			// Remove last and second last waypoint from array. Second last is the waypoint placed. Last is the dummy waypoint to be placed.
			this.waypoints.splice(this.waypoints.length - 2, 2);
			if (this.waypoints.length === 2) {
				const firstNode = this.waypoints[0].node;
				direction = this.getDirectionFromSpeed(firstNode.speed);
			}
			if (!!direction) {
				// re-adding
				console.log(`setting endwaypoint direction to ${direction}`);
				this.endWaypoint.direction = direction;
				if (this.waypoints.length > 2) {
					// TODO: re-add
					// console.log(`setting currentStartWaypoint task back to ${task}`);
					// this.currentStartWaypoint.task = task;
					// this.currentStartWaypoint.direction = direction;
				} else {
					console.log(`NOT setting currentStartWaypoint (2 or less waypoints)`);
				}
			} else {
				console.log('not setting endwaypoint task');
			}
			// update path with remaining waypoints
			await this.sendClothoidPathRequest();
			// re-add dummy waypoint currently being placed
			this.addNextWaypoint();

			this.checkMidwaypointLimit();

			// re-draw guideline from the new endWaypoint
			this.fromGraphic = this.nodeGraphicById(this.endWaypoint);
			this.fromGraphic?.startGuideLine();
			// Attempt to reset properties
			const setReadOnlyDirection = true;
			const setReadOnlyTask = false;
			this.resetClothoidProperties(setReadOnlyDirection, setReadOnlyTask, direction, threeOrMoreWaypoints);
			// May the previous waypoint the end waypoint?
		} else {
			setCustomTag('map-interface', 'delete/cancel-a-waypoint (draw mode)');
			// Case 2: Less than three nodes placed. Reinit handler with fresh data.
			this.restoreLinks();
			this.processTSDErrorsForCancel();
			this.getEventHandler().setMapEventState('clothoid');
		}
	}

	/**
	 * Case 1: If two or more waypoints _placed_, transition to EDIT mode
	 * Case 2: If one waypoint _placed_, REMOVE it and reset
	 * Case 3: No waypoint placed, transition to SELECTOR mode
	 * @param event
	 * @returns 
	 */
	async onEscapePressed(event: KeyboardEvent) {
		if (this.isBusy())
			return;

		if (!this.waypointCreation) {
			if (this.isEndWaypointSnapped) {
				console.log(`onEscapePressed: Continue with waypointCreation = false. Special case isEndWaypointSnapped`);
			} else {
				console.log('waypointCreation = false. Ignoring')
				return;
			}
		}
		
		const tool = this.getController().getSelectedToolType();
		if (tool !== 'clothoid') {
			console.warn(`Unexpected tool ${tool}. Ignoring. (expected clothoid)`);
			return;
		}
		this.waypointCreation = false;
		const eventHandler = this.getEventHandler();
		const n = this.getNumberOfWaypointsPlaced();
		if (n < 1) {
			// no waypoint placed. transition to selector tool with bg image
			this.clearGuidelines();
			eventHandler.setActiveTool('selector');
		} else if (n === 1) {
			// start waypoint only. remove it and reset tool
			eventHandler.setMapEventState('clothoid');
		} else {
			// Pass it the existing link and object
			console.log('onEscapePressed: Transition to EDIT handler');
			this.transitionToEditHandler(true);
		}
	}

	/**
	 * Handlers transition to Edit handler after full creation of path
	 * e.g. Place waypoints -> Press enter -> Transition to Edit handler
	 * 
	 * @param isRemoveDummyWaypoint 
	 */
	private transitionToEditHandler(isRemoveDummyWaypoint: boolean) {
		runInAction(() => {
			// may not be necessary
			this.isDrawMode = false;
		});

		// To ensure path not removed in dispose
		this.isTransitionToEdit = true;

		this.clearGuidelines();

		if (isRemoveDummyWaypoint) {
			// Remove dummy waypoint for edit mode (waypoint in array but not placed)
			this.removeNextWaypoint();
		}

		// filter for attributes only (not strictly necessary)
		const attr = Object.entries(this)
			.filter(v => typeof v[1] !== 'function')
			.reduce((acc,item) => { 
				acc[item[0]] = item[1];
				return acc
			},{});

		console.log(`transitionToEditHandler: Transition with ${this.waypoints.length} waypoints`);

		// Since it's an unconfirmed, edit handler requires the current ClothoidStateHandler attributes 
		this.getEventHandler().setMapEventState('edit_clothoid',  {
			attr: attr,
		});
	}

	/**
	 * Number of waypoints placed on map (handles edge cases to give correct count)
	 * @returns 
	 */
	getNumberOfWaypointsPlaced() {
		const { waypoints, startWaypoint } = this;
		const isFirstWaypointPlaced = waypoints.length > 1 && !!startWaypoint.northing;
		if (!isFirstWaypointPlaced) {
			return 0;
		}
		// Two waypoints, where location has been set for first waypoint (see outer if statement) but not set for second waypoint
		const isOnlyFirstWaypointPlaced = waypoints.length === 2 && !waypoints[1].node.northing
		if (isOnlyFirstWaypointPlaced) {
			return 1;
		}
		return this.waypoints.length <= 3 ? 2 : (this.waypoints.length - 1);
	}

	clearGuidelines() {
		this.endWaypointGraphic?.clearGuideLine();
		if (this.waypoints.length > 1) {
			// After deletion of waypoints, sometimes the endWAypointGraphic is not set corretly and guideline
			// isn't cleared. This is a temp fix. TODO: fix propertly by updating endWaypointGraphic correctly
			const prevWaypointGraphic = this.nodeGraphicById(this.endWaypoint);
			prevWaypointGraphic?.clearGuideLine();
		} else {
			console.log(`waypoints.length = ${this.waypoints.length} (expected > 1)`, 'warn');
		}
	}

	/**
	 * Handle updates from properties panel (invoked from from mapEventHandler)
	 * @param requestUpdateParams 
	 */
	async onRequestUpdate(requestUpdateParams?: IClothoidRequestUpdate) {
		super.onRequestUpdate(requestUpdateParams);

		let isRemoved = false;
		if (this.isDrawMode && this.waypointCreation) {
			// Remove latest waypoint placed in draw mode
			isRemoved = this.removeNextWaypoint();
		}
		const minWaypoints = 2;
		if (this.getNumberOfWaypointsPlaced() >= minWaypoints) {
			await this.sendClothoidPathRequest();
		} else {
			console.log(`Not sending new request. Only ${this.waypoints.length} waypoints`);
		}

		if (isRemoved) {
			console.log("onRequestUpdate: Removed waypoint, now re-adding (addNextWaypoint)");
			this.addNextWaypoint();
			this.fromGraphic = this.nodeGraphicById(this.endWaypoint);
			this.fromGraphic?.startGuideLine();
		}
		this.checkMidwaypointLimit();
		
	}

	// Process response to async request
	public onAsyncRequestResponse(result: IPathRequestResponse) {
		super.onAsyncRequestResponse(result);
		if (result.isIgnore) {
			return;
		}
		if (this.isAsyncRequestComplete()) {
			setCustomTag('map-interface', 'create-a-path (long click)');
			// logic after processing of final update
			if (this.isDynamicHeadingFinialised) {
				// implies no more pending requests
				console.log('onAsyncRequestResponse: Got final response. Set guideline');
				this.isDynamicHeadingFinialised = false;
				this.afterSetDynamicHeading();
			}
		}
	}

	/**
	 * Sets the 'final' value of the heading after it was adjusted via the mouse
	 * and clears the heading graphic. Called via onDragEnd.
	 * @returns
	 */
	protected setDynamicHeading() {
		this.getController().setDefaultCursor();
		if (!this.isNextWaypointClick) {
			console.log(`setDynamicHeading: isNextWaypointClick is false. Not adding next waypoint`);
			this.startWaypointGraphic?.startGuideLine();
			this.startWaypointGraphic = undefined;
		} else {
			console.log("setDynamicHeading: Since it's not first waypoint and longclick, add the next waypoint");
			this.endWaypointGraphic?.clearDynamicHeading();
			this.endWaypointGraphic = undefined;

			if (this.isAsyncRequestComplete()) {
				// Not waiting for request. Finialise heading.
				this.afterSetDynamicHeading();
			} else {
				// Still waiting for request. Set flag to finalise when request returns
				this.isDynamicHeadingFinialised = true;
			}
		}
	}

	/**
	 * Final steps to set dynamic heading
	 */
	protected async afterSetDynamicHeading() {
		const err = this.getLastRequestErrorAndClear();
		if (err === pathRequestError.NONE) {
			// If still in draw mode, add next waypoint and draw guide line
			this.addNextWaypoint();
			// The point of using fromGraphic is that the line will be cleared upon successful request
			this.fromGraphic = this.nodeGraphicById(this.endWaypoint);
			this.fromGraphic?.startGuideLine();
			this.checkMidwaypointLimit();
		} else {
			this.processLongClickErrors(err);
		}
		this.clearWaitingFlag();
	}

	// Used when transitioning from draw/waypoint creation mode into edit mode
	// Remove the waypoint that was added with addNextWaypoint
	protected removeNextWaypoint() { // TODO:
		// whether or not to remove waypoint
		let isRemove = false;
		if (this.waypoints.length > 2) {
			// NOTE: code is intentionally verbose 
			// Check the see if currentStartWaypoint (this.waypoints[this.waypoints.length - 2]) is either invalid
			// or just a duplicate of endwaypoint (i.e. it has been created with addNextWaypoint but no new values
			// have been set)
			// If it's invalid or a duplicate, its safe to remove
			const isInvalid = !PathToolHelper.isWaypointValid(this.currentStartWaypoint);
			if (isInvalid) {
				isRemove = true;
				// console.log("removeNextWaypoint: waypoint not valid");
			} else {
				const isDuplicate = PathToolHelper.areWaypointsEqual(this.currentStartWaypoint, this.endWaypoint);
				if (isDuplicate) {
					isRemove = true;
				} else {
					// console.log("removeNextWaypoint: waypoint not duplicate");
				}
			}
		}
		const remainingText = `${this.waypoints.length} waypoints remaining`;
		if (isRemove) {
			this.waypoints.splice(this.waypoints.length - 2, 1);
			// console.log(`removeNextWaypoint: Waypoint removed. ${remainingText}`);
		} else {
			// console.log(`removeNextWaypoint: Waypoint NOT removed.  ${remainingText}`);
		}
		return isRemove;
	}

	// Endwaypoint must always be the same entity, hence new waypoints must be added before it
	@action
	protected addNextWaypoint(): boolean {
		if (!this.waypointCreation) {
			console.log('addNextWaypoint: Ignored because waypointCreation = false');
			return false;
		}
		
		// On longclick, this should ONLY happen after the heading is set.
		const newNode = new NodeEntity({ ...this.endWaypoint, id: undefined });
		newNode.id = newNode._clientId;

		// HITMAT-921: When drawing a path with multiple waypoints, if I select a special task in the middle of drawing, that task is incorrectly given to the mid-waypoints.
		let isAddingMidReversePoint = false; 
		// if (this.waypoints.length > 1 && this.waypoints.length < 5) {
		if (this.waypoints.length > 1) {
			// console.log(`ADDING NODE number ${this.waypoints.length}`);
			if (this.endWaypoint.task === 'REVERSEPOINT') {
				isAddingMidReversePoint = true;
			} else {
				newNode.isMidWaypoint = true;
			}
		}
		newNode.task = isAddingMidReversePoint ? 'REVERSEPOINT' : 'HAULING';
		
		//console.log(`Adding waypoint as 2nd last with task ${newNode.task}`);
		if (isAddingMidReversePoint) {
			// newNode.heading = this.calcReverseHeading(newNode.heading);
			//this.currentSegmentDirection = this.endWaypoint.direction === 'forward' ? 'reverse' : 'forward';
			//console.log(`Setting custom direction to ${this.currentSegmentDirection}`);
			// TODO/FIXME: update endpoint direction at the correct time
			const nodes = PathToolHelper.getAllNodesOfLink(this.clothoidLinkEntity).reverse();
			const index = nodes.findIndex(x => !!x.speed);
			if (index > -1) {
				const node = nodes[index];
				const prevDirection = this.endWaypoint.direction; 
				// If a reverse point is added, the following segment direction turn the other way round
				const newDirection = node.speed > 0 ? 'reverse' : 'forward';
				if (prevDirection !== newDirection) {
					this.endWaypoint.direction = newDirection;
				}
			}
		}

		// add in 2nd last position (last waypoint must not change)
		this.waypoints.splice(this.waypoints.length - 1, 0, {
			node: newNode,
			isReverse: isAddingMidReversePoint,
		});
		newNode.id = newNode._clientId;
		if (this.waypoints.length > 3) {
			// Currently the last two waypoints can be anything but HAULING
			this.waypoints.slice(0, this.waypoints.length - 2).forEach(w => {
				if (!w.isReverse) {
					w.node.task = 'HAULING';
				} else {
					console.log(`Skipping set to HAULING due to REVERSEMIDWAYPOINT`);
				}
			});
		}
		// console.log(`addNextWaypoint: Added. length = ${this.waypoints.length}`);

		if (this.isStartWaypointSnapped && this.waypoints.length > 2) {
			// After second waypoint is placed, isStartWaypointSnapped logic should not be run. Set it to false.
			this.isStartWaypointSnapped = false;
		}
		console.log(`addNextWaypoint: now there are ${this.waypoints.length} waypoints`);
		this.printWaypointInfo();
		return true;
	}

	async onDoubleClick(event: LeafletMouseEvent) {
		if (this.isBusy())
			return;

		this.onEnter();
	}

	async onClick(event: LeafletMouseEvent) {
		if (this.isBusy())
			return;

		setCustomTag('map-interface', 'place-a-waypoint (short click)');
		this.isSnapOperation = isCtrlKeyHeld(event.originalEvent); // TODO: This kind of conflicts with HIT-390, where ctrl ignored if shift is also being held
		await this.handleClothoidClick(this.getRenderer().getRealWorldCoords(event.latlng), true);
	}

	/**
	 * Set the first waypoint at given coordinates. Handles both short/long click
	 * @param coords
	 * @param isShortClick
	 */
	@action
	protected processStartWaypointPlacement(coords: RealWorldCoordinates, isShortClick: boolean = false) {
		this.trc('processStartWaypointPlacement');
		// Activate drawing mode
		this.isDrawMode = true;

		this.startWaypoint.up = 0;

		let isSnapStartwaypointSuccess = false;
		if (this.isSnapOperation) {
			isSnapStartwaypointSuccess = !!this.snapStartwaypoint(coords);
			if (!isSnapStartwaypointSuccess) {
				return;
			} else {
				setCustomTag('map-interface', 'snap-a-waypoint (draw mode)');
			}
		}

		// // Set location and default values
		// TODO: tidy up
		// if (snapCoords !== undefined && snapHeading !== undefined && snapForward !== undefined) {
		if (!this.isSnapOperation) {
			this.isFirstWaypointShortclick = isShortClick; // for snapping of second waypoint
			this.startWaypoint.northing = coords.northing;
			this.startWaypoint.easting = coords.easting;
			this.startWaypoint.task = 'HAULING';
			this.startWaypoint.heading = 0;
			this.startWaypoint.direction = 'forward';
		}

		// console.log(`processStartWaypointPlacement: createAndRenderPath`);
		this.createAndRenderPath();

		if (!isShortClick && this.clothoidPathObjectId) {
			this.trc(`Longclick logic for clothoid ${this.clothoidPathObjectId}`);
			// Longclick logic - activate dynamic heading for first waypoint
			// processStartWaypointPlacement
			const nodeGraphics = this.getRenderer().getObjectById(this.clothoidPathObjectId).getChildren();

			if (nodeGraphics.length !== 1) {
				console.error(`nodeGraphics.length Should be 1, Got ${nodeGraphics.length}`);
			}

			this.startWaypointGraphic = this.nodeGraphicById(this.startWaypoint);
			if (this.startWaypointGraphic) {
				this.trc(`REMOVE endWaypointGraphic`);
				this.endWaypointGraphic = undefined;
				this.fromGraphic = this.startWaypointGraphic; // used in onDragEnd
				this.startWaypointGraphic.startDynamicHeading();
				this.getController().setRotateCursor(true);
			} else {
				console.error('startWaypointGraphic not found');
			}
		} else if (this.clothoidPathObjectId) {
			this.fromGraphic = this.nodeGraphicById(this.startWaypoint);
			if (this.fromGraphic) {
				this.fromGraphic.startGuideLine();
			} else {
				console.error('fromGraphic not found');
			}
		}

		if (this.isStartWaypointSnapped) {
			this.updateEndwaypointDirection(this.startWaypoint.direction);
		}

		this.getEventHandler().emit('onSetHCMPGToggleButtonReadOnly', true);
	}
	
	/**
	 * Set the second waypoint at given coordinates. Handles both short/long click
	 * @param coords
	 * @param isShortClick
	 * @returns
	 */
	protected async processEndWaypointPlacement(coords: RealWorldCoordinates, isShortClick: boolean = false) {
		// Called via handleClothoidClick
		// shortclick: set path as straightline, request clothoid from server, go into edit mode (or invalid)
		// longclick: generate new dummy path to display end waypoint so that heading
		// can be dynamically edited by user

		const originalEndwaypoint = new NodeEntity({ ...this.endWaypoint });
		// TODO: when this.direction = attributes.direction; is added to NodeEntity, the line below can be removed
		originalEndwaypoint.direction = this.endWaypoint.direction;

		this.endWaypoint.up = 0;

		if (this.currentStartWaypoint.task === 'HAULING' && this.currentStartWaypoint.speed === 0) {
			const nodes = this.getAllNodes().reverse();
			const index = nodes.findIndex(x => !!x.speed);
			if (index > -1) {
				const node = nodes[index];
				console.log(`processEndWaypointPlacement: got DUMPINGCRUSHER as prevWaypoint. Change to HAULING and set speed to ${node.speed}`);
				runInAction(() => {
					this.currentStartWaypoint.speed = node.speed;
				});
			}
		}

		let isSnapEndwaypointSuccess: undefined | boolean; // used to determine if task set to readonly

		// this.trc(`processEndWaypointPlacement`);
		if (this.endWaypoint.task === undefined) {
			this.endWaypoint.task = 'HAULING';
		}

		// This needs to be a non-empty string so the node rendering will think it's an end node
		this.endWaypoint.previousNodeId = 'not_empty';

		this.endWaypoint.heading = 0;

		if (this.endWaypoint.direction === undefined) {
			this.endWaypoint.direction = 'forward';
		}
		this.isOppositeDirections = false;

		if (isShortClick) {
			// *** START Handle Short Click ***
			this.trc(`Short click manager`);
			// Second waypoint is placed, and since this is a short click:
			// 1. Set heading of both waypoints to make straight line
			// 2. Send request to server
			if (this.isSnapOperation) {
				const snapResult = this.snapEndwaypoint(coords);
				// TODO: tidy up
				// if (snapCoords !== undefined && snapHeading !== undefined && snapForward !== undefined) {
				if (snapResult === undefined) {
					// Check if first waypoint was shortclick
					console.error('Snap failed.');
					return false;
				}

				isSnapEndwaypointSuccess = true;

				const { snapForward } = snapResult;
				const isSecondWaypoint = this.waypoints.length < 3;
				// if first waypoint was shortclick, it should make straight line with snapped waypoint
				if (this.isFirstWaypointShortclick && isSecondWaypoint) {
					this.trc(`isFirstWaypointShortclick = true && isSecondWaypoint = true. Make straight line with snapped waypoint`);
					const distance = calcDistanceBetweenNodes(this.startWaypoint, this.endWaypoint);
					const alongLine = offsetAlongHeadingRealWorld(distance, this.endWaypoint.heading);

					// TODO: use pathAlongHeadingMulti, but deal with sign issues
					const northingOffset = snapForward ? alongLine.northing : -alongLine.northing;
					const eastingOffset = snapForward ? alongLine.easting : -alongLine.easting;

					this.startWaypoint.northing = this.endWaypoint.northing - northingOffset;
					this.startWaypoint.easting = this.endWaypoint.easting - eastingOffset;
					this.startWaypoint.heading = this.endWaypoint.heading;
				} else {
					this.trc(`Snap waypoint after midwaypoint. Don't make straight line.`);	
				}
				// TODO: not 100% if this is right. and the code below isn't in BETA, but needs to be?
				this.endWaypoint.direction = snapForward ? 'forward' : 'reverse';
				this.trc(`Set endWaypoint direction to ${this.endWaypoint.direction}`);
				this.trc(`Snapped waypoint other than start. Disable waypoint creation and prepare edit mode. set waypointCreation = false`);
				this.waypointCreation = false;
			} else {
				// else NOT snap op. normal short-click straight line logic
				this.endWaypoint.northing = coords.northing;
				this.endWaypoint.easting = coords.easting;
				if (this.isStartWaypointSnapped) {
					// ignoring reverse point placement for now
					// TODO: check logic (was previously startWaypoint instead of currentStartWaypoint)
					const isForward = this.isForwardDirection(this.currentStartWaypoint.direction);
					this.endWaypoint.direction = this.currentStartWaypoint.direction;
					this.pathAlongHeadingMulti(this.currentStartWaypoint, this.endWaypoint, this.currentStartWaypoint.heading, isForward);
				} else {
					const isReverse = !this.isForwardDirection(this.endWaypoint.direction);
					// Heading of start waypoint needs to restored later for the following edge case
					// Longclick -> Shortclick (error) -> Longclick
					this.savedStartWaypointHeading = this.currentStartWaypoint.heading;
					if (this.waypoints.length < 3) {
						this.trc(`calc straight line heading (case of waypoints.length < 3`);
						let straightLineHeading = calcHeading(
							{ northing: this.currentStartWaypoint.northing, easting: this.currentStartWaypoint.easting },
							{ northing: this.endWaypoint.northing, easting: this.endWaypoint.easting },
						);

						if (isReverse) {
							straightLineHeading = this.calcReverseHeading(straightLineHeading);
						}

						// Simple case of two points forming a striaght line. Keep the points and use the angle
						// between them to create the straight line
						this.currentStartWaypoint.heading = straightLineHeading;
						this.endWaypoint.heading = straightLineHeading;
					} else {
						// Using the heading of the previous waypoint and set the position of end waypoint such that
						// a straight line is created with previous waypoint heading value
						this.pathAlongHeadingMulti(this.currentStartWaypoint, this.endWaypoint, this.currentStartWaypoint.heading, !isReverse);
					}
				}
			}
			// No other actions should be performed with invalid clothoid
			if (this.isOppositeDirections) {
				// TODO: we should never reach here, as snapping would have already failed
				console.error('Snapping already failed');
				return false;
			}

			let isSuccessfulRequest = false;

			const response = await this.sendClothoidPathRequest(); // TODO: this does re-render. confusing.
			isSuccessfulRequest = response?.isSuccess === true;
			if (!isSuccessfulRequest) {
				const err = this.getLastRequestErrorAndClear();
				if (err !== pathRequestError.NONE) {
					this.processShortClickErrors(err, originalEndwaypoint);
				}
			}
			// Set the direction property to read-only
			if (isSnapEndwaypointSuccess === true) {
				setCustomTag('map-interface', 'snap-a-waypoint (draw mode)');
				this.trc(`Successful snap operation. Set task and direction to readonly`);
				this.getEventHandler().emit('onSetTaskAndDirectionReadOnly', true);	
			} else {
				this.trc(`Successful placement of end waypoint Set direction to readonly`);
				this.getEventHandler().emit('setDirectionPropertyReadonly', true);
			}
			
			this.endWaypointGraphic = this.nodeGraphicById(this.endWaypoint);
			this.trc(`(shortClick): endWaypointGraphic found? ${!!this.endWaypointGraphic}`);
			if (this.waypointCreation) {
				this.endWaypointGraphic?.startGuideLine();
			} else {
				this.trc(`clearGuideLine`);
				this.endWaypointGraphic?.clearGuideLine();
			}

			if (isSuccessfulRequest) {
				setCustomTag('map-interface', 'create-a-path (short click)');
				if (isSnapEndwaypointSuccess) {
					// snapped last waypoint. transition to edit mode.
					this.setLastWaypoint();
				} else {
					console.log(`Since it's short click, add next waypoint`);
					this.addNextWaypoint();
					this.checkMidwaypointLimit();
				}
			} else {
				// prevents handler from getting stuck in useless state on failed request
				this.waypointCreation = true;
			}
			// *** END Handle Short Click ***
		} else {
			// *** START Handle long Click ***
			// this.trc(`processEndWaypointPlacement: Long click manager. Setting endWaypointGraphic`);
			// Render new dummy path and activate dynamic heading on end waypoint
			this.getEventHandler().emit('setDirectionPropertyReadonly', true);
			this.endWaypoint.northing = coords.northing;
			this.endWaypoint.easting = coords.easting;
			this.savedStartWaypointHeading = this.currentStartWaypoint.heading;
			console.log(`ProcessEndWaypointPlacement: createAndRenderPath`);
			this.createAndRenderPath();
			this.endWaypointGraphic = this.nodeGraphicById(this.endWaypoint);
			console.log(`processEndWaypointPlacement: (longClick): endWaypointGraphic found? ${!!this.endWaypointGraphic}`);
			if (this.endWaypointGraphic) {
				console.log(`processEndWaypointPlacement ENDWAYPOINT ID ${this.endWaypointGraphic.getId()}`)
				this.startWaypointGraphic = undefined;
				this.endWaypointGraphic.startDynamicHeading();
				this.getController().setRotateCursor(true);
				this.setWaitingFlag();
			} else {
				console.log('endWaypointGraphic not found');
			}

			// TODO: remove
			this.trc(`end waypoint heading ${this.endWaypoint.heading}`);
			// *** END Handle long Click ***
		}
		return true;
	}

	/**
	 * shared steps for reverting long/short click (see revertShortClick/revertLongClick/)
	 */
	private _finishRevertClick() {
		this.addNextWaypoint(); // re-add placeholder waypoint
		this.fromGraphic = this.nodeGraphicById(this.endWaypoint);
		this.fromGraphic?.startGuideLine();
		this.checkMidwaypointLimit();
		this.waypointCreation = true;
	}

	/**
	 * Revert short click by restoring data and re-rendering
	 * @param originalEndWaypoint data to restore
	 */
	private processShortClickErrors(err: pathRequestError, originalEndWaypoint: NodeEntity) {
		this.showPathErrorToast(err);
		if (this.waypoints.length > 2) {
			this.restoreWaypoint(originalEndWaypoint, this.endWaypoint);
			this.removeNextWaypoint(); // remove placeholder waypoint
			this.createAndRenderPath();
			this._finishRevertClick();
		} else {
			// failure to generate path
			console.log('processShortClickErrors: Two waypoints or less. Default handling (reset)');
			this.afterPathError();
			this.fromGraphic = this.nodeGraphicById(this.startWaypoint);
			this.fromGraphic?.startGuideLine();
		}
	}


	/**
	 * Handle error that occurs after dynamically setting the heading
	 * Restores waypoint to last-known state where path was generated successfully
	 * 
	 * @param err 
	 * @returns 
	 */
	private async processLongClickErrors(err: pathRequestError) {
		if (err === pathRequestError.NONE) {
			return;
		}
		this.showPathErrorToast(err);
		if (this.waypoints.length > 2) {
			// revert placement
			this.restoreWaypoint(this.currentStartWaypoint, this.endWaypoint);
			this.removeNextWaypoint(); // remove placeholder waypoint
			// new request it required as previous path is likely removed
			const result = await this.sendClothoidPathRequest();
			if (!result.isSuccess) {
				// this should never happen, as it's using known good values
				console.log(`request failed. ignoring.`);
			}
			this._finishRevertClick();
		} else {
			// failure to generate path
			console.log('revertLongClick: Two waypoints or less. Default handling (reset)');
			this.afterPathError();
			this.fromGraphic = this.nodeGraphicById(this.startWaypoint);
			this.fromGraphic?.startGuideLine();
		}
	}

	/**
	 * Processing of error where clothoid generation failed ShortClick & LongClick
	 */
	@action
	protected afterPathError() {
		// path has not changed
		this.isClothoidValid = false;
		const nodes = this.clothoidLinkEntity.sublinkss[0].nodess;
		let removedNodes: NodeEntity[] | undefined;

		if (nodes.length > 1) {
			console.log(`Clothoid error. Removing ${nodes.length} clothoid nodes.`);
			// remove all but the first node
			removedNodes = nodes.splice(1);
		}

		// Re-add the last node to the list
		if (removedNodes !== undefined && removedNodes.length > 0) {
			// TODO: split into create/edit handlers
			if (this.isDrawMode && removedNodes.length === 1) {
				// Special case where adding the end waypoint fails
				// This is most likely a validation error
				console.log('afterPathError: Create new link with startWaypoint and restore heading');
				// Restore heading for edge case longclick -> shortclick (fail) -> longclick
				if (this.savedStartWaypointHeading !== undefined) {
					this.startWaypoint.heading = this.savedStartWaypointHeading;	
				} else {
					// expected for EDIT handler
					console.log('savedStartWaypointHeading not defined');
				}
				this.createNewLinkAndResetProperties(this.startWaypoint, undefined);
			} else {
				// This means that a valid clothoid was previously created, but params have been
				// changed such that an error has occured. Since no path can be generated, simply
				// display the waypoints and allow the user to adjust values to create valid path
				console.log('afterPathError: Re-add start and end waypoint');
				const useExstingTaskAndDirection = true;
				this.createNewLinkAndResetProperties(this.startWaypoint, this.endWaypoint, useExstingTaskAndDirection);
			}
			this.createAndRenderPath();
		}
	}

	private checkMidwaypointLimit() {
		const { haulingMidWaypoints, reverseMidWaypoints, parkingMidWaypoints } = this.getMidWaypointCountOnMap();
		const nextTask = this.endWaypoint.task;
		// console.log(`haulingMidWaypoints: ${haulingMidWaypoints} reverseMidWaypoints: ${reverseMidWaypoints}`);
		// this.trc1('checkMidwaypointLimit');
		if (haulingMidWaypoints > MAX_HAULING_WAYPOINTS
			||  reverseMidWaypoints > MAX_REVERSE_WAYPOINTS
			|| parkingMidWaypoints > MAX_PARKING_WAYPOINTS)
		{
			this.getController().setCursor('not-allowed');
			this.isWaypointLimit = true;
			return true;
		}
		this.getController().setDefaultCursor();
		this.isWaypointLimit = false;
		return false;
	}

	/**
	 * Handle setting of waypoints on the map. Contains logic for determining start/end waypoint
	 * Called from both onDragStart and onClick
	 */
	public handleClothoidClick = action(async (coords: RealWorldCoordinates, isShortClick: boolean = false) => {
		const reachedLimit = this.checkMidwaypointLimit();

		if (reachedLimit) {
			console.log(`handleClothoidClick: Reached waypoint limit. Ignoring.`);
			return;
		}

		if (!this.getRenderer().isPointInMapBounds(coords)) {
			alertToast('Unable to place waypoint outside of map bounds', 'error');
			return;
		}

		if (!this.waypointCreation) {
			console.log('Ignored because waypointCreation = false');
			return;
		}

		if (this.startWaypoint.northing === undefined) {
			// Place start waypoint
			console.log('Placing first waypoint and setting waypointCreation = true');
			
			this.isNextWaypointClick = false;
			this.isStartWaypointSnapped = false;
			this.isFirstWaypointShortclick = false;
			this.targetEndLinkIdNum = undefined;
			this.targetStartLinkIdNum = undefined;
			this.processStartWaypointPlacement(coords, isShortClick);
			this.getEventHandler().emit('toggleConfirmCreation', true, true);
		} else {
			this.isBlockAddNextWaypoint = false;
			// Check distance between waypoints
			const wayPointsLength = this.waypoints.length;
			if (wayPointsLength >= 2) {
				const previousNode = this.waypoints[wayPointsLength - 2].node;
				const currentCoords = coords;
				const distance = calcDistanceBetweenCoords(previousNode.easting, previousNode.northing, currentCoords.easting, currentCoords.northing);
				if (distance < MINIMUM_DISTANCE_BETWEEN_WAYPOINTS) {
					this.isBlockAddNextWaypoint = true;
					alertToast('Unable to generate a path to the previous waypoint. The minimum distance to the previous waypoint should be at least 9 m', 'error');
					return;
				}
			}

			// const isValid = this.isValidMaxDistance(coords);
			// if (isValid === undefined) {
			// 	// TODO: should be addressed in HITMAT-977. should never reach here. 
			// 	console.warn(`isValidMaxDistance skipped due to invalid data.`);
			// } else if (isValid === false) {
			// 	alertToast(`Total distance between waypoints exceeds ${MAX_PATH_DISTANCE}m`, 'error');
			// 	return;
			// }

			// Place waypoint 2-5
			console.log(`handleClothoidClick: Placing subsequent waypoint. waypoints.length ${wayPointsLength}`);
			this.isNextWaypointClick = true;
			// this.getMidWaypointCountOnMap();
			const result = await this.processEndWaypointPlacement(coords, isShortClick);
			if (result) {
				// Enable the confirm button if the end waypoint has successfully been placed
				this.getEventHandler().emit('toggleConfirmCreation', true, false);

				// Should NOT come here if there's any kind of error
				console.log('Placed endwaypoint successfully.');
			} else {
				// TODO: may require extra error handling
				console.log('Failed to place end waypoint');
			}
		}
	});

	// TODO: consider removing (keep for now)
	isValidMaxDistance(coords: RealWorldCoordinates): boolean | undefined {
		if (this.waypoints.length < 2) {
			return undefined;
		}

		try {
			let distance = 0;
			const secondLastWaypointIndex = this.waypoints.length - 2;
			for (let i = 0; i < secondLastWaypointIndex; i++) {
				const node1Index = i;
				const node2Index = i + 1;
				const segment = calcDistanceBetweenNodes(this.waypoints[node1Index].node, this.waypoints[node2Index].node);
				if (isNaN(segment)) {
					throw Error(`Mid segment value Not a Number. Waypoint index ${node1Index} and ${node2Index}`);
				}
				distance += segment;
			}
			
			const secondLast = this.waypoints[secondLastWaypointIndex].node;
			const newSegment = calcDistanceBetweenCoords(secondLast.easting, secondLast.northing, coords.easting, coords.northing);
			if (isNaN(newSegment)) {
				throw Error(`Last segment value Not a Number. Waypoint index ${secondLastWaypointIndex}`);
			}
			distance += newSegment;
			// console.log(`total distance between waypoints: ${distance} < ${MAX_PATH_DISTANCE}`);
			return distance < MAX_PATH_DISTANCE;
		} catch(error) {
			const errorMsg = !!error ? error : 'unknown';
			console.log(`isValidMaxDistance: could not calculate distance. ${errorMsg}. Skipping validation.`);
			return undefined;
		}
	}

	protected waitEnded() {
		if (this.isSetLastWaitpoint) {
			console.log('waitEnded: Async requests cleared. Calling setLastWaitpoint.');
			this.setLastWaypoint();
		}
	}

	/**
	 * Transition to edit handler triggered by reaching waypoint limit
	 * Enforce waiting for completion of Create network operations - if busy, set flag
	 * to trigger transition when network request is complete.
	 * 
	 * 
	 * @returns 
	 */
	@action
	setLastWaypoint() {
		this.clearGuidelines();
		this.isTransitionToEdit = true;
		if (this.isWaiting) {
			this.isSetLastWaitpoint = true;
			return;
		}
		console.log(`setLastWaypoint isRequestInProgress: ${this.isRequestInProgress()}`);
		this.waypointCreation = false;
		this.isDrawMode = false;
		this.lastWaypoint = true;
		this.transitionToEditHandler(false);
	}

	/**
	 * Dynamically updates heading graphic according to given coordinates. Used with onDragMovein both EDIT and DRAW
	 * @param coords
	 */
	@action
	protected async updateDynamicHeading(coords: RealWorldCoordinates) {
		// console.log(`setDynamicHeading: Ignoring. startWaypointGraphic ${!!this.startWaypointGraphic} endWaypointGraphic ${!!this.endWaypointGraphic} isNextWaypointClick ${this.isNextWaypointClick}`);
		if (!this.isNextWaypointClick) {
			if (this.startWaypointGraphic) {
				// MUST equal hoverGraphic
				const heading = calcHeading(
					{ northing: this.startWaypoint.northing, easting: this.startWaypoint.easting }, coords,
				);
				this.startWaypoint.heading = heading;
			} else {
				console.log(`Ignoring (no startWaypointGraphic). startWaypointGraphic ${!!this.startWaypointGraphic} endWaypointGraphic ${!!this.endWaypointGraphic} isNextWaypointClick ${this.isNextWaypointClick}`);
			}
		} else {
			let waypointEntity: NodeEntity | undefined;
			let waypointGraphic: NodeGraphic | undefined;
			if (this.isDrawMode) {
				waypointEntity = this.endWaypoint;
				waypointGraphic = this.endWaypointGraphic;
				// this.endWaypointGraphic = this.nodeGraphicById(this.endWaypoint);
			} else {
				console.log(`UNEXPECTED: NOT IN DRAW NODE`);
			}
			if (!!waypointEntity && !!waypointGraphic) {
				const heading = calcHeading(
					{ northing: waypointEntity.northing, easting: waypointEntity.easting }, coords,
				);
				// console.log(`: updateDynamicHeadingSetting heading to ${heading}`);
				waypointEntity.heading = heading;
				this.asyncClothoidPathRequest();
			} else {
				console.log(`Ignoring (no endWaypointGraphic). hoverGraphic (${this.hoverGraphic}) startWaypointGraphic ${!!this.startWaypointGraphic} endWaypointGraphic ${!!this.endWaypointGraphic} isNextWaypointClick ${this.isNextWaypointClick}`);
			}
		}
	}

	async onDragStart(event: LeafletMouseEvent, originalCoordinates: LeafletCoordinates): Promise<void> {
		if (this.isBusy())
			return;

		console.log(`onDragStart: Draw mode`);
		// isSnapOperation is fixed here for longclick. even if user releases the CTRL key before
		// releasing the mouse button it's still considered a 'snap' operation (TODO: conflict with HIT-390?)
		this.isSnapOperation = isCtrlKeyHeld(event.originalEvent); // TODO: conflict with HIT-390 ?

		setCustomTag('map-interface', 'place-a-waypoint (long click)');
		if (this.isSnapOperation) {
			console.log('Ctrl Held on onDragStart. Wait for onDragEnd and treat as shortclick');
			return;
		}

		const mouseDownPosition = this.getRenderer().getRealWorldCoords(originalCoordinates);
		if (mouseDownPosition) {
			console.log(`calling handleClothoidClick with isShortClick = false`);
			await this.handleClothoidClick(mouseDownPosition, false);
			this.onDragMove(event); //HITMAT-2009
		} else {
			console.log('Undefined mouse position')
		}
	}

	public onDragMove(event: LeafletMouseEvent): void | Promise<void> {
		// Update position of heading graphic for longclick placement
		if (this.isWaypointLimit || this.isSnapOperation) {
			console.log("this.isWaypointLimit: " + this.isWaypointLimit + ", this.isSnapOperation: " + this.isSnapOperation);
			return;
		}

		if (this.isBlockAddNextWaypoint) {
			console.log('onDragMove ignored. Do not call updateDynamicHeading and then asyncClothoidPathRequest.');
			return;
		}
		this.updateDynamicHeading(this.getRenderer().mousePosition);
	}

	async onDragEnd(event: LeafletMouseEvent): Promise<void> {
		// NOTE: Use isSnapOperation is fixed in onDragStart
		if (this.isWaypointLimit) {
			console.log('onDragEnd ignored');
			return;
		}

		if (this.isBlockAddNextWaypoint) {
			this.isBlockAddNextWaypoint = false;
			console.log('onDragEnd ignored. The minimum distance to the previous waypoint should be at least 9 m.');
			return;
		}
		
		if (this.isSnapOperation) {
			// This is to treat Ctrl+longclick the same as Ctrl+shortclick
			console.log(`onDragEnd: snap operation. treat as ctrl-click`);
			await this.handleClothoidClick(this.getRenderer().getRealWorldCoords(event.latlng), true);
		} else {
			// Note that optimised requests are being made via onDragMove
			console.log('onDragEnd');
			this.setDynamicHeading();
			if (!this.isNextWaypointClick) {
				if (this.fromGraphic) {
					this.fromGraphic.startGuideLine(); // TODO: this looks wrong
				} else {
					console.error('fromGraphic not found');
				}
			}
		}
	}
}
