import { AreaEntity, BayEntity, LinkEntity, LinkFromLinkTo, NodeEntity, SignalSetEntity, SublinkEntity } from "Models/Entities";
import TurnSignalHelper from "../Map/MapStateHandlerHelpers/TurnSignalHelper";
import { ActionGroupType } from "./UndoRedoTracker";
import { Area, Bay, MapController, MapLookup, NodeGraphic, Path, Sublink } from "..";
import { convertSublinkItem, getBayName, getLinkName, getNodeName } from "../LayersPanel/AhsMapObjects";
import BayValidator from "../Map/MapValidators/BayValidator";
import LinkConnectivityEditHelper from "../Map/MapStateHandlerHelpers/LinkConnectivityEditHelper";
import { Model } from "Models/Model";
import { UndoRedoAction } from "./UndoRedoAction";
import { runInAction } from "mobx";
import DynamicScaleObjectHelper from "../Map/MapStateHandlerHelpers/DynamicScaleObjectHelper";


export default class UndoRedoHelper<U> {
    private controller: MapController;
    private lookup: MapLookup;
    private readonly attribute: string;
    private readonly isLast: boolean;
    private readonly opType?: ActionGroupType;
	public readonly oldValue: U;
	public readonly newValue: U;
    public readonly actionGroupId: string;
    public readonly entityType: string;
    private readonly isUndo: boolean

	/**
	 * @param controller
	 * @param undoRedoAction  
	 * @param isUndo 
	 * @param isLast 
	 * @param attribute optional used for property updates only
	 */
    constructor(controller: MapController, undoRedoAction: UndoRedoAction<U>,  isUndo: boolean, isLast: boolean, attribute?: string) {
		const { entityType, oldValue, newValue, parentActionGroup } = undoRedoAction;
		const { opType, actionGroupId } = parentActionGroup;
		this.controller = controller;
        this.lookup = this.controller.getMapLookup();
        this.entityType = entityType;
        this.isUndo = isUndo;
        this.attribute = attribute ?? '';
        this.oldValue = oldValue;
        this.newValue = newValue;
        this.isLast = isLast;
        this.newValue = newValue;
        this.opType = opType;
        this.actionGroupId = actionGroupId;
    }

    public processEntity(obj: Model) {
        if (this.entityType === 'AreaEntity') {
			return this.processArea(obj as AreaEntity);
		}
		if (this.entityType === 'BayEntity') {
			return this.processBay(obj as BayEntity);
		}
		if (this.entityType === 'LinkEntity') {
			// NOTE: isLast unused
			return this.processLink(obj as LinkEntity);
		}
		if (this.entityType === 'NodeEntity') {
			return this.processNode(obj as NodeEntity);
		}
		if (this.entityType === 'SublinkEntity') {
			return this.processSublink(obj as SublinkEntity);
		}
		if (this.entityType === 'SignalSetEntity') {
			const turnSignal = this.controller.getMapLookup().getEntity(obj.id, SignalSetEntity);
			return this.processSignalSet(turnSignal);
		}

		if (this.isLast && ['AreaEntity', 'LinkEntity', 'NodeEntity', 'SublinkEntity'].includes(this.entityType)) {
			this.lookup.setCommitDynamicConnections(false);
			DynamicScaleObjectHelper.updateDynamicObjectDisplay(this.controller);
		}

        return '';
    }

	private processArea(entity: AreaEntity) {
		const controller = this.controller
		const mapObjectID = controller.getMapLookup().getMapObjectId(entity.getModelId(), 'area');
		const areaMapObject = controller.getMapRenderer().getObjectById(mapObjectID) as Area;
		if (this.attribute === 'polygon') {
			areaMapObject.setPointsFromEntity();
		}

		controller.getMapRenderer().markObjectToRerender(areaMapObject.getId());
		if (this.isLast) {
			controller.getEventHandler().emit('onUndoRedoComplete', this.opType);
		}
		if (this.attribute === 'areaType' || this.attribute === 'areaname') {
			controller.getEventHandler().emit('onMapObjectUpdate', entity);
		}
		controller.getEventHandler().setActiveTool('selector');
		controller.getEventHandler().emit('onPropertiesPanel', 'map');

		const name = entity.areaName;

		if (this.attribute !== 'polygon') {
			return `${name} properties`;
		}
		return name;
	}

	private processBay(entity: BayEntity) {
		const controller = this.controller;
		console.log('%c Bay undo/redo on update ', 'background: #222; color: #bada55',
		this.attribute, this.newValue, this.oldValue);
		const mapObjectID = controller.getMapLookup().getMapObjectId(entity.getModelId(), 'bay');
		const bayMapObject = controller.getMapRenderer().getObjectById(mapObjectID) as Bay;
		controller.getMapRenderer().markObjectToRerender(bayMapObject.getId());
		if (this.isLast) {
			controller.getEventHandler().emit('onUndoRedoComplete', this.opType);
		}

		const name = getBayName(entity);
		controller.getEventHandler().setActiveTool('selector');
		controller.getEventHandler().emit('onPropertiesPanel', 'map');
		let area;
		let areaid;
		// HITMAT-930 do not allow bay to be moved from original area
		// TODO: this.attribute === 'areaId' if statement may be able to be removed.
		if (this.attribute === 'areaId') {
			if (this.isUndo) {
				areaid = this.newValue as any as string;
				// bay may be undefined bay
				if (areaid !== undefined) {
					area = controller.getMapLookup().getEntity(this.newValue as any as string, AreaEntity);
				}
			} else {
				areaid = this.oldValue as any as string;
				area = controller.getMapLookup().getEntity(this.oldValue as any as string, AreaEntity);
			}

			controller.getEventHandler().emit('onMapObjectDrag', { bay: entity, area: area });
			controller.getEventHandler().emit('onMapObjectCreateConfirm', entity);
		}
		if (this.attribute === 'heading') {
			return `The rotating of Bay ${name}`;
		}
		if (this.attribute === 'bayLocation') {
			let easting;
			let northing;

			if (typeof entity.bayLocation === 'string') {
				const newBayCoordinates = JSON.parse(entity.bayLocation);
				const { coordinates } = newBayCoordinates;
				easting = coordinates[0] as any as number;
				northing = coordinates[1] as any as number;
			} else {
				const { coordinates } = entity.bayLocation;
				// @ts-ignore
				easting = coordinates[0];
				// @ts-ignore
				northing = coordinates[1];
			}

			// BayValidator.checkBayLocationIsValid(entity, { northing, easting }, controller);
			const bayArea = BayValidator.getBayArea({ northing, easting }, controller);
			if (!!bayArea) {
				// TODO: is this needed??
				BayValidator.updateBayArea(entity, bayArea, controller);
			}
			controller.getEventHandler().setActiveTool('selector');
			controller.getEventHandler().emit('onPropertiesPanel', 'map');
			return `The moving of Bay ${name}`;
		}
		if (this.attribute === 'areaname' || this.attribute === 'areaType' || this.attribute === 'locType') {
			return `${name} properties`;
		}
		return `${name} properties`;
	}

	private processLink(entity: LinkEntity) {
        const { isUndo, controller } = this; 
		console.log('%c LinkEntity properties panel undo/redo on update ', 'background: #222; color: #bada55',
		this.attribute, this.newValue, this.oldValue);

		if (this.attribute === 'constantSpeed') {
			entity.sublinkss.forEach(sublink => {
				sublink.nodess.forEach(node => {
					const value = isUndo ? this.oldValue : this.newValue;
					runInAction(() => {
						node.speed = value as any as number;
					});
					console.log('node speed has been updated', node.speed);
				});
			});
			this.lookup.isPendingDynamicConnectionUpdate = true;
		} else if (this.attribute === 'sublinkss') {
			// Used for break/join link
			const linkEntity = this.lookup.getEntity(entity.id, LinkEntity);
			const value = isUndo ? this.oldValue : this.newValue;
			if (!linkEntity) {
				console.error(`link id ${entity.linkId} not found in lookup`);
				return '';
			}
			console.log(`PropertyUpdateAction (undo):
				updating sublinks for link ${linkEntity?.linkId} isUndo: ${isUndo}`);
			this.updateSublinksAndNodes(linkEntity, value);
			controller.removeAndReAddPath(linkEntity, true);
		} else if (this.attribute === 'linkTos') {
			// Used for break/join link
			const linkEntity = this.lookup.getEntity(entity.id, LinkEntity);
			if (!linkEntity) {
				console.error(`link id ${entity.linkId} not found in lookup`);
				return '';
			}
			const value = isUndo ? this.oldValue : this.newValue;
			console.log(`PropertyUpdateAction (redo):
				updating linkTos for link ${linkEntity?.linkId} isUndo: ${isUndo}`);
			const linkTos: LinkFromLinkTo[] = [];
			(value as any as LinkFromLinkTo[]).forEach(x => {
				linkTos.push(new LinkFromLinkTo({ ...x }));
			});
			// NOTE: the below method is async (this shouldn't matter)
			LinkConnectivityEditHelper.undoRedoEndWaypointConnectivity(this.lookup, linkEntity, linkTos);
		}

		const name = getLinkName(entity.linkId);
		controller.getEventHandler().setActiveTool('selector');
		controller.getEventHandler().emit('onPropertiesPanel', 'map');
		return `${name} properties`;
	}

	/**
	 * Update the lookup correctly. Remove the residue in the lookup caused by null refs when running markEntityToUpdate(link).
	 * @param prevValue 
	 * @param attribute 
	 */
	public updateLookupRef(prevValue: any, attribute: string) {
		const lookup = this.controller.getMapLookup();
		if (typeof(prevValue) === 'string' && !!prevValue) {
			if (this.entityType === 'NodeEntity' && attribute === 'previousNodeId') {
				lookup.removeNextNode(prevValue);
			} else if (this.entityType === 'SublinkEntity' && attribute === 'previousSublinkId') {
				lookup.removeNextSublink(prevValue);
			}
		}
	}

	private processNode(entity: NodeEntity) {
		const { isUndo, controller, isLast, opType } = this;
		const mapObjectID = controller.getMapLookup().getMapObjectId(entity.getModelId(), 'node');
		const nodeMapObject = controller.getMapRenderer().getObjectById(mapObjectID) as NodeGraphic;
		controller.getMapRenderer().markObjectToRerender(nodeMapObject.getId());
		if (isLast) {
			controller.getEventHandler().emit('onUndoRedoComplete', this.opType);
		}
		const name = getNodeName(entity.nodeId);
		controller.getEventHandler().setActiveTool('selector');
		controller.getEventHandler().emit('onPropertiesPanel', 'map');
		controller.getEventHandler().setActiveTool('selector');
		controller.getEventHandler().emit('onPropertiesPanel', 'map'); // TODO: this line is repeated. check if necessary

		if (isLast && opType === 'break_sublink') {
			const sublink = entity.getSublink();
			const link = sublink?.getLink();
			let isSuccess = false;
			let sublinkToRemove: SublinkEntity | undefined;
			if (!!sublink && !!link) {
				if (isUndo) {
					// Removal of sublink. Determine by getting index of current and taking the next SL.
					const nextSublinkIndex = link.sublinkss.findIndex(sl => sl.id === sublink.id) + 1;
					if (nextSublinkIndex > 0) {
						sublinkToRemove = link.sublinkss[nextSublinkIndex];
					} else {
						throw Error('nextSublinkIndex not found');
					}
				} else {
					// find sublink in sublinkss that's not
					sublinkToRemove = sublink;
				}
				if (!!sublinkToRemove) {
					const isJoin = isUndo;
					isSuccess = this.processFinalAction(opType, isJoin, sublinkToRemove);
				}
			}
			if (isSuccess) {
				console.log(`Last action processed successfully`);
			} else {
				console.log(`Processing of last action failed`);
			}
		}
		return `${name} properties`;
	}

	private processSublink(entity: SublinkEntity) {
		const { isUndo, controller, isLast, opType } = this; 
		const mapObjectID = controller.getMapLookup().getMapObjectId(entity.getModelId(), 'sublink');
		if (!!mapObjectID) {
			const sublinkMapObject = controller.getMapRenderer().getObjectById(mapObjectID) as Sublink;
			const myLink = controller.getMapLookup().getEntity(entity.linkId!, LinkEntity);
			if (!!sublinkMapObject) {
				controller.getMapRenderer().markObjectToRerender(sublinkMapObject.getId());
				if (isLast) {
					controller.getEventHandler().emit('onUndoRedoComplete', this.opType);
				}
				// TODO: the code below should perhaps go outside if statement
				controller.getEventHandler().setActiveTool('selector');
				controller.getEventHandler().emit('onPropertiesPanel', 'map');
				let { name } = convertSublinkItem(entity);
				name = name.replace('[NEW]', '');
				controller.getEventHandler().setActiveTool('selector');
			}
		}
		controller.getEventHandler().emit('onPropertiesPanel', 'map');
		if (isLast) {
			if (opType === 'break_sublink') {
				const isJoin = isUndo;
				const isSuccess = this.processFinalAction(opType, isJoin, entity);
				if (isSuccess) {
					console.log(`Last action processed successfully`);
				} else {
					console.log(`Processing of last action failed`);
				}
			} else if (opType === 'join_sublink') {
				// Do the opposite of break
				const isJoin = !isUndo;
				const isSuccess = this.processFinalAction(opType, isJoin, entity);
				if (isSuccess) {
					console.log(`Last action processed successfully`);
				} else {
					console.log(`Processing of last action failed`);
				}
			}
		}
		return `${name} properties`;
	}

	private processSignalSet(entity: SignalSetEntity) {
		const controller = this.controller
		TurnSignalHelper.regenerateSignalMapObject(entity, controller, false);
		controller.getEventHandler().setActiveTool('selector');
		controller.getEventHandler().emit('onPropertiesPanel', 'map');
		return '';
	}

	/**
	 * Update sublinks and nodes in the context of Undo/Redo
	 * Important: it's assumed the original operation will have
	 * ordered the sublinks and nodes correctly
	 * @param linkEntity
	 * @param value
	 */
	// TODO: move most of this music somewhere else
	updateSublinksAndNodes(linkEntity: LinkEntity, value: any) {
        // TODO: IS THIS EVEN NECESSARY???
		const sublinks: SublinkEntity[] = [];
		const objects = value as any as SublinkEntity[];
		const newSublinks: SublinkEntity[] = [];
		let isNewNodes = false;
		objects.forEach(sl => {
			const sublink = this.lookup.getEntity(sl.id, SublinkEntity);
			if (!!sublink) {
				// if sublink is already in lookup, use existing one
				sublinks.push(sublink);
			} else {
				// re-add any removed sublinks
				const newSublink = new SublinkEntity({ ...sl });
				newSublinks.push(newSublink);
				sublinks.push(newSublink);
			}
		});
		sublinks.forEach((sl, i, arr) => {
			sl.link = linkEntity;
			sl.linkId = linkEntity.id;
			sl.previousSublinkId = i > 0 ? arr[i - 1].id : undefined;
			// nodes are already ordered correctly, this is to update references
			const newNodes: NodeEntity[] = [];
			const nodes: NodeEntity[] = [];
			objects[i].nodess.forEach(n => {
				const node = this.lookup.getEntity(n.id, NodeEntity);
				if (!!node) {
					nodes.push(node);
				} else {
					// nodes should already exist in lookup
					console.log('adding new node');
					isNewNodes = true;
					const newNode = new NodeEntity({ ...n });
					newNodes.push(newNode);
					nodes.push(newNode);
				}
			});
			sl.nodess = nodes;
			nodes.forEach(x => x.setSublink(sl));
		});
		// re-add removed sublinks to lookup
		newSublinks.forEach(sl => {
			this.lookup.createEntity(sl);
		});
		linkEntity.sublinkss = sublinks;
		// sublinks are already ordered correctly, this is to update references
		linkEntity.sublinkss = linkEntity.getSublinks();
		if (isNewNodes) {
			// TODO: revisit which lookups need to be updated.
			// not sure if this is necessary.
			this.lookup.addPath(linkEntity);
		}
	}

	public processJoinSublink(entity: SublinkEntity): boolean {
		const { controller } = this;
		let isProcessed = false;
		const link = controller.getMapLookup().getEntity(entity.linkId!, LinkEntity);

		console.log('%c processJoinSublink: initail ', 'background: #222; color: #bada55');
		// controller.getMapLookup().printLink(link.id, true);
		link.validateStructure('processJoinSublink: initail - link');

		// sublinks currently present
		const actualSublinks = link.sublinkss.filter(sl => !!this.lookup.getEntity(sl.id, SublinkEntity));
		
		// IMPORTANT: the sublink to be removed is determined by comparing the actualSublinks with the sublinkss array
		const nextSublinkIndex = link.sublinkss.findIndex(x => actualSublinks.every(y => y.id !== x.id));
		// nextSublink is to be removed and it's nodes added to the restored sublink
		const nextSublink = link.sublinkss[nextSublinkIndex];

		if (!!nextSublink) {
			// the restored sublink will take the nodes of nextSublink
			const restoredSublink = link.sublinkss[nextSublinkIndex - 1];
			console.log(`processJoinSublink: restoredSublink ${restoredSublink.id}${restoredSublink.sublinkId}`);
			restoredSublink.nodess[restoredSublink.nodess.length - 1].nextNode = nextSublink.nodess[0];
			restoredSublink.nodess.push(...nextSublink.nodess);
			// Now that nodes have been added to restoredSublink, remove nextSublink
			link.removeSublink(nextSublink);
			// need to reach here for successful processing
			isProcessed = true;
		}

		console.log('%c processJoinSublink: after removeSublink ', 'background: #222; color: #bada55');
		// controller.getMapLookup().printLink(link.id, true);
		link.resetSublinkAndNodeRefs(true);
		link.validateStructure('processJoinSublink: after removeSublink - link');
		
		// Update refs (important for correct rendering)
		const sublinks = link.getSublinks();
		sublinks.forEach(sl => {
			sl.nextSublink = undefined;
			const nodes = sl.getNodes();
			nodes.forEach(n => {
				n.sublink = controller.getMapLookup().getEntity(n.sublinkId!, SublinkEntity);
				n.nextNode = undefined;
			});
			(nodes[nodes.length - 1].nextNode as any) = null;
		});
		(sublinks[sublinks.length - 1].nextSublink as any) = null;

		console.log('%c processJoinSublink: after update refs ', 'background: #222; color: #bada55');
		// controller.getMapLookup().printLink(link.id, true);
		link.validateStructure('processJoinSublink: after update refs - link');

		// regenerate lookup
		controller.getMapLookup().markEntityToUpdate(link);
		controller.getMapLookup().performUpdateOfEntity(link);
		link.orderSublinksAndNodes();

		console.log('%c processJoinSublink: after regenerate lookup ', 'background: #222; color: #bada55');
		// controller.getMapLookup().printLink(link.id, true);
		link.validateStructure('processJoinSublink: after regenerate lookup - link');

		controller.removeAndReAddPath(link, true); // TODO: rendering occurs here, put somewhere else
		return isProcessed;
	}

	public processBreakSublink(entity: SublinkEntity) {
		const { controller } = this;
		let isProcessed = false;

		const link = entity.getLink();
		// find sublink in sublinkss that's not
		if (!link) {
			console.log('processBreakSublink: LINK NOT FOUND');
			return false;
		}
		const sublinkToAdd = entity;

		console.log('%c processBreakSublink: initial ', 'background: #222; color: #bada55');
		// controller.getMapLookup().printLink(link.id, true);
		link.validateStructure('processBreakSublink: initial - link');

		// Store info needed for successful save in savePatch. Applied at time of save.
		const actionType = this.opType === 'break_sublink' ? 'create' : 'delete';
		if (this.opType === 'break_sublink' || this.opType === 'join_sublink') {
			this.lookup.addSavePatch({
				actionType: actionType,
				entityType: 'SublinkEntity',
				entityId: sublinkToAdd.id,
				attribute: 'previousSublinkId',
				revertToValue: undefined,
				actionGroupId: this.actionGroupId
			});
		}
		if (!!sublinkToAdd) {
			// const prevSublink = controller.getMapLookup().getEntity(sublinkToAdd.previousSublinkId!, SublinkEntity);
			// addSublink also orders sublinks/nodes and performs update operations on lookup
			link.addSublink(sublinkToAdd);
			isProcessed = true; // MUST reach here for successful processing
			// if (!!prevSublink) {
			// 	prevSublink.nodess = prevSublink.nodess.filter(n => sublinkToAdd.nodess.every(x => x.id !== n.id));
			// }
		}

		console.log('%c processBreakSublink: after addSublink ', 'background: #222; color: #bada55');
		// controller.getMapLookup().printLink(link.id, true);
		link.validateStructure('processBreakSublink: after addSublink - link');

		controller.removeAndReAddPath(link, true); // TODO: put somewhere else - this does a render
		return isProcessed;
	}

	/**
	 * Case 1: REDO Join link - the restored link will take the sublinks of deleted link
	 * Case 2: UNDO Break link (not including break sublink) - the restored link will take the sublinks of deleted link
	 * Case 3: UNDO Break link (including break sublink) -
	 *      3-1. the last sublink of the restored link will take the nodes of the first sublink of deleted link
	 *      3-2. the restored link will take the left sublinks of deleted link
	 */
	public processJoinLink(entity: LinkEntity) {
		const { controller } = this;
		let isProcessed = false;
		
		const deletedLink = entity;
		// deleting link (last action) also delete nextSublinkRef, so cannot use getSublinks()
		const nextSublinks = deletedLink.sublinkss;		
		const restoredLink = this.lookup.getLinkByIdNumber(nextSublinks[0].nodess[0].linkIdNumber);

		// Case 1: previousSublinkId of the first next sublink is set to a value when join link
		// Case 2: previousSublinkId of the first next sublink is set to null when break link
		//         Undo -> previousSublinkId of the first next sublink is set back to a value
		// Case 3: the first next sublink is created without previousSublinkId (null)
		//         Undo -> the sublink is deleted but previousSublinkId is still null
		const include = !nextSublinks[0].previousSublinkId; // false: Case 1, 2. true: Case 3
		
		if (restoredLink) {
			this.lookup.markEntityToUpdate(restoredLink);
			restoredLink.sublinkss[restoredLink.sublinkss.length - 1].nextSublink = nextSublinks[0];
			
			// Case 1 & Case 2: the restored link will take the sublinks of deleted link
			// Update refs and add sublinks (delete from and re-add to lookup table)
			if (!include) {
				nextSublinks.forEach(s => {
					(s.link as any) = undefined;
					s.linkId = restoredLink.id;
					restoredLink.addSublink(s, include);
				});

				console.log('%c processBreakLink: after addSublink Case 1, 2 ', 'background: #222; color: #bada55');
				// controller.getMapLookup().printLink(restoredLink.id, true);
				restoredLink.validateStructure('processBreakLink: after addSublink Case 1, 2 - originalLink');
			}
			
			// Case 3
			if (include) {
				// 3-1. the last sublink of the restored link will take the nodes of the first sublink of deleted link
				nextSublinks[0].previousSublinkId = restoredLink.sublinkss[restoredLink.sublinkss.length - 1].id;
				(nextSublinks[0].link as any) = undefined;
				nextSublinks[0].linkId = restoredLink.id;
				restoredLink.addSublink(nextSublinks[0], include);
				// remove nextSublink[0] from lookup entity for running processJoinSublink because deleted sublink is add back to lookup table in addSublink
				this.lookup.deleteEntity(nextSublinks[0].id, SublinkEntity);
				this.processJoinSublink(nextSublinks[0]);

				// 3-2. the restored link will take the left sublinks of deleted link
				// Update refs and add sublinks (delete from and re-add to lookup table)
				nextSublinks.forEach((s, index) => {
					if (index > 0) {
						(s.link as any) = undefined;
						s.linkId = restoredLink.id;
						restoredLink.addSublink(s, include);
					}
				});

				console.log('%c processJoinLink: after addSublink Case 3 ', 'background: #222; color: #bada55');
				// controller.getMapLookup().printLink(restoredLink.id, true);
				restoredLink.validateStructure('processJoinLink: after addSublink Case 3 - restoredLink');	
			}

			restoredLink.resetSublinkAndNodeRefs(true);
			restoredLink.resetLinkRefs();
			this.lookup.performUpdateOfEntity(restoredLink);

			console.log('%c processJoinLink: reset refs ', 'background: #222; color: #bada55');
			// controller.getMapLookup().printLink(restoredLink.id, true);
			restoredLink.validateStructure('processJoinLink: reset refs - restoredLink');

			controller.removeAndReAddPath(restoredLink, true);

			// Update the layers panel LinkEntity label
			controller.getEventHandler().emit('onMapObjectUpdate', restoredLink);
			isProcessed = true;
		}

		return isProcessed;
	}

	/**
	 * Case 1: UNDO Join link - the sublink of originalLink has been split but originalLink is not split yet
	 * Case 2: REDO Break link (not including break sublink) - the sublink of originalLink has been split but originalLink is not split yet
	 * Case 3: REDO Break link (including break sublink) -
	 *      3-1. Remove duplicate sublinks
	 *      3-2. Truncate the sublink by removing duplicate nodes
	 */
	public processBreakLink(entity: LinkFromLinkTo) {
		const { controller } = this;
		let isProcessed = false;

		// TODO: Check - creating/editing/deleting and saving connectivity etc. have been updated recently,
		// which relates to undo/redo break/join link a lot.
		// If there is any issue, check
		// 1) the creating/editing/deleting/saving of connectivity related to break/join link (and undo redo) first
		// 2) the data of originalLink may be wrong due to undo/redo action on a connectivity (see HITMAT-967 comments and commit 9ef7d038)

		// A link was just re-created.
		// It is the SECOND link. Set/reset data of refs accordingly
		let originalLink = controller.getMapLookup().getEntity(entity.linkFromId, LinkEntity);
		const newLink = controller.getMapLookup().getEntity(entity.linkToId, LinkEntity);

		if (newLink.sublinkss[0].previousSublinkId === undefined) {
			(newLink.sublinkss[0].previousSublinkId as any) = null;
		}

		console.log('%c processBreakLink: initial ', 'background: #222; color: #bada55');
		// controller.getMapLookup().printLink(originalLink.id, true);
		// controller.getMapLookup().printLink(newLink.id, true);

		// this.lookup.cleanLinkLookups(originalLink);
		// TODO: Test more
		// above cleanLinkLookups may be able to be removed after adding updateLookupRef
		this.lookup.markEntityToUpdate(originalLink);

		// Whether originalLink contains all sublinks of newLink or not
		const contain = newLink.sublinkss.every(s => {
			return !!originalLink.sublinkss.find(x => x.id === s.id);
		});

		// Case 1 & Case 2: the sublink of originalLink has been split but originalLink is not split yet
		if (contain) {
			const sublinksToBeRemoved = [...newLink.sublinkss].reverse();
			sublinksToBeRemoved.forEach(s => {
				(s.link as any) = undefined;
				originalLink.removeSublink(s);
			});

			console.log('%c processBreakLink: after break Case 1, 2 ', 'background: #222; color: #bada55');
			// controller.getMapLookup().printLink(originalLink.id, true);
			originalLink.validateStructure('processBreakLink: after break Case 1, 2 - originalLink');
		}

		// Case 3: the sublink of originalLink are not split yet.
		if (!contain) {			
			// 3-1. Remove duplicate sublinks
			const sublinksToBeRemoved = [...newLink.sublinkss].reverse();
			sublinksToBeRemoved.forEach((s, i) => {
				(s.link as any) = undefined;
				originalLink.removeSublink(s);
			});
			
			// 3-2. Truncate the sublink by removing duplicate nodes
			// The sublink where the first node of the first sublink of newLink is located
			const firstSublinkOfNewLink = newLink.sublinkss[0];
			const sublinkTobeTruncated = originalLink.sublinkss.find(s => {
				return !!s.nodess.find(n => n.id === firstSublinkOfNewLink.nodess[0].id);
			});
			const nodesToBeRemoved = [...firstSublinkOfNewLink.nodess].reverse();
			nodesToBeRemoved.forEach(n => {
				sublinkTobeTruncated?.removeNode(n, originalLink);
			});

			console.log('%c processBreakLink: after break Case 3 ', 'background: #222; color: #bada55');
			// controller.getMapLookup().printLink(originalLink.id, true);
			originalLink.validateStructure('processBreakLink: after break Case 3 - originalLink');
		}

		// regenerate lookup
		this.lookup.markEntityToUpdate(originalLink);
		originalLink.resetSublinkAndNodeRefs(true);
		originalLink.resetLinkRefs();
		this.lookup.performUpdateOfEntity(originalLink);
		this.lookup.markEntityToUpdate(newLink);
		newLink.resetSublinkAndNodeRefs(true);
		newLink.resetLinkRefs();
		this.lookup.performUpdateOfEntity(newLink);

		console.log('%c processBreakLink: after reset refs ', 'background: #222; color: #bada55');
		// controller.getMapLookup().printLink(originalLink.id, true);
		// controller.getMapLookup().printLink(newLink.id, true);
		originalLink.validateStructure('processBreakLink: after reset refs - originalLink');
		newLink.validateStructure('processBreakLink: after reset refs - newLink');

		// Re-render BOTH
		controller.removeAndReAddPath(originalLink, true);
		controller.removeAndReAddPath(newLink, true);

		// Update the layers panel LinkEntity label
		controller.getEventHandler().emit('onMapObjectUpdate', originalLink);
		isProcessed = true;
		return isProcessed;
	}

	public processFinalAction(
		opType: ActionGroupType,
		isJoin: boolean,
		entity: SublinkEntity | LinkEntity | LinkFromLinkTo
	): boolean {
		let isProcessed = false;
		const { controller } = this;

		switch (opType)
		{
			case 'break_sublink':
			case 'join_sublink':
				if (entity instanceof SublinkEntity) {
					if (isJoin) {
						isProcessed = this.processJoinSublink(entity); 
					} else { // isLast && !isJoin (Break)
						isProcessed = this.processBreakSublink(entity);			
					}
				}
				break;
			case 'break_link':
			case 'join_link':
				if (isJoin) {
					if (entity instanceof LinkEntity) {
						isProcessed = this.processJoinLink(entity); 
					}
				} else { // isLast && !isJoin (Break)
					if (entity instanceof LinkFromLinkTo) {
						isProcessed = this.processBreakLink(entity); 
					}			
				}
				break;
			default:
				console.log('ActionGroupType does not match!');
		}
		if (isProcessed) {
			controller.getTracker().saveChanges()
			.then(() => {
				console.log('saving after final action!');
			});
		}
		return isProcessed;
	}
}