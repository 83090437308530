import L from 'leaflet';
import { areaLocationType, areaType } from 'Models/Enums';

export function getDrivingZoneStyleOptions() {
	const styleOptions: L.PolylineOptions = {
		// stroke: false,
		smoothFactor: 0,
		color: '#464646',
		// fillColor: '#464646',
		// fillOpacity: 1,
	};

	return styleOptions;
}

function getAreaStyleOptions(type: areaType, loctype: areaLocationType) {
	const styleOptions: L.PolylineOptions = { stroke: true, weight: 2, smoothFactor: 0 };
	const dashArraySetting = '4';
	switch (type) {
		case 'AREAAHS':
			// AKA AOZ
			styleOptions.color = '#FFFF00';
			styleOptions.dashArray = dashArraySetting;
			break;
		case 'AREAAUTONOMOUS':
			switch (loctype) {
				case 'DIG':
					styleOptions.color = '#0000FF';
					styleOptions.fillColor = '#804040';
					styleOptions.fillOpacity = 0.5;
					break;
				case 'PARKING':
					styleOptions.color = '#0000FF';
					styleOptions.fillColor = '#800000';
					styleOptions.fillOpacity = 0.5;
					break;
				case 'DUMP':
					styleOptions.color = '#0000FF';
					styleOptions.fillColor = '#00C462';
					styleOptions.fillOpacity = 0.5;
					break;
				default:
					styleOptions.color = '#0000FF';
					break;
			}
			break;
		case 'AREABARRIER':
			styleOptions.color = '#D3D3D3';
			styleOptions.dashArray = dashArraySetting;
			break;
		case 'AREALOCKOUT':
			styleOptions.color = '#FF0000';
			break;
		case 'AREAOBSTACLE':
			styleOptions.color = '#FFFF00';
			styleOptions.fillColor = '#000000';
			styleOptions.fillOpacity = 0.5;
			break;
		case 'AREAEXCLUSION':
			styleOptions.color = '#FFFFFF';
			styleOptions.fillColor = '#FF0000';
			styleOptions.fillOpacity = 0.5;
			break;
		default:
			console.log(`No style for areaType ${type}`);
			break;
	}

	if (typeof styleOptions.fillColor === 'undefined') {
		styleOptions.fill = false;
	}
	return styleOptions;
}

export default getAreaStyleOptions;
