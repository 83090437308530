import {
	Area, Bay, MapController, Sublink, DrivingZone,
	MapRenderer,
	MapLookup,
} from 'Views/MapComponents';
import { PixiCoordinates } from '../Helpers/Coordinates';
import * as PIXI from 'pixi.js';
import DrivingArea from '../MapObjects/Area/DrivingArea';
import alertToast from '../../../../Util/ToastifyUtils';
import MapValidator from './MapValidator';
import {
	AreaEntity, BayEntity, DrivingAreaEntity, MapObjectWarningsEntity, NodeEntity, SublinkEntity,
} from '../../../../Models/Entities';
import axios, { AxiosError } from 'axios';
import { SERVER_URL } from '../../../../Constants';
import MapObject from "../MapObjects/MapObject";
import PathValidator from "./PathValidator";
import {Polygon} from "pixi.js";
import NodeGraphic from "../MapObjects/Node/NodeGraphic";
import L from 'leaflet';
export const AREA_ERROR_MAP_BOUNDARY = 'This operation is not allowed. An Area Node must remain within the allowed boundary.';
export const AREA_ERROR_SELF_INTERSECT = 'This operation is not allowed. An area cannot be self-intersecting.';
// eslint-disable-next-line max-len
export const AREA_ERROR_NON_DRIVABLE = 'This operation is not allowed. An Autonomous Area cannot intersect with a non drivable area.';
// eslint-disable-next-line max-len
export const AREA_ERROR_AUTONOMOUS_INTERSECT = 'This operation is not allowed. An Autonomous Area cannot intersect with another Autonomous Area.';
export const AREA_ERROR_BAYS = 'This operation is not allowed. Bays must remain inside their Autonomous Area.';

export const AREA_ERROR_ORIGINAL_DATA = 'Areas from the original map data cannot be deleted.';
// eslint-disable-next-line max-len
export const AREA_ERROR_MINIMUM_NODES_CLOSE = 'This operation is not allowed. An Area needs at least 3 nodes to be closed.';
export const AREA_ERROR_MINIMUM_NODES_DELETE = 'Area node cannot be deleted. An area must contain at least 3 nodes.';
export const AREA_ERROR_MAXIMUM_NODES = 'This operation is not allowed. An area must contain less than 1000 nodes.';
export const MAXIMUM_AREA_NODES = 1000;

interface IActiveNodeEdges {
	startLineA: PIXI.IPointData;
	endLineA: PIXI.IPointData;
	startLineB: PIXI.IPointData;
	endLineB: PIXI.IPointData;
}

export default class AreaValidator extends MapValidator {
	private areaMapObject: Area;
	private originalSelectedPoint: PixiCoordinates;
	private selectPointIndex: number = -1;

	/**
	 * Check if location is valid for area node
	 * @param coords
	 * @param areaMapObject
	 * @param originalSelectedPoint
	 * @param isClosingPoint
	 * @returns empty string upon success or err message on failure
	 */
	public validateLocation(coords: L.LatLng, areaMapObject: Area, originalSelectedPoint: PixiCoordinates, isClosingPoint: boolean = false): string {
		const isClosed = areaMapObject.isClosed();
		this.areaMapObject = areaMapObject;
		this.originalSelectedPoint = originalSelectedPoint;
		const points = areaMapObject.getPoints();

		const isFirstPoint = points.length === 1;

		if (isClosed) {
			if (this.areaMapObject.selectPointIndex === -1) {
				// we're checking the last point to be placed. Since last point is duplicated it's len - 2
				this.selectPointIndex = points.length - 2;
			} else {
				this.selectPointIndex = this.areaMapObject.selectPointIndex;
			}
		} else {
			// Open shape. take last point as selected index
			this.selectPointIndex = points.length - 1;
		}

		if (!this.checkMapBounds(coords)) {
			return AREA_ERROR_MAP_BOUNDARY;
		}

		if (!isFirstPoint) { // skip check for first point
			const selfIntersectionCheck = isClosed ? this.checkSelfIntersection()
				: this.checkSingleEdgeSelfIntersection();

			if (!selfIntersectionCheck) {
				return AREA_ERROR_SELF_INTERSECT;
			}
		}

		const entity = this.areaMapObject.getEntity();
		if (entity.areaType === 'AREAAUTONOMOUS') {
			if (isFirstPoint) {
				if (!this.checkNonDrivablePoint(coords)) {
					return AREA_ERROR_NON_DRIVABLE;
				}
				if (!this.checkAutonomousAreaIntersectPoint(coords)) {
					return AREA_ERROR_AUTONOMOUS_INTERSECT;
				}
			} else {
				const drivableCheck = isClosed ? this.checkNonDrivable(isClosingPoint)
					: this.checkSingleEdgeNonDrivable();
				if (!drivableCheck) {
					return AREA_ERROR_NON_DRIVABLE;
				}
				const autonomousIntersectCheck = isClosed ? this.checkAutonomousAreaIntersect()
					: this.checkSingleEdgeAutonomousAreaIntersect();
				if (!autonomousIntersectCheck) {
					return AREA_ERROR_AUTONOMOUS_INTERSECT;
				}

				if (isClosed && this.areaMapObject.selectPointIndex !== -1) {
					// Closed shape that is not new (don't change to else if)
					if (!this.checkBaysOnAreaNodeDragAndDrop()) {
						return AREA_ERROR_BAYS;
					}
				}
			}
		}

		return '';
	}

	/**
	 * Get all bayIds inside given polygon
	 * @param polygon
	 * @returns
	 */
	private getBayIdsForPolygon(polygon: PIXI.Polygon): Record<string, boolean> {
		const bayIds: Record<string, boolean> = {};
		this.lookup.getAllEntities(BayEntity).forEach((bay: BayEntity) => {
			const bayObjectId = this.lookup.getMapObjectId(bay.id, 'bay');
			if (bayObjectId) {
				const bayObject = this.renderer.getObjectById(bayObjectId) as Bay;
				const { center } = bayObject.getBayPoints();
				if (polygon.contains(center.x, center.y)) {
					bayIds[bayObject.getEntity().id] = true;
				}
			}
		});
		return bayIds;
	}

	/**
	 * Check if any bays are excluded from new polygon
	 * @returns True if no bays are excluded
	 */
	public checkBaysOnAreaNodeDragAndDrop() {
		const points = this.areaMapObject.getPoints();
		const originalPolygonPoints = points.map((point, index) => {
			if (index === this.selectPointIndex) {
				const { x, y } = this.originalSelectedPoint;
				return new PIXI.Point(x, y);
			}
			return point;
		});
		originalPolygonPoints[points.length - 1] = originalPolygonPoints[0];
		const originalAreaPolygon = new PIXI.Polygon(originalPolygonPoints);
		const originalBayIds = this.getBayIdsForPolygon(originalAreaPolygon);
		const newPolygon = new PIXI.Polygon(points);
		const newBayIds = this.getBayIdsForPolygon(newPolygon);
		const isMatchingBays = Object.keys(originalBayIds).every(id => originalBayIds[id] === newBayIds[id]);
		return isMatchingBays;
	}

	/**
	 * Check if new are node coordinate would cause intersection with autonomous area
	 * @returns
	 */
	public checkAutonomousAreaIntersect() {
		const areaPointsWithoutClose = this.areaMapObject.getPointsWithoutClose();

		const edges = this.getDynamicEdges(areaPointsWithoutClose);

		if (edges === undefined) {
			return false;
		}

		const areaPolygon = new PIXI.Polygon(this.areaMapObject.getPoints());

		const {
			startLineA, endLineA, startLineB, endLineB,
		} = edges;

		return !this.lookup.getAllEntities(AreaEntity)
			.filter((area: AreaEntity) => area.areaType === 'AREAAUTONOMOUS')
			.some((area: AreaEntity) => {
				const areaObjectId = this.lookup.getMapObjectId(area.id, 'area');
				if (!areaObjectId) {
					return false;
				}
				if (this.areaMapObject.getId() !== areaObjectId) {
					const areaObject = this.renderer.getObjectById(areaObjectId) as Area;
					const points = areaObject.getPointsWithoutClose();
					const { x, y } = points[0];
					// Is completely enclosed?
					let hasIntersection = areaPolygon.contains(x, y);
					if (!hasIntersection) {
						// edges intersect?
						const numerOfPoints = points.length;
						hasIntersection = points.some((currentPoint, i) => {
							const nextIndex = (i + 1) % numerOfPoints;
							return this.checkInterSegmentIntersection(currentPoint, points[nextIndex], startLineA, endLineA)
								|| this.checkInterSegmentIntersection(currentPoint, points[nextIndex], startLineB, endLineB);
						});
					}
					return hasIntersection;
				}
				return false;
			});
	}

	checkAutonomousAreaIntersectPoint(coords: L.LatLng) {
		const { renderer } = this;

		const pixiCoords = renderer.project(coords);
		const { x, y } = pixiCoords;
		return !this.lookup.getAllEntities(AreaEntity)
			.filter((area: AreaEntity) => area.areaType === 'AREAAUTONOMOUS')
			.some((area: AreaEntity) => {
				const areaObjectId = this.lookup.getMapObjectId(area.id, 'area');
				if (!areaObjectId) {
					return false;
				}
				if (this.areaMapObject.getId() !== areaObjectId) {
					const areaObject = this.renderer.getObjectById(areaObjectId) as Area;
					const polygon = new PIXI.Polygon(areaObject.getPoints());
					return polygon.contains(x, y);
				}
				return false;
			});
	}

	/**
	 * Check if any orphan bays are created due to delete operation
	 * @param originalPoints
	 * @returns Ture if there are no orphan bays created, otherwise false
	 */
	private checkBaysOnDelete = (originalPoints: PIXI.Point[]): boolean => {
		const originalPolygon = new PIXI.Polygon(originalPoints);
		const updatedPolygon = new PIXI.Polygon(this.areaMapObject.getPoints());
		const allOriginalBays = this.getBayIdsForPolygon(originalPolygon);
		const allUpdatedBays = this.getBayIdsForPolygon(updatedPolygon);
		const noOrphanBays = Object.keys(allOriginalBays).every(id => allOriginalBays[id] === allUpdatedBays[id]);
		return noOrphanBays;
	}

	/**
	 * Validate the node getting deleted
	 * @param areaMapObject
	 * @param originalAreaNodes
	 * @param deleteNodeParams
	 */
	public validateDeleteNode(areaMapObject: Area,
		originalAreaNodes: PIXI.Point[],
		deleteNodeParams: { startIndex: number, endIndex: number }): boolean {
		this.areaMapObject = areaMapObject;
		const entity = this.areaMapObject.getEntity();
		if (entity.areaType === 'AREAAUTONOMOUS') {
			// extra checks for autonomous areas
			const ensureNoOrphanNodes = this.checkBaysOnDelete(originalAreaNodes);
			if (!ensureNoOrphanNodes) {
				alertToast(AREA_ERROR_BAYS, 'error');
				return false;
			}
			const singleEdgeAutonomousAreaIntersection = this.checkSingleEdgeAutonomousAreaIntersect(deleteNodeParams);
			if (!singleEdgeAutonomousAreaIntersection) {
				alertToast(AREA_ERROR_AUTONOMOUS_INTERSECT, 'error');
				return false;
			}
			const checkIfNonDrivableIntersection = this.checkSingleEdgeNonDrivable(deleteNodeParams);
			if (!checkIfNonDrivableIntersection) {
				alertToast(AREA_ERROR_NON_DRIVABLE, 'error');
				return false;
			}
		}
		// check self-intersection for all area types
		const checkIfSelfIntersection = this.checkSingleEdgeSelfIntersection(deleteNodeParams);
		if (!checkIfSelfIntersection) {
			alertToast(AREA_ERROR_SELF_INTERSECT, 'error');
			return false;
		}
		return true;
	}

	/**
	 * Check for single edge intersection of autonomous area
	 * For delete node validation, if existing area has nodes 0,1,2,3,4,5 and node 3 is deleted,
	 * the new nodes are 0,1,2,3,4 with startIndex/endIndex being 2 and 3, respectively
	 * In addition, if deleteNodeParams area set a check for enclosed autonomous areas will also be done
	 * @param deleteNodeParams if these params are set, this validation is for when a node is deleted
	 * @returns
	 */
	public checkSingleEdgeAutonomousAreaIntersect(deleteNodeParams?: { startIndex: number, endIndex: number }) {
		const areaPoints = this.areaMapObject.getPoints();
		if (areaPoints.length < 2 || (this.areaMapObject.isClosed() && !deleteNodeParams)) {
			console.error('This method can only be used for open shapes of at least 2 points');
			return false;
		}

		const selectedIndex = areaPoints.length - 1;

		const isDeleteNodeCheck = !!deleteNodeParams;

		const indexA = isDeleteNodeCheck ? deleteNodeParams.startIndex : selectedIndex;
		const indexB = isDeleteNodeCheck ? deleteNodeParams.endIndex : selectedIndex - 1;

		const startLineA = areaPoints[indexA];
		const endLineA = areaPoints[indexB];

		let selectedPolygon: PIXI.Polygon | undefined;
		if (isDeleteNodeCheck) {
			selectedPolygon = new PIXI.Polygon(this.areaMapObject.getPoints());
		}

		return !this.lookup.getAllEntities(AreaEntity)
			.filter((area: AreaEntity) => area.areaType === 'AREAAUTONOMOUS')
			.some((area: AreaEntity) => {
				const areaObjectId = this.lookup.getMapObjectId(area.id, 'area');
				if (!areaObjectId) {
					return false;
				}
				if (this.areaMapObject.getId() !== areaObjectId) {
					const areaObject = this.renderer.getObjectById(areaObjectId) as Area;
					const points = areaObject.getPointsWithoutClose();

					let hasIntersection = false;
					if (isDeleteNodeCheck && !!selectedPolygon) {
						// Is completely enclosed?
						const { x, y } = points[0];
						hasIntersection = selectedPolygon.contains(x, y);
					}
					if (!hasIntersection) {
						const numerOfPoints = points.length;
						hasIntersection = points.some((currentPoint, i) => {
							const nextIndex = (i + 1) % numerOfPoints;
							return this.checkInterSegmentIntersection(currentPoint, points[nextIndex],
								startLineA, endLineA);
						});
					}
					return hasIntersection;
				}
				return false;
			});
	}

	checkNonDrivablePoint(coords: L.LatLng) {
		const { controller } = this;
		const { lookup } = this;
		const { renderer } = this;

		const pixiCoords = renderer.project(coords);
		const isFound = lookup.getDrivingAreas().some((entity: DrivingAreaEntity) => {
			const id = lookup.getMapObjectId(entity.id, 'driving_area');
			if (!id) {
				return false;
			}
			const drivingArea = renderer.getObjectById(id) as DrivingArea;
			return DrivingArea.isPointDrivable(drivingArea, pixiCoords);
		});
		return isFound;
	}

	/**
	 * Get bounding drivable area points
	 * @param testPoint
	 * @returns bounding points (drivable area perimeter of hole)
	 */
	private getDrivableAreaBoundingPoints(testPoint: PixiCoordinates):
		{ points: PIXI.Point[], isContainsHole: boolean } | undefined {
		const drivingAreas = this.lookup.getDrivingAreas();
		for (let i = 0; i < drivingAreas.length; i++) {
			const entity = drivingAreas[i];
			const id = this.lookup.getMapObjectId(entity.id, 'driving_area');
			if (!!id) {
				const drivingArea = this.renderer.getObjectById(id) as DrivingArea;
				// Test individual driving areas
				const boundingPoints = MapValidator.getBoundingPoints(drivingArea, testPoint);
				if (!!boundingPoints) {
					const innerPoints = drivingArea.getInnerPoints();
					let isContainsHole = false;
					if (!!innerPoints) {
						// eslint-disable-next-line max-len
						const { x, y } = this.originalSelectedPoint;
						const originalPixiPoint = new PIXI.Point(x, y);
						const previousPoints = this.areaMapObject.getCopyPoints()
							.map((value, index) => index === this.selectPointIndex ? originalPixiPoint : value);
						if (this.selectPointIndex === 0 && this.areaMapObject.isClosed()) {
							// if the selected point is the first point and the area is closed, update last point to match first point
							const lastIndex = previousPoints.length - 1;
							previousPoints[lastIndex].x = previousPoints[0].x;
							previousPoints[lastIndex].y = previousPoints[0].y;
						}
						const previousAreaPolygon = new PIXI.Polygon(previousPoints);
						// eslint-disable-next-line max-len
						isContainsHole = innerPoints.some(holePoints => holePoints.some(holePoint => previousAreaPolygon.contains(holePoint.x, holePoint.y)));
					}
					return { points: boundingPoints.points, isContainsHole: isContainsHole };
				}
			}
		}
		return undefined;
	}

	/**
	 * Check if coords are within drivable area
	 * This method has some strange conditions to deal with edge cases related to
	 * existing areas not conforming with rules
	 * @returns true if test is passed, false if test fails (e.g. has intersection)
	 */
	public checkNonDrivable(isClosingPoint: boolean) {
		const boundingPointInformation = this.getDrivableAreaBoundingPoints(this.originalSelectedPoint);

		if (!!boundingPointInformation) {
			const { points, isContainsHole } = boundingPointInformation;
			const numberOfPoints = points.length;
			const edges = this.getDynamicEdges(this.areaMapObject.getPointsWithoutClose());

			if (edges === undefined) {
				console.log('Edges undefined');
				return false;
			}

			const {
				startLineA, endLineA, startLineB, endLineB,
			} = edges;
			const hasIntersection = points.some((currentPoint, i) => {
				const nextIndex = (i + 1) % numberOfPoints;
				return this.checkInterSegmentIntersection(currentPoint, points[nextIndex], startLineA, endLineA)
					|| this.checkInterSegmentIntersection(currentPoint, points[nextIndex], startLineB, endLineB);
			});

			if (!hasIntersection) {
				if (isClosingPoint || !isContainsHole) {
					// Not checking for holes when isContainsHole is true because this must be an imported
					// driving area already containing holes. isClosingPoint is for newly created areas
					// that are being closed.
					return this.checkNonDrivableHoles();
				}
				console.log('Skipping checkNonDrivableHoles as area already contains holes');

				return true;
			}
		}

		console.log('No driable area.');
		return false;
	}

	/**
	 * Check if coords are within drivable area
	 * @returns
	 */
	public checkNonDrivableHoles() {
		const areaPoints = this.areaMapObject.getPoints();

		const drivingArea = this.getDrivingAreaOfPoint(this.originalSelectedPoint);

		if (drivingArea === undefined) {
			console.error('Driving area check failed: Area was already invalid.');
			return false;
		}

		const areaPolygon = new PIXI.Polygon(areaPoints);

		let hasIntersection = false;

		// Check if the edge intersects with any of the holes
		const innerPoints = drivingArea.getInnerPoints();
		if (!!innerPoints) {
			// check intersection for each hole
			// eslint-disable-next-line max-len
			hasIntersection = innerPoints.some(innerPointsArray => innerPointsArray.some(currentPoint => areaPolygon.contains(currentPoint.x, currentPoint.y)));
		}
		return !hasIntersection;
	}

	private getDrivingAreaOfPoint(testPoint: PixiCoordinates): DrivingArea | undefined {
		const { x, y } = testPoint;
		const drivingAreas = this.lookup.getDrivingAreas();
		let result: DrivingArea | undefined;
		for (let i = 0; i < drivingAreas.length; i++) {
			const entity = drivingAreas[i];
			const id = this.lookup.getMapObjectId(entity.id, 'driving_area');
			if (!!id) {
				const drivingArea = this.renderer.getObjectById(id) as DrivingArea;
				const outerPolygon = new PIXI.Polygon(drivingArea.getOuterPoints());
				if (outerPolygon.contains(x, y)) {
					result = drivingArea;
					break;
				}
			}
		}
		return result;
	}

	/**
	 * Checks single edge for non-drivable intersection
	 * @returns true if passes the test (no intersection)
	 */
	checkSingleEdgeNonDrivable(deleteNodeParams?: { startIndex: number, endIndex: number }) {
		const areaPoints = this.areaMapObject.getPoints();
		const selectedIndex = areaPoints.length - 1;
		if (areaPoints.length < 2 || (this.areaMapObject.isClosed() && !deleteNodeParams)) {
			console.error('This method cannot be used with closed shapes except for checking deleted nodes');
			return false;
		}

		const isDeleteNodeCheck = !!deleteNodeParams;

		const indexA = isDeleteNodeCheck ? deleteNodeParams.startIndex : selectedIndex - 1;
		const indexB = isDeleteNodeCheck ? deleteNodeParams.endIndex : selectedIndex;

		// new edge being checked
		const startLineA = areaPoints[indexA];
		const endLineA = areaPoints[indexB];

		let selectedPolygon: PIXI.Polygon | undefined;
		if (isDeleteNodeCheck) {
			selectedPolygon = new PIXI.Polygon(this.areaMapObject.getPoints());
		}
		const drivingArea = this.getDrivingAreaOfPoint(areaPoints[indexA]);

		if (drivingArea === undefined) {
			console.error('Driving area check failed: Area was already invalid.');
			return false;
		}

		// check for intersection of outer perimeter
		const outerPoints = drivingArea.getOuterPoints();
		let hasIntersection = outerPoints.some((currentPoint, index, array) => {
			const nextIndex = (index + 1) % array.length;
			return this.checkInterSegmentIntersection(currentPoint, array[nextIndex], startLineA, endLineA);
		});

		if (hasIntersection) {
			return false;
		}

		// Check if the edge intersects with any of the holes
		const innerPoints = drivingArea.getInnerPoints();
		if (!!innerPoints) {
			hasIntersection = innerPoints.some(innerPointsArray => {
				if (!!selectedPolygon) {
					// If any point of hole is within the selected polygon, it means a hole is fully contained
					// within the polygon. Simply take the first point as the test point.
					const { x, y } = innerPointsArray[0];
					if (selectedPolygon.contains(x, y)) {
						return true;
					}
				}
				// check intersection for each hole
				const intersection = innerPointsArray.some((currentPoint, index, array) => {
					const nextIndex = (index + 1) % array.length;
					return this.checkInterSegmentIntersection(currentPoint, array[nextIndex], startLineA, endLineA);
				});
				return intersection;
			});
		}

		return !hasIntersection;
	}

	public static validateAreasInMapBounds (map: MapController) {
		const areaEntities = map.getMapLookup().getAllEntities(AreaEntity);
		areaEntities.forEach(a => {
			this.validateAreaInMapBounds(a, map);
		});
		console.log("validateAreasInMapBounds is finished.");
	}

	public static validateAreaInMapBounds (areaEntity: AreaEntity, map: MapController) {
		const lookup = map.getMapLookup();
		const areaMapObject = lookup.getMapObjectByEntity(areaEntity, 'area') as Area;
		if (!areaMapObject) {
			return;
		}
		const isInMapBound = areaMapObject.getPoints().every(p => {
								const renderer = map.getMapRenderer();
								const _p = renderer.unproject(p);
								return renderer.isPointInMapBounds(renderer.getRealWorldCoords(_p));
							});
		const errorMessage = `Area_${areaEntity.areaId} is outside the allowed boundary for AHS objects`;
		if (!isInMapBound) {			
			lookup.addObjectError(areaEntity.id, AreaEntity, errorMessage);
		} else {
			lookup.removeObjectError(areaEntity.id, AreaEntity, errorMessage);
		}

		// Check entity error and warning count and render error or warning styling
		const hasError = areaEntity.getErrorCount() > 0;
		const hasWarning = areaEntity.getWarningCount() > 0;
		MapValidator.setMapObjectTooltipErrorWarning(areaMapObject, hasError, hasWarning);
		// Update the label in the layers panel
		map.getEventHandler().emit('onMapObjectUpdate', areaEntity, areaEntity.id);
	}

	/**
	* Check if area node is within map boundary
	* @param coords
	* @returns
	*/
	public checkMapBounds(coords: L.LatLng): boolean {
		return this.renderer.isPointInMapBounds(this.renderer.getRealWorldCoords(coords));
	}

	private getDynamicEdges(points: PIXI.Point[]): IActiveNodeEdges | undefined {
		const selectedIndex = this.selectPointIndex;
		if (selectedIndex === -1) {
			return undefined;
		}
		const selectedPoint = points[selectedIndex];
		return {
			startLineA: selectedPoint,
			startLineB: selectedPoint,
			endLineA: points[(selectedIndex + 1) % points.length],
			endLineB: selectedIndex === 0 ? points[points.length - 1] : points[selectedIndex - 1],
		};
	}

	checkSingleEdgeSelfIntersection(deleteNodeParams?: { startIndex: number, endIndex: number }) {
		const points = this.areaMapObject.getPointsWithoutClose(); // TODO: this will need to be updated for create area

		if (points.length < 2 || (this.areaMapObject.isClosed() && !deleteNodeParams)) {
			console.error('This method cannot be used with closed shapes except for checking deleted nodes');
			return false;
		}

		const isDeleteNodeCheck = !!deleteNodeParams;

		const selectedIndex = isDeleteNodeCheck ? deleteNodeParams.startIndex : points.length - 1;
		const endIndex = isDeleteNodeCheck ? deleteNodeParams.endIndex : selectedIndex - 1;
		const numerOfPoints = points.length;
		const startLineA = points[selectedIndex];
		const endLineA = points[endIndex];

		return !points.some((currentPoint, i) => {
			const nextIndex = (i + 1) % numerOfPoints;
			if (i !== selectedIndex) {
				const startTestLine = currentPoint;
				const endTestLine = points[nextIndex];
				return this.checkInterSegmentIntersection(startTestLine, endTestLine, startLineA, endLineA);
			}
			return false;
		});
	}

	/**
	 * Take the two edges that have been modified due to shifting the area node
	 * and see if they intersect with any other edges
	 * @returns true if there's an intersection
	 */
	public checkSelfIntersection() {
		const { areaMapObject } = this;
		const points = areaMapObject.getPointsWithoutClose(); // TODO: this will need to be updated for create area
		const edges = this.getDynamicEdges(points);

		if (edges === undefined) {
			return false;
		}

		const selectedIndex = this.selectPointIndex;
		const numerOfPoints = points.length;

		const {
			startLineA, endLineA, startLineB, endLineB,
		} = edges;

		return !points.some((currentPoint, i) => {
			const nextIndex = (i + 1) % numerOfPoints;
			if (i !== selectedIndex && nextIndex !== selectedIndex) {
				const startTestLine = currentPoint;
				const endTestLine = points[nextIndex];
				return this.checkInterSegmentIntersection(startTestLine, endTestLine, startLineA, endLineA)
				|| this.checkInterSegmentIntersection(startTestLine, endTestLine, startLineB, endLineB);
			}
			return false;
		});
	}

	public static async checkAreaErrors(controller: MapController): Promise<void> {
		const importVersionEntityId = controller.getImportVersion().id;

		// serverside validation
		await axios.post(`${SERVER_URL}/api/entity/AreaEntity/validateAreaErrors`, {
			importVersionId: importVersionEntityId,
		}).then(data => {
			// populate areaEntity mapObjectErros with returned error
			if (!!data) {
				Object.keys(data.data).forEach(async (key, index) => {
					// for each area, add errors
					// store the errorCode and areaId
					const { errorCodes } = data.data[key];
					const areaId = key;
					const area = controller.getMapLookup().getEntity(areaId, AreaEntity);
					if (!!area) {
						controller.getMapLookup()
							.addNewErrorsForObject(area.getModelId(), AreaEntity, errorCodes);

						controller.getEventHandler().emit('onMapObjectUpdate', area);
						// re-render error information
						const areaMapObjectId = controller.getMapLookup().getMapObjectId(area.id, 'area');
						const areaMapObject = controller.getMapRenderer().getObjectById(areaMapObjectId);
						const hasErrors = area.mapObjectErrorss.length > 0;
						areaMapObject?.setTooltipDisplay(true, hasErrors);
						controller.getEventHandler().emit('onMapObjectUpdate', area);
					}
				});
			}
			console.log('serverside errors', data.data);
		}).catch((err: AxiosError) => {
			console.log('error in axios call ', err);
		});
	}

	public validateAreaDrivingZones(areaObject: Area) {
		const sublinkEntities = this.lookup.getAllEntities(SublinkEntity);
		const drivingZones : DrivingZone[] = [];

		sublinkEntities.forEach(_sl => {
			const sl = this.lookup.getMapObjectByEntity(_sl, 'sublink') as Sublink;
			if (!!sl) {
				const dz = sl.getDrivingZoneObject();
				if (!!dz) {
					drivingZones.push(dz);
				}
			}
		});	
		
		const isAreaIntersect = this.checkAreaDrivingZones(areaObject ,drivingZones);
		const isRerender = true;
		if (isAreaIntersect) {
			// Create warning and add to entity
			type warningType = 'Barrier area' | 'Obstacle area' | 'INVALID WARNING';
			let warningTypeToUse : warningType;
			const areaType = areaObject.getEntity().areaType.toString();

			switch(areaType) {
				case 'AREAOBSTACLE':
					warningTypeToUse = 'Obstacle area';
					break;
				case 'AREABARRIER':
					warningTypeToUse = 'Barrier area';
					break;
				default:
					warningTypeToUse = 'INVALID WARNING';
					console.warn(`${warningTypeToUse}: needless validation processing in ${this.validateAreaDrivingZones.name}`); // [HITMAT-1399] should only calling this function if the area is obstacle or barrier  
					return;
			}
			// Render error styling
			this.setMapObjectWarning(areaObject, isRerender);

			//Driving zone and barrier/obstacle area interference
			const areaWarningMessage = `${warningTypeToUse} and driving zone interference`;
			this.addAreaWarning(areaWarningMessage, areaObject.getEntity());
		}
	}

	/**
	 * Check for Interference warnings between Driving Zones and Areas (Obstacle/Barrier)
	 * @param map controller used to access mapLookup
	 * @returns void
	 */
	public static checkDrivingZonesAreasInterference(map: MapController) {
		const sublinkEntities = map.getMapLookup().getAllEntities(SublinkEntity);
		const pathValidator = new PathValidator(map);
		const isRerender = false;
		const areaTypes = ['barrier', 'obstacle'];
		const clearInterferenceWarnings = (sublinkEntity: SublinkEntity) => {
			areaTypes.forEach((areaType) => {
				map.getMapLookup().removeObjectWarning(sublinkEntity.id, SublinkEntity, `Driving zone and ${areaType} area interference`);
			});
			map.getEventHandler().emit('onMapObjectUpdate', sublinkEntity, sublinkEntity.id);
		};
		
		sublinkEntities.forEach(_subLink => {
			const subLink = map.getMapLookup().getMapObjectByEntity(_subLink, 'sublink') as Sublink;
			if (!!subLink) {
				const drivingZone = subLink.getDrivingZoneObject();
				if (!!drivingZone) {
					// Check for interference warnings for driving zones
					const sublinkEntity = subLink.getSublinkEntity();
					const points = drivingZone.getEntity() as PIXI.Point[];
					const isBarrierIntersect = pathValidator.checkBarrierArea(points);
					const isObstableIntersect = pathValidator.checkObstacleArea(points);

					if (isBarrierIntersect || isObstableIntersect) {
						clearInterferenceWarnings(sublinkEntity);
						// Render error styling
						pathValidator.setMapObjectWarning(drivingZone, isRerender);
						const intersections = [{isIntersect: isBarrierIntersect, areaType: 'barrier'}, {isIntersect : isObstableIntersect, areaType: 'obstacle'}];
						intersections.forEach((intersection, index) => {
							if (intersection.isIntersect) {
								// Create warning and add to entity
								const sublinkWarningMessage = `Driving zone and ${intersection.areaType} area interference`;
								const sublinkWarningEntity = pathValidator.addSublinkWarning(sublinkWarningMessage, sublinkEntity);
								if (!!sublinkWarningEntity) {
									pathValidator.addSublinkWarning(sublinkWarningMessage, sublinkEntity);
								}
							}
						});
					}
					else {
						// remove pathValidator warning styling and layers panel warnings
						pathValidator.setMapObjectWarning(drivingZone, isRerender, false);
						clearInterferenceWarnings(sublinkEntity);
					}					
				}
			}
		});
		map.getMapRenderer().rerender();
	}

	/**
	 * For the current area, search nearby nodes and check if their parent driving zone intersects with the area
	 * @param areaEntity: AreaEntity - the obstacle/barrier area entity being checked for interference with driving zones
	 * @param controller - controller to get the map renderer
	 */
	public static checkDrivingZoneInterferenceForArea = (areaEntity: AreaEntity, controller: MapController) => {
		if (!['AREAOBSTACLE', 'AREABARRIER'].includes(areaEntity.areaType.toString())) {
			return;
		}
		const warningType = areaEntity.areaType === "AREAOBSTACLE" ? 'obstacle area' : 'barrier area';
		const sublinkWarningMessage = `Driving zone and ${warningType} interference`;
		const lookup = controller.getMapLookup();
		const renderer = controller.getMapRenderer();
		const nodeSearchRegion = AreaValidator.generateSearchPolygon(controller, renderer);
		const nodesFound: NodeEntity[] = AreaValidator.searchNodesInRegion(lookup, renderer, nodeSearchRegion);

		const uniqueSublinkIds = nodesFound.map(node => node.sublinkId).filter((v, i, a) => a.indexOf(v) === i);
		const pathValidator = new PathValidator(controller);
		const isRerender = false;
		const hasWarning = false;

		uniqueSublinkIds.forEach(subLinkId => {
			const subLink = controller.getMapLookup().getMapObjectByEntityId(subLinkId!, 'sublink') as Sublink;
			if (!!subLink) {
				const drivingZone = subLink.getDrivingZoneObject();
				if (!!drivingZone) {
					// Check for interference warnings for driving zones
					const sublinkEntity = subLink.getSublinkEntity();
					const dzPoints = drivingZone.getEntity() as PIXI.Point[];
					const isIntersectedWithArea = pathValidator.checkArea([areaEntity], dzPoints);

					if (isIntersectedWithArea) {
						// Render warning styling
						pathValidator.setMapObjectWarning(drivingZone, isRerender);
						// Create warning and add to entity
						const sublinkWarningEntity = pathValidator.addSublinkWarning(sublinkWarningMessage, sublinkEntity);
						if (!!sublinkWarningEntity) {
							pathValidator.addSublinkWarning(sublinkWarningMessage, sublinkEntity);
						}
					} else {
						// Remove warning styling & re-render taking into account the other existing warnings
						const isOtherBarrierIntersect = pathValidator.checkBarrierArea(dzPoints);
						const isOtherObstableIntersect = pathValidator.checkObstacleArea(dzPoints);
						if ((areaEntity.areaType === "AREABARRIER" && !isOtherBarrierIntersect)) {
							if (!isOtherObstableIntersect) {
								pathValidator.setMapObjectWarning(drivingZone, isRerender, hasWarning);
							}
							lookup.removeObjectWarning(sublinkEntity.id, SublinkEntity, sublinkWarningMessage);
							//renderer.rerender();
							controller.getEventHandler().emit('onMapObjectUpdate', sublinkEntity, sublinkEntity.id);
						}
						if ((areaEntity.areaType === "AREAOBSTACLE" && !isOtherObstableIntersect)) {
							if (!isOtherBarrierIntersect) {
								pathValidator.setMapObjectWarning(drivingZone, isRerender, hasWarning);
							}
							lookup.removeObjectWarning(sublinkEntity.id, SublinkEntity, sublinkWarningMessage);
							controller.getEventHandler().emit('onMapObjectUpdate', sublinkEntity, sublinkEntity.id);
						}
					}
				}
			}
		});
		renderer.rerender();
	}
	
	private static searchNodesInRegion(lookup: MapLookup, renderer: MapRenderer, nodeSearchRegion: PIXI.Polygon): NodeEntity[] {
		return lookup.getAllEntities(NodeEntity)
			.filter(node => {
				const nodeObjectId = lookup.getMapObjectId(node.id, 'node');
				if (!nodeObjectId) {
					return false;
				}
				const nodeObject = renderer.getObjectById(nodeObjectId) as NodeGraphic;
				const { x, y } = nodeObject.coordinates;
				return nodeSearchRegion.contains(x, y);
			});
	}

	/**
	 * Clear interference warnings between Driving Zones and specified Area (Obstacle/Barrier)
	 */
	public clearAreaDrivingZoneWarnings(areaObject : Area) {
		const lookup = this.lookup;
		const sublinkEntities = lookup.getAllEntities(SublinkEntity);
		const pathValidator = new PathValidator(this.controller);
		const isRerender = true;

		sublinkEntities.forEach(_subLink => {
			const subLink = lookup.getMapObjectByEntity(_subLink, 'sublink') as Sublink;
			if (!!subLink) {
				const drivingZone = subLink.getDrivingZoneObject();
				if (!!drivingZone) {
					// Check for interference warnings for driving zones
					const sublinkEntity = subLink.getSublinkEntity();
					const dzPoints = drivingZone.getEntity() as PIXI.Point[];

					if (this.checkPolygonIntersection(areaObject.getPoints(), dzPoints)) {
						// Remove warning styling & re-render
						const hasWarning : boolean = false;
						pathValidator.setMapObjectWarning(drivingZone, isRerender, hasWarning);
						// Define warning and remove from entity
						let warningType : String;
						const areaType = areaObject.getEntity().areaType.toString();
						if (areaType === 'AREAOBSTACLE') {
							warningType = 'obstacle area';
						} else if (areaType === 'AREABARRIER') {
							warningType = 'barrier area';
						} else {
							console.warn(`${this.clearAreaDrivingZoneWarnings.name} should only be called for area types of Obstacle or Barrier`);
							return;
						}
						const sublinkWarningMessage = `Driving zone and ${warningType} interference`;
						lookup.removeObjectWarning(sublinkEntity.id, SublinkEntity, sublinkWarningMessage);
						this.renderer.rerender();
					}
				}
			}
		});
	}
	
	/**
	 *
	 * @param areaToCheck area entity to check for intersection with
	 * @param drivingZonesToCheck array of drivingZone points to check intersection against area object
	 * @returns true if area intersect any of the driving zones
	 */
	private checkAreaDrivingZones(areaObject: Area, drivingZonesToCheck: DrivingZone[]) {
		return drivingZonesToCheck.some(drivingZone => {
			if (!!areaObject) {
				const isIntersection = this.checkPolygonIntersection(areaObject.getPoints(), drivingZone.getEntity() as PIXI.Point[]);
				return isIntersection;
			}
			console.log('Area not found!!');
			return false;
		});
	}

	public setMapObjectWarning(mapObject: MapObject<unknown>, isRerender: boolean = true, hasWarning: boolean = true, forceMarkRerender: boolean = false) {
		if (mapObject.isWarning !== hasWarning || forceMarkRerender) {
			// only re-render if the obj changes
			mapObject.isWarning = hasWarning;
			this.renderer.markObjectToRerender(mapObject.getId());
		}
		if (isRerender) {
			this.renderer.rerender();
		}
	}

	/**
	 * Add a warning to the area entity and add to warning table
	 * @param warningMessage
	 * @param areaEntity
	 * @returns the newly created MapObjectWarningsEntity
	 */
	private addAreaWarning(warningMessage: string, areaEntity: AreaEntity) {
		const warningEntity = new MapObjectWarningsEntity({
			warningMessage: warningMessage,
			areaId: areaEntity.getModelId(),
		});
		areaEntity.mapObjectWarningss.push(warningEntity);
	}
	
	/**
	 * Return a polygon that represents the current map view for search purposes
	 * @param controller
	 * @param renderer
	 * @returns the newly created Polygon
	 */
	static generateSearchPolygon(controller: MapController, renderer: MapRenderer) {
		const bounds = controller.getLeafletMap().getBounds();
		const southWest = bounds.getSouthWest();
		const northEast = bounds.getNorthEast();
		const southEast = L.latLng(southWest.lat, northEast.lng);
		const northWest = L.latLng(northEast.lat, southWest.lng);
	
		const projectedNE = renderer.project({ lat: northEast.lat, lng: northEast.lng });
		const projectedNW = renderer.project({ lat: northWest.lat, lng: northWest.lng });
		const projectedSW = renderer.project({ lat: southWest.lat, lng: southWest.lng });
		const projectedSE = renderer.project({ lat: southEast.lat, lng: southEast.lng });
	
		const nodeSearchRegion = new Polygon([
			new PIXI.Point(projectedNE.x, projectedNE.y),
			new PIXI.Point(projectedNW.x, projectedNW.y),
			new PIXI.Point(projectedSW.x, projectedSW.y),
			new PIXI.Point(projectedSE.x, projectedSE.y),
			new PIXI.Point(projectedNE.x, projectedNE.y),
		]);
		return nodeSearchRegion;
	}	
}

