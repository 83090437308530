/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
import MapStateHandler from './MapStateHandler';
import { LeafletMouseEvent } from 'leaflet';

export default class ArcToolHandler extends MapStateHandler {

	onInit() {
	}

	onMove(event: LeafletMouseEvent) {
	}

	dispose() {
	}

}
