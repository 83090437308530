import * as React from 'react';
import { useEffect, useState } from 'react';
import {
	Button, Colors, Display,
} from '../../Components/Button/Button';
import classNames from 'classnames';
import { disableMapDomEvents } from '../Map/Helpers/MapDOMEvents';
import MapController, { ImportVersionStatus } from '../Map/MapController';
import { UserImageText } from 'Views/Components/Shared/ProfileIndicator';
import { MAXIMUM_ZOOM, MINIMUM_ZOOM } from '../Map/MapRenderer';
import alertToast from 'Util/ToastifyUtils';
import { NumberTextField } from 'Views/Components/NumberTextBox/NumberTextBox';
import axios from 'axios';
import { MapErrorFlags } from '../Map/ServerValidation';
import {validateFullMap} from '../Map/Helpers/FullMapValidation';
import { setCustomTag } from '../Map/Helpers/MapUtils';
import {store} from "../../../Models/Store";
import {
	ERROR_NO_PATH_IN_AHC_MAP,
	ERROR_NO_AUTONOMOUS_AREA_IN_AHC_MAP,
} from 'Constants';

// needed for test
const DEFAULT_SCALE = 0.125;

export type ToolbarEvent = 'undo'
    | 'redo'
    | 'selector'
    | 'ruler'
    | 'area'
    | 'bay'
    | 'clothoid'
	| 'path'
    | 'arc'
    | 'connection';

interface ZoomInfo {
	zoomLevel: number;
}

export interface IToolbarProps {
    // Trigger an event when something becomes active
    onItemSelect: (eventType: ToolbarEvent) => void,

    // Related to zoom levels
    currentZoomLevel?: number;
	map: MapController;
}

function formatScale(scale: number) {
	return parseFloat(scale.toFixed(3));
}

function zoomLevelToScale(zoom?: number) {
	if (zoom !== undefined) {
		const scale = 2 ** (zoom);
		return formatScale(scale);
	}
	console.log('Returning default scale');
	return DEFAULT_SCALE; // used in test
}

/**
 * Toolbar found on the mapedit page.
 * - Used to display options
 * - For map interactivity
 * - Sets "selector" as default item
 * - Shows the user image
 * @param props
 * @returns
 */
export default function Toolbar(props: IToolbarProps) {
	const [selectedItem, setSelectedItem] = useState<ToolbarEvent>('selector');
	const [zoomLevel, setZoomLevel] = useState(props.currentZoomLevel ?? 0);
	const { map } = props;
	// const redoObject = observable(map.getTracker().redo);
	// const tracker = map.getTracker();
	// Below state is used to know if the current tool is creating a new map object or not
	const [isShowConfirmButton, setIsShowConfirmButton] = useState(false);
	const [isDisableConfirmButton, setIsDisableConfirmButton] = useState(false);

	// possibly put in one object
	const [isMapErrors, setIsMapErrors] = useState(true);// [HITMAT-2278] assume there are errors until fullValidation is run to keep the fms button disabled
	const [isSaved, setIsSaved] = useState(false);
	const [isGenerateFmsInProgress, setIsGenerateFmsInProgress] = useState(false);
	const [isFmsGenerated, setIsFmsGenerated] = useState(false);

	const [isMapEditStatusInvalid, setIsMapEditStatusInvalid] = useState(getIsInvalidStatus())

	const onZoomChange = (zoomChangeLevel: number) => setZoomLevel(zoomChangeLevel);
	const onSetActiveTool = (tool: ToolbarEvent) => {
		map.setSelectedToolType(tool);
		setSelectedItem(tool);
	};

	useEffect(() => {
		map.getEventHandler().addListener('setActiveTool', onSetActiveTool);
		map.getEventHandler().addListener('onZoomChange', onZoomChange);
		map.getEventHandler().addListener('onUpdateToolbarUndoRedo', onUpdateToolbarUndoRedo);
		map.getEventHandler().addListener('toggleConfirmCreation', onToggleConfirmCreation);
		map.getEventHandler().addListener('onErrorCountUpdate', onErrorCountUpdate);
		map.getEventHandler().addListener('onAutoSave', onAutoSaveActivity);
		map.getEventHandler().addListener('onImportVersionStatusChanged', handleMapObjectChanged);
		setIsFmsGenerated(map.getImportVersion().fmsGenerated);
		return () => {
			map.getEventHandler().removeListener('setActiveTool', onSetActiveTool);
			map.getEventHandler().removeListener('onZoomChange', onZoomChange);
			map.getEventHandler().removeListener('toggleConfirmCreation', onToggleConfirmCreation);
			map.getEventHandler().removeListener('onUpdateToolbarUndoRedo', onUpdateToolbarUndoRedo);
			map.getEventHandler().removeListener('onErrorCountUpdate', onErrorCountUpdate);
			map.getEventHandler().removeListener('onAutoSave', onAutoSaveActivity);
			map.getEventHandler().removeListener('onImportVersionStatusChanged', handleMapObjectChanged);
			setZoomLevel(zoomLevel);
		};
	}, []);

	useEffect(() => {
		// disable autosave if FMS map generation is in progress
		map.isAutoSaveDisabled = isGenerateFmsInProgress;
		if (isGenerateFmsInProgress) {
			console.log(`Generate FMS in Progress. Disable save`);
		} else {
			console.log(`Generate FMS Finished. Enable save`);
		}
	}, [isGenerateFmsInProgress]);

	useEffect(() => {
		if (isShowConfirmButton) {
			map.getMapLookup().confirmButtonWillShow = false;
		} else {
			setTimeout(() => {
				map.getMapLookup().confirmButtonConfirming = false; //HITMAT-1809
				map.getEventHandler().startListeningKeyDown(); //HITMAT-1809
			}, 1500);
		}
	}, [isShowConfirmButton]);
	
	const handleMapObjectChanged = async(mapObjectType: string | ImportVersionStatus, editedOrPublished: boolean) => {
		let isPathUpdate = false;
		let isAreaUpdate = false;
		if (typeof(mapObjectType) === 'string') {
			isPathUpdate = (mapObjectType === 'path');
			isAreaUpdate = (mapObjectType === 'area');
		} else {
			// must be ImportVersionStatus
			isPathUpdate = mapObjectType.pathEdited;
			isAreaUpdate = mapObjectType.areaEdited;
		}

		// We used isPathUpdate = mapObjectType.areaEdited; to check if area has been updated. If an area has been updated,
		// then mark path as updated.
		// The idea may come from what Jose mentioned: it is possible to make some edits to areas that will also cause
		// the path data to be edited, even though the path shape has not changed. This is because dynamic connections
		// can change in this case and dynamic connections are considered path data.
		// TODO: we need another way to make sure an area actually updates path data instead of make path status updated
		// for every edition of area
		if (isPathUpdate) {
			if (editedOrPublished) {
				// console.log('Path has been edited. Set FMS flag to false');
				map.getImportVersion().fmsGenerated = false;
				setIsFmsGenerated(false);
				map.getImportVersion().pathEdited = true;
				await map.getImportVersion().saveMapStatus();
			}
		}
		if (isPathUpdate || isAreaUpdate) {
			// Updates connectivity endpoints and dynamic connections (if shown)
			// TODO: save needs to be complete to ensure this works correctly.
			// map.getMapLookup().resetEntryAndExitNodeIds(); // invalidated when there's an update
			// if (map.getTracker().unsavedActions.length === 0) {
			// 	await DynamicScaleObjectHelper.updateDynamicObjectDisplay(map);
			// } else {
			// 	console.log(`Got pending changes. Setting isPendingDynamicConnectionUpdate = true;`);
			// 	map.getMapLookup().isPendingDynamicConnectionUpdate = true;
			// 	map.getTracker().saveChanges();
			// }
		}
		setIsMapEditStatusInvalid(getIsInvalidStatus());
	}

	const onToggleConfirmCreation = (showConfirmButton: boolean, isDisabled?: boolean) => {
		map.setConfirmButtonStatus(showConfirmButton, !(isDisabled ?? false));

		if (map.getMapLookup().confirmButtonConfirming) {
			setIsShowConfirmButton(false);
		} else {
			setIsShowConfirmButton(showConfirmButton);
		}
		setIsDisableConfirmButton(!map.isActionConfirmAllowed());
	};

	// console.log(`Disable FMS: ${isMapErrors} ${isGenerateFmsInProgress} ${isFmsGenerated} ${isMapEditStatusInvalid}`);

	function getIsInvalidStatus() {
		const { areaEdited, areaPublished, pathEdited, fmsGenerated } = map.getImportVersion();
		const isDisabled = (!!areaEdited || !!areaPublished || !pathEdited); // potentially add OR fmsGenerated
		// console.log(`getEditStatusForFms: areaEdited: ${areaEdited} pathEdited: ${pathEdited} isDisabled: ${isDisabled}`);
		// console.log(`Disable FMS Flags: ${isMapErrors} ${isGenerateFmsInProgress} ${isFmsGenerated} ${isDisabled}`);
		return isDisabled;
	}

	const onErrorCountUpdate = (newCount: number) => {
		// When there are map errors or save has not successfully completed, disable button
		// const isDisabled = (map.getTracker().unsavedActions.length > 0 && !isSaved) || newCount > 0;
		// setIsMapErrors(isDisabled);
	};

	const onAutoSaveActivity = (isSaved: boolean, savedTimeStamp?: boolean | string) => {
		let isFmsDisabled = true;
		// See onAutoSaveActivity for origin of this logic.
		const isSaveSuccessful = isSaved && !!savedTimeStamp && typeof savedTimeStamp === 'string';
		setIsSaved(isSaveSuccessful);
		if (isSaveSuccessful && map.getMapLookup().getMapErrorCount() === 0) {
			// Enable FMS button only if save has successfuly completed and there are no map errors
			isFmsDisabled = false;
		}
		setIsMapErrors(isFmsDisabled);
	};

	const onClickItem = (eventType: ToolbarEvent) => {
		return () => {
			props.onItemSelect(eventType);
			setCustomTag('click-tool-bar', eventType);
		};
	};

	const makeItemActive = (eventType: ToolbarEvent) => {
		/* Hide the confirm button from the toolbar on
		* click of any of the tools
		*/
		return () => {
			props.map.setSelectedToolType(eventType);
			setIsShowConfirmButton(false);
			setSelectedItem(eventType);
			props.onItemSelect(eventType);
			map.getEventHandler().emit('onMapObjectSelectedInMap', undefined);
			setCustomTag('click-tool-bar', eventType);
		};
	};

	useEffect(() => disableMapDomEvents('map-toolbar'));

	const getZoomText = () => {
		const scale = zoomLevelToScale(zoomLevel);
		return `${formatScale(scale)}x`;
	};

	/**
	 * Triggered on mouse down of the confirm button on the toolbar
	 * Keyboard input is prevented on mouseDown to prevent
	 * duplicate path etc when 'p' or other keyboard shortcut is clicked
	 * straight after confirm is clicked  //HITMAT-1809
	 */
	const onPointerDown = () => {
		map.getEventHandler().stopListeningKeyDown(); //HITMAT-1809
	}

	/**
	 * Triggered when user clicks on confirm button on the toolbar
	 * Emits event to be processed by respective tool handlers for the
	 * actual confirmation logic
	 */
	const onClickConfirm = () => {
		map.getMapLookup().confirmButtonConfirming = true;
		// map.getTracker().enableBayTrackingChanges();

		map.getEventHandler().emit('onConfirmMapObjectCreation');
		setIsShowConfirmButton(false);
	};

	const onGenerateFms = async () => {
		setCustomTag('generate-fms-map', 'click-generate-fms-map-btn');

		// change button to "generating" and call endpoint
		const importVersionId = map.getImportVersion().id;
		setIsGenerateFmsInProgress(true);

		// If there is any error, don't hit the generateFMS endpoint
		const showErrorsWarnings = true;
		await validateFullMap(map, showErrorsWarnings);
		if (isMapErrors || map.getMapLookup().getMapErrorCount() > 0) {
			return;
		}

		// Save in progress icon
		axios
			.post('/api/entity/ImportVersionEntity/generateFms',
				{ ImportVersionId: importVersionId })
			.then(result => {
				if (result.data.success) {
					// TODO: set it on serverside instead of here
					map.getImportVersion().fmsGenerated = true;
					map.getImportVersion().saveMapStatus()
					alertToast('Fms generation successful');
					// reRender all FMS objects (objects not returned to reload the map)
					setTimeout(() => {
						window.location.reload();
					}, 1000)
				} else {
					const mapErrorFlags = result.data.errors as any as MapErrorFlags[];

					let errString = '';
					const hasNoPathError = (mapErrorFlags as any).includes('FMSMapNoPathInAHCMap');
					const hasNoAutonomousAreaError = (mapErrorFlags as any).includes('FMSMapNoAutonomousAreaInAHCMap');

					// Path data does not exist in AHC map data
					if (hasNoPathError) {
						errString = ERROR_NO_PATH_IN_AHC_MAP;
					// Autonomous Area data does not exist in AHC map data
					} else if (hasNoAutonomousAreaError){
						errString = ERROR_NO_AUTONOMOUS_AREA_IN_AHC_MAP;
					} else {
						// Display error flags with count of each error
						let mapErrors: Record<string, number> = {};
						mapErrorFlags.forEach(e => {
							mapErrors[e] = !!mapErrors[e] ? mapErrors[e]++ : 1; 
						});
						errString = 'FMS generation failed ';
						errString += Object.entries(mapErrors).map(v => `${v[0]} (${(v[1])})`).join(',');
					}
					alertToast(errString, 'error');
				}
			})
			.catch((e) => {
				alertToast(`generateFms request failed. Message: ${e.message}`, 'error');
			})
			.finally(() => {
				setIsGenerateFmsInProgress(false);
			});
	}

	const [isRedo, setIsRedo] = useState(false);
	const [isUndo, setIsUndo] = useState(false);

	const onUpdateToolbarUndoRedo = () => {
		// setIsUndo(tracker.undoActionAvailable() && !tracker.savingInProgress && !tracker.blockingUndoRedo);
		// setIsRedo(tracker.redoActionAvailable() && !tracker.savingInProgress && !tracker.blockingUndoRedo);
	};

	return (
		<div className="map-toolbar" id="map-toolbar">
			<ul className="left">
				<ToolbarItem icon="undo" disabled={true} onClick={onClickItem('undo')} />
				<ToolbarItem icon="redo" disabled={true} onClick={onClickItem('redo')} />
				<ToolbarItem
					icon="pointer"
					selectedItem={selectedItem}
					selectedWhen={['selector']}
					onClick={makeItemActive('selector')}
				/>
				<ToolbarItem
					icon="ruler-new"
					selectedItem={selectedItem}
					selectedWhen={['ruler']}
					onClick={makeItemActive('ruler')}
				/>
				<ToolbarItem
					icon="area-new"
					selectedItem={selectedItem}
					selectedWhen={['area']}
					onClick={makeItemActive('area')}
				/>
				<ToolbarItem
					icon="back-in"
					selectedItem={selectedItem}
					selectedWhen={['bay']}
					onClick={makeItemActive('bay')}
				/>
				<ToolbarItem
					icon="link"
					selectedItem={selectedItem}
					selectedWhen={['clothoid', 'connection', 'path']}
					onClick={makeItemActive('path')}
					children={[
						// { text: 'Path', onClick: makeItemActive('clothoid'), selectedWhen: ['clothoid'] },
						{ text: 'Path', onClick: makeItemActive('path'), selectedWhen: ['path'] },
						{ text: 'Connectivity', onClick: makeItemActive('connection'), selectedWhen: ['connection'] },
					]}
				/>
				{isShowConfirmButton
					&& (
						<Button
							className="confirm-button"
							display={Display.Solid}
							onClick={onClickConfirm}
							onPointerDown={onPointerDown}
							buttonProps={{ id: 'confirm_button' }}
							disabled={isDisableConfirmButton}
						>Confirm
						</Button>
					)}
			</ul>

			<EvaluationDisclaimer />

			<ul className="right">
				<Button
					className="generate-fms-button btn--solid btn--primary"
					colors={Colors.Primary}
					onClick={onGenerateFms}
					disabled={isMapErrors || isGenerateFmsInProgress || isFmsGenerated || isMapEditStatusInvalid}
					icon={{ icon: isGenerateFmsInProgress ? 'generate-fms' : '', iconPos: 'icon-left' }}
				>
					{ isGenerateFmsInProgress ? 'Generating...' : 'Generate FMS Map' }
				</Button>
				<UserImageText />
				{/*<ToolbarItem text="3d" icon="3d" onClick={displayComingSoonToast} />*/}
				<ZoomToolbarItem
					text={getZoomText()}
					map={map}
				/>
			</ul>
		</div>
	);
}

function EvaluationDisclaimer() {
	if (!store.isTestMineSite) {
		return null;
	}

	return (
		<div className="evaluation-mode-disclaimer-toolbar">
			<div>
				No map data generated by the application is to be used in a Production, Simulation or UAT Environment. Map data may be deleted with each new version release.
			</div>
		</div>
	);
}

interface IToolbarItemProps {
    icon?: string;
    text?: string;
    selectedItem?: ToolbarEvent;
	selectedWhen?: ToolbarEvent[];
	disabled?: boolean;
    onClick?: () => void;

    children?: IToolbarItemProps[];
}

/**
 * Renders each individual toolbar item.
 * Creates an expandable dropdown if an item contains children
 * @param props
 * @returns
 */
function ToolbarItem(props: IToolbarItemProps) {
	const [isExpanded, setExpanded] = useState(false);
	return (
		<li className={props.selectedItem && props.selectedWhen?.includes(props.selectedItem) ? 'active' : undefined}>
			<div className="buttons">
				<Button onClick={props.onClick} disabled={props.disabled} className={props.children !== undefined ? 'has-dropdown' : undefined}>
					{!props.icon ? props.text : <span className={`icon-${props.icon} icon-only`} />}
				</Button>

				{props.children !== undefined
					? (
						<Button
							buttonProps={{
								onClick: () => setExpanded(!isExpanded),
								onBlur: e => {
								/* The relevant lifecycle of a dom click event is: mousedown -> blur -> mouseup -> click
                                 * The blur and click event conflict with each other because the blur event is triggered
                                 * by clicking. To fix this, we check if the target of the click event is different to
                                 * the target of the blur event.
                                 */
									const eventTarget = e.currentTarget.parentElement?.parentElement ?? e.currentTarget;
									if (!eventTarget.contains(e.relatedTarget as Node)) {
										setExpanded(false);
									}
								},
							}}
							className={classNames(`arrow-${props.icon}`,
								'arrow', `icon-chevron-${isExpanded ? 'up' : 'down'}`, 'icon-only')}
						/>
					)
					: undefined}
			</div>

			{/* Render any child elements */}
			{props.children === undefined ? <></> : (
				<>
					<ul className={classNames('sublist', isExpanded ? 'expanded' : undefined)}>
						{props.children.map(childProps => (
							<ToolbarItem
								key={childProps.text}
								{...childProps}
								selectedItem={props.selectedItem}
								onClick={() => {
									if (childProps.onClick) {
										childProps.onClick();
									}

									// Hide the dropdown when something is clicked
									setExpanded(false);
								}}
							/>
						))}
					</ul>
				</>
			)}
		</li>
	);
}

interface IZoomToolbarItemProps {
    text: string;
	map: MapController;
}

/**
 * The toolbar zoom action menu button
 * @param props
 * @returns
 */
function ZoomToolbarItem(props: IZoomToolbarItemProps) {
	const [isExpanded, setExpanded] = useState(false);

	const onZoomMenuClick = () => {
		setExpanded(!isExpanded);
	};

	const collapseZoomMenu = () => {
		setExpanded(false);
	};

	const onZoomBlur = (e: React.FocusEvent<Element>) => {
		const eventTarget = e.currentTarget.parentElement?.parentElement ?? e.currentTarget;
		const relatedTarget = e.relatedTarget as Node;
		if (!eventTarget.contains(e.relatedTarget as Node)) {
			setExpanded(false);
		} else if (relatedTarget.nodeName !== 'INPUT') {
			(e.target as any).focus();
		}
	};

	return (
		<li className={isExpanded ? 'active' : undefined}>
			<div className="buttons">
				<Button
					className="has-dropdown zoom-button"
					buttonProps={{
						onClick: onZoomMenuClick,
						onBlur: onZoomBlur,
					}}
				>
					{props.text}
				</Button>
				<Button
					buttonProps={{
						onClick: onZoomMenuClick,
						onBlur: onZoomBlur,
					}}
					className={classNames('arrow', `icon-chevron-${isExpanded ? 'up' : 'down'}`,
						'icon-only', 'zoom-menu')}
				/>
			</div>
			{
				isExpanded ? <ZoomActionToolbarMenu map={props.map} onInputLoseFocus={collapseZoomMenu} /> : null
			}
		</li>
	);
}

interface IZoomActionToolbarMenu {
	map: MapController;
	onInputLoseFocus?: () => void;
}

/**
 * The toolbar zoom action dropdown menu
 * @param props
 * @returns
 */
function ZoomActionToolbarMenu(props: IZoomActionToolbarMenu) {
	const leafletMap = props.map.getLeafletMap();

	const zoom = leafletMap?.getZoom();
	const scale = zoomLevelToScale(zoom);
	const zoomInfo: ZoomInfo = { zoomLevel: scale };
	const unit = 'x';
	const inputClassName = `unit-${unit?.length ?? 0}`;

	const zoomByStep = (isZoomIn: boolean) => {
		const step = isZoomIn ? 1 : -1;
		if (leafletMap) {
			leafletMap.setZoom(leafletMap.getZoom() + step);
		}
	};

	const calcZoomScale = (zoomLevel: number) => {
		return Math.log2(zoomLevel);
	};

	const onProcessInput = () => {
		if (leafletMap) {
			let err = '';
			const minZoomScale = zoomLevelToScale(MINIMUM_ZOOM);
			const maxZoomScale = zoomLevelToScale(MAXIMUM_ZOOM);
			let zoom = MINIMUM_ZOOM;
			if (zoomInfo.zoomLevel > 0) {
				if (zoomInfo.zoomLevel > maxZoomScale) {
					zoom = MAXIMUM_ZOOM;
					err = `Value too high. Setting to max of ${maxZoomScale}`;
				} else if (zoomInfo.zoomLevel < minZoomScale) {
					err = `Value to low. Setting to min of ${minZoomScale}`;
				} else {
					zoom = calcZoomScale(zoomInfo.zoomLevel);
				}
			} else {
				err = `Value to low. Setting to min of ${minZoomScale}`;
			}
			if (err.length > 0) {
				alertToast(err, 'error');
			}
			leafletMap.setZoom(zoom);
		}
	};

	const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			onProcessInput();
		}
	};

	// tabIndex is added to the divs so they become focusable and therefore appear in event.relatedTarget
	// https://stackoverflow.com/questions/42764494/blur-event-relatedtarget-returns-null
	return (
		<div
			tabIndex={0}
			className="toolbar-zoom-action-dropdown"
			onBlur={e => {
				// Handles case of collapsing menu when input box had focus
				const eventTarget = e.currentTarget.parentElement?.parentElement ?? e.currentTarget;
				const relatedTarget = e.relatedTarget as Node;
				if (!eventTarget.contains(e.relatedTarget as Node)) {
					if (eventTarget.nodeName === 'UL' && relatedTarget.nodeName === 'DIV') {
						if (props.onInputLoseFocus) {
							props.onInputLoseFocus();
						}
					}
				}
			}}
		>
			<div className="properties-input">
				<div className="info-fields">
					<NumberTextField
						className="zoom-level-input"
						model={zoomInfo}
						modelProperty="zoomLevel"
						inputClassName={inputClassName}
						inputProps={{
							onBlur: onProcessInput,
							onKeyPress: onKeyPress,
						}}
					/>
					<p>{unit}</p>
				</div>
			</div>
			<div className="separator" />
			<div tabIndex={1} className="zoom-info clickable" onClick={() => zoomByStep(true)}>
				<p>Zoom in</p>
				<p>+</p>
			</div>
			<div tabIndex={2} className="zoom-info clickable" onClick={() => zoomByStep(false)}>
				<p>Zoom out</p>
				<p>-</p>
			</div>
			<div className="separator" />
			<div tabIndex={3} className="zoom-info">
				<p>Pan map</p>
				<p>Space + Drag</p>
			</div>
		</div>
	);
}
