import React, { useEffect, useState } from 'react';
import LinkEntity from '../../../Models/Entities/LinkEntity';
import {convertToFixed, submitFormOnEnter, truncateNumber} from './PropertiesSidePanel';
import alertToast from '../../../Util/ToastifyUtils';
import { NumberTextField } from '../../Components/NumberTextBox/NumberTextBox';
import { LinkFromLinkTo, MapToolParamEntity, NodeEntity, SignalSetEntity, SublinkEntity } from '../../../Models/Entities';
import MapController from '../Map/MapController';
import InputWrapper, { InputType } from '../../Components/Inputs/InputWrapper';
import classNames from 'classnames';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { connectivityOptions, turnSignal, turnSignalOptions } from 'Models/Enums';
import { observable, runInAction } from 'mobx';
import CollapsibleProperty from '../CollapsibleProperty';
import LinkOperationsHelper, {
	updateLinkStateOnly,
	updateLinkSublinkStatesOnly,
	updateLinkSublinkNodeStates
} from '../Map/MapStateHandlerHelpers/LinkOperationsHelper';
import ErrorsAndWarnings from './ErrorsAndWarnings';
import ConnectivityToolHandler, { CONNECTIVITY_INVALID_NODE, CONNECTIVITY_PAIR } from '../Map/MapStateHandlers/ConnectivityToolHandler';
import ConnectivityValidator, {
	CONNECTIVITY_INVALID_DIRECTION,
	CONNECTIVITY_INVALID_DISTANCE
} from '../Map/MapValidators/ConnectivityValidator';
import PathToolHelper from '../Map/MapStateHandlerHelpers/PathToolHelper';
import { upperCaseFirst } from 'Util/StringUtils';
import TurnSignalHelper from '../Map/MapStateHandlerHelpers/TurnSignalHelper';
import { getAffectedLinks } from '../Map/Helpers/DrivingZone';
import {RenderInformationCombobox} from "./PropertiesPanelComponents/RenderInformationCombobox";
import InputField from "./PropertiesPanelComponents/InputField";
import {SetLinkSpeedCommand} from "../ChangeTracker/ChangeTypes/SetLinkSpeedCommand";
import DeleteConnectivityCommand from "../ChangeTracker/ChangeTypes/DeleteConnectivityCommand";
import CreateConnectivityCommand from "../ChangeTracker/ChangeTypes/CreateConnectivityCommand";
import UpdateConnectivityCommand from "../ChangeTracker/ChangeTypes/UpdateConnectivityCommand";
import UpdateTurnSignalCommand from "../ChangeTracker/ChangeTypes/UpdateTurnSignalCommand";
import DeleteTurnSignalCommand from "../ChangeTracker/ChangeTypes/DeleteTurnSignalCommand";
import CreateTurnSignalCommand from "../ChangeTracker/ChangeTypes/CreateTurnSignalCommand";

// making error messages as a constant to keep track of error messages
const LINK_ID_DOES_NOT_EXIST = 'This Link ID does not exist. Please enter a valid Link ID.';
const LINK_ID_NEGATIVE = 'A Link ID must be a positive number between 1 and [StaticLinkIDMax from map params]. Please enter a valid Link ID.';
const LINK_ID_CONNECTIVITY_WITH_ITSELF = 'A Link cannot have a connectivity with itself. Please enter a valid Link ID.';
const LINK_ID_EXISTING = 'This connectivity already exist. Please enter a valid Link ID.';

const SIGNAL_NOT_AN_INTEGER = 'The Start position and Length should be an integer.';
const SIGNAL_START_NEGATIVE = 'The Start position should not be negative.';
const SIGNAL_START_INVALID = 'The Start position cannot be greater than 99m or (total length of the link - 1m). The minimum turn signal length is 1m.';
const SIGNAL_LENGTH_EMPTY = 'The Length should not be empty or 0.';
const SIGNAL_LENGTH_LESS_THAN_ONE = 'The Length should not be negative and the minimum allowed value is 1m.';
const SIGNAL_LENGTH_OVER_LINK_100 = 'The turn signal has been over the link or the 100m position. Set length to a valid value.';

interface DerivedProperties {
	sublinksCount: number,
	linkLength: string,
	maxSpeed: number,
	minSpeed: number,
	rampStartSpeed?: number,
	rampEndSpeed?: number,
	constantSpeed?: number,
	turnSignalLength?: number,
}

/**
 * Render properties side panel for a selected link.
 * Link properties panel can be used to view link information and edit link connectivities and speed.
 * @param props
 * - entity: LinkEntity
 * - mapParams: MapToolParamEntity used for getting link restrictions such as min or max speed.
 * - map: MapController used for getting a lookup table
 * @constructor
 */
// eslint-disable-next-line max-len
export default function LinkProperties({ entity, mapParams, map } : { entity: LinkEntity, mapParams: MapToolParamEntity, map: MapController }) {
	let link = observable(entity);

	map.getEventHandler().emit('onUpdateToolbarUndoRedo');

	const initialState = {
		sublinksCount: 0,
		linkLength: '0',
		maxSpeed: 0,
		minSpeed: 0,
		constantSpeed: undefined,
		rampMinSpeed: undefined,
		rampMaxSpeed: undefined,
		turnSignalLength: calculateTurnSignalLength(),
	};
	const [derivedProperties, setDerivedProperties] = useState<DerivedProperties>(initialState);
	const [displayConstantSpeed, setDisplayConstantSpeed] = useState(false);
	const [displayAdvanced, setDisplayAdvanced] = useState(false);
	const [displayAddTurnSignal, setDisplayAddTurnSignal] = useState(false);

	const [error, setError] = useState('');
	const [previousLinks, setPreviousLinks] = useState(link.previousLinks());
	const [nextLinks, setNextLinks] = useState(link.nextLinks());
	const action = 'edit';

	const [signalStartError, setSignalStartError] = useState('');
	const [signalLengthError, setSignalLengthError] = useState('');
	const [turnSignal, setTurnSignal] = useState(link.signalSetss.length > 0 ? link.signalSetss[0] : undefined);
	const [validLinkLengthForSignal, setValidLinkLengthForSignal] = useState(link.getDistance() <= 100 ? link.getDistance() : 100);

	function isConstantSpeed() {
		const result = link.isDefaultSpeed === false
			&& !!link.constantSpeed; // NOT undefined && NOT null && NOT zero
		setDisplayConstantSpeed(result);
		return result;
	}

	useEffect(() => {
		setDerivedProperties({
			sublinksCount: link.sublinkss.length,
			linkLength: getLinkLength(),
			maxSpeed: calculateMaxSpeed(),
			minSpeed: calculateMinSpeed(),
			rampStartSpeed: undefined,
			rampEndSpeed: undefined,
			constantSpeed: link.constantSpeed,
			turnSignalLength: calculateTurnSignalLength(),
		});
		setDisplayConstantSpeed(isConstantSpeed());
		setPreviousLinks(link.previousLinks());
		setNextLinks(link.nextLinks());
		setTurnSignal(link.signalSetss.length > 0 ? link.signalSetss[0] : undefined);
		return () => {
			if (!link.constantSpeed) {
				// removed to fix undo/redo of edit link
				// runInAction(() => {
				// 	link.isdefaultspeed = true;
				// });
				setDisplayConstantSpeed(false);
			}
		};
	}, [entity]);

	function getLinkLength() {
		return link.getDistance().toFixed(2);
	}

	function calculateMaxSpeed() {
		let maxSpeed = Number.NEGATIVE_INFINITY;
		let negative = false;
		link.sublinkss.forEach(s => s.nodess.forEach(n => {
			if (n.speed !== 0 && Math.abs(n.speed) > maxSpeed) {
				maxSpeed = Math.abs(n.speed);
				negative = n.speed < 0;
			}
		}));

		// If the speeds are equal, but have different signs (positive and negative), prioritize displaying the positive value
		if (negative) {
			const hasPositiveEqualValue = link.sublinkss.some(s => s.nodess.some(n => maxSpeed === Math.abs(n.speed) && n.speed > 0));
			negative = hasPositiveEqualValue? !negative : negative;
		}

		return maxSpeed * (negative ? -1 : 1);
	}

	function calculateMinSpeed() {
		let minSpeed = Number.POSITIVE_INFINITY;
		let negative = false;
		link.sublinkss.forEach(s => s.nodess.forEach(n => {
			if (n.speed !== 0 && Math.abs(n.speed) < minSpeed) {
				minSpeed = Math.abs(n.speed);
				negative = n.speed < 0;
			}
		}));

		// If the speeds are equal, but have different signs (positive and negative), prioritize displaying the positive value
		if (negative) {
			const hasPositiveEqualValue = link.sublinkss.some(s => s.nodess.some(n => minSpeed === Math.abs(n.speed) && n.speed > 0));
			negative = hasPositiveEqualValue? !negative : negative;
		}

		return minSpeed * (negative ? -1 : 1);
	}

	function calculateTurnSignalLength(): number | undefined {
		if (link.signalSetss.length > 0 && !!link.signalSetss[0]) {
			const len = link.signalSetss[0].signalEnd - link.signalSetss[0].signalStart
			return len > 0 ? len : undefined;
		}
		return undefined;
	}

	/**
	 * Validate link constant speed
	 * @return true if valid
	 * @return false otherwise
	 * @constructor
	 */
	function validateLinkConstantSpeed(value: number): string | undefined {
		if (value === 0 || !value) {
			return 'Please enter a valid speed';
		}

		const isReverse = value < 0;
		const speedType = isReverse ? ' reverse ' : ' ';

		// prevent changing the sign of link speed
		if ((link.constantSpeed > 0 && value < 0) || (link.constantSpeed < 0 && value > 0)) {
			return 'Speed sign of a link cannot be changed.';
		}

		const isConstantSpeedAboveMaximumLimit = value > mapParams.maxSpeedEmpty
			|| value < mapParams.maxSpeedBackward;

		if (isConstantSpeedAboveMaximumLimit) {
			const maxSpeed = isReverse ? mapParams.maxSpeedBackward : mapParams.maxSpeedEmpty;
			return `Constant speed exceeds the maximum${speedType}speed ${maxSpeed} km/h`;
		}

		if (Math.abs(value) < mapParams.minSpeedEmpty) {
			const minSpeed = isReverse ? -mapParams.minSpeedEmpty : mapParams.minSpeedEmpty;
			return `Constant speed is below the minimum${speedType}speed ${minSpeed} km/h`;
		}

		const firstSublink = map.getMapLookup().getFirstSublinkForLink(link.id);
		const firstNode = map.getMapLookup().getFirstNodeForSublink(firstSublink?.id ?? '');
		if (firstNode?.task === 'PARKING' && value < 0) {
			return 'Link speed must be positive';
		}

		// derivedProperties.constantSpeed and value here are not always number type
		// If focus and then blur twice (don't change value), they become string type,
		// which causes issue because constantSpeed at the backend only receive number type
		if (Number(derivedProperties.constantSpeed) === Number(value)) {
			console.log("No need to update constantSpeed");
		} else {
			// If input a new value, value is number type
			console.log("Update constantSpeed");
			derivedProperties.constantSpeed = value;

			// Emit the path edit event
			map.getEventHandler().emitPathEditedEvent();

			link.constantSpeed = value;
			link.isDefaultSpeed = false;

			map.getTracker1().addChange(SetLinkSpeedCommand.fromLink(link));
		}
		
		return undefined;
	}

	/**
	 * TODO: Add action don't need to pass linkIdTobeRemoved, this should be refactor
	 * Validate connectivity.
	 * Without validating connectivity distance if it is 'add' action.
	 * @param currentLink The link that is selected
	 * @param linkIdToBeConnected The linkIdNumber that is to be connected
	 * @param state Previous or Next
	 * @param _action Add or Edit
	 * @param linkIdTobeRemoved If 'add', this is the id of a link that is to be connected. If 'edit', this is the id of a link that is to be removed.
	 */
	// eslint-disable-next-line max-len
	function validateConnectivity(
		currentLink: LinkEntity,
		linkIdToBeConnected: number,
		state: string,
		_action: string,
		linkIdTobeRemoved: string,
	): string {
		let matchingExistingConnectivity: boolean[] | undefined;
		let count = 0;
		let errorStatus = 'false';

		// check if the link id already exist in previous or next column
		if (state.toLowerCase() === 'previous') {
			matchingExistingConnectivity = previousLinks?.map((id: LinkEntity) => id.linkId === linkIdToBeConnected);
		}
		if (state.toLowerCase() === 'next') {
			matchingExistingConnectivity = nextLinks?.map((id: LinkEntity) => id.linkId === linkIdToBeConnected);
		}

		if (!!matchingExistingConnectivity) {
			matchingExistingConnectivity.forEach((value: boolean) => {
				if (value) {
					count += 1;
				}
			});
		}

		const linkToBeConnected = map?.getMapLookup().getLinkByIdNumber(linkIdToBeConnected);

		// validations
		if (!linkIdToBeConnected || !linkToBeConnected) {
			return LINK_ID_DOES_NOT_EXIST;
		}

		if (linkIdToBeConnected < 0 || linkIdToBeConnected > mapParams?.staticLinkIdMax) {
			return LINK_ID_NEGATIVE;
		}

		const startNode = state.toLowerCase() === 'previous' ? currentLink.firstNode() : linkToBeConnected.firstNode();
		if (startNode?.task !== 'HAULING') {
			return CONNECTIVITY_INVALID_NODE;
		}

		if (linkIdToBeConnected === currentLink.linkId) {
			return LINK_ID_CONNECTIVITY_WITH_ITSELF;
		}

		if ((count === 1)) {
			return LINK_ID_EXISTING;
		}

		// The following code is for 'edit'
		const linkTobeRemoved = map?.getMapLookup().getEntity(linkIdTobeRemoved, LinkEntity);
		if (!linkTobeRemoved) {
			return 'The link to be removed does not exist';
		}

		// Add action: check the link that is to be added
		// Edit action: check the link that is to be removed
		// If the removal passes validation, it means there are no connectivity pairs, which allows a new one to be added
		const _linkToBeChecked = _action === 'add' ? linkToBeConnected : linkTobeRemoved;
		const startNodeLink = state.toLowerCase() === 'next' ? _linkToBeChecked : currentLink;
		const endNodeLink = state.toLowerCase() === 'previous' ? _linkToBeChecked : currentLink;
		const hasConnectivityPair = ConnectivityToolHandler
			.hasConnectivityPair(startNodeLink, endNodeLink, map.getMapLookup(), _action);
		if (hasConnectivityPair) {
			return CONNECTIVITY_PAIR;
		}

		setError('');
		errorStatus = 'true';
		const isEditAction = _action === 'edit';
		if (isEditAction) {
			console.log('Inital entity id', linkToBeConnected.getModelId());
			if (state.toLowerCase() === 'previous') {
				if (!!linkToBeConnected) {
					// for undo/redo actions, removing the existing connectivity -> we need to pass a LinkFromLinkTo Model
					// Hence, fetching the LinkFromLinkTo from the LinkFroms
					const lfltToBeRemoved = currentLink.linkFroms.find(
						item => (
							item.linkToId === currentLink?.getModelId()) && (item.linkFromId === linkTobeRemoved?.getModelId()
						),
					);
					if (lfltToBeRemoved) {
						lfltToBeRemoved.getModelId();
					}

					// validate connectivity distance before removing the previous link
					if (!validateConnectivityDistance(currentLink, linkToBeConnected, false)) {
						return CONNECTIVITY_INVALID_DISTANCE;
					}

					if (!validateConnectivityDirections(linkToBeConnected, currentLink)) {
						return CONNECTIVITY_INVALID_DIRECTION;
					}

					currentLink.removePreviousLink(linkIdTobeRemoved);
					addConnectivity(currentLink, linkToBeConnected, isEditAction, lfltToBeRemoved);
					setPreviousLinks(currentLink.previousLinks());
				} else {
					errorStatus = 'Link not found.';
				}
			}

			if (state.toLowerCase() === 'next') {
				if (!!linkToBeConnected) {
					// for undo/redo actions, removing the existing connectivity -> we need to pass a LinkFromLinkTo Model
					// Hence, fetching the LinkFromLinkTo from the LinkFroms
					const lfltToBeRemoved = currentLink.linkTos.find(
						item => (
							item.linkFromId === currentLink?.getModelId()) && (item.linkToId === linkTobeRemoved?.getModelId()
						),
					);
					if (lfltToBeRemoved) {
						lfltToBeRemoved.getModelId();
					}

					// validate connectivity distance before removing the next link
					if (!validateConnectivityDistance(linkToBeConnected, currentLink, false)) {
						return CONNECTIVITY_INVALID_DISTANCE;
					}

					if (!validateConnectivityDirections(linkToBeConnected, currentLink)) {
						return CONNECTIVITY_INVALID_DIRECTION;
					}

					currentLink.removeNextLink(linkIdTobeRemoved);
					addConnectivity(linkToBeConnected, currentLink, isEditAction, lfltToBeRemoved);
					setNextLinks(currentLink.nextLinks());
				} else {
					errorStatus = 'Link not found.';
				}
			}
		}

		return errorStatus;
	}

	/**
	 * Handle delete connectivity
	 * @param linkToBeRemoved
	 * @param currentLink
	 * @param state
	 */
	const handleDeleteConnectivity = (linkToBeRemoved: LinkEntity, currentLink: LinkEntity, state: string) => {
		console.log('LinkIds from handleDeleteConnectivity', currentLink.linkId, linkToBeRemoved.linkId);

		const _currentLink = LinkOperationsHelper.copyLink(currentLink);
		const _linkToBeRemoved = LinkOperationsHelper.copyLink(linkToBeRemoved);
		map.getEventHandler().emitPathEditedEvent();

		let deletedConnectivities: LinkFromLinkTo[] = [];
		if (state.toLowerCase() === 'previous') {
			deletedConnectivities = currentLink.removePreviousLink(linkToBeRemoved);
			setPreviousLinks(link.previousLinks());

			console.assert(deletedConnectivities.length === 1, 'In theory, there should be only one connectivity to be deleted at once.', deletedConnectivities);

			deletedConnectivities.forEach(x => map?.getTracker1().addChange(new DeleteConnectivityCommand(x.linkFromId, x.linkToId)));
		}
		if (state.toLowerCase() === 'next') {
			deletedConnectivities = currentLink.removeNextLink(linkToBeRemoved);
			setNextLinks(link.nextLinks());

			console.assert(deletedConnectivities.length === 1, 'In theory, there should be only one connectivity to be deleted at once.', deletedConnectivities);

			deletedConnectivities.forEach(x => map?.getTracker1().addChange(new DeleteConnectivityCommand(x.linkFromId, x.linkToId)));
		}
		if (map && deletedConnectivities.length === 1) {
			PathToolHelper.calculateDrivingZone(map.getMapLookup(), map.getImportVersion().id, undefined, deletedConnectivities[0], 'LP.handleDeleteConnectivity');
		}
	};

	/**
	 * Render connectivity table
	 * @constructor
	 */
	const RenderConnectivityTable = () => {
		return (
			<div className="connectivity-table">
				<div className="properties-column">
					<div className="label">
						<p>Previous Link ID</p>
					</div>
					{previousLinks?.length === 0 ? <div className="disabled-info-fields"> None </div>
						: !!previousLinks && previousLinks.map(p => {
							const model = { linkId: p.linkId };
							return (
								<div className="info-fields" key={p.id ?? p._clientId}>
									<InputField
										isNumber
										key={p.id ?? p._clientId}
										model={model}
										modelProperty="linkId"
										onValidateInput={(value: any) => validateConnectivity(link, value,
											'previous', action, p.id)}
										maxLength={5}
									/>
									<span
										key={p.id + "_minus" ?? p._clientId + "_minus"}
										className={classNames('icon', 'icon-only', 'icon-minus', 'connnectivity-icon')}
										onClick={() => { handleDeleteConnectivity(p, link, 'previous'); }}
									/>
								</div>
							);
						})}
				</div>

				<div className="properties-column">
					<div className="label">
						<p>Next Link ID</p>
					</div>
					{
						nextLinks?.length === 0 ? <div className="disabled-info-fields"> None </div>
							: !!nextLinks && nextLinks.map(p => {
								const model = { linkId: p.linkId };
								return (
									<div className="info-fields" key={p.id ?? p._clientId}>
										<InputField
											isNumber
											key={p.id ?? p._clientId}
											model={model}
											modelProperty="linkId"
											onValidateInput={
												(value: any) => validateConnectivity(link, value,
													'next', action, p.id)
											}
											maxLength={5}
										/>
										<span
											key={p.id + "_minus" ?? p._clientId + "_minus"}
											className={classNames('icon', 'icon-only', 'icon-minus', 'button-width', 'connectivity-icon')}
											onClick={() => { handleDeleteConnectivity(p, link, 'next'); }}
										/>
									</div>
								);
							})

					}
				</div>
			</div>
		);
	};

	/**
	 * Validate a turn signal
	 * @param linkLength
	 * @param options
	 */
	const validateTurnSignal = (linkLength: number, options: TurnSignalProperties): string | undefined => {
		let errorStatus: string | undefined = undefined;

		if (options.startPosition < 0) {
			return SIGNAL_START_NEGATIVE;
		}

		if (!Number.isInteger(options.startPosition)) {
			return SIGNAL_NOT_AN_INTEGER;
		}

		if (options.startPosition > linkLength - 1 || options.startPosition > 99) {
			return SIGNAL_START_INVALID;
		}

		if (!options.turnSignalLength) {
			return SIGNAL_LENGTH_EMPTY;
		}

		if (!Number.isInteger(options.turnSignalLength)) {
			return SIGNAL_NOT_AN_INTEGER;
		}

		if (options.turnSignalLength < 1) {
			return SIGNAL_LENGTH_LESS_THAN_ONE;
		}

		if (options.turnSignalLength > validLinkLengthForSignal - options.startPosition) {
			return SIGNAL_LENGTH_OVER_LINK_100;
		}

		return errorStatus;
	};

	const validateTurnSignalForInput = (parentLink: LinkEntity, signalType: turnSignal, startPosition: number, turnSignalLength: number | undefined) => {
		const options: TurnSignalProperties = observable({
			type: signalType,
			startPosition: startPosition,
			turnSignalLength: turnSignalLength,
		});
		let errorMessage: string | undefined;

		const linkLength = parentLink.getDistance();
		errorMessage = validateTurnSignal(linkLength, options);

		return errorMessage;
	}

	const handleUpdateTurnSignal = (parentLink: LinkEntity, signalType: turnSignal, startPosition: number, turnSignalLength: number | undefined) => {
		if (!turnSignal) {
			return;
		}

		const myOldTurnSignal = new SignalSetEntity(turnSignal);

		// Update link state
		updateLinkStateOnly(link, map.getEventHandler());

		runInAction(() => {
			turnSignal!.signalStart = startPosition
			if (!!turnSignalLength) {
				turnSignal!.signalEnd = startPosition + turnSignalLength;
			}
		});
		TurnSignalHelper.regenerateSignalMapObject(turnSignal, map, true);
		setDerivedProperties(prevState => {
			return { ...prevState, turnSignalLength: turnSignalLength };
		});
		// map?.getEventHandler().emit('onTrackUpdateSignal', myOldTurnSignal, turnSignal as SignalSetEntity);
		// map.getEventHandler().emitPathEditedEvent();
		map.getTracker1().addChange(new UpdateTurnSignalCommand(parentLink.id, turnSignal));
	};

	const handleDeleteTurnSignal = (turnSignal: SignalSetEntity) => {
		const oldLink = LinkOperationsHelper.copyLink(link);
		link.signalCount = 0;
		updateLinkSublinkStatesOnly(link);
		map.getEventHandler().emit('onLinkStateUpdate', oldLink, link);

		setTurnSignal(undefined);
		setDerivedProperties(prevState => {
			return { ...prevState, turnSignalLength: undefined };
		});

		// map.getEventHandler().emit('onTrackDelete', turnSignal);
		// map.deleteEntity(turnSignal, true);
		// map.getEventHandler().emitPathEditedEvent();
		map.getTracker1().addChange(new DeleteTurnSignalCommand(oldLink.id, turnSignal.id));
	};

	/**
	 * Render turn signal table
	 * @constructor
	 */
	const RenderTurnSignalTable = () => {
		return (
			<div className="turn-signal-table">
				<div className="properties-column">
					<div className="label">
						<p>Signal</p>
					</div>
					{!turnSignal || turnSignal.signalStart < 0 ? <div className="disabled-info-fields"> None </div>
						: (
						<div className="info-fields">
							<p>{upperCaseFirst(turnSignal.signalType.toLowerCase())}</p>
						</div>
					)}
				</div>
				<div className="properties-column">
					<div className="label">
						<p>Start</p>
					</div>
					{(!turnSignal || turnSignal.signalStart < 0) ? null
						: (
						<div className="info-fields">
							<InputField
								isNumber
								key={turnSignal.id ? `${turnSignal.id}_start` : `${turnSignal._clientId}_start`}
								model={turnSignal}
								modelProperty="signalStart"
								propertyUnit="m"
								maxLength={3}
								renderDisplayValue={value => truncateNumber(value)}
								onValidateInput={(value: any) => validateTurnSignalForInput(link,
									turnSignal.signalType as turnSignal, value, derivedProperties.turnSignalLength)}
								onUpdate={value => {
									handleUpdateTurnSignal(link,
										turnSignal.signalType as turnSignal, value, derivedProperties.turnSignalLength);
								}}
							/>
						</div>
					)}
				</div>
				<div className="properties-column">
					<div className="label">
						<p>Length</p>
					</div>
					{(!turnSignal || !derivedProperties.turnSignalLength || turnSignal.signalStart < 0) ? null
						: (
						<div className="info-fields">
							<InputField
								isNumber
								key={turnSignal.id ? `${turnSignal.id}_length` : `${turnSignal._clientId}_length`}
								model={derivedProperties}
								modelProperty="turnSignalLength"
								propertyUnit="m"
								maxLength={3}
								renderDisplayValue={value => truncateNumber(value)}
								onValidateInput={(value: any) => validateTurnSignalForInput(link,
									turnSignal.signalType as turnSignal, turnSignal.signalStart, value)}
								alterValueBeforeConfirm={x => {
									if (x === undefined) {
										return x;
									}

									const maxLength = Math.trunc(validLinkLengthForSignal - turnSignal.signalStart);
									if (x > maxLength) {
										return maxLength;
									}

									return Math.trunc(x);
								}}
								onUpdate={value => {
									handleUpdateTurnSignal(link,
										turnSignal.signalType as turnSignal, turnSignal.signalStart, value);
								}}
							/>
						</div>
					)}
				</div>
				<div className="properties-column">
					<div className="label">
					</div>
					{(!turnSignal || turnSignal.signalStart < 0) ? null
						: (
						<div className="info-fields">
							<span
								className={classNames('icon', 'icon-only', 'icon-minus', 'button-width', 'turn-signal-icon')}
								onClick={() => { handleDeleteTurnSignal(turnSignal); }}
							/>
						</div>
					)}
				</div>
			</div>
		);
	};

	/**
	 * Render set link speed tab
	 * @constructor
	 */
	function RenderSetLinkSpeed() {
		/*
		 * Prevent setting a constant speed if a special node is in the middle of the link (not the first or last node)
		 */
		const canSetConstantSpeed = (): boolean => !link.getNodes()
			.every((n, i, arr) => (n.task === 'HAULING' || i === 0 || i === arr.length - 1));

		return (
			<>
				<div className="link-speed">
					<InputWrapper inputType={InputType.RADIO} label="Default Speed" className="extra-margin">
						<input
							type="radio"
							onChange={() => {
								runInAction(() => {	
									link.isDefaultSpeed = true;
								});
								setDisplayConstantSpeed(!link.isDefaultSpeed);
								map.getEventHandler().emitPathEditedEvent();

								map.getTracker1().addChange(SetLinkSpeedCommand.fromLink(link));
							}}
							checked={!displayConstantSpeed}
						/>
					</InputWrapper>
					<InputWrapper inputType={InputType.RADIO} label="Constant">
						<input
							type="radio"
							disabled={canSetConstantSpeed()}
							onChange={() => {
								runInAction(() => {
									link.isDefaultSpeed = false;
								});
								if (!!link.constantSpeed) {
									map.getEventHandler().emitPathEditedEvent();
								}
								setDisplayConstantSpeed(!link.isDefaultSpeed);

								map.getTracker1().addChange(SetLinkSpeedCommand.fromLink(link));
							}}
							checked={displayConstantSpeed}
						/>
					</InputWrapper>
					{
						displayConstantSpeed
							? (
								<>
									<InputField
										key={`speed_${link.linkId}`}
										model={derivedProperties}
										label="Speed"
										modelProperty="constantSpeed"
										propertyUnit="km/h"
										isNumber
										renderDisplayValue={value => convertToFixed(value, 1)}
										onValidateInput={(value: any) => validateLinkConstantSpeed(value)}
									/>
								</>
							) : null
					}
				</div>
			</>
		);
	}

	/**
	 * Validate connectivity distance via two nodes
	 * @param startNodeLink: The link containing the start node of the connectivity (not the first link in the path)
	 * @param endNodeLink: The link containing the end node of the connectivity (not the final link in the path)
	 * @param showErrorToast
	 */
	function validateConnectivityDistance(startNodeLink: LinkEntity, endNodeLink: LinkEntity, showErrorToast: boolean = true) {
		if (!!map) {
			const startNode = map.getMapLookup().getFirstNodeForLink(startNodeLink.id ?? startNodeLink._clientId);
			const lastSublinkOfFromLink = endNodeLink.sublinkss?.find(sublink => !sublink.getNextSublink() || false);
			if (!!lastSublinkOfFromLink) {
				const endNode = lastSublinkOfFromLink.getLastNode();
				if (!!startNode && !!endNode) {
					const isValidDistance = ConnectivityValidator.validateConnectivityDistance(
						startNode,
						endNode,
						map,
						showErrorToast,
					);
					if (!isValidDistance) {
						return false;
					}
				}
			}
		}
		return true;
	}

	function validateConnectivityDirections(startNodeLink: LinkEntity, endNodeLink: LinkEntity) {
		if (!!map) {
			const startNode = map.getMapLookup().getFirstNodeForLink(startNodeLink.id ?? startNodeLink._clientId);
			const lastSublinkOfFromLink = endNodeLink.sublinkss?.find(sublink => !sublink.nextSublink || false);
			if (!!lastSublinkOfFromLink) {
				const endNode = lastSublinkOfFromLink.getLastNode();
				if (!!startNode && !!endNode) {
					const isValidDirection = ConnectivityValidator
						.validateConnectivityDirections(endNode, startNode, map.getMapLookup());
					if (!isValidDirection) {
						return false;
					}
				}
			}
		}
		return true;
	}

	/**
	 * Add connectivity for the given two links, update link state(s), and
	 * emit onTrackAddConnectivity or onTrackUpdateConnectivity event.
	 * @param startNodeLink: The link containing the start node of the connectivity (not the first link in the path)
	 * @param endNodeLink: The link containing the end node of the connectivity (not the final link in the path)
	 * @param isEditAction
	 * @param lfltToBeRemoved if isEditAction is true, provide connectivity need to be removed.
	 */
	function addConnectivity(startNodeLink: LinkEntity, endNodeLink: LinkEntity, isEditAction?: boolean, lfltToBeRemoved?: LinkFromLinkTo): boolean {
		const _startNodeLink = LinkOperationsHelper.copyLink(startNodeLink);
		const _endNodeLink = LinkOperationsHelper.copyLink(endNodeLink);

		// add connectivity
		const newLinkFrom = new LinkFromLinkTo({
			linkFrom: endNodeLink, linkFromId: endNodeLink.id, linkTo: startNodeLink, linkToId: startNodeLink.id,
		});
		newLinkFrom.id = newLinkFrom._clientId;
		startNodeLink.linkFroms.push(newLinkFrom);
		endNodeLink.linkTos.push(newLinkFrom);

		if (startNodeLink && endNodeLink && map) {
			updateLinkSublinkNodeStates(startNodeLink, map.getEventHandler());
			updateLinkSublinkNodeStates(endNodeLink, map.getEventHandler());
			// map.getEventHandler().emitPathEditedEvent();

			let linksToUpdate = getAffectedLinks(endNodeLink, startNodeLink, mapParams);

			// Unconnected link needs to update DZ
			if (!!lfltToBeRemoved) {
				const unconnectedLinkFrom = map?.getMapLookup().getLinkById(lfltToBeRemoved.linkFromId);
				const unconnectedLinkTo = map?.getMapLookup().getLinkById(lfltToBeRemoved.linkToId);
				linksToUpdate = linksToUpdate.concat(getAffectedLinks(unconnectedLinkFrom, unconnectedLinkTo, mapParams));
			}
			/**
			 * Emit the event to render the connectivity graphic for the updated link
			 */
			map.getEventHandler().emit('requestUpdate', link);
			PathToolHelper.calculateDrivingZone(map.getMapLookup(), map.getImportVersion().id, linksToUpdate, undefined, 'LP.addConnectivity (NO AWAIT)');			
		}
		if (!isEditAction) {
			console.log('emit add connectivity event');
			map.getTracker1().addChange(new CreateConnectivityCommand(endNodeLink.id, startNodeLink.id));
		} else if (isEditAction && lfltToBeRemoved) {
			console.log('emit update connectivity event');

			map.getTracker1().addChange(new UpdateConnectivityCommand(lfltToBeRemoved.linkFromId, lfltToBeRemoved.linkToId, endNodeLink.id, startNodeLink.id));
		}

		return true;
	}

	/**
	 * Add Connectivity tab
	 * @param currentLink
	 * @param hidePanel
	 * @constructor
	 */
	function AddConnectivity({ currentLink, hidePanel } : { currentLink: LinkEntity, hidePanel: () => void }) {
		const options = observable({
			selected: 'PREVIOUS',
			linkId: undefined,
		});

		const connectivityOptionsCombobox = Object
			.entries(connectivityOptions)
			.map(([value, display]) => ({ display, value }));

		const handleAdd = () => {
			// Some of the validations here had better to be integrated into validateConnectivity
			if (!options.linkId || !map?.getMapLookup().getLinkByIdNumber(parseInt(options.linkId, 10))) {
				alertToast('This Link ID does not exist. Please enter a valid Link ID.', 'error');
				setError('error');
				return;
			}

			const linkToBeConnected = map?.getMapLookup().getLinkByIdNumber(parseInt(options.linkId, 10));
			if (!linkToBeConnected) {
				setError('error');
				return;
			}

			const connectivityValidationResult = validateConnectivity(
				currentLink, options.linkId, options.selected, 'add', linkToBeConnected.id,
			);
			if (connectivityValidationResult !== 'true') {
				alertToast(connectivityValidationResult, 'error');
				setError('error');
				return;
			}

			if (options.selected.toLowerCase() === 'previous') {
				if (!validateConnectivityDistance(currentLink, linkToBeConnected)) {
					setError('error');
					return;
				}
				addConnectivity(currentLink, linkToBeConnected)
				setPreviousLinks(link.previousLinks());
			}

			if (options.selected.toLowerCase() === 'next') {
				if (!validateConnectivityDistance(linkToBeConnected, currentLink)) {
					setError('error');
					return;
				}
				addConnectivity(linkToBeConnected, currentLink)
				setNextLinks(link.nextLinks());
			}
			setError('');
		};

		return (
			<div className="connectivity-sidebar-popout">
				<div className="additional-properties-input">
					<div className="section-header">
						<h6>Add Connectivity</h6>
						<Button
							className="close-button no-background"
							icon={{ icon: 'cross', iconPos: 'icon-bottom' }}
							colors={Colors.White}
							display={Display.Text}
							onClick={() => {
								setError('');
								hidePanel();
							}}
						/>
					</div>
					<div className="section-divider" />
					<RenderInformationCombobox
						model={options}
						label="Position"
						modelProperty="selected"
						options={connectivityOptionsCombobox}
					/>

					<NumberTextField
						className={error === 'error' ? 'add-connectivity-link-id-error' : 'add-connectivity-link-id'}
						model={options}
						label="Link ID"
						modelProperty="linkId"
						inputProps={{
							maxLength: 5,
							onKeyDown: submitFormOnEnter(handleAdd)
						}}
					/>

					<Button type="submit" className="connectivity-submit btn--primary" onClick={handleAdd}>
						Add
					</Button>
				</div>
			</div>
		);
	}

	interface TurnSignalProperties {
		type: turnSignal,
		startPosition: number,
		turnSignalLength: number | undefined,
	}

	/**
	 * Creates a new turn signal
	 */
	function createNewTurnSignal(options: TurnSignalProperties) {
		const newSignalEntity = new SignalSetEntity({
			signalType: options.type,
			signalStart: options.startPosition,
			signalEnd: options.startPosition + (options.turnSignalLength ?? 0),
			linkId: link.id,
		});

		newSignalEntity.id = newSignalEntity._clientId;
		const signalMapObject = TurnSignalHelper.createSignalMapObject(newSignalEntity, map, true);

		if(!!signalMapObject) {
			const renderer = map.getMapRenderer();
			const lookup = map.getMapLookup();

			renderer.addObject(signalMapObject, true);
			renderer.rerender();
			
			lookup.createEntity(newSignalEntity);
			const createdSignalEntity = lookup.getEntity(newSignalEntity.id, SignalSetEntity);
			lookup.setSignalSetByLinkId(createdSignalEntity as SignalSetEntity);
			map.getEventHandler().emit('onUpdateSignal', signalMapObject);

			TurnSignalHelper.addSignalToLinkEntity(newSignalEntity, link);

			// Update link state
			const oldLink = LinkOperationsHelper.copyLink(link);
			link.signalCount = 1;
			// updateLinkSublinkNodeStates(link, map.getEventHandler());
			map.getEventHandler().emit('onLinkStateUpdate', oldLink, link);

			setTurnSignal(link.signalSetss[0]);
			setDerivedProperties(prevState => {
				return { ...prevState, turnSignalLength: options.turnSignalLength };
			});

			// map.getEventHandler().emit('onTrackCreate', newSignalEntity);
			// map.getEventHandler().emitPathEditedEvent();
			map.getTracker1().addChange(new CreateTurnSignalCommand(link.id, newSignalEntity));
		}
	}

	/**
	 * Add Turn Signal tab
	 * @param parentLink
	 * @param hidePanel
	 * @constructor
	 */
	function AddTurnSignal({ parentLink, hidePanel } : { parentLink: LinkEntity, hidePanel: () => void }) {
		const options: TurnSignalProperties = observable({
			type: 'LEFT',
			startPosition: 0,
			turnSignalLength: undefined,
		});

		const linkLength = parentLink.getDistance();

		const turnSignalOptionsCombobox = Object
			.entries(turnSignalOptions)
			.map(([value, display]) => ({ display, value }));

		const handleAdd = () => {
			setSignalStartError('');
			setSignalLengthError('');

			const error = validateTurnSignal(linkLength, options);

			if ((error === SIGNAL_NOT_AN_INTEGER && !Number.isInteger(options.startPosition)) ||
				error === SIGNAL_START_NEGATIVE || error === SIGNAL_START_INVALID) {
				alertToast(error, 'error');
				setSignalStartError('error');
				return;
			}

			if (error === SIGNAL_NOT_AN_INTEGER || error === SIGNAL_LENGTH_EMPTY ||
				error === SIGNAL_LENGTH_LESS_THAN_ONE || error === SIGNAL_LENGTH_OVER_LINK_100) {
				if (error === SIGNAL_LENGTH_OVER_LINK_100) {
					runInAction(() => {
						options.turnSignalLength = Math.trunc(validLinkLengthForSignal - options.startPosition);
					});	
				}
				alertToast(error, 'error');
				setSignalLengthError('error');
				return;
			}

			createNewTurnSignal(options);

			setSignalStartError('');
			setSignalLengthError('');
			hidePanel();
		}

		return (
			<div className="turn-signal-sidebar-popout">
				<div className="additional-properties-input">
					<div className="section-header">
						<h6>Add Turn Signal</h6>
						<Button
							className="close-button no-background"
							icon={{ icon: 'cross', iconPos: 'icon-bottom' }}
							colors={Colors.White}
							display={Display.Text}
							onClick={() => {
								setSignalStartError('');
								setSignalLengthError('');
								hidePanel();
							}}
						/>
					</div>
					<div className="section-divider" />
					<RenderInformationCombobox
						model={options}
						modelProperty="type"
						label="Signal Type"
						options={turnSignalOptionsCombobox}
					/>

					<div className="flex">
						<NumberTextField
							className={signalStartError === 'error' ? 'add-turn-signal-error' : 'add-turn-signal'}
							model={options}
							modelProperty="startPosition"
							label="Start Position"
							inputProps={{
								maxLength: 3,
								onKeyDown: submitFormOnEnter(handleAdd)
							}}
						/>
						<span className="unit-add-turn-signal">m</span>
					</div>

					<div className="flex">
						<NumberTextField
							className={signalLengthError === 'error' ? 'add-turn-signal-error' : 'add-turn-signal'}
							model={options}
							modelProperty="turnSignalLength"
							label="Length"
							inputProps={{
								maxLength: 3,
								onKeyDown: submitFormOnEnter(handleAdd)
							}}
						/>
						<span className="unit-add-turn-signal">m</span>
					</div>

					<Button type="submit" className="turn-signal-submit btn--primary" onClick={handleAdd}>
						Add
					</Button>
				</div>
			</div>
		);
	}

	const _maxOrMinSpeed = (speedKey: string) => {
		const speed = derivedProperties[speedKey];
		const dataType = typeof speed;
		let newSpeed = '0.0';
		if (dataType === 'number') {
			newSpeed = speed.toFixed(1);
		} else if (dataType === 'string') {
			newSpeed = speed as any as string;
		}
		return { 
			[speedKey]: newSpeed,
		}
	};

	const connectivityPlusButton = (
		<Button
			className="plus-btn no-background"
			icon={{ icon: 'plus', iconPos: 'icon-right' }}
			colors={Colors.White}
			display={Display.Text}
			onClick={() => setDisplayAdvanced(prevState => !prevState)}
		/>
	);

	const signalPlusButton = (
		<Button
			className="plus-btn no-background"
			icon={{ icon: 'plus', iconPos: 'icon-right' }}
			colors={Colors.White}
			display={Display.Text}
			onClick={() => {
				let validSignals = link.signalSetss.map(s => s.signalStart >= 0);
				if(validSignals.length === 0) {
					setDisplayAddTurnSignal(prevState => !prevState);
				}
			}}
		/>
	);

	return (
		<>
			<h6>Link Properties</h6>
			<InputField model={link} label="ID" modelProperty="linkId" propertyUnit="" isReadOnly />
			<InputField
				model={derivedProperties}
				label="No. of Sublinks"
				modelProperty="sublinksCount"
				propertyUnit=""
				isReadOnly
			/>
			<InputField model={derivedProperties} label="Length" modelProperty="linkLength" propertyUnit="m" isReadOnly />
			<InputField
				model={_maxOrMinSpeed("maxSpeed")}
				label="Max Speed"
				modelProperty="maxSpeed"
				propertyUnit="km/h"
				isNumber
				isReadOnly
			/>
			<InputField
				model={_maxOrMinSpeed("minSpeed")}
				label="Min Speed"
				modelProperty="minSpeed"
				propertyUnit="km/h"
				isNumber
				isReadOnly
			/>
			<div className="section-divider" />

			{displayAdvanced
				&& <AddConnectivity currentLink={link} hidePanel={() => setDisplayAdvanced(false)} />}

			<CollapsibleProperty
				className="connectivity"
				propertyTitle="Connectivity"
				displayProperty
				plusButton={connectivityPlusButton}
			>
				<RenderConnectivityTable />
			</CollapsibleProperty>

			<div className="section-divider" />

			{displayAddTurnSignal
				&& <AddTurnSignal parentLink={link} hidePanel={() => setDisplayAddTurnSignal(false)} />}

			<CollapsibleProperty
				className="turn-signal"
				propertyTitle="Turn Signal"
				displayProperty
				plusButton={signalPlusButton}
			>
				<RenderTurnSignalTable />
			</CollapsibleProperty>

			<div className="section-divider" />
			<CollapsibleProperty propertyTitle="Link Speed" displayProperty>
				{RenderSetLinkSpeed()}
			</CollapsibleProperty>
			<div className="section-divider" />
			<ErrorsAndWarnings mapObject={link} mapController={map} />
			<div className="section-divider" />
		</>
	);
}
