import type {RealWorldCoordinates} from "../../../Helpers/Coordinates";
import type {nodetask} from "../../../../../../Models/Enums";
import * as uuid from 'uuid';
import {action, computed, IObservableArray, observable, runInAction} from "mobx";
import {IWaypointRequestData} from "../../../MapStateHandlerHelpers/PathRequestHelper";
import {NodeEntity} from "../../../../../../Models/Entities";
import {SnapOption} from "./PathSnapHelper";

// mixed is technically possible between each sublink but shouldn't occur in MAT
export type ValidPathDirections = Direction | 'mixed';

export type Direction = 'forward' | 'reverse';

export function reverseDirection(direction: Direction): Direction {
	return direction === 'forward' ? 'reverse' : 'forward';
}

export class Waypoint {
	@observable
	public id: string = uuid.v4();

	@observable
	public northing: number;

	@observable
	public easting: number;

	@observable
	public heading: number;

	@observable
	public elevation: number;

	@observable
	public shortClicked: boolean;

	@observable
	public task: nodetask;

	// This direction value specifies the direction between this and the NEXT waypoint
	@observable
	public direction: Direction;

	@observable
	public isConfirmed: boolean = false;

	public connections: string[] = [];

	constructor(waypoint?: Partial<Waypoint>) {
		this.assignAttributes(waypoint);
	}

	@action
	public assignAttributes(waypoint?: Partial<Waypoint>) {
		if (!waypoint) {
			return;
		}

		const {
			heading,
			northing,
			easting,
			shortClicked,
			task,
			elevation,
			direction,
			id,
			isConfirmed,
			connections
		} = waypoint;

		if (heading !== undefined) {
			this.heading = heading;
		}
		if (northing !== undefined) {
			this.northing = northing;
		}
		if (easting !== undefined) {
			this.easting = easting;
		}
		if (shortClicked !== undefined) {
			this.shortClicked = shortClicked;
		}
		if (task !== undefined) {
			this.task = task;
		}
		if (elevation !== undefined) {
			this.elevation = elevation;
		}
		if (direction !== undefined) {
			this.direction = direction;
		}
		if (id !== undefined) {
			this.id = id;
		}
		if (isConfirmed !== undefined) {
			this.isConfirmed = isConfirmed;
		}
		if (connections !== undefined) {
			this.connections = Array.from(connections);
		}
	}

	@action
	public setHeading(heading: number) {
		this.heading = heading;
	}

	@action
	public setElevation(elevation: number) {
		this.elevation = elevation;
	}

	@action
	public setTask(task: nodetask) {
		this.task = task;
	}

	@action
	public setDirection(direction:  Direction) {
		this.direction = direction;
	}

	@action
	public reverseDirection() {
		this.direction = this.direction === 'forward' ? 'reverse' : 'forward';
	}

	public get coordinates(): RealWorldCoordinates {
		return {
			northing: this.northing ?? 0,
			easting: this.easting ?? 0
		}
	}

	public set coordinates(coords: RealWorldCoordinates) {
		const { northing, easting } = coords;

		runInAction(() => {
			this.northing = northing;
			this.easting = easting;
		});
	}

	public get isSnapped() {
		return this.connections.length > 0;
	}

	public removeConnections() {
		this.connections = [];
	}

	public addConnection(connectionId: string) {
		console.log('add connection', connectionId);
		this.connections.push(connectionId);
	}

	public removeConnection(connectionId: string) {
		this.connections = this.connections.filter(x => x !== connectionId);
	}

	public toNode(isFinalNode: boolean) {
		return new NodeEntity({
			id: this.id,
			northing: this.northing,
			easting: this.easting,
			task: this.task,
			heading: this.heading,
			direction: this.direction,
			up: this.elevation,

			// If the waypoint is the last waypoint, assign anything to previousNodeId to trick the NodeGraphic into
			// displaying as the last node (prevents the flicker from start to end node type when rendering)
			previousNodeId: isFinalNode ? this.id : undefined,
		});
	}

	public copy(): Waypoint {
		return new Waypoint({
			...this
		});
	}
}


/**
 * Perform updates on waypoints in such a way that it will trigger mobx updates.
 */
export class WaypointList {
	public waypoints: IObservableArray<Waypoint> = observable.array([]);

	public isFullPath() {
		return this.waypoints.length > 1;
	}

	public hasWaypoint() {
		return this.waypoints.length !== 0;
	}

	@computed
	public get firstWaypoint() {
		if (!this.hasWaypoint()) {
			return undefined;
		}

		return this.waypoints[0];
	}

	@computed
	public get mostRecentlyPlacedWaypoint() {
		if (!this.hasWaypoint()) {
			return undefined;
		}

		return this.waypoints[this.waypoints.length - 1];
	}

	@computed
	public get lastWaypoint() {
		return this.waypoints[this.waypoints.length - 1];
	}

	@computed
	public get lastWaypointForPropertiesPanel() {
		return this.lastWaypoint?.isConfirmed === true ? this.lastWaypoint : undefined;
	}

	public getWaypoint(waypointId: string): Waypoint | undefined {
		return this.waypoints.find(x => x.id === waypointId);
	}

	public getWaypointBefore(waypointId: string): Waypoint | undefined {
		const waypointIndex = this.waypoints.findIndex(x => x.id === waypointId);
		return this.waypoints[waypointIndex - 1];
	}

	public isWaypoint(waypointId: string): boolean {
		return this.getWaypoint(waypointId) !== undefined;
	}

	/**
	 * Returns true if the given id is a mid-waypoint that is a hauling task
	 *
	 * @param waypointId to check if it is a mid-waypoint
	 */
	public isMidWaypoint(waypointId: string): boolean {
		const waypointIndex = this.waypoints.findIndex(x => x.id === waypointId
			&& x.task === 'HAULING');

		// If the waypoint is the first or last waypoint, it is not a mid-waypoint
		return waypointIndex > 0 && waypointIndex < this.waypoints.length - 1;
	}

	public isStartOrEndWaypoint(waypointId: string): boolean {
		const waypointIndex = this.waypoints.findIndex(x => x.id === waypointId);

		// If the waypoint is the first or last waypoint, it is not a mid-waypoint
		return waypointIndex === 0 || waypointIndex === this.waypoints.length - 1;
	}

	@action
	public addWaypoint(coords: RealWorldCoordinates, waypoint: Partial<Waypoint> = {}) {
		// Confirm the previous waypoint if we are adding a new one
		this.confirmWaypoint();

		const newWaypoint = new Waypoint({
			id: uuid.v4(),
			heading: 0,
			elevation: 0,
			shortClicked: true,
			task: 'HAULING',
			direction: 'forward',
			isConfirmed: false,
			...waypoint,
		});

		newWaypoint.coordinates = coords;
		this.waypoints.push(newWaypoint);

		return newWaypoint;
	}

	@action
	public updateWaypoint(waypoint: Waypoint) {
		const tempWaypoint = this.waypoints.find(x => x.id === waypoint.id);
		if (!tempWaypoint) {
			return;
		}

		tempWaypoint.assignAttributes(waypoint);
	}

	/**
	 * Insert a new waypoint after the waypoint with the given id. If the given previousWaypointId is undefined and the
	 * waypoint list is empty, the new waypoint will be pushed to the list directly
	 * @param previousWaypointId is the id of the previous waypoint to insert the new waypoint after
	 * @param waypoint to insert
	 */
	@action
	public insertWaypoint(previousWaypointId: string | undefined, waypoint: Partial<Waypoint>) {
		if (previousWaypointId === undefined && this.length === 0) {
			this.waypoints.push(new Waypoint(waypoint));
			return;
		}

		const waypointIndex = this.waypoints.findIndex(x => x.id === previousWaypointId);
		if (waypointIndex === -1) {
			return;
		}

		this.waypoints.spliceWithArray(waypointIndex + 1, 0, [new Waypoint(waypoint)]);
	}

	@action
	public removeWaypoint(id?: string) {
		if (id === undefined) {
			this.waypoints.pop();
			return;
		}

		const waypointToRemove = this.getWaypoint(id);
		if (!!waypointToRemove) {
			if (!this.waypoints.remove(waypointToRemove)) {
				console.warn('Unable to remove waypoint:', id);
			}
		}
	}

	@action
	public removeLastWaypoint() {
		if (!this.waypoints.remove(this.waypoints[this.waypoints.length - 1])) {
			console.warn('Unable to remove last waypoint');
		} else {
			this.resetStartWaypoint();
		}
	}

	@action
	public removeAllWaypoints() {
		this.waypoints.clear();
	}

	@action
	public confirmWaypoint() {
		if (!!this.lastWaypoint) {
			this.lastWaypoint.isConfirmed = true;
		}
	}

	public removeUnconfirmedWaypoint() {
		if (this.lastWaypoint?.isConfirmed === false) {
			this.removeLastWaypoint();
			return true;
		}

		return false;
	}

	public get iter() {
		return this.waypoints;
	}

	public getRequestData(): IWaypointRequestData[] {
		return this.waypoints.map((waypoint, i) => ({
			id: waypoint.id,
			northing: waypoint.coordinates!.northing,
			easting: waypoint.coordinates!.easting,
			heading: waypoint.heading,
			task: waypoint.task,
			isReverse: waypoint.direction === 'reverse',
			isMidWaypoint: i !== 0 || i < this.waypoints.length,
		}));
	}

	public get length() {
		return this.waypoints.length;
	}

	public copy(): WaypointList {
		const newWaypoints = new WaypointList();

		this.waypoints.forEach(x => newWaypoints.waypoints.push(x.copy()));

		return newWaypoints;
	}

	public setWaypoints(waypointList: WaypointList) {
		// Handle the case when waypoints have been removed
		if (this.length !== waypointList.length) {
			// Remove waypoints that are no longer in the waypoint list
			this.iter
				.filter(x => !waypointList.isWaypoint(x.id))
				.forEach(x => this.removeWaypoint(x.id));
		}

		if (this.length === 0) {
			this.waypoints.replace(waypointList.waypoints);
		}

		// Update or insert the waypoints
		waypointList.iter.forEach((x, i) => {
			const previousWaypointId = i > 0 && this.waypoints.length > 0 ? this.waypoints[i - 1].id : undefined;
			this.updateOrInsertWaypoint(x, previousWaypointId);
		});
	}

	public addNextConnection(connectionId: string) {
		this.lastWaypoint?.addConnection(connectionId);
	}

	public addPreviousConnection(connectionId: string) {
		this.firstWaypoint?.addConnection(connectionId);
	}

	public isStartSnapped() {
		return this.firstWaypoint?.isSnapped === true;
	}

	public isEndSnapped() {
		return this.lastWaypoint?.isSnapped === true;
	}

	public isStartShortClicked() {
		return this.firstWaypoint?.shortClicked === true;
	}

	public getPreviousConnections() {
		return this.firstWaypoint?.connections ?? [];
	}

	public getNextConnections() {
		return this.lastWaypoint?.connections ?? [];
	}

	public unSnapWaypoint(waypointId: string) {
		// Can only un-snap if the waypoint is a start or end waypoint
		if (!this.isStartOrEndWaypoint(waypointId)) {
			return;
		}

		const firstWaypoint = this.firstWaypoint;
		const lastWaypoint = this.lastWaypoint;

		if (firstWaypoint && waypointId === firstWaypoint.id) {
			firstWaypoint.removeConnections();
		} else if (lastWaypoint && waypointId === lastWaypoint.id) {
			lastWaypoint.removeConnections();
		}
	}

	public containsMidWaypoints() {
		return this.waypoints.length > 2;
	}

	public handleSnapOption(snapOption: SnapOption) {
		if ('start' === snapOption.type) {
			this.addNextConnection(snapOption.entityId);
		} else if ('end' === snapOption.type) {
			this.addPreviousConnection(snapOption.entityId);
		}
	}

	private updateOrInsertWaypoint(waypoint: Waypoint, previousWaypointId?: string) {
		const oldWaypoint = this.getWaypoint(waypoint.id);

		// If the waypoint exits, update it
		if (!!oldWaypoint) {
			oldWaypoint.assignAttributes(waypoint);
			return;
		}

		if (previousWaypointId !== undefined) {
			this.insertWaypoint(previousWaypointId, waypoint);
		}

	}
	
	public resetStartWaypoint() {
		const firstWaypoint = this.firstWaypoint;
		if (this.length === 1 && firstWaypoint?.shortClicked) {
			firstWaypoint?.setHeading(0);
		}
	}
}
