import { ERROR_MAXIMUM_SUBLINK_ID } from 'Constants';
import { action } from 'mobx';
import { LinkEntity, NodeEntity, SublinkEntity } from 'Models/Entities';
import { store } from 'Models/Store';
import alertToast from 'Util/ToastifyUtils';
import MapController from '../MapController';

interface IReUseIdInfo {
	idNum: number;
	isImported: boolean;
	speed?: number;
}

export default class PathToolIdHelper {

	protected controller: MapController;

	constructor(controller: MapController) {
		this.controller = controller;
	}
	/**
	 * Assigns and validates the ids for
	 * nodes, sublinks and links
	 */
	@action
	public validateAndAssignIdsForPath(link: LinkEntity, originalLinkId: string | undefined): boolean {
		// Get params to know the limit to which you can create links and underlying entities
		const params = this.controller.getImportVersion().maptoolparam;

		const lookup = this.controller.getMapLookup();

		// switch to disable reuse of ids (on edit link)
		const reuseDeletedLinkIds = true;

		if (!params) {
			console.error('No parameters found!');
			return false;
		}

		// Save the initial values for the counters and the validation state
		let isValid: boolean = true;1;

		// const link: LinkEntity = this.clothoidLink;
		const sublinks: SublinkEntity[] = link.sublinkss;

		// const allAssociatedIds = this.getLookup().getAllInclusiveIdsForLinks();

		let isImportedLink = false;
		let linkId: number | undefined;

		// Only used when editing an existing link. Need both the Id and import status
		let reuseSublinkIdsInfo: IReUseIdInfo[] = [];
		let reuseNodeIdsInfo: IReUseIdInfo[] = [];
		let skipNodeIds: number[] = [];
		let oldLastNode: NodeEntity | undefined;
		let oldFirstNode: NodeEntity | undefined;

		let newLastNode: NodeEntity | undefined;
		let newFirstNode: NodeEntity | undefined;
		let constantSpeed: number | undefined;
		let shouldBeDefaultspeed: boolean | undefined;

		const lastNodeIndex = sublinks[sublinks.length - 1].nodess.length - 1;
		newLastNode = sublinks[sublinks.length - 1].nodess[lastNodeIndex];
		newFirstNode = sublinks[0].nodess[0];

		sublinks.forEach(s => {
			s.nodess.forEach(n => {
				// Contain special nodes (other than start/end nodes), set to "default speed"
				if (n.isSpecialTask() && n.nodeId !== newFirstNode?.nodeId && n.nodeId !== newLastNode?.nodeId) {
					shouldBeDefaultspeed = true;
				}
			});
		});

		if (!!originalLinkId) {
			// Special case where previous ids must be reused.
			const deleteLinkEntity = lookup.getEntity(originalLinkId, LinkEntity);
			// Get all sublink ids
			// Get all node ids
			if (!!deleteLinkEntity) {
				// Start changing ids
				link.linkId = deleteLinkEntity.linkId;
				link.isImported = deleteLinkEntity.isImported;

				// If it's an imported link, the first and last nodes of the link
				// must have the same Id
				oldLastNode = deleteLinkEntity.lastNode();
				oldFirstNode = deleteLinkEntity.firstNode();
				// During assignment of Ids, don't use these ones as they're already taken
				if (!!oldLastNode) {
					skipNodeIds.push(oldLastNode.nodeId);
					// console.log(`firstNodeId is ${oldFirstNode.nodeId}`);
				} else {
					console.error('First node of original link not found on clothoid. May lead to unexpected results');
				}
				if (!!oldFirstNode) {
					skipNodeIds.push(oldFirstNode.nodeId);
					// console.log(`lastNodeId is ${oldLastNode.nodeId}`);
				} else {
					console.error('Last node of original link not found on clothoid edit. May lead to unexpected results');
				}				
				// Finish changing ids
				linkId = deleteLinkEntity.linkId;
				// link.isImported = deleteLinkEntity.isImported;
				isImportedLink = deleteLinkEntity.isImported;

				// Prepare for setting speeds
				if (!shouldBeDefaultspeed && !!oldFirstNode) {
					const nodeSpeed = oldFirstNode.speed;
					// If first node has speed of zero (e.g. due to special task), take speed from the next node
					constantSpeed = nodeSpeed != 0 ? nodeSpeed : oldFirstNode.getNextNode()?.speed;
				}

				if (reuseDeletedLinkIds) {
					deleteLinkEntity.sublinkss.forEach(sl => {
						reuseSublinkIdsInfo.push({idNum: sl.sublinkId, isImported: sl.isImported});
						sl.nodess.forEach(n => {
							const idNum = n.nodeId;
							if (!skipNodeIds.includes(idNum)) {
								// Only add to list if it's not first/last node of link (ids already assigned above)
								reuseNodeIdsInfo.push({idNum: idNum, isImported: n.isImported, speed: n.speed});

								if (!shouldBeDefaultspeed) {
									// Contain variable speed, set to "default speed"
									if (constantSpeed !== n.speed) {
										shouldBeDefaultspeed = true;
									}
								}
							}
						});
					});
					reuseSublinkIdsInfo.reverse();
					reuseNodeIdsInfo.reverse();
				}
				link.isDefaultSpeed = shouldBeDefaultspeed ?? deleteLinkEntity.isDefaultSpeed;
			}
		}
		/**
		 * Assign and validate ids for links
		 */
		const nextAvaialbleIdForLink = !!linkId ? linkId : store.mapStore.getNextAvailableLinkId(1)[0];

		if (!nextAvaialbleIdForLink) {
			alertToast('This operation is not allowed. The maximum allowed number'
				+ ' of links would be exceeded.', 'error');
			isValid = false;
			return isValid;
		}
		link.linkId = nextAvaialbleIdForLink;
		/**
		 * Assign and validate ids for sublinks
		 */
		if (isValid) {
			const newIds = store.mapStore.getNextAvailableSublinkId(sublinks.length);
			sublinks.forEach(sublink => {
				if (!isValid) return;
				const nextExistingIdInfo = reuseSublinkIdsInfo.pop();
				const nextAvaialbleIdForSublink = !!nextExistingIdInfo ? nextExistingIdInfo.idNum : newIds.shift();
				const tempSublinkId = sublink.sublinkId;

				if (!nextAvaialbleIdForSublink) {
					alertToast(ERROR_MAXIMUM_SUBLINK_ID, 'error');
					isValid = false;
					return;
				}
				sublink.sublinkId = nextAvaialbleIdForSublink;
				sublink.linkId = link.id;
				sublink.link = link;
				sublink.mapObjectErrorss.forEach(error => {
					error.errorMessage = error.errorMessage.replace(`Sublink_${tempSublinkId}`, `Sublink_${nextAvaialbleIdForSublink}`);
				});

				if (!!nextExistingIdInfo) {
					sublink.state = 'MODIFIED';
					if (isImportedLink) {
						sublink.isImported = nextExistingIdInfo.isImported;
					}
				}
			});
		}

		/**
		 * Assign and validate ids for nodes
		 */
		if (isValid) {
			const lastSublinkIndex = sublinks.length - 1;
			sublinks.forEach((sublink, sublinkIndex) => {
				let _nodes = sublink.nodess;
				if (!!originalLinkId) {
					let firstOrLastNodeOfLink: NodeEntity | undefined;
					let originalFirstOrLastNodeOfLink: NodeEntity | undefined;
					if (sublinkIndex === 0 && !!oldFirstNode) {
						originalFirstOrLastNodeOfLink = oldFirstNode;
						firstOrLastNodeOfLink = _nodes[0];
						this.setFirstOrLastNodeIds(firstOrLastNodeOfLink, originalFirstOrLastNodeOfLink, sublink, link.linkId);

						if (!shouldBeDefaultspeed && !firstOrLastNodeOfLink.isSpecialTask()) {
							firstOrLastNodeOfLink.speed = oldFirstNode.speed;
						}

						_nodes = _nodes.slice(1);
					}
					
					if (sublinkIndex === lastSublinkIndex && !!oldLastNode) {
						originalFirstOrLastNodeOfLink = oldLastNode;
						firstOrLastNodeOfLink = _nodes[_nodes.length - 1];
						this.setFirstOrLastNodeIds(firstOrLastNodeOfLink, originalFirstOrLastNodeOfLink, sublink, link.linkId);

						if (!shouldBeDefaultspeed && !firstOrLastNodeOfLink.isSpecialTask()) {
							firstOrLastNodeOfLink.speed = oldLastNode.speed;
						}

						_nodes = _nodes.slice(0, -1);
					}
				}

				const newNodeIds = store.mapStore.getNextAvailableNodeId(_nodes.length);
				_nodes.forEach(node => {
					if (!isValid) return;
					const nextExistingNodeIdInfo = reuseNodeIdsInfo.pop();

					// Special case where first and last ids have already been assigned (earlier in this method)

					const nextAvaialbleIdForNode = !!nextExistingNodeIdInfo ? nextExistingNodeIdInfo.idNum : newNodeIds.shift();
					if (!nextAvaialbleIdForNode) {
						alertToast('This operation is not allowed. The maximum allowed '
							+ 'number of nodes would be exceeded.', 'error');
						isValid = false;
						return;
					}
					node.nodeId = nextAvaialbleIdForNode;

					// node.sublink = sublink;
					if (!!nextExistingNodeIdInfo) {
						node.state = 'MODIFIED';
						if (isImportedLink) {
							node.isImported = nextExistingNodeIdInfo.isImported;
						}
						if (!shouldBeDefaultspeed && nextExistingNodeIdInfo.speed) {
							node.speed = nextExistingNodeIdInfo.speed;
						}
					} else { // new nodes
						if (!shouldBeDefaultspeed && constantSpeed) {
							node.speed = constantSpeed;
						}
					}
					this.setNodeIdsFromSublink(node, sublink, link.linkId);
				});
			});
		}

		return isValid;
	}

	/**
	 * Used for updating the first or the last node.
	 * Sets nodeId, isImported, linkIdNumber and sublink information
	 * @param firstOrLastNodeOfLink
	 * @param originalFirstOrLastNodeOfLink
	 * @param sublink
	 * @param linkIdNumber
	 */
	private setFirstOrLastNodeIds(
		firstOrLastNodeOfLink: NodeEntity,
		originalFirstOrLastNodeOfLink: NodeEntity,
		sublink: SublinkEntity,
		linkIdNumber: number
	) {
		if (!!firstOrLastNodeOfLink && !!originalFirstOrLastNodeOfLink) {
			firstOrLastNodeOfLink.nodeId = originalFirstOrLastNodeOfLink.nodeId;
			firstOrLastNodeOfLink.isImported = originalFirstOrLastNodeOfLink.isImported;
			console.log(`Updating first/last node of link (nodeId ${firstOrLastNodeOfLink.nodeId})`);
			this.setNodeIdsFromSublink(firstOrLastNodeOfLink, sublink, linkIdNumber);
		}
	}

	/**
	 * Used for updating node when sublink is changed
	 * sets linkIdNumber sublinkIdNumber and sublinkId of node according to sublink information
	 * @param node
	 * @param sublink
	 */
	private setNodeIdsFromSublink(node: NodeEntity, sublink: SublinkEntity, linkIdNumber: number) {
		node.linkIdNumber = linkIdNumber; //this.clothoidLink.linkId;
		node.sublinkIdNumber = sublink.sublinkId;
		node.sublinkId = sublink.id ?? sublink._clientId;
		node.sublink = sublink;
	}
}