import { LinkEntity, NodeEntity } from '../../../Models/Entities';
import { convertToFixed, IClothoidParams } from './PropertiesSidePanel';
import React, { useEffect, useState } from 'react';
import { nodetask, nodetaskOptions } from '../../../Models/Enums';
import { observable, runInAction } from 'mobx';
import MapController from '../Map/MapController';
import {
	EASTING_DEFAULT_VALUE, EASTING_INSIDE_BOUNDS_ERROR, EASTING_LESS_THAN_ZERO_ERROR,
	HEADING_ERROR, NORTHING_DEFAULT_ERROR, NORTHING_INSIDE_BOUNDS_ERROR, NORTHING_LESS_THAN_ZERO_ERROR,
} from 'Constants';
import ErrorsAndWarnings from './ErrorsAndWarnings';
import { IClothoidRequestUpdate } from '../Map/MapStateHandlers/ClothoidStateHandler';
import { setCustomTag } from '../Map/Helpers/MapUtils';
import { store } from 'Models/Store';
import {RenderInformationCombobox} from "./PropertiesPanelComponents/RenderInformationCombobox";
import {RenderToggleButton} from "./PropertiesPanelComponents/RenderToggleButton";
import InputField from "./PropertiesPanelComponents/InputField";

type StartOrEndNodeType = 'startNode' | 'endNode';

interface DirectionInfo {
	direction: string;
}

const HCM_PG_SET_KEY = 'isHcmPgSet';

/**
 * Render properties side panel for clothoid path generation.
 * Clothoid path properties panel can be used to edit way point coordinates and angles
 * @param props LinkEntity of generated clothoid path
 * @constructor
 */
export default function ClothoidPathProperties(props: { link: LinkEntity, map: MapController, clothoidParams?: IClothoidParams }) {
	const { link, map, clothoidParams } = props;

	const initialDirection: string = clothoidParams?.direction ?? 'forward';

	const initialDirectionInfo: DirectionInfo = {
		direction: initialDirection,
	};
	if (!!clothoidParams) {
		console.log(`clothoidParams: ${JSON.stringify(clothoidParams)}`);
	} else {
		console.log(`No clothoid params set`);
	}
	useState(initialDirectionInfo)
	const [directionInfo, setDirectionInfo] = useState(initialDirectionInfo);

	// console.log(`ClothoidPathProperties init`);

	// TODO: due to this early return, react hooks are called conditionally
	if (link.sublinkss.length === 0 || link.sublinkss[0].nodess.length < 2) {
		console.error('Invalid clothoid path link entity');
		return <></>;
	}

	const startNode = observable(link.sublinkss[0].nodess[0]); // TODO: why is this observable?
	const lastSublink = link.sublinkss[link.sublinkss.length - 1];
	const endNode = lastSublink.nodess[lastSublink.nodess.length - 1];

	// console.log(`ClothoidPathProperties: endNode.id = ${endNode.id}`);
	// const endNode = nodes[nodes.length - 1];

	const [endNodeDirection, setEndNodeDirection] = useState(endNode.direction);

	// Task options depend on direction (forward doesn't have DUMPINGCRUSHER)
	const generateTasks = (endNodeDirection: string) => {
		const tasks: nodetask[] = ['HAULING', 'REVERSEPOINT', 'PARKING', 'DUMPINGCRUSHER'];
		return Object
			.entries(nodetaskOptions)
			.map(([value, display]) => ({ display, value }))
			.filter(x => {
				if (endNodeDirection === 'forward') {
					return tasks.slice(0, 3).includes(x.value as nodetask);
				}
				//return tasks.includes(x.value as nodetask); // HITMAT-1397 (Temporarily deactivated DUMPINGCRUSHER option) 
				return tasks.slice(0, 3).includes(x.value as nodetask);
				
			});
	}

	const initialtaskOptions = generateTasks(endNodeDirection);

	const [taskOptions, setTaskOptions] = useState(initialtaskOptions);

	// Options used in generateDirections
	const getForwardOption = () => {
		return { display: 'Forward', value: 'forward' };
	};
	const getReverseOption = () => {
		return { display: 'Reverse', value: 'reverse' };
	};
	// Only displayed in Edit mode (when Path has +ve and -ve speeds)
	const getMixedDirectionOption = () => {
		return { display: 'Mixed', value: 'Mixed' };
	};


	const initialDirectionOptions = [getForwardOption(), getReverseOption()];
	if (directionInfo.direction === 'Mixed') {
		initialDirectionOptions.push(getMixedDirectionOption());
	}

	const [directionOptions, setDirectionOptions] = useState(initialDirectionOptions);

	// Draw mode -  Forward/Reverse Edit mode: Forward/Reverse/Mixed 
	const generateDirections = (isEdit: boolean) => {
		if (isEdit) {
			return [getForwardOption(), getReverseOption(), getMixedDirectionOption()];
		}
		return [getForwardOption(), getReverseOption()];
	}

	const fixStartEndLocationDataType = (node: NodeEntity) => {
		runInAction(() => {
			if (node.easting !== undefined && typeof node.easting === 'string') {
				node.easting = parseFloat(node.easting);
			}
			if (node.northing !== undefined && typeof node.northing === 'string') {
				node.northing = parseFloat(node.northing);
			}
		});
	}

	/**
	 * Facilitates dynamic update of clothoid on map when any of 
	 * northing/easting/heading/task are changed by user via properties
	 * emits requestUpdate which is processed by state handlers
	 * @param updatedItem 'startNode' or 'endNode'
	 */
	const updateClothoid = (updatedItem?: StartOrEndNodeType) => {
		setEndNodeDirection(endNode.direction);
		setTaskOptions(generateTasks(endNode.direction));
		console.log(`ClothoidPathProperties (updateClothoid): endNode.task = ${endNode.task} endNode.id = ${endNode.id}`);
		// HITMAT-942 - northing and easting can turn into string under certain conditions
		// Correct data type accordingly
		fixStartEndLocationDataType(startNode);
		fixStartEndLocationDataType(endNode);

		let node: NodeEntity | undefined;
		if (updatedItem === "startNode") {
			node = startNode;
		} else if (updatedItem === "endNode") {
			node = endNode;
		}
		const requestUpdateParams: IClothoidRequestUpdate = {
			entity: node
		};
		map?.getEventHandler().emit('requestUpdate', requestUpdateParams);
	};

	// Validate direction after user update from direction dropdown
	// IMPORTANT: changes task to HAULING if previous direction was reverse with task DUMPINGCRUSHER 
	const processDirectionSelection = () => {
		console.log(`processDirectionSelection: ${directionInfo.direction}`);
		runInAction(() => {
			endNode.direction = directionInfo.direction;
			if (endNode.direction === 'forward' && endNode.task === 'DUMPINGCRUSHER') {
				console.log(`processDirectionSelection: Forbidden Foward/DumpingCrusher combo. Forcing endnode to task HAULING. `)
				endNode.task = 'HAULING';
			}
			updateClothoid();
		});
	};

	const [isEditMode, setIsEditMode] = useState(false);
	const [isDirectionReadOnly, setIsDirectionReadOnly] = useState(clothoidParams?.isDirectionReadOnly ?? false);
	const [isTaskReadOnly, setIsTaskReadOnly] = useState(clothoidParams?.isTaskReadOnly ?? false);
	const [isHCMPG, setIsHCMPG] = useState(localStorage[HCM_PG_SET_KEY] === 'true');
	store.isHCMPGEnabled = isHCMPG;
	const [isHCMPGButtonReadonly, setIsHCMPGButtonReadonly] = useState(clothoidParams?.isHCMPGButtonReadonly ?? false);

	// Used set readonly correctly according to statehandler (create vs edit)
	const onStateChangeHandler = (isEdit: boolean, direction?: string) => {
		// console.log(`!!!onStateChangeHandler: isEdit ${isEdit} direction ${direction}`);
		if (isEdit !== isEditMode) {
			const modeText = isEdit ? 'Edit Mode' : 'Draw Mode';
			console.log(`State changed to ${modeText}`);
		}
		if (isEdit) {
			// Direction should be readonly in Edit mode
			setIsDirectionReadOnly(true);
			setDirectionOptions(generateDirections(isEdit));
			updateDirectionProperty(isEdit, direction);
		}
		setIsEditMode(isEdit);
	};

	// Process direction updates from Clothoid state handlers
	const onDirectionHandler = (direction: string) => {
		// console.log(`!!!onDirectionHandler: ${direction}`);
		if (endNode.direction !== direction) {
			console.log(`onDirectionHandler: ${endNode.direction} -> ${direction}`);
		}
		endNode.direction = direction;
		updateDirectionProperty(isEditMode, direction)
		setTaskOptions(generateTasks(direction));
	};

	const updateDirectionProperty = (isReadOnly: boolean, direction?: string) => {
		setDirectionOptions(generateDirections(isReadOnly));
		if (!!direction) {
			// console.log(`!!!***updateDirectionProperty: ${direction}`)
			setDirectionInfo({direction: direction});
		}
	}

	const onSetDirectionReadOnly = (isReadOnly: boolean, direction?: string) => {
		// console.log(`!!!onSetDirectionReadOnly: isReadOnly ${isReadOnly} direction ${direction}`);
		setIsDirectionReadOnly(isReadOnly);
		updateDirectionProperty(isReadOnly, direction);
	};

	const onSetTaskAndDirectionReadOnly = (isReadOnly: boolean, direction?: string) => {
		// console.log(`!!!onSetTaskAndDirectionReadOnly: isReadOnly ${isReadOnly} direction: ${direction}`);
		setIsDirectionReadOnly(isReadOnly);
		if (isReadOnly) {
			updateDirectionProperty(isReadOnly, direction);
		}
		setIsTaskReadOnly(isReadOnly);
	};

	const onSetHCMPGToggleButtonReadOnly = (isReadOnly: boolean) => {
		setIsHCMPGButtonReadonly(isReadOnly);
	};

	// Initialisation and de-initialisation
	useEffect(() => {
		map.getEventHandler().addListener('onStateChange', onStateChangeHandler);
		map.getEventHandler().addListener('setDirectionPropertyReadonly', onSetDirectionReadOnly);
		map.getEventHandler().addListener('onDirectionChange', onDirectionHandler);
		map.getEventHandler().addListener('onSetTaskAndDirectionReadOnly', onSetTaskAndDirectionReadOnly);
		map.getEventHandler().addListener('onSetHCMPGToggleButtonReadOnly', onSetHCMPGToggleButtonReadOnly);
		return () => {
			map.getEventHandler().removeListener('onStateChange', onStateChangeHandler);
			map.getEventHandler().removeListener('setDirectionPropertyReadonly', onSetDirectionReadOnly);
			map.getEventHandler().removeListener('onDirectionChange', onDirectionHandler);
			map.getEventHandler().removeListener('onSetTaskAndDirectionReadOnly', onSetTaskAndDirectionReadOnly);
			map.getEventHandler().removeListener('onSetHCMPGToggleButtonReadOnly', onSetHCMPGToggleButtonReadOnly);
			setIsEditMode(false);
		};
	}, []);

	useEffect(() => {
		console.log(`endNodeDirection change to ${endNodeDirection}`);
	}, [endNodeDirection])

	const onValidateHeading = (value: number, startOrEndNode?: StartOrEndNodeType): string | undefined => {
		if ((!!value && value < 0) || (value === undefined) || !startOrEndNode) {
			return HEADING_ERROR;
		}
		return undefined;
	};

	const onValidateEasting = (value: number, startOrEndNode: StartOrEndNodeType): string | undefined => {
		let realWorldCoords;
		if (startOrEndNode === 'startNode') {
			realWorldCoords = { easting: value, northing: startNode.northing };
		}
		if (startOrEndNode === 'endNode') {
			realWorldCoords = { easting: value, northing: endNode.northing };
		}

		if (realWorldCoords) {
			const insideBounds = map?.getEventHandler().getRenderer().isPointInMapBounds(realWorldCoords);
			if (value < 0) {
				return EASTING_LESS_THAN_ZERO_ERROR;
			}
			if (!insideBounds) {
				return EASTING_INSIDE_BOUNDS_ERROR;
			}
		} else {
			return EASTING_DEFAULT_VALUE;
		}

		return undefined;
	};

	const onValidateNorthing = (value: number, startOrEndNode: StartOrEndNodeType): string | undefined => {
		let realWorldCoords;
		if (startOrEndNode === 'startNode') {
			realWorldCoords = { easting: startNode.easting, northing: value };
		}
		if (startOrEndNode === 'endNode') {
			realWorldCoords = { easting: endNode.easting, northing: value };
		}

		if (realWorldCoords) {
			const insideBounds = map?.getEventHandler().getRenderer().isPointInMapBounds(realWorldCoords);
			if (value < 0) {
				return NORTHING_LESS_THAN_ZERO_ERROR;
			}
			if (!insideBounds) {
				return NORTHING_INSIDE_BOUNDS_ERROR;
			}
		} else {
			return NORTHING_DEFAULT_ERROR;
		}

		return undefined;
	};

	const toggleHCMPG = () => {
		const isHCMPGEnabled = !isHCMPG;
		store.isHCMPGEnabled = isHCMPGEnabled;
		localStorage[HCM_PG_SET_KEY] = isHCMPGEnabled;
		setIsHCMPG(isHCMPGEnabled);
		const option = isHCMPGEnabled ? 'enable' : 'disable'
		setCustomTag('properties-panel', `${option}-HCMPG`);
		map.getEventHandler().emit('onActivateHCMPG', isHCMPGEnabled);
	};

	return (
		<>
			<h6>Path Properties</h6>
			<RenderInformationCombobox
				model={directionInfo}
				label="Direction"
				modelProperty="direction"
				options={directionOptions}
				onAfterChange={processDirectionSelection}
				readonly={isDirectionReadOnly}
			/>
			<div className="sub-heading">
				<p>Start Waypoint</p>
			</div>
			<InputField
				model={startNode}
				label="Easting"
				modelProperty="easting"
				propertyUnit="m"
				isNumber
				isReadOnly={!isEditMode}
				renderDisplayValue={value => convertToFixed(value, 2)}
				onValidateInput={(value: any) => onValidateEasting(value, 'startNode')}
				onUpdate={() => updateClothoid("startNode")}
				errorsObject={link.propertyValidationErrors}
			/>
			<InputField
				model={startNode}
				label="Northing"
				modelProperty="northing"
				propertyUnit="m"
				isNumber
				isReadOnly={!isEditMode}
				renderDisplayValue={value => convertToFixed(value, 2)}
				onValidateInput={(value: any) => onValidateNorthing(value, 'startNode')}
				onUpdate={() => updateClothoid("startNode")}
				errorsObject={link.propertyValidationErrors}
			/>
			<InputField
				model={startNode}
				label="Elevation"
				modelProperty="up"
				propertyUnit="m"
				isNumber
				renderDisplayValue={value => convertToFixed(value, 2)}
				isReadOnly
				onValidateInput={(value: any) => onValidateHeading(value, 'startNode')}
				onUpdate={() => updateClothoid("startNode")}
				errorsObject={link.propertyValidationErrors}
			/>
			<InputField
				model={startNode}
				label="Heading"
				modelProperty="heading"
				propertyUnit="°"
				isNumber
				alterValueBeforeConfirm={(value) => value % 360}
				renderDisplayValue={value => convertToFixed(value, 1)}
				isReadOnly={!isEditMode}
				onValidateInput={(value: any) => onValidateHeading(value, 'startNode')}
				onUpdate={() => updateClothoid("startNode")}
				errorsObject={link.propertyValidationErrors}
			/>
			<div className="sub-heading">
				<p>End Waypoint</p>
			</div>
			<InputField
				model={endNode}
				label="Easting"
				modelProperty="easting"
				propertyUnit="m"
				isNumber
				isReadOnly={!isEditMode}
				renderDisplayValue={value => convertToFixed(value, 2)}
				onValidateInput={value => onValidateEasting(value, 'endNode')}
				onUpdate={() => updateClothoid("endNode")}
				errorsObject={endNode.propertyValidationErrors}
			/>
			<InputField
				model={endNode}
				label="Northing"
				modelProperty="northing"
				propertyUnit="m"
				isNumber
				isReadOnly={!isEditMode}
				onUpdate={() => updateClothoid("endNode")}
				renderDisplayValue={value => convertToFixed(value, 2)}
				onValidateInput={(value: any) => onValidateNorthing(value, 'endNode')}
				errorsObject={endNode.propertyValidationErrors}
			/>
			<InputField
				model={endNode}
				label="Elevation"
				modelProperty="up"
				propertyUnit="m"
				isNumber
				renderDisplayValue={value => convertToFixed(value, 2)}
				isReadOnly
				onValidateInput={value => onValidateHeading(value, 'endNode')}
				onUpdate={() => updateClothoid("endNode")}
				errorsObject={endNode.propertyValidationErrors}
			/>
			<InputField
				model={endNode}
				label="Heading"
				modelProperty="heading"
				propertyUnit="°"
				isNumber
				renderDisplayValue={value => convertToFixed(value, 1)}
				isReadOnly={!isEditMode}
				alterValueBeforeConfirm={v => v % 360}
				onValidateInput={(value: any) => onValidateHeading(value, 'endNode')}
				onUpdate={() => updateClothoid("endNode")}
				errorsObject={endNode.propertyValidationErrors}
			/>
			<RenderInformationCombobox
				model={endNode}
				label="Task"
				modelProperty="task"
				options={taskOptions}
				onAfterChange={updateClothoid}
				readonly={isEditMode || isTaskReadOnly}
			/>
			<RenderToggleButton
				label="Enable HCM PG"
				isChecked={isHCMPG}
				onChange={toggleHCMPG}
				isDisabled={isHCMPGButtonReadonly}
			/>

			<div className="section-divider" />
			<ErrorsAndWarnings mapObject={link} mapController={map} />
			<div className="section-divider" />
		</>
	);
}
