import { LinkEntity, LinkFromLinkTo, NodeEntity } from '../../../../Models/Entities';
import MapController from '../MapController';
import { PATH_ERROR_UNKNOWN, SERVER_URL } from '../../../../Constants';
import axios, { AxiosError } from 'axios';
import * as uuid from 'uuid';
import alertToast from '../../../../Util/ToastifyUtils';
import { action, runInAction } from 'mobx';
import { NodeGraphic } from '../../index';
import PathToolHelper, { IRequestDrivingZonesParam } from './PathToolHelper';
import { nodetask } from 'Models/Enums';
import { AnotherLinkNodeErrorResult, MapErrorFlags, NodeErrorResult, SublinkErrorResult } from '../ServerValidation';
import { IClothoidRequestUpdate, LinkSegmentDirection, Waypoint } from '../MapStateHandlers/ClothoidStateHandler';
import { store } from 'Models/Store';
import { realWorldCoordinates } from '../Helpers/Coordinates';


export enum pathRequestError {
	NONE = 0,
	NODE_LIMIT = 1,
	UNKNOWN_ERROR = 2,
}

export interface IPathRequestResponse {
	isIgnore: boolean; // an event occured which requires result to be discarded
	isSuccess: boolean; // could a path be generated?
	pathRequestError?: pathRequestError;
	pathReponseData?: IPathResponseData; // path data (only when iSuccess is true)
	isStale: boolean; // is there new data when req returns (asyn only)
	originalStartWaypoint: NodeEntity;
	originalEndWaypoint: NodeEntity;
}

export interface IPathResponseData {
	link: LinkEntity; // updated linkentity
	linksWithDZUpdate: LinkEntity[]
	pathId?: string; // new path object
	validationData: IPathValidationData; // result of validation
}

export interface IPathValidationData {
	linkErrors: MapErrorFlags[];
	// Sublink errors and node errors contain an array of error flags. Hence, there can be multiple errors
	// on a single object. The no error case is an empty array.
	sublinkErrors: SublinkErrorResult[];
	nodeErrors: NodeErrorResult[];
	anotherLinkNodeErrors: AnotherLinkNodeErrorResult[];
	isProcessed: boolean; // to prevent duplicate processing on restore
	linksWithDZUpdate: LinkEntity[];
}

export interface IWaypointRequestData {
	id: string;
	northing: number;
	easting: number;
	heading: number;
	task: nodetask;
	isReverse: boolean;
	isMidWaypoint: boolean;
}

export default class PathRequestHelper {
	private _isPendingAsyncUpdate: boolean = false;
	private _requestInProgress: boolean = false;
	private lastError: pathRequestError = pathRequestError.NONE;

	// Last validation data stored to restore error info in restoreWaypoint
	private lastValidationData: IPathValidationData | undefined;

	private controller: MapController;

	constructor(controller: MapController) {
		this.controller = controller;
	}

	public get requestInProgress() {
		return this._requestInProgress;
	}

	private set requestInProgress(v: boolean) {
		this._requestInProgress =  v;
	}

	public get isPendingAsyncUpdate() {
		return this._isPendingAsyncUpdate;
	}

	public set isPendingAsyncUpdate(v: boolean) {
		this._isPendingAsyncUpdate =  v;
	}

	public getLastValidationData() {
		return this.lastValidationData;
	}

	getLookup() { return this.controller.getMapLookup();}
	getRenderer() { return this.controller.getMapRenderer(); }
	getController() { return this.controller; }
	getEventHandler() { return this.controller.getEventHandler(); }

	/**
	 * Used for requests that involve async updates (e.g. drag heading)
	 *
	 * If there is no request in process, send request. If there is request in process, sent isPendingAsyncUpdate flag
	 * so that another request will be generated once current request is complete.
	 * asyncClothoidPathRequest
	 */
	public asyncSendRequest(
		originalWaypoints: Waypoint[],
		linkEntity: LinkEntity,
		segmentDirections: LinkSegmentDirection[],
		tempLinkFroms: LinkFromLinkTo[],
		tempLinkTos: LinkFromLinkTo[],
		dzParams?: IRequestDrivingZonesParam
	): boolean {

		// Flag can ONLY be cleared here
		this.isPendingAsyncUpdate = this.requestInProgress;
		// console.log(`asyncSendRequest (${id}): No request in progress. Send and Pending = false`);
		if (!this.isPendingAsyncUpdate) {
			// console.log(`asyncSendRequest (${id}): Making request with latest data`);
			this._sendRequest(originalWaypoints, linkEntity, segmentDirections, tempLinkFroms, tempLinkTos, true, dzParams);
		} // else there's still a req in progress. this req will call update handler, which in turn makes next req
		return this.isPendingAsyncUpdate;
	}

	public isAsyncRequestComplete() {
		const { requestInProgress, isPendingAsyncUpdate } = this;
		// console.log(`isAsyncRequestComplete: requestInProgress - ${requestInProgress} isPendingAsyncUpdate - ${isPendingAsyncUpdate}`);
		return requestInProgress === false && isPendingAsyncUpdate === false;
	}

	/**
	 * Sends request to server to retrieve clothiod path coordinates
	 * Provides core functionality to sendClothoidPathRequest / asyncSendRequest
	 * Should not be called directly (except for the above case)
	 * 
	 * @param originalWaypoints waypoint info to be used in req (after generating IWaypointRequestData)
	 * @param linkEntity 
	 * @param segmentDirections predetermined direction for each "segment" between waypoints
	 * @param isAsyncRequest was it part of async request? 
	 * @returns true if successful
	 */
	public async _sendRequest(
		originalWaypoints: Waypoint[],
		linkEntity: LinkEntity,
		segmentDirections: LinkSegmentDirection[],
		tempLinkFroms: LinkFromLinkTo[],
		tempLinkTos: LinkFromLinkTo[],
		isAsyncRequest: boolean,
		dzParams?: IRequestDrivingZonesParam,
	): Promise<IPathRequestResponse> {

		// organise data
		const len = originalWaypoints.length;
		const startWaypoint = originalWaypoints[0].node;
		const endWaypoint = originalWaypoints[len - 1].node;
		const sublink = linkEntity.sublinkss[0];
		const nodes = sublink.nodess;

		const result: IPathRequestResponse = {
			isIgnore: false,
			isSuccess: false,
			isStale: false,
			originalStartWaypoint: new NodeEntity({ ...startWaypoint }),
			originalEndWaypoint: new NodeEntity({ ...endWaypoint }),
		}

		this.getLookup().currentRequestId = Math.round(performance.now());
		// const areWaypointsDefined = !!startWaypoint.northing && !!endWaypoint.northing;

		const areWaypointsDefined = startWaypoint.northing !== undefined && endWaypoint.northing !== undefined;
		const waypoints: IWaypointRequestData[] = originalWaypoints.map(waypoint => ({
			id: waypoint.node.id,
			northing: waypoint.node.northing,
			easting: waypoint.node.easting,
			heading: waypoint.node.heading,
			task: waypoint.node.task !== undefined ? waypoint.node.task : 'HAULING',
			isReverse: waypoint.isReverse === true,
			isMidWaypoint: waypoint.node.isMidWaypoint === true,
		}));

		if (!areWaypointsDefined) {
			// eslint-disable-next-line max-len
			console.log(`Waypoints not fully defined, ignoring request startWaypoint ${startWaypoint.northing} endWaypoint ${endWaypoint.northing}`);
			this.processPendingAsyncUpdate(result, isAsyncRequest);
			return result;
		}

		// import version is needed for fetching of mapParams on server
		const importVersion = this.getController().getImportVersion().id;

		this.requestInProgress = true;

		const actualStart = waypoints[0];
		const actualEnd = waypoints.slice(-1)[0];
		const exitPathArea = PathToolHelper.isExitPath(realWorldCoordinates(actualStart.northing, actualStart.easting),
			realWorldCoordinates(actualEnd.northing, actualEnd.easting), this.controller);
		const entryPathArea = PathToolHelper.isEntryPath(realWorldCoordinates(actualStart.northing, actualStart.easting),
			realWorldCoordinates(actualEnd.northing, actualEnd.easting), this.controller);
		if (!!exitPathArea) {
			console.log(`Exit Area: ${exitPathArea.areaId} ${exitPathArea.areaName} ${exitPathArea.id}`);
		} else {
			console.log("No exit area!");
		}
		const isExitPath = !!exitPathArea;
		const isEntryPath = !!entryPathArea;

		// in edit mode, set direction based on the start waypoint speed, in draw mode, use waypoint set on endwaypoint.direction
		// if there are no Reverse Midwaypoints use ONE direction
		// console.log(`Request direction: ${direction.join(',')}`);
		const isHCMPGEnabled = store.isHCMPGEnabled;
		let allExitPathIds = dzParams?.exitPathIds;
		if (isExitPath) {
			if (!!allExitPathIds) {
				allExitPathIds.push(linkEntity.id ?? uuid.NIL);
			} else {
				allExitPathIds = [linkEntity.id ?? uuid.NIL];
			}
		}
		try {
			const { data } = await axios.post(`${SERVER_URL}/api/entity/LinkEntity/calulateClothoidPath`, {
				waypoints,
				importVersionId: importVersion,
				directions: segmentDirections,
				isHCMPGEnabled: isHCMPGEnabled,
				tempLinkFroms: tempLinkFroms,
				tempLinkTos: tempLinkTos,
				tempLinkId: linkEntity.id,
				linkEntities: dzParams?.linksToUpdateJson ?? [],
				startEndLinks: dzParams?.startEndLinksJson ?? [],
				exitPathIds: dzParams?.exitPathIds ?? [],
				isExitPath: isExitPath,
				isEntryPath: isEntryPath,
			});
			this.lastError = pathRequestError.NONE;
			if (this.isBlockProcessing()) {
				result.isIgnore = true;
				this.requestInProgress = false;
				console.log(`isIgnore (successful response) true`);
				this.processPendingAsyncUpdate(result, isAsyncRequest);
				return result;
			}

			const { item1, item2, item3, item4, item5, item6 } = data;
			const linkData = item1;

			const link = new LinkEntity({ ...linkData });
			link.id = link._clientId;

			// Original start/end waypoint (rather than using new object, original must be used and data updated)
			PathToolHelper.processLinkData(link, startWaypoint, endWaypoint, waypoints);


			// Notify event handler if update still pending
			result.isStale = this.isPendingAsyncUpdate;
			result.isIgnore = false;
			result.isSuccess = true;
			this.lastValidationData = {
				linkErrors: item2 as MapErrorFlags[],
				sublinkErrors: item3 as SublinkErrorResult[],
				nodeErrors: item4 as NodeErrorResult[],
				anotherLinkNodeErrors: item5 as AnotherLinkNodeErrorResult[],
				isProcessed: false,
				linksWithDZUpdate: item6 as LinkEntity[],
			}
			result.pathReponseData = {
				link: this.addReferencesForLink(link),
				linksWithDZUpdate: item6 as LinkEntity[],
				validationData: this.lastValidationData
			}
			this.requestInProgress = false;
			this.processPendingAsyncUpdate(result, isAsyncRequest);
			return result;
		} catch (err: any) {
			result.pathRequestError = pathRequestError.UNKNOWN_ERROR;
			this.lastError = result.pathRequestError;
			if (this.isBlockProcessing()) {
				this.requestInProgress = false;
				result.isIgnore = true;
				console.log(`isIgnore (error response) true`);
				this.processPendingAsyncUpdate(result, isAsyncRequest);
				return result;
			}

			// Set error variables. Actual error handling in create/edit modules
			let msg = err.response?.data.errors[0]?.message;
			// TODO: use a return value encoded into response instead of string comparison
			if (!!msg && msg.includes('Exceeded limit of ')) {
				// Reject placement of waypoint.
				const totalNodes = parseInt(msg.split('totalNodes:')[1]);
				console.log(`Exceeded nodes limit of 100. Got ${totalNodes} nodes`);
				result.pathRequestError = pathRequestError.NODE_LIMIT;
				this.lastError = result.pathRequestError;
			} else {
				msg =  !!msg ? (msg.message ?? PATH_ERROR_UNKNOWN) : PATH_ERROR_UNKNOWN;
				if (!msg) {
					msg = msg.message ?? PATH_ERROR_UNKNOWN
				}
				console.log(`sendRequest: !!!ERROR!!! msg: ${msg}`);
				// alertToast(err.response?.data.errors[0]?.message ?? 'Clothoid path error', 'error');
			}
			this.requestInProgress = false;
			result.isStale = this.isPendingAsyncUpdate;
			this.processPendingAsyncUpdate(result, isAsyncRequest);
			return result;
		}
	}

	/**
	 * The data returned from the server no longer contains the object references on the sublinks and nodes (to their
	 * neighbours). So we need to add these references back.
	 * @param link entity to
	 * @private
	 */
	private addReferencesForLink(link: LinkEntity): LinkEntity {
		link.sublinkss.forEach((sl, i, sublinks) => {
			if (i !== 0) {
				sl.previousSublinkId = sublinks[i - 1].id;
				sl.previousSublink = sublinks[i - 1];
			}

			if (i !== sublinks.length - 1) {
				sl.nextSublink = sublinks[i + 1];
			}

			sl.nodess.forEach((n, j, nodes) => {
				if (j !== 0) {
					n.previousNodeId = nodes[j - 1].id;
					n.previousNode = nodes[j - 1];
				}

				if (j !== nodes.length - 1) {
					n.nextNode = nodes[j + 1];
				}
			});
		});

		return link;
	}

	public getLastError() {
		return this.lastError;
	}

	public clearLastError() {
		this.lastError = pathRequestError.NONE;
	}

	/**
	 * Any request sent with asyncSendRequest must fire onAsyncRequestResponse
	 *  
	 * @param result 
	 * @param isAsyncRequest 
	 */
	public processPendingAsyncUpdate(result: IPathRequestResponse, isAsyncRequest: boolean) {
		// latest response must be processed
		// pending update flag can ONLY be cleared upon receiving event
		if (isAsyncRequest) {
			setTimeout(() => {
				this.getEventHandler().emit('onAsyncRequestResponse', result);
			}, 1);
		}
	}

	private isBlockProcessing() {
		const lookup = this.getLookup();
		if (lookup.currentRequestId === lookup.blockRequestId) {
			if (this.isPendingAsyncUpdate) {
				console.log(`isBlockProcessing: Setting isPendingAsyncUpdate to false`);
				this.isPendingAsyncUpdate = false;
			}
			console.log(`sendClothoidPathRequest: Blocking request ${lookup.blockRequestId}`);
			return true;
		}
		return false;
	}
}
