import { action } from 'mobx';
import { LinkEntity, LinkFromLinkTo, NodeEntity } from 'Models/Entities';
import alertToast from 'Util/ToastifyUtils';
import { Bay, MapController, NodeGraphic } from 'Views/MapComponents';
import * as Coordinates from '../Helpers/Coordinates';
import { calcDistanceBetweenCoords, calcDistanceBetweenNodes, calcHeading, offsetAlongHeadingRealWorld, trc_disable } from '../Helpers/MapUtils';
import ConnectivityToolHandler from '../MapStateHandlers/ConnectivityToolHandler';
import { ERROR_SNAP_OPERATION_NOT_ALLOWED, VICINITY_RADIUS } from '../../../../Constants';
import { nodetask } from 'Models/Enums';
import { LinkSegmentDirection } from '../MapStateHandlers/ClothoidStateHandler';

type WaypointDirectionType = 'forward' | 'reverse';

export interface ISnapParams {
	snapCoords: Coordinates.RealWorldCoordinates;
	snapHeading: number;
	snapForward: boolean;
	isSnapToBay: boolean;
}

interface ISnapResult {
	isSuccess: boolean;
	errorToastMessage?: string;
	params?: ISnapParams;
	targetStartLinkIdNum?: number;
	targetEndLinkIdNum?: number;
	isOppositeDirections?: boolean;
	direction?: WaypointDirectionType;
	isCrusherDumpingToHauling?: boolean; // related to HITMAT-868
	internalError?: string;
}

interface IObjInVicinity {
	nodeGraphic?: NodeGraphic;
	bay?: Bay;
	distance: number;
}

export default class SnapWaypointsOperationsHelper {
	private _controller;
	constructor(controller: MapController) {
		this._controller = controller;
	}

	getController() {
		return this._controller;
	}

	getLookup() {
		return this.getController().getMapLookup();
	}

	getRenderer() {
		return this.getController().getMapRenderer();
	}

	getEventHandler() {
		return this.getController().getEventHandler();
	}

	private trc = trc_disable;

	public snapEndWaypointToBay(
		bay: Bay,
		coords: Coordinates.RealWorldCoordinates,
		startWaypoint: Readonly<NodeEntity>,
		endWaypoint:Readonly<NodeEntity>,
		startWaypointSnappedLink: number | undefined,
		directions: LinkSegmentDirection[],
		hasMidwaypoints: boolean,
		isDrawMode: boolean,
		snapSegmentDirection: LinkSegmentDirection,
		result: ISnapResult
	) {

		const bayLocation = bay.getBayLocation();
		const bayEntity = bay.getEntity();
		// Find heading of target node by using its previous node

		const truckOffset = this.getController().getImportVersion().maptoolparam?.truckOffset ?? 0;
		console.log(`truckOffset is ${truckOffset}`);

		const snapForward = snapSegmentDirection === 'forward';

		// let snapHeading = calcHeading(fromPoint, toPoint);
		// const snapHeading = snapForward ? bayEntity.heading : (bayEntity.heading + 180) % 360;
		const snapHeading = bayEntity.heading;
		const sign = -1;
		const offsetAlongTarget = offsetAlongHeadingRealWorld(truckOffset * sign, snapHeading);
		
		const snapCoords = Coordinates.realWorldCoordinates(bayLocation.northing + offsetAlongTarget.northing,
			bayLocation.easting + offsetAlongTarget.easting);
		console.log(`snapHeading ${snapHeading} offsetNorthing: ${offsetAlongTarget.northing} offsetEasting: ${offsetAlongTarget.easting}`)
		result.isSuccess = true;
		result.params = {
			snapCoords: snapCoords,
			snapHeading: snapHeading,
			snapForward: snapForward,
			isSnapToBay: true,
		};
		return result;
	}

	@action
	public snapEndwaypointOperation(
			coords: Coordinates.RealWorldCoordinates,
			startWaypoint: Readonly<NodeEntity>,
			endWaypoint:Readonly<NodeEntity>,
			startWaypointSnappedLink: number | undefined,
			directions: LinkSegmentDirection[],
			hasMidwaypoints: boolean,
			isDrawMode: boolean,
	) {

		this.trc('Begin');

		const isStartWaypointSnapped = !!startWaypointSnappedLink;

		const result: ISnapResult = {
			isSuccess: false,
		};

		// const hasMidwaypoints = directions.length > 1;

		// Since we are snapping the end waypoint, use the direction of the last link segment
		const snapSegmentDirection = directions.slice(-1)[0];

		// There must be at least one direction (but what about on creation?)
		if (directions.length < 1) {
			result.internalError = 'No direction detected. Ignoring.';
			this.trc(result.internalError, 'warn');
			return result;
		}
		// Snap operation
		const params = this.getController().getImportVersion().maptoolparam;

		const nodeDistMin = params?.nodeDistMin ?? 1;

		let snapCoords: Coordinates.RealWorldCoordinates | undefined;
		let snapForward: boolean = true;
		let northingOffset: number;
		let eastingOffset: number;
		let offsetAlongTarget;

		// Start node with hauling task in vicinity
		const target = this.getNearestEligibleObj(coords, VICINITY_RADIUS, false, snapSegmentDirection, endWaypoint.task);

		if (target === undefined) {
			result.internalError = 'Target node or bay not found';
			this.trc(result.internalError, 'error');
			return result;
		}

		let targetNodeEntity: NodeEntity;

		if (!!target.nodeGraphic) {
			// Snap to node control flow (rest of this method)
			targetNodeEntity = target.nodeGraphic.getEntity();
		} else {
			// Snap to bay control flow (handled via snapEndWaypointToBay)
			console.log('Process snap to bay');
			if (!!target.bay) {
				const baySnapResult = this.snapEndWaypointToBay(
					target.bay,
					coords,
					startWaypoint,
					endWaypoint,
					startWaypointSnappedLink,
					directions,
					hasMidwaypoints,
					isDrawMode,
					snapSegmentDirection,
					result
				) 
				return baySnapResult;
			} else {
				// Should never reach here - denotes logic error
				result.internalError = 'Eligible obj empty (bay expected)';
				this.trc(result.internalError, 'error');
				return result;
			}
		}

		const nextNode = targetNodeEntity.getNextNode();

		// End node must have a previous node
		if (!nextNode) {
			// TODO: check this is set for new paths
			result.internalError = `nextNode is ${String(targetNodeEntity.nextNode)}`;
			this.trc(result.internalError, 'error');
			return result;
		}

		// TODO: Also need to check clientID?
		if (nextNode === undefined) {
			result.internalError = 'Lookup of nextNode id failed';
			this.trc(result.internalError, 'error');
			return result;
		}

		result.targetStartLinkIdNum = targetNodeEntity.linkIdNumber;

		const startNodeLink = this.getLookup().getLinkByIdNumber(targetNodeEntity.linkIdNumber);
		if (!startNodeLink) {
			return result;
		}

		if (isStartWaypointSnapped) {
			// TODO: this does not look right and will end up with duplicates (see HIT-622)
			const startLink = this.getLookup().getLinkByIdNumber(startWaypointSnappedLink);
			const endLink = targetNodeEntity.getLink();
			if (!!startLink && !!endLink) {
				if (ConnectivityToolHandler.isConnectivityPair(startLink, endLink, this.getLookup())) {
					result.errorToastMessage = 'Link can not have connectivity pair';
					return result;
				}
			} else {
				this.trc(`Link entity not found. Cannot check connectivity pair. startLink ${startLink} endLink ${endLink}`, 'error');
			}
		}

		// this.targetStartLinkIdNum = targetNodeEntity.linkIdNumber;

		// Find heading of target node by using its previous node
		const fromPoint = {
			easting: targetNodeEntity.easting,
			northing: targetNodeEntity.northing,
		};

		const toPoint = {
			easting: nextNode.easting,
			northing: nextNode.northing,
		};

		this.trc(`Target node task: ${targetNodeEntity.task}`);

		let targetNodeheading = calcHeading(fromPoint, toPoint);
		// Handle each of the three end waypoint cases: Hauling, Reverse Point, and Parking/Crusher Dumping
		switch (endWaypoint.task) {
			case 'HAULING':
				// Set direction to same as target node (based on speed sign)
				snapForward = targetNodeEntity.speed >= 0;
				if (!snapForward) {
					targetNodeheading = (targetNodeheading + 180) % 360;
				}
				offsetAlongTarget = offsetAlongHeadingRealWorld(3, targetNodeheading);
				northingOffset = snapForward ? offsetAlongTarget.northing : -offsetAlongTarget.northing;
				eastingOffset = snapForward ? offsetAlongTarget.easting : -offsetAlongTarget.easting;
				snapCoords = Coordinates.realWorldCoordinates(targetNodeEntity.northing - northingOffset,
					targetNodeEntity.easting - eastingOffset);
				break;

			case 'REVERSEPOINT':
				// Set direction opposite to the target node (based on speed sign)
				snapForward = targetNodeEntity.speed < 0;
				if (snapForward) {
					targetNodeheading = (targetNodeheading + 180) % 360;
				}
				offsetAlongTarget = offsetAlongHeadingRealWorld(nodeDistMin + 0.1, targetNodeheading);
				northingOffset = snapForward ? -offsetAlongTarget.northing : offsetAlongTarget.northing;
				eastingOffset = snapForward ? -offsetAlongTarget.easting : offsetAlongTarget.easting;
				snapCoords = Coordinates.realWorldCoordinates(targetNodeEntity.northing - northingOffset,
					targetNodeEntity.easting - eastingOffset);
				break;

			case 'PARKING':
				// Original rule: Set direction to same as target node (based on speed sign) 
				// New Rule (as per HITMAT-858): If the snapped node task is Parking and the target node direction is forward (based on speed sign),
				// set and lock (make read-only) the Direction of the path to whatever the Direction property is set at the time of snapping
				// snapForward = targetNodeEntity.speed >= 0;
				snapForward = snapSegmentDirection === 'forward';
				// if (!snapForward) {
					// TODO: Removed as per HITMAP-858. Keeping incase it's needed again
				// 	// alertToast(ERROR_SNAP_OPERATION_NOT_ALLOWED, 'error');
				// 	result.errorToastMessage = ERROR_SNAP_OPERATION_NOT_ALLOWED;
				// 	result.isOppositeDirections = true;
				// 	// this.isOppositeDirections = true;
				// 	return result;
				// }
				let offsetSign = snapForward ? 1 : -1;
				const isTargetForward = targetNodeEntity.speed >= 0;


				// According to spects, if target is reverse snap is not possible
				if (!isTargetForward) {
					result.errorToastMessage = ERROR_SNAP_OPERATION_NOT_ALLOWED;
				 	return result;
				}

				if (isTargetForward && !snapForward) {
					this.trc(`Snap endnode of reverse path with parking node to forward path. Reverse sign.`);
					offsetSign = -offsetSign;
				}
				offsetAlongTarget = offsetAlongHeadingRealWorld(nodeDistMin + 0.1, targetNodeheading);
				this.trc(`Paths are in opposite directions. Reverse offset sign.`);
				northingOffset = offsetSign * offsetAlongTarget.northing;
				eastingOffset = offsetSign * offsetAlongTarget.easting;
				snapCoords = Coordinates.realWorldCoordinates(targetNodeEntity.northing - northingOffset,
					targetNodeEntity.easting - eastingOffset);
				this.getEventHandler().emit('setDirectionPropertyReadonly', true);
				break;
			case 'DUMPINGCRUSHER':
				// Set direction opposite to the target node (based on speed sign)
				// TODO: should this be checking the PREVIOUS node speed?
				if (targetNodeEntity.speed > 0) {
					// Set the direction to reverse if the target node direction is forward
					snapForward = false;
				} else {
					// alertToast(ERROR_SNAP_OPERATION_NOT_ALLOWED, 'error');
					result.errorToastMessage = ERROR_SNAP_OPERATION_NOT_ALLOWED;
					result.isOppositeDirections = true;
					// this.isOppositeDirections = true; // TODO: i don't think this should happen for Edit
					return result;
				}
				offsetAlongTarget = offsetAlongHeadingRealWorld(nodeDistMin + 0.1, targetNodeheading);
				northingOffset = snapForward ? -offsetAlongTarget.northing : offsetAlongTarget.northing;
				eastingOffset = snapForward ? -offsetAlongTarget.easting : offsetAlongTarget.easting;
				snapCoords = Coordinates.realWorldCoordinates(targetNodeEntity.northing - northingOffset,
					targetNodeEntity.easting - eastingOffset);
				break;
		}

		// Snapped heading always matches that of target node
		const snapHeading = targetNodeheading;
		if (!!snapCoords) {
			// endWaypoint.direction = snapForward ? 'forward' : 'reverse';
			result.direction = snapForward ? 'forward' : 'reverse';
			// HITMAT-870/HITMAT-859
			if ((hasMidwaypoints || !isDrawMode) && (snapSegmentDirection !== result.direction)) {
				result.errorToastMessage = ERROR_SNAP_OPERATION_NOT_ALLOWED;
				this.trc(`Snapping NOT allowed because: (hasMidwaypoints (${hasMidwaypoints}) || isDrawMode ${isDrawMode}) && snapSegmentDirection(${snapSegmentDirection}) != targetWaypointDirection (${result.direction})`);
				return result;
			} else {
				this.trc(`Snapping IS allowed because: (hasMidwaypoints (${hasMidwaypoints}) || isDrawMode ${isDrawMode}) && snapSegmentDirection(${snapSegmentDirection}) != targetWaypointDirection (${result.direction})`);
			}

			if (isStartWaypointSnapped
				&& startWaypoint.direction !== result.direction) {
				result.errorToastMessage = ERROR_SNAP_OPERATION_NOT_ALLOWED;

				// Ignore the snap and show the error toast if the
				// directions of both the waypoints are not equal
				// and the first waypoint is snapped

				result.isOppositeDirections = true;

				// TODO: Check whether the point of this is to revert direction after it had been auto-changed to
				// something invalid
				// this.endWaypoint.direction = this.startWaypoint.direction;
				result.direction = startWaypoint.direction === 'forward' ? 'forward' : 'reverse';
				// this.getEventHandler().emit('onDirectionChange', this.endWaypoint.direction);
				this.trc(`snapEndwaypointOperation: emit direction change to ${result.direction}`);
				this.getEventHandler().emit('onDirectionChange', result.direction);
			} else {
				result.params = {
					snapCoords: snapCoords,
					snapHeading: snapHeading,
					snapForward: snapForward,
					isSnapToBay: false,
				};
				result.isSuccess = true;
			}
		}
		return result;
	}

	@action
	public snapStartwaypointOperation(coords: Coordinates.RealWorldCoordinates,
		endWaypoint: Readonly<NodeEntity>,
		endWaypointSnappedLinkId: number | undefined,
		directions: LinkSegmentDirection[],
		isDrawMode: boolean,
	): ISnapResult {
		
		this.trc('Begin');
		const isEndWaypointSnapped = !!endWaypointSnappedLinkId;
		const result: ISnapResult = {
			isSuccess: false,
		};

		if (directions.length < 1) {
			result.internalError = 'snapStartwaypointOperation: No direction detected. Ignoring.';
			this.trc(result.internalError, 'warn');
			return result;
		}

		const hasMidWaypoints = directions.length > 1;
		// Since the start waypoint is being snapped, only the direction of the first segment matters
		const snapSegmentDirection = directions[0];

		let snapCoords: Coordinates.RealWorldCoordinates;
		let snapHeading: number;
		let snapForward: boolean;
		// Snap operation
		const params = this.getController().getImportVersion().maptoolparam;

		const nodeDistMin = params?.nodeDistMin ?? 1;

		// Returns nearest end node in vicinity
		// TODO: not sure if task is correct here
		const target = this.getNearestEligibleObj(coords, VICINITY_RADIUS, true, snapSegmentDirection, endWaypoint.task);

		if (target === undefined || target?.nodeGraphic ==  undefined) {
			result.internalError = 'Target node not found';
			this.trc(result.internalError);
			return result;
		}

		const targetNodeEntity = target.nodeGraphic.getEntity();

		// Set the direction property to read-only
		this.getEventHandler().emit('setDirectionPropertyReadonly', true);

		// End node must have a previous node
		if (targetNodeEntity.previousNodeId === undefined) {
			this.trc('previousNodeId is null');
			return result;
		}

		const lookup = this.getLookup();
		const targetPreviousNode = lookup.getEntity(targetNodeEntity.previousNodeId, NodeEntity);
		if (targetPreviousNode === undefined) {
			result.internalError = 'Previous node not found';
			this.trc(result.internalError);
			return result;
		}

		// this.targetEndLinkIdNum = targetNodeEntity.linkIdNumber;
		result.targetEndLinkIdNum = targetNodeEntity.linkIdNumber;

		// Find heading of target node by using its previous node
		const fromPoint = {
			easting: targetPreviousNode?.easting,
			northing: targetPreviousNode?.northing,
		};

		const toPoint = {
			easting: targetNodeEntity.easting,
			northing: targetNodeEntity.northing,
		};

		let targetNodeHeading = calcHeading(fromPoint, toPoint);

		this.trc(`Target node task: ${targetNodeEntity.task}`);

		let offsetAlongTarget;
		let northingOffset;
		let eastingOffset;
		// Handle each of the three target node cases: Hauling, Reverse Point, and Parking/Crusher Dumping
		switch (targetNodeEntity.task) {
			case 'HAULING':
				// Set direction to same as target node (based on speed sign)
				snapForward = targetNodeEntity.speed >= 0;
				if (!snapForward) {
					targetNodeHeading = (targetNodeHeading + 180) % 360;
				}
				// Place 'in front' of target node at distance of 3m
				offsetAlongTarget = offsetAlongHeadingRealWorld(3, targetNodeHeading);
				northingOffset = snapForward ? offsetAlongTarget.northing : -offsetAlongTarget.northing;
				eastingOffset = snapForward ? offsetAlongTarget.easting : -offsetAlongTarget.easting;

				snapCoords = Coordinates.realWorldCoordinates(targetNodeEntity.northing + northingOffset,
					targetNodeEntity.easting + eastingOffset);
				break;

			case 'REVERSEPOINT':
				this.trc('Reverse Point');
				// Set direction to opposite of target node's previous node (e.g. if negative set to forward)
				snapForward = targetPreviousNode.speed < 0;

				if (snapForward) {
					targetNodeHeading = (targetNodeHeading + 180) % 360;
				}
				// Place 'behind' target node at distance of nodeDistMin + 0.1
				offsetAlongTarget = offsetAlongHeadingRealWorld(nodeDistMin + 0.1, targetNodeHeading);
				northingOffset = snapForward ? offsetAlongTarget.northing : -offsetAlongTarget.northing;
				eastingOffset = snapForward ? offsetAlongTarget.easting : -offsetAlongTarget.easting;
				snapCoords = Coordinates.realWorldCoordinates(targetNodeEntity.northing + northingOffset,
					targetNodeEntity.easting + eastingOffset);
				break;

			default:
				if (!(targetNodeEntity.task === 'PARKING' || targetNodeEntity.task === 'DUMPINGCRUSHER')) {
					// In theory this code should never be hit
					this.trc('Expected task PARKING or DUMPINGCRUSHER. Ignoring.', 'error');
					return result;
				}
				this.trc('Other -  Parking or Crusher Dumping');
				// Place waypoint 'in front' at nodeDistMin + 0.1
				if (targetPreviousNode.speed < 0) {
					targetNodeHeading = (targetNodeHeading + 180) % 360;
				}

				offsetAlongTarget = offsetAlongHeadingRealWorld(nodeDistMin + 0.1, targetNodeHeading);
				snapCoords = Coordinates.realWorldCoordinates(targetNodeEntity.northing + offsetAlongTarget.northing,
					targetNodeEntity.easting + offsetAlongTarget.easting);

				// Always set direction to forward
				snapForward = true;
				break;
		}

		// Snapped heading always matches that of target node
		// eslint-disable-next-line prefer-const
		snapHeading = targetNodeHeading;

		result.isOppositeDirections = !((endWaypoint.direction === 'forward') === snapForward);
		result.direction = snapForward ? 'forward' : 'reverse';

		if (isEndWaypointSnapped) {
			// If end waypoint is snapped, we cannot modify the direction. Hence, if direction
			// does not match return an error. This can only happen in edit mode.
			if (result.isOppositeDirections) {
				this.trc('Snap failed: start waypoint direction would mismatch end waypoint direction');
				result.errorToastMessage = ERROR_SNAP_OPERATION_NOT_ALLOWED;
				return result;
			}
			if (!!endWaypointSnappedLinkId) {
				const endLink = this.getLookup().getLinkByIdNumber(endWaypointSnappedLinkId);
				const startLink = targetNodeEntity.getLink();
				if (!!startLink && !!endLink) {
					if (ConnectivityToolHandler.isConnectivityPair(startLink, endLink, this.getLookup())) {
						result.errorToastMessage = 'Link can not have connectivity pair';
						return result;
					}
				} else {
					this.trc(`Link entity not found. Cannot check connectivity pair. startLink ${startLink} endLink ${endLink}`, 'error');
				}
			}
		}

		// HITMAT-870/HITMAT-859
		if ((!isDrawMode || hasMidWaypoints) && (snapSegmentDirection !== result.direction)) {
			result.errorToastMessage = ERROR_SNAP_OPERATION_NOT_ALLOWED;
			this.trc(`Snapping not allowed because direction cannot change in edit mode. snapSegmentDirection(${snapSegmentDirection}) != targetWaypointDirection (${snapForward})`);
			return result;
		}

		if (snapForward && endWaypoint.task === 'DUMPINGCRUSHER' && result.isOppositeDirections) {
			// HITMAT-868
			/* When creating or editing a clothoid path,
 			 * If the start waypoint is snapped to a forward link that causes the clothoid path direction to be forced to Forward,
			 * and the Clothoid path end waypoint has a task of Crusher Dumping, then the task should be forced to Hauling
			 */
			this.trc(`Force task of segment endwaypoint from DUMPINGCRUSHER to HAULING (nodeId: ${endWaypoint.nodeId} id: ${endWaypoint.id})`);
			result.isCrusherDumpingToHauling = true;
		}

		result.isSuccess = true;
		result.params = {
			snapCoords: snapCoords,
			snapHeading: snapHeading,
			snapForward: snapForward,
			isSnapToBay: false
		};
		return result;
	}

	/**
	 * If placing startWaypoints, returns all end nodes
	 * If placing endWaypoint, returns all start nodes with hauling task
	 * @param coords
	 * @param radius
	 * @param isPlacingStartWaypoint
	 * @returns
	 */
	// eslint-disable-next-line max-len
	private getNearestEligibleObj(coords: Coordinates.RealWorldCoordinates, radius: number, isPlacingStartWaypoint: boolean, snapSegmentDirection: LinkSegmentDirection, task: nodetask): IObjInVicinity | undefined {
		// Since we're placing the first waypoint, find all end nodes
		let nearest: IObjInVicinity | undefined;

		let nodes: NodeGraphic[] = [];
		const renderer = this.getRenderer();

		// All start/end nodes.
		renderer.getContainer('node').children.forEach(o => {
			const ng = renderer.getObjectById(o.name!) as NodeGraphic;
			if (ng.isStartOrEnd()) {
				nodes.push(ng);
			}
		});

		if (isPlacingStartWaypoint) {
			nodes = nodes.filter(ng => ng.isEnd());
		} else {
			nodes = nodes.filter(ng => ng.isStartWithHaulingTask());
		}

		// using record to avoid dups
		const inVicinity: Record<string, IObjInVicinity> = {};

		nodes.forEach(ng => {
			const entity = ng.getEntity();
			const distance = calcDistanceBetweenCoords(coords.northing, coords.easting,
				entity.northing, entity.easting);
			// Select nodes that are in vicinity of click point
			if (distance <= radius) {
				if (entity.nodeId === undefined) {
					this.trc('nodeId undefined', 'error'); // TODO: investigate. this shouldn't really happen!
				} else {
					// TODO: use actual ID instead?
					inVicinity[entity.nodeId.toString()] = { nodeGraphic: ng, distance: distance };
				}
			}
		});

		let includeBays = false;

		const isParkingNode = task === 'PARKING';
		const isCrusherDumpingNode = task === 'DUMPINGCRUSHER';
		const isForward = snapSegmentDirection === 'forward';

		if (isPlacingStartWaypoint) {
			// AOZ transition area logic
		} else {
			if (isParkingNode) {
				includeBays = true;
			} else if (isCrusherDumpingNode && !isForward) {
				includeBays = true;
			}
		}

		if (includeBays) {
			renderer.getContainer('bay').children.forEach(o => {
				const bay = renderer.getObjectById(o.name!) as Bay;
				const bayEntity = bay.getEntity();
				let isAddEntry = false;
				if (isParkingNode) {
					if ((bayEntity.bayType === 'FUELLING' || bayEntity.bayType === 'PARKING')) {
						if (isForward) {
							if (bayEntity.spotDir === 'DRIVETHROUGH') {
								isAddEntry = true;
							}
						} else {
							// Reverse
							if (bayEntity.spotDir === 'BACKIN') {
								isAddEntry = true;
							}
						}
					}
				} else if (isCrusherDumpingNode) { // reverse only
					if (bayEntity.bayType === 'DUMPCRUSHER' && bayEntity.spotDir === 'BACKIN') {
						// console.log(`Snap Crusher node to bay (Reverse/BackIn/${bayEntity.baytype})`);
						isAddEntry = true;
					}
				}
				// const _bayCenter = renderer.unproject(bay.getBayPoints().center);
				// const isInMapBound = renderer.isPointInMapBounds(renderer.getRealWorldCoords(_bayCenter));
				if (isAddEntry) {
					const location = bay.getBayLocation();
					const distance = calcDistanceBetweenCoords(coords.northing, coords.easting,
						location.northing, location.easting);
					// Select nodes that are in vicinity of click point
					if (distance <= radius) {
						if (bayEntity.bayId === undefined) {
							this.trc('bayid undefined', 'error'); // TODO: investigate. this shouldn't really happen!
						} else {
							console.log(`Bay in vicinity: baytype ${bayEntity.bayType} spotdir ${bayEntity.spotDir} entityId: ${bayEntity.id} northing: ${location.northing}`)
							inVicinity[bayEntity.id.toString()] = { bay: bay, distance: distance };
						}
					}
				}
			});	
		}

		const found = Object.values(inVicinity);

		if (found.length === 0) {
			this.trc('No eligible obj in vincinity. Ignore.');
		} else {
			nearest = found.sort((a, b) => a.distance - b.distance)[0];
			const id = found
			console.log(`Nearest: bayId ${nearest.bay?.getEntity().id} nodeId ${nearest.nodeGraphic?.getEntity().id}`)
		}
		return nearest;
	}
}
