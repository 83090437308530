import React, { useEffect, useState } from 'react';
import {
	convertToFixed, twoDecimalsFixedReadOnly,
} from './PropertiesSidePanel';
import { AreaEntity } from '../../../Models/Entities';
import MapController from '../Map/MapController';
import { areaLocationTypeOptions, areaTypeOptions } from 'Models/Enums';
import If from 'Views/Components/If/If';
import { IAreaNodeLocation } from '../Map/MapStateHandlers/AreaEditHandler';
import { observable, runInAction, autorun } from 'mobx';
import {
	EASTING_LESS_THAN_ZERO_ERROR, EASTING_INSIDE_BOUNDS_ERROR, EASTING_DEFAULT_VALUE,
	NORTHING_DEFAULT_ERROR, NORTHING_INSIDE_BOUNDS_ERROR, NORTHING_LESS_THAN_ZERO_ERROR,
} from 'Constants';
import CollapsibleProperty from '../CollapsibleProperty';
import ErrorsAndWarnings from './ErrorsAndWarnings';
import {RenderInformationCombobox} from "./PropertiesPanelComponents/RenderInformationCombobox";
import InputField from "./PropertiesPanelComponents/InputField";

const AREA_NAME_SIZE = 'Area name needs to be minimum 1 character long';
const AREA_UNIQUE_ERROR = 'Area name needs to be unique';

/**
 * Render properties side panel for a selected area.
 * Area properties panel can be used to view area information
 * @param props
 * - entity: AreaEntity
 * @constructor
 */
export default function AreaProperties({ entity, map } : { entity: AreaEntity, map: MapController }) {
	const _entity = map.getMapLookup().getEntity(entity.id, AreaEntity);
	let area = observable(entity);

	// filtering out area types and locations
	const areaTypes = (() => {
		let areaTypes = ['Autonomous', 'Exclusion', 'Lockout', 'Obstacle', 'Barrier', 'AOZ'];

		// Do not allow 'Autonomous' to be selected when creating a new area
		if (!_entity) {
			areaTypes = areaTypes.slice(1);
		}
		return areaTypes;
	})();
	//const locationTypes = ['Not Applicable', 'Loading', 'Dumping', 'Stockpile', 'Parking', 'Crusher'];  // HITMAT-1397 (Temporarily deactivated Stockpile and Crusher options)
	const locationTypes = ['Not Applicable', 'Loading', 'Dumping', 'Parking'];

	const areaTypeOptionsCombobox = Object
		.entries(areaTypeOptions)
		.map(([value, display]) => ({ display, value })).filter(
			x => {
				return areaTypes.includes(x.display as string);
			},
		);

	const areaLocationTypeOptionsCombobox = Object
		.entries(areaLocationTypeOptions)
		.map(([value, display]) => ({ display, value })).filter(
			x => {
				if (area.areaType === 'AREAAUTONOMOUS') {
					return locationTypes.slice(1).includes(x.display as string);
				}
				return locationTypes.includes(x.display as string);
			},
		);

	const updatePropertiesAndArea = () => {
		map?.getEventHandler().emit('onPropertiesUpdate');
		map?.getEventHandler().emit('requestUpdate');
	};

	const areaTypeValidation = () => {
		if (area.areaType === 'AREAAUTONOMOUS') {
			runInAction(() => {
				area.locType = 'DUMP';
			});
		} else {
			runInAction(() => {
				area.locType = 'INVALID';
			});
		}
	};

	const nameValidation = (value: string): string | undefined => {
		// name validation
		const nameRegex = new RegExp('^.{1,13}$');
		const localValue = value.trim().replace(/ /g, '_');

		// value has not changed
		let areaEntity = map?.getMapLookup()?.getAreaByName(localValue);

		if (!nameRegex.test(localValue)) {
			return AREA_NAME_SIZE;
		}

		if(areaEntity?.id === area.id) {
			return undefined;
		}
		
		if (areaEntity !== undefined) {
			return AREA_UNIQUE_ERROR;
		}

		map?.getMapLookup()?.deleteEntity(area);
		runInAction(() => {
			area.areaName = localValue;
		});
		map?.getMapLookup()?.createEntity(area);

		return undefined;
	};

	const [firstNodePlaced, setFirstNodePlaced] = useState(entity.perimeterCount > 0);

	// ensure that location is read-only before first point is placed
	useEffect(() => {
		const updatePropertyFieldsDisposer = autorun(() => setFirstNodePlaced(entity.perimeterCount > 0));
		return () => updatePropertyFieldsDisposer();
	}, [entity]);

	const isCurrentToolArea = () => map?.getSelectedToolType() === 'area';

	// HITMAT-926 make the area type and location type fields read-only
	// When the area polygon is closed at the end of drawing (map tool type 'area'), this is when the area enter "Edit" mode -> read-only
	// when selected/edit mode in map tool type 'selector' -> read-only
	const getConditions = () => area.isImported|| (isCurrentToolArea() && firstNodePlaced) || !isCurrentToolArea();

	return (
		<>
			<h6>Area Identification</h6>
			<InputField model={area} label="ID" modelProperty="areaId" propertyUnit="" isNumber isReadOnly />
			<InputField
				key={`${area._clientId}_areaName`}
				model={area}
				label="Name"
				modelProperty="areaName"
				propertyUnit=""
				maxLength={13}
				isReadOnly={area.isImported}
				onValidateInput={(value: any) => nameValidation(value)}
				onUpdate={updatePropertiesAndArea}
			/>
			<InputField model={area} label="Version" modelProperty="areaVersion" propertyUnit="" isNumber isReadOnly />
			<InputField model={area} label="Last Editor" modelProperty="areaCreator" propertyUnit="" isReadOnly />
			<InputField model={area} label="No. of Nodes" modelProperty="perimeterCount" propertyUnit="" isReadOnly />
			<div className="section-divider" />
			<CollapsibleProperty propertyTitle="Area Properties" displayProperty>
				<RenderInformationCombobox
					model={area}
					label="Type"
					modelProperty="areaType"
					options={areaTypeOptionsCombobox}
					readonly={getConditions()}
					onAfterChange={areaTypeValidation}
				/>
				<RenderInformationCombobox
					model={area}
					label="Location Type"
					modelProperty="locType"
					options={areaLocationTypeOptionsCombobox}
					readonly={true}
				/>
			</CollapsibleProperty>
			<AreaNodeLocation area={area} map={map} firstNodePlaced={firstNodePlaced} />
			<div className="section-divider" />
			<ErrorsAndWarnings key={area.getModelId()} mapObject={area} mapController={map} />
			<div className="section-divider" />
		</>
	);
}

interface AreaNodeLocationProps {
	area: AreaEntity;
	map: MapController;
	firstNodePlaced: boolean;
}

function AreaNodeLocation(props: AreaNodeLocationProps) {
	const { area, map, firstNodePlaced } = props;

	const initialAreaNodeLocation: IAreaNodeLocation = {
		northing: undefined,
		easting: undefined,
	};

	const elevationPlaceholder = {
		elevation: 0.00,
	};

	const [areaNodeLocation, setAreaNodeLocation] = useState(initialAreaNodeLocation);

	const [isEditMode, setIsEditMode] = useState(false);
	const isLocationReadonly = () => !isEditMode || !firstNodePlaced;

	const propertiesUpdate = () => {
		map?.getEventHandler().emit('onPropertiesUpdate', areaNodeLocation);
	};

	const onValidateEasting = (value?: number): string | undefined => {
		if (value === undefined) {
			return undefined;
		}

		let realWorldCoords;
		if (!!areaNodeLocation.northing && !!value) {
			realWorldCoords = { easting: value, northing: areaNodeLocation.northing};
		}

		if (realWorldCoords) {
			const insideBounds = map?.getEventHandler().getRenderer().isPointInMapBounds(realWorldCoords);
			if (value < 0) {
				return EASTING_LESS_THAN_ZERO_ERROR;
			}
			if (!insideBounds) {
				return EASTING_INSIDE_BOUNDS_ERROR;
			}
		} else {
			return EASTING_DEFAULT_VALUE;
		}

		return undefined;
	};

	const onValidateNorthing = (value?: number): string | undefined => {
		if (value === undefined) {
			return undefined;
		}

		let realWorldCoords;
		if (!!areaNodeLocation.easting && !!value) {
			realWorldCoords = { easting: areaNodeLocation.easting, northing: value };
		}

		if (realWorldCoords) {
			const insideBounds = map?.getEventHandler().getRenderer().isPointInMapBounds(realWorldCoords);
			if (value < 0) {
				return NORTHING_LESS_THAN_ZERO_ERROR;
			}
			if (!insideBounds) {
				return NORTHING_INSIDE_BOUNDS_ERROR;
			}
		} else {
			return NORTHING_DEFAULT_ERROR;
		}

		return undefined;
	};

	const onStateChangeHandler = (currentAreaNodeLocation: IAreaNodeLocation, isEditable?: boolean) => {
		setAreaNodeLocation(currentAreaNodeLocation);
		setIsEditMode(isEditable ?? false);
	};

	// Initialisation and de-initialisation
	useEffect(() => {
		if (map) {
			map.getEventHandler().addListener('onAreaStateChange', onStateChangeHandler);
		}
		return () => {
			if (map) {
				map.getEventHandler().removeListener('onAreaStateChange', onStateChangeHandler);
			}
		};
	}, []);

	return (
		<If condition={areaNodeLocation.northing !== undefined}>
			<div className="section-divider" />
			<CollapsibleProperty propertyTitle="Area Node Location" displayProperty>
				<InputField
					key={areaNodeLocation.easting}
					model={areaNodeLocation}
					label="Easting"
					modelProperty="easting"
					propertyUnit="m"
					isNumber
					isReadOnly={isLocationReadonly()}
					onUpdate={propertiesUpdate}
					renderDisplayValue={value => convertToFixed(value, 2)}
					onValidateInput={value => onValidateEasting(value)}
					errorsObject={area.propertyValidationErrors}
				/>
				<InputField
					key={areaNodeLocation.northing}
					model={areaNodeLocation}
					label="Northing"
					modelProperty="northing"
					propertyUnit="m"
					isNumber
					isReadOnly={isLocationReadonly()}
					onUpdate={propertiesUpdate}
					renderDisplayValue={value => convertToFixed(value, 2)}
					onValidateInput={value => onValidateNorthing(value)}
					errorsObject={area.propertyValidationErrors}
				/>
				<InputField
					model={elevationPlaceholder}
					label="Elevation"
					modelProperty="elevation"
					propertyUnit="m"
					isNumber
					renderDisplayValue={twoDecimalsFixedReadOnly}
					isReadOnly
				/>
			</CollapsibleProperty>
		</If>
	);
}
