/* eslint-disable max-len */
import React, {
	useEffect, useMemo, useState,
} from 'react';
import classNames from 'classnames';
import MapController from '../Map/MapController';
import {
	AreaEntity,
	BayEntity,
	LinkEntity,
	SublinkEntity,
	NodeEntity,
	SegmentEntity,
	BeaconEntity,
} from 'Models/Entities';
import { Button, Colors, Display } from '../../Components/Button/Button';
import { disableMapDomEvents } from '../Map/Helpers/MapDOMEvents';
import { ISearch } from '../../Components/CRUD/EntityCollection';
import SearchForm from '../../Components/SearchForm/SearchForm';
import buildAhsMapObjects, {
	categoriseAreasAndBays, convertAreaItem, convertBayItem, convertLinkItem, convertNodeItem, convertSublinkItem, dummyArea,
} from './AhsMapObjects';
import buildFmsMapObjects from './FmsMapObjects';
import setObjectDisplayAndRerender from './ObjectsDisplay';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { ILayersPanelItem, LayersPanelItem, layersPanelItemId } from './LayersPanelItem';
import { areaLocationTypeOptions, areaTypeOptions } from '../../../Models/Enums';
import { Model } from '../../../Models/Model';
import MapObject, { MapObjectType } from '../Map/MapObjects/MapObject';
import { IMenuShownStatus, initialMenuShownStatus } from '../EditMap';
import { UNDEFINED_BAYS_AREANAME } from 'Constants';
import { setCustomTag } from '../Map/Helpers/MapUtils';
import { store } from 'Models/Store';
import {runInAction} from "mobx";
import AreaEditHandler, {areaMode} from "../Map/MapStateHandlers/AreaEditHandler";
import {MapObjectEntityType} from "../Map/MapStore";

interface ILayersSidePanelProps {
	map: MapController;
}

function getListItemChildren(list: ILayersPanelItem[] | undefined, index: number | number[]): ILayersPanelItem[] | undefined {
	const result = getListItem(list, index);
	if (result === undefined) {
		return result;
	}

	return result.children;
}

function getListItem(list: ILayersPanelItem[] | undefined, index: number | number[]): ILayersPanelItem | undefined {
	if (list === undefined) {
		return undefined;
	}

	const getItem = (currentIndex: number) => {
		if (list.length <= currentIndex) {
			return undefined;
		}

		return list[currentIndex];
	}

	if (typeof index === 'number') {
		return getItem(index);
	} else {
		const currentIndex = index.shift();
		if (currentIndex === undefined) {
			return undefined;
		}

		const item = getItem(currentIndex);
		if (index.length == 0) {
			return item;
		} else {
			return getListItem(item?.children, index);
		}
	}
}

/**
 * Displays all map objects in a treeview via a collapsible
 * sidepanel on the left-hand-side of the mapedit page
 * @param props
 * @returns
 */
export function LayersSidePanel(props: ILayersSidePanelProps) {
	const [displaySidePanel, setDisplaySidePanel] = useState(true);

	useEffect(() => {
		disableMapDomEvents('object-sidebar-panel');
	});

	const toggleSidePanel = () => setDisplaySidePanel(!displaySidePanel);
	const toggleButton = (
		<Button
			className="show-hide-button no-background"
			icon={{ icon: displaySidePanel ? 'chevrons-left' : 'chevrons-right', iconPos: 'icon-bottom' }}
			colors={Colors.White}
			display={Display.Text}
			onClick={toggleSidePanel}
		/>
	);

	const sideBarView = useMemo(() => LayersSidePanelContent(props), [props]);

	return (
		<div
			id="object-sidebar-panel"
			className={classNames('object-sidebar', !displaySidePanel ? 'hide' : undefined)}
		>
			{toggleButton}
			{displaySidePanel ? sideBarView : null}
		</div>
	);
}

/**
 * Organise area entity data and render the sidebar
 */
export function LayersSidePanelContent(props: ILayersSidePanelProps) {
	const { map } = props;

	const fmsMap = buildFmsMapObjects(map.getMapLookup());

	const objects = [
		fmsMap,
		buildAhsMapObjects(map.getMapLookup()),
	];

	if (!store.isInit) {
		store.isInit = true;
		console.log(`LayersSidePanelContent: Setting isInit to true`);
	} 
	// The line below caused a performance hit by rerendering after initial render for the
	// purpose of hiding FMS objects by default. Instead, the initial state of these map objects
	// should be invisible so that the initial render is connect and extra rerender is not needed
	// setObjectDisplayAndRerender(fmsMap, map, false);

	return (
		<div className="side-panel-content">
			<LayersPanel objectItems={objects} map={map}/>
		</div>
	);
}

export interface ISelectedObjectRef {
	entityId: string;
	entityType?: MapObjectType;
	selectedViaLayers?: boolean;
}

/**
 * Actual rendering of the treeview structure
 * with Areas and Paths at the top-level of the
 * AHS map treeview
 */
const LayersPanel = ({ objectItems, map }: { objectItems: ILayersPanelItem[], map: MapController }) => {
	const [search, setSearch] = useState<ISearch>({ searchTerm: '' });
	const [searchState, setSearchState] = useState<{
		filteredObjects: ILayersPanelItem[],
		isSearched: boolean,
	}>({
		filteredObjects: objectItems,
		isSearched: false,
	});

	const [searchLastUpdate, setSearchLastUpdate] = useState(0);
	const [searchTagTimeoutId, setSearchTagTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined);

	let buildHierarchyToExpand: string[] = [];
	/**
	 * The expanding issue(?) exists for long time. Which is the previously expanded items have been added into hierarchyToExpand and won't be removed.
	 * If collapse all expanded items and click on a label (new selected item) just bellowing the previously expanded items,
	 * the collapsed items are expanded and push the new selected item out of the page. See HITMAT-947.
	 */
	const [hierarchyToExpand, setHierarchyToExpand] = useState<string[]>(['AHC Map', 'Areas', 'Paths']);
	const [entityIdForSelectedMapObject, setEntityIdForSelectedMapObject] = useState<ISelectedObjectRef>({ entityId: '' });
	const [inactiveEntityId, setInactiveEntityId] = useState<ISelectedObjectRef>({ entityId: '' });
	// using this state variable for custom styling of the error button on the bottom of the layers panel
	const [errorToggle, setErrorToggle] = useState(false);
	const [errorCount, setErrorCount] = useState(map.getMapLookup().getMapErrorCount());
	const [warningToggle, setWarningToggle] = useState(false);
	const [warningCount, setWarningCount] = useState(map.getMapLookup().getMapWarningCount());

	const [isViewMenuShownInLayersPanel, setIsViewMenuShownInLayersPanel] = useState<IMenuShownStatus>(initialMenuShownStatus);

	const onMapObjectSearch = (
		items: ILayersPanelItem[] | undefined,
		value: string,
		exactMatch: boolean,
		filterErrors: boolean,
		filterWarnings: boolean,
	): ILayersPanelItem[] => {
		if (items === undefined || items.length === 0) {
			return [];
		}

		const hasValue = value.length > 0;

		return items.filter(item => {
			item.filteredChildren = onMapObjectSearch(item.children, value, exactMatch, filterErrors, filterWarnings);

			const childrenMatched = item.filteredChildren && item.filteredChildren.length > 0;

			if (item.skipSearch === true) {
				return childrenMatched;
			}

			const nameMatch = exactMatch
				? item.name.toLowerCase() === value
				: item.name.toLowerCase().includes(value);

			const errorMatch = item.isError === true;
			const warningMatch = item.isWarning === true;

			const errorFilterAndMatch = filterErrors && errorMatch && !hasValue && !filterWarnings;
			const warningFilterAndMatch = filterWarnings && warningMatch && !hasValue && !filterErrors;
			const nameFilterAndMatch = hasValue && nameMatch && !filterErrors && !filterWarnings;
			const errorNameEnabledAndMatch = filterErrors && errorMatch && hasValue && nameMatch && !filterWarnings;
			const warningNameEnabledAndMatch = filterWarnings && warningMatch && hasValue && nameMatch && !filterErrors;
			const errorWarningEnabledAndMatch = (filterErrors && errorMatch || filterWarnings && warningMatch) && !hasValue;
			const allEnabledAndMatch = (filterErrors && errorMatch || filterWarnings && warningMatch) && hasValue && nameMatch;

			return errorFilterAndMatch || warningFilterAndMatch || nameFilterAndMatch
				|| errorNameEnabledAndMatch || warningNameEnabledAndMatch || errorWarningEnabledAndMatch
				|| allEnabledAndMatch || childrenMatched;
		});
	};

	const performSearch = AwesomeDebouncePromise(((searchTerm: string, filterErrors: boolean, filterWarnings: boolean) => {
		if (searchTerm.length === 0 && !filterErrors && !filterWarnings) {
			const unfilterItems = (items: ILayersPanelItem[]) => {
				items.forEach(item => {
					item.filteredChildren = undefined;

					if (!!item.children) {
						unfilterItems(item.children);
					}
				});
			};
			unfilterItems(objectItems);

			setSearchState({
				filteredObjects: objectItems,
				isSearched: false,
			});

			// Reset to the location of the selected object
			setEntityIdForSelectedMapObject({ ...entityIdForSelectedMapObject, selectedViaLayers: false });
		} else if (searchTerm.length > 1 || filterErrors || filterWarnings) {
			const searchValue = searchTerm.replace(/['"]+/g, '');
			const exactMatchSearch = (searchTerm.charAt(0) === '"' && searchTerm.charAt(searchTerm.length - 1) === '"');
			const searchResult = onMapObjectSearch(objectItems, searchValue.toLowerCase(), exactMatchSearch, filterErrors, filterWarnings);
			setSearchState({
				filteredObjects: searchResult,
				isSearched: true,
			});
		}
	}), 100);

	function debounce(fn: Function, delay = 500) {
		let timeoutId: NodeJS.Timeout | undefined;
	
		return (inputValue: string) => {
			if (!!timeoutId) {
				clearTimeout(timeoutId);
			}
			timeoutId = setTimeout(() => {
				fn(inputValue);
			}, delay);
		};
	}

	// Send custom tag if there is no any changes after 500 ms
	const sentSearchCustomTag = debounce((searchTerm: string) => {
		setCustomTag('search-map-objects', searchTerm);
	}, 500);

	const handleSearchChange = (value: string) => {
		setSearch({ searchTerm: value });
		performSearch(value, errorToggle, warningToggle);
		sentSearchCustomTag(value);
	};
	// Add event listeners for the map object operations
	useEffect(() => {
		map.getEventHandler().addListener('onMapObjectCreateConfirm', onCreateConfirm);
		map.getEventHandler().addListener('onMapObjectDrag', onBayDragEnd);
		map.getEventHandler().addListener('onMapObjectDelete', onMapObjectDelete);
		map.getEventHandler().addListener('onMapObjectUpdate', onEntityUpdate);
		map.getEventHandler().addListener('onMapObjectSelectedInMap', handleSelection);
		map.getEventHandler().addListener('onItemSelectedInLayersPanel', handleSetInactiveEntityId);
		map.getEventHandler().addListener('onToggleItemsFromViewMenu', handleToggleItemsFromViewMenu);
		map.getEventHandler().addListener('onErrorCountUpdate', onErrorCountUpdate);
		map.getEventHandler().addListener('onUpdateMapObjectsPanel', onUpdateMapObjectsPanel);

		return () => {
			map.getEventHandler().removeListener('onMapObjectCreateConfirm', onCreateConfirm);
			map.getEventHandler().removeListener('onMapObjectDrag', onBayDragEnd);
			map.getEventHandler().removeListener('onMapObjectDelete', onMapObjectDelete);
			map.getEventHandler().removeListener('onMapObjectUpdate', onEntityUpdate);
			map.getEventHandler().removeListener('onMapObjectSelectedInMap', handleSelection);
			map.getEventHandler().removeListener('onItemSelectedInLayersPanel', handleSetInactiveEntityId);
			map.getEventHandler().removeListener('onToggleItemsFromViewMenu', handleToggleItemsFromViewMenu);
			map.getEventHandler().removeListener('onErrorCountUpdate', onErrorCountUpdate);
			map.getEventHandler().removeListener('onUpdateMapObjectsPanel', onUpdateMapObjectsPanel);
		};
	}, [search, errorToggle, warningToggle]);

	const onUpdateMapObjectsPanel = (entityId?: string, entityType?: MapObjectType) => {
		objectItems[1] = buildAhsMapObjects(map.getMapLookup());

		// Refresh the layers panel to show the newly added map object
		performSearch(search.searchTerm, errorToggle, warningToggle);
		setErrorCount(() => map.getMapLookup().getMapErrorCount());
		setWarningCount(() => map.getMapLookup().getMapWarningCount());

		if (!!entityId && !!entityType) {
			setTimeout(() => {
				setEntityIdForSelectedMapObject({
					entityId,
					entityType,
					selectedViaLayers: false
				});
			}, 100);
		}
	};

	const onErrorCountUpdate = () => {
		setErrorCount(() => map.getMapLookup().getMapErrorCount());
		setWarningCount(() => map.getMapLookup().getMapWarningCount());
	};

	const handleToggleItemsFromViewMenu = (type: MapObjectType, isViewMenuShown: IMenuShownStatus, checkItems: boolean) => {
		
		if (checkItems) {
			// Rerender only once (after all objects have been marked for rerender)
			const isRerender = false;
			const toggleFromViewMenu = true;
			objectItems[1].children?.[1].children?.map(link => {
				setObjectDisplayAndRerender(link, map, link.isDisplayed ?? true, isRerender,
					isViewMenuShownInLayersPanel, toggleFromViewMenu, type);
			});
			if (searchState.isSearched) {
				searchState.filteredObjects[0].children?.[1].children?.map(link => {
					setObjectDisplayAndRerender(link, map, link.isDisplayed ?? true, isRerender,
						isViewMenuShownInLayersPanel, toggleFromViewMenu, type);
				});
			}

			map.getMapRenderer().rerender();
		}

		// TODO: refactor. No need the local isViewMenuShownInLayersPanel if using the global isViewMenuShown in MapStore
		setIsViewMenuShownInLayersPanel(isViewMenuShown);
	};

	/**
	 * Sets the entity id to make inactive
	 * Handles the expansion of the panel to selected item
	 * if the valid map-type is provided
	 * @param entityId
	 * @param entityMapType
	 */
	const handleSetInactiveEntityId = (entityId?: string, entityMapType?: string) => {
		if (!!entityId && !!entityMapType) {
			setEntityIdForSelectedMapObject(() => ({
				entityId: entityId,
				entityType: entityMapType as MapObjectType,
				selectedViaLayers: true,
			}));
			setInactiveEntityId(() => ({
				entityId: '',
			}));

			let entity: Model | undefined;
			// retaining the state of the layers panel after clearing the search box input
			switch (entityMapType) {
				case 'link':
					entity = map?.getMapLookup().getEntity(entityId, LinkEntity);
					break;

				case 'sublink':
					entity = map?.getMapLookup().getEntity(entityId, SublinkEntity);
					break;

				case 'area':
				case 'location':
					entity = map?.getMapLookup().getEntity(entityId, AreaEntity);
					const areaEditHandler : AreaEditHandler | any = map.getEventHandler().getStateHandler(); 
					runInAction(() => {
						areaEditHandler.isConfirmable = false;
					});
					if (areaEditHandler.areaMapObject === undefined) {
						const areaMapObjectId = map.getMapLookup().getMapObjectId(entityId, 'area');
						areaEditHandler.areaMapObject = map.getMapRenderer().getObjectById(areaMapObjectId);
					}
					areaEditHandler.areaMapObject.disableEditMode();
					areaEditHandler.areaMapObject.setPointsFromEntity();
					if (areaEditHandler instanceof AreaEditHandler) {
						areaEditHandler.updateArea();
					}
					areaEditHandler.mode = areaMode.SELECTED;
					break;

				case 'bay':
					entity = map?.getMapLookup().getEntity(entityId, BayEntity);
					break;

				case 'node':
					entity = map?.getMapLookup().getEntity(entityId, NodeEntity);
					break;

				case 'segment':
					entity = map?.getMapLookup().getEntity(entityId, SegmentEntity);
					break;

				case 'beacon':
					entity = map?.getMapLookup().getEntity(entityId, BeaconEntity);
					break;
			}

			if (!!entity) {
				handleSelection(entity, entityMapType as MapObjectType, true);
			}

			return;
		}
		setInactiveEntityId(() => entityIdForSelectedMapObject);
	};

	/**
	 * Builds and sets the hierarchy to expand in the layers panel
	 * till the selected bay map object.
	 * Also sets the bay's entity id to highlight.
	 * @param bayEntity
	 */
	const handleBayMapSelection = (bayEntity: BayEntity, selectedViaLayers?: boolean) => {
		setEntityIdForSelectedMapObject({
			entityId: bayEntity.getModelId(),
			entityType: 'bay',
			selectedViaLayers: selectedViaLayers,
		});
		buildHierarchyToExpand.push(objectItems[1].name);

		if (bayEntity.areaId) {
			const areaForSelectedBay = map.getMapLookup().getEntity(bayEntity.areaId, AreaEntity);
			if (!!areaForSelectedBay) {
				buildHierarchyToExpand.push(areaTypeOptions[areaForSelectedBay.areaType]);
				if (areaForSelectedBay.areaType === "AREAAUTONOMOUS") {
					buildHierarchyToExpand.push(areaLocationTypeOptions[areaForSelectedBay.locType]);
				}
			}

			const areaName = (!!areaForSelectedBay && !areaForSelectedBay.isImported ? '[NEW] ' : '') + bayEntity.areaName;

			buildHierarchyToExpand.push(`area_${areaName}`);
		} else { // Handle Undefined bay
			buildHierarchyToExpand.push(areaTypeOptions["AREAAUTONOMOUS"]);
			buildHierarchyToExpand.push(`area_${UNDEFINED_BAYS_AREANAME}`);
		}
		setHierarchyToExpand(prev => [...prev, ...buildHierarchyToExpand]);
	};

	/**
	 * Builds and sets the hierarchy to expand in the layers panel
	 * till the selected area map object.
	 * Also sets the area's entity id to highlight.
	 * @param areaEntity
	 */
	const handleAreaMapSelection = (areaEntity: AreaEntity, selectedViaLayers?: boolean) => {
		setEntityIdForSelectedMapObject({
			entityId: areaEntity.getModelId(),
			entityType: 'area',
			selectedViaLayers: selectedViaLayers,
		});
		buildHierarchyToExpand.push(objectItems[1].name); // 'AHS Map'
		buildHierarchyToExpand.push(objectItems[1].children![0].name); // 'Areas'

		buildHierarchyToExpand.push(areaTypeOptions[areaEntity.areaType]);
		if (areaEntity.areaType === "AREAAUTONOMOUS") {
			buildHierarchyToExpand.push(areaLocationTypeOptions[areaEntity.locType]);
		}

		setHierarchyToExpand(prev => [...prev, ...buildHierarchyToExpand]);
	};

	/**
	 * Builds and sets the hierarchy to expand in the layers panel
	 * till the selected sublink map object.`
	 * Also sets the sublink's entity id to highlight.
	 * @param sublinkEntity
	 */
	const handleSublinkMapSelection = (sublinkEntity: SublinkEntity, selectedViaLayers?: boolean) => {
		setEntityIdForSelectedMapObject({
			entityId: sublinkEntity.getModelId(),
			entityType: 'sublink',
			selectedViaLayers: selectedViaLayers,
		});
		buildHierarchyToExpand.push(objectItems[1].name); // 'AHS Map'
		buildHierarchyToExpand.push(objectItems[1].children![1].name); // 'Paths'

		const parentLink = objectItems[1]?.children?.[1].children?.find(link => link.entityId === sublinkEntity.linkId);
		if (parentLink && parentLink.entityId) {
			buildHierarchyToExpand.push(layersPanelItemId(parentLink));
			setHierarchyToExpand(prev => [...prev, ...buildHierarchyToExpand]);
		}
	};

	/**
	 * Builds and sets the hierarchy to expand in the layers panel
	 * till the selected node map object.
	 * Also sets the node's entity id to highlight.
	 * @param nodeEntity
	 */
	const handleNodeMapSelection = (nodeEntity: NodeEntity, selectedViaLayers?: boolean) => {
		setEntityIdForSelectedMapObject({
			entityId: nodeEntity.getModelId(),
			entityType: 'node',
			selectedViaLayers: selectedViaLayers,
		});
		buildHierarchyToExpand.push(objectItems[1].name); // 'AHS Map'
		buildHierarchyToExpand.push(objectItems[1].children![1].name); // 'Paths'

		const linkForSelectedNode = map.getMapLookup().getLinkByIdNumber(nodeEntity.linkIdNumber);
		const parentLink = objectItems[1]?.children?.[1]
			.children?.find(link => link.entityId === linkForSelectedNode?.id);
		if (!parentLink || !parentLink.entityId) {
			setHierarchyToExpand(buildHierarchyToExpand);
			return;
		}

		buildHierarchyToExpand.push(layersPanelItemId(parentLink));

		/**
		 * Start and end nodes of the newly created link does not have sublink data.
		 * Hence adding the below logic to get the required sublink id to get the 'parentSublink'.
		 */
		let sublinkIdForSelectedNode: string;
		if (!nodeEntity.sublink) {
			const _sublink = linkForSelectedNode?.sublinkss.find(x => x.nodess.includes(nodeEntity));
			sublinkIdForSelectedNode = _sublink ? _sublink.getModelId() : '';
		} else {
			sublinkIdForSelectedNode = nodeEntity.sublink.getModelId();
		}

		const parentSublink = parentLink.children?.find(sublink => sublink.entityId === sublinkIdForSelectedNode);

		if (parentSublink && parentSublink.entityId) {
			buildHierarchyToExpand.push(layersPanelItemId(parentSublink));
		}

		setHierarchyToExpand(prev => [...prev, ...buildHierarchyToExpand]);
	};

	const handleFmsMapObjectSelection = (entity: Model, mapObjectType?: MapObjectType, selectedViaLayers?: boolean) => {
		setEntityIdForSelectedMapObject({
			entityId: entity.getModelId(),
			entityType: mapObjectType,
			selectedViaLayers: selectedViaLayers,
		});

		const findInChildren = (entityId: string, items: ILayersPanelItem[] | undefined): boolean => {
			if (!items) {
				return false;
			}
			for (const item of items) {
				if (item.entityId === entityId) {
					buildHierarchyToExpand.unshift(layersPanelItemId(item));
					return true;
				}

				if (findInChildren(entityId, item.children)) {
					buildHierarchyToExpand.unshift(layersPanelItemId(item));
					return true;
				}
			}

			return false;
		};

		// Find the object
		findInChildren(entity.getModelId(), objectItems[0].children);
		buildHierarchyToExpand.unshift(objectItems[0].name);
		setHierarchyToExpand(prev => [...prev, ...buildHierarchyToExpand]);
	};

	/**
	 * Invokes the appropriate handle for expansion in the layers panel based on the type of entity.
	 * @param entity
	 * @param mapObject
	 */
	const handleSelection = (entity: Model | undefined, mapObjectType?: MapObjectType, selectedViaLayers?: boolean) => {
		if (!entity) {
			setEntityIdForSelectedMapObject({ entityId: '' });
			return;
		}

		if (!entity.getModelDisplayName || (typeof entity.getModelDisplayName !== 'function')) {
			// TODO: check if this is still a bug
			// sometimes the type info has been removed from the entity
			console.log('handleSelection: invalid entity');
			return;
		}

		if (entityIdForSelectedMapObject === inactiveEntityId) {
			setInactiveEntityId(() => ({ entityId: '' }));
		} else { // @ts-ignore
			// eslint-disable-next-line no-lonely-if
			if (entityIdForSelectedMapObject === entity.getModelId()) {
				return;
			}
		}

		buildHierarchyToExpand = [];

		switch (entity.getModelDisplayName()) {
			case 'Bay':
				handleBayMapSelection(entity as BayEntity, selectedViaLayers);
				break;
			case 'Area':
				if (mapObjectType !== 'location') {
					handleAreaMapSelection(entity as AreaEntity, selectedViaLayers);
				} else {
					handleFmsMapObjectSelection(entity, mapObjectType, selectedViaLayers);
				}
				break;
			case 'Link':
				setEntityIdForSelectedMapObject(() => ({ 
					entityId: (entity as LinkEntity).getModelId(),
					entityType: mapObjectType,
					selectedViaLayers: selectedViaLayers,
				}));
				break;
			case 'Sublink':
				handleSublinkMapSelection(entity as SublinkEntity, selectedViaLayers);
				break;
			case 'Node':
				handleNodeMapSelection(entity as NodeEntity, selectedViaLayers);
				break;
			case 'Beacon':
			case 'Segment':
			case 'Location':
				handleFmsMapObjectSelection(entity, mapObjectType, selectedViaLayers);
				break;
			default:
				buildHierarchyToExpand = [];
				setEntityIdForSelectedMapObject({ entityId: '' });
				break;
		}
	};

	/**
	 * Sorts and prioritizes new map objects in the given array
	 * @param entity - ILayersPanelItem
	 * @param sortById - In case of links
	 */
	const sortMapObjectsByName = (entity: ILayersPanelItem, sortById?: boolean) => {
		// Separate the newly added bays and existing ones
		const allNewMapObjects: ILayersPanelItem[] = [];
		const allExistingMapObjects: ILayersPanelItem[] = [];
		entity.children?.forEach(newMapObject => {
			if (newMapObject.isNew) {
				allNewMapObjects.push(newMapObject);
			} else {
				allExistingMapObjects.push(newMapObject);
			}
		});
		// Sort the newly added bays
		allNewMapObjects.sort((MapObject1, MapObject2) => {
			if (!sortById) {
				if (MapObject1.name < MapObject2.name) {
					return -1;
				}
				if (MapObject1.name > MapObject2.name) {
					return 1;
				}
			} else {
				const id1 = Number.parseInt(MapObject1.name.split('_')[1], 10);
				const id2 = Number.parseInt(MapObject2.name.split('_')[1], 10);
				if (id1 < id2) {
					return -1;
				}
				if (id1 > id2) {
					return 1;
				}
			}
			return 0;
		});
		entity.children = allNewMapObjects.concat(allExistingMapObjects);
	};

	/**
	 * Check the triangle icon colour of AHS Map, Areas, and Autonomous subtype labels in the layers panel.
	 * @param checkAutonomous
	 */
	const checkAreasTriangleIcon = (checkAutonomous: boolean = false) => {
		if (checkAutonomous) {
			// Check the Autonomous triangle icon colour
			const autonomousArea = getListItem(objectItems, [1, 0]);

			if (!!autonomousArea) {
				autonomousArea.isError = autonomousArea.children?.some(area => area.isError);
				autonomousArea.isWarning = autonomousArea.children?.some(area => area.isWarning);
			}
		}

		// Check Areas triangle icon colour
		const area = getListItem(objectItems, [1, 0]);
		if (!!area) {
			area.isError = area.children?.some(areaType => areaType.isError);
			area.isWarning = area.children?.some(areaType => areaType.isWarning);
		}

		// Check AHS Map triangle icon colour
		objectItems[1].isError = objectItems[1].children?.some(areasPaths => areasPaths.isError);
		objectItems[1].isWarning = objectItems[1].children?.some(areasPaths => areasPaths.isWarning);
	};

	/**
	 * Check the triangle icon colour of AHS Map and Paths labels in the layers panel.
	 */
	const checkPathsTriangleIcon = () => {
		// Check Paths triangle icon colour
		objectItems[1].children![1].isError = objectItems[1].children![1]
											.children?.some(link => link.isError);
		objectItems[1].children![1].isWarning = objectItems[1].children![1]
											.children?.some(link => link.isWarning);
		// Check AHS Map triangle icon colour
		objectItems[1].isError = objectItems[1].children?.some(areasPaths => areasPaths.isError);
		objectItems[1].isWarning = objectItems[1].children?.some(areasPaths => areasPaths.isWarning);
	};

	/**
	 * Add newly created bay to the layers panel
	 * @param bayEntity
	 */
	const addBayEntity = (bayEntity: BayEntity) => {
		const convertedBay = convertBayItem(bayEntity);

		const bayEntry = getListItemChildren(objectItems, [1, 0, 1]);

		bayEntry?.forEach(areaSubtype => {
				if (bayEntity.areaId) {
					const areaOfBay = map.getMapLookup().getEntity(bayEntity.areaId, AreaEntity);
					if (areaSubtype.name === areaLocationTypeOptions[areaOfBay.locType]) {
						areaSubtype.children?.forEach(area => {
							if (area.entityId === bayEntity.areaId
								&& !area.children?.some(item => item.entityId === bayEntity.getModelId())) {
								// Push the new bay
								area.children?.push(convertedBay);
								// Sort and prioritize new bays in the list
								sortMapObjectsByName(area);

								// Check the the area triangle icon colour
								const convertedArea: ILayersPanelItem = convertAreaItem(areaOfBay, map.getMapLookup());
								area.isError = convertedArea.isError;
								area.isWarning = convertedArea.isWarning;
								// Check the the areaSubtype triangle icon colour
								areaSubtype.isError = areaSubtype.children?.some(area => area.isError);
								areaSubtype.isWarning = areaSubtype.children?.some(area => area.isWarning);
								checkAreasTriangleIcon(true);
							}
						});
					}
				}
			});
	};
	/**
	 * Add newly created link and its associated
	 * entities to the layers panel
	 * @param linkEntity
	 */
	const addLinkEntity = (linkEntity: LinkEntity) => {
		const convertedLink = convertLinkItem(linkEntity);

		if (convertedLink.isNew) {
			objectItems[1]?.children?.[1]
				.children?.push(convertedLink);
		} else {
			const insertedId = Number.parseInt(convertedLink.name.split('_')[1], 10);
			const index = objectItems[1]?.children?.[1].children?.findIndex(link => {
				const id = Number.parseInt(link.name.split('_')[1], 10);
				return id > insertedId;
			});

			if (!!index) {
				if (index >= 0) {
					// Insert new convertedLink in the correct place to make links in order
					objectItems[1]?.children?.[1].children?.splice(index, 0, convertedLink);
				} else {
					objectItems[1]?.children?.[1].children?.push(convertedLink);
				}
			}
		}
		
		/**
		 * Sort the whole array to show new items
		 * at the top of the list and sorted by it's name
		 */
		if (objectItems[1]?.children?.[1]) {
			sortMapObjectsByName(objectItems[1]?.children?.[1], true);
		}
	};

	/**
	 * Insert newly created sublink and update affected sublink
	 * Used for break sublinks
	 * @param sublinkEntity newly created sublink
	 */
	const insertSublinkEntity = (sublinkEntity: SublinkEntity) => {
		const convertedSublink = convertSublinkItem(sublinkEntity);
		let sublinkIndex = -1;
		let linkIndex = -1;

		// Add new sublink
		objectItems[1]?.children?.[1]
			.children?.forEach((item, _linkIndex) => {
				if (item.entityId === sublinkEntity.linkId) {
					linkIndex = _linkIndex;
					item.children?.push(convertedSublink);
					sublinkIndex = item.children?.findIndex(item1 => item1.entityId === sublinkEntity.previousSublinkId) ?? -1;
					sortMapObjectsByName(item);
				}
			});

		// Update previous sublink
		if (sublinkIndex !== -1 && linkIndex !== -1) {
			const updatedNodes = objectItems[1]?.children?.[1]
				.children?.[linkIndex].children?.[sublinkIndex].children?.filter(nodeItem => !sublinkEntity.nodess.some(n => (n.id === nodeItem.entityId || n.getModelId() === nodeItem.entityId)));
			if (!!updatedNodes) {
				objectItems[1]!.children![1].children![linkIndex].children![sublinkIndex].children = updatedNodes;
			}
		}

		// TODO: is sorting necessary?
	};

	/**
	 * Add newly created area to the layers panel
	 * @param areaEntity
	 */
	const addAreaEntity = (areaEntity: AreaEntity) => {
		// Check if areaType and areaSubtype have been in the layers panel
		let areaTypeIsExisting = false;
		let areaSubtypeIsExisting = false;

		const areaEntry = getListItemChildren(objectItems, [1, 0]);
		areaEntry?.forEach(areaType => {
				if (areaType.name.includes(areaTypeOptions[areaEntity.areaType])) {
					// Autonomous subtype
					if (areaEntity.areaType === "AREAAUTONOMOUS") {
						areaType.children?.forEach(areaSubtype => {
							if (areaSubtype.name.includes(areaLocationTypeOptions[areaEntity.locType])) {
								areaSubtypeIsExisting = true;
							}
						});
					// The other subtypes
					} else {
						areaSubtypeIsExisting = true;
					}
					areaTypeIsExisting = true;
				}
			});
		
		// Rebuild layers panel if doesn't exist
		if (!areaTypeIsExisting || !areaSubtypeIsExisting) {
			const allAreas = map.getMapLookup().getAllEntities(AreaEntity);
			const allBays = map.getMapLookup().getAllEntities(BayEntity);
			objectItems[1]!.children![0].children = categoriseAreasAndBays(allAreas, allBays, map.getMapLookup());
			objectItems[1]?.children?.[0]
				.children?.forEach(areaType => {
					sortMapObjectsByName(areaType);
				});
		} else {
			const convertedArea = convertAreaItem(areaEntity, map.getMapLookup());

			objectItems[1]?.children?.[0]
				.children?.forEach(areaType => {
					if (areaType.name === areaTypeOptions[areaEntity.areaType]) {
						// Autonomous subtype
						if (areaEntity.areaType === "AREAAUTONOMOUS") {
							areaType.children?.forEach(areaSubtype => {
								if (areaSubtype.name === areaLocationTypeOptions[areaEntity.locType]) {
									areaSubtype.children?.push(convertedArea);
									// Sort and prioritize new areas in the list
									sortMapObjectsByName(areaSubtype);
								}
							});
						// The other subtypes
						} else {
							areaType.children?.push(convertedArea);
							// Sort and prioritize new areas in the list
							sortMapObjectsByName(areaType);
						}
					}
				});
		}
	};
	/**
	 * Perform create map object in the layers panel
	 * @param entity
	 */
	const onCreateConfirm = (entity: unknown) => {

		// TODO: this debug code can be removed when layers issues are fixed
		// Start debug code
		if (!entity) {
			console.log(`onCreateConfirm: UNDEFINED ENTITY`);
			return;
		}

		// @ts-ignore
		if (!entity.getModelDisplayName) {
			console.log(`onCreateConfirm: NOT AN ENTITY. Actual object`);
			console.log(entity);
			return;
		}
		// End debug code

		if (getListItemChildren(objectItems, 1)?.length === 0) {
			objectItems[1] = buildAhsMapObjects(map.getMapLookup());
			return;
		}

		objectItems[1] = buildAhsMapObjects(map.getMapLookup());

		// @ts-ignore
		// switch (entity.getModelDisplayName()) {
		// 	case 'Bay':
		// 		addBayEntity(entity as BayEntity);
		// 		break;
		// 	case 'Area':
		// 		addAreaEntity(entity as AreaEntity);
		// 		break;
		// 	case 'Link':
		// 		addLinkEntity(entity as LinkEntity);
		// 		break;
		// 	case 'Sublink':
		// 		insertSublinkEntity(entity as SublinkEntity);
		// 		break;
		// }
		// Refresh the layers panel to show the newly added map object
		performSearch(search.searchTerm, errorToggle, warningToggle);
		setErrorCount(() => map.getMapLookup().getMapErrorCount());
		setWarningCount(() => map.getMapLookup().getMapWarningCount());
	};
	/**
	 * onBayDragEnd event listener
	 * @param mapObjects
	 */
	const onBayDragEnd = (mapObjects: {bay: unknown, area: unknown}) => {
		const bayEntity = mapObjects.bay as BayEntity;
		const areaEntity = mapObjects.area as AreaEntity;
		// Remove it from the old area
		if (areaEntity) {
			objectItems[1]?.children?.[0]
				.children?.[1]
				.children?.forEach(x => {
					if (x.entityId === areaEntity?.id) {
						x.children = x.children?.filter(item => {
							return !item.isNew || item.entityId !== bayEntity.getModelId();
						});
					}
				});
		}
		performSearch(search.searchTerm, errorToggle, warningToggle);
	};
	/**
	 * onBayDragEnd event listener
	 * @param entity
	 * @param entityId
	 */
	const onEntityUpdate = (entity: unknown, entityId?: string) => {
		// @ts-ignore
		switch (entity.getModelDisplayName()) {
			case 'Bay':
				updateBayEntity(entity as BayEntity);
				break;
			case 'Area':
				updateAreaEntity(entity as AreaEntity, entityId);
				break;
			case 'Link':
				updateLinkEntity(entity as LinkEntity);
				break;
			case 'Sublink':
				updateSubinkEntity(entity as SublinkEntity);
				break;
			case 'Node':
				updateNodeEntity(entity as NodeEntity);
				break;
		}
		// Refresh the layers panel to show the newly added map object
		performSearch(search.searchTerm, errorToggle, warningToggle);
		setErrorCount(() => map.getMapLookup().getMapErrorCount());
		setWarningCount(() => map.getMapLookup().getMapWarningCount());
	};

	const updateLayersPanelItem = (oldItem: ILayersPanelItem, convertedItem: ILayersPanelItem, hasAndUpdateChildren: boolean = false) => {
		oldItem.name = convertedItem.name;
		oldItem.entityId = convertedItem.entityId;
		oldItem.labelStyling = convertedItem.labelStyling;
		oldItem.isError = convertedItem.isError;
		oldItem.isWarning = convertedItem.isWarning;
		if (hasAndUpdateChildren) {
			oldItem.children = convertedItem.children;
		}
	}

	/**
	 * Update the bay entity
	 * @param bayEntity
	 */
	const updateBayEntity = (bayEntity: BayEntity) => {
		// If bay is undefined
		if (!bayEntity.areaId) {
			const areaEntry = getListItemChildren(objectItems, [1, 0, 1]);
			// This is used for updating triangle icon colour of undefined bays
			areaEntry?.forEach(area => {
					if (area.entityId === 'unknown') {
						const isExisted = area.children?.some(bay => bay.entityId === bayEntity.id);
						if (!isExisted) {
							// This should not happen.
							console.log("Bays without areaId are not in the Undefined Bays.");
						} else {
							area.children?.forEach(bay => {
								if (bay.entityId === bayEntity.id) {
									const convertedBay: ILayersPanelItem = convertBayItem(bayEntity);
									updateLayersPanelItem(bay, convertedBay);
								}
							});
						}

						// Imported undefined bays are always warning.
						area.isWarning = area.children?.some(bay => bay.isWarning);							
						objectItems[1].children![0].children![1].isWarning = objectItems[1].children![0]
															.children![1].children?.some(area => area.isWarning);
						objectItems[1].children![0].isWarning = objectItems[1].children![0]
															.children?.some(areaType => areaType.isWarning);
						objectItems[1].isWarning = objectItems[1].children?.some(areasPaths => areasPaths.isWarning);
					}
				});
		} else {
			const areaEntry = getListItemChildren(objectItems, [1, 0, 1]);
			areaEntry?.forEach(areaSubtype => {
					if (bayEntity.areaId) {
						const areaOfBay = map.getMapLookup().getEntity(bayEntity.areaId, AreaEntity);
						if (areaSubtype.name === areaLocationTypeOptions[areaOfBay.locType]) {
							areaSubtype.children?.forEach(area => {
								if (area.entityId === bayEntity.areaId) {
									area.children?.forEach(bay => {
										if (bay.entityId === bayEntity.getModelId()) {
											const convertedBay: ILayersPanelItem = convertBayItem(bayEntity);
											updateLayersPanelItem(bay, convertedBay);

											// Check the the area triangle icon colour
											const convertedArea: ILayersPanelItem = convertAreaItem(areaOfBay, map.getMapLookup());
											area.isError = convertedArea.isError;
											area.isWarning = convertedArea.isWarning;
											// Check the the areaSubtype triangle icon colour
											areaSubtype.isError = areaSubtype.children?.some(area => area.isError);
											areaSubtype.isWarning = areaSubtype.children?.some(area => area.isWarning);
											checkAreasTriangleIcon(true);
										}
									});
								}
							});	
						}
					}
				});
		}
	};

	/**
	 * Update the area entity
	 * @param areaEntity
	 * @param entityId
	 */
	const updateAreaEntity = (areaEntity: AreaEntity, entityId?: string) => {
		const areaEntry = getListItemChildren(objectItems, [1, 0]);

		areaEntry?.forEach(areaType => {
				if (areaType.name === areaTypeOptions[areaEntity.areaType]) {
					// Autonomous subtype
					if (areaEntity.areaType === "AREAAUTONOMOUS") {
						areaType.children?.forEach(areaSubtype => {
							if (areaSubtype.name === areaLocationTypeOptions[areaEntity.locType]) {
								areaSubtype.children?.forEach(area => {
									if (area.entityId === (entityId ?? areaEntity.id)) {
										const convertedArea: ILayersPanelItem = convertAreaItem(areaEntity, map.getMapLookup());
										updateLayersPanelItem(area, convertedArea);

										// Check the the areaSubtype triangle icon colour
										areaSubtype.isError = areaSubtype.children?.some(area => area.isError);
										areaSubtype.isWarning = areaSubtype.children?.some(area => area.isWarning);
										// Check the the areaType triangle icon colour
										areaType.isError = areaType.children?.some(areaSubtype => areaSubtype.isError);
										areaType.isWarning = areaType.children?.some(areaSubtype => areaSubtype.isWarning);
										checkAreasTriangleIcon();
									}
								});
							}
						});
					// The other subtypes
					} else {
						areaType.children?.forEach(area => {
							if (area.entityId === (entityId ?? areaEntity.id)) {
								const convertedArea: ILayersPanelItem = convertAreaItem(areaEntity, map.getMapLookup());
								updateLayersPanelItem(area, convertedArea);

								// Check the the areaType triangle icon colour
								areaType.isError = areaType.children?.some(area => area.isError);
								areaType.isWarning = areaType.children?.some(area => area.isWarning);
								checkAreasTriangleIcon();
							}
						});
					}
				}
			});
	};

	/**
	 * Update the link entity
	 * @param linkEntity
	 */
	const updateLinkEntity = (linkEntity: LinkEntity) => {
		const regxString = /\[EDIT\] |\[NEW\] /;

		const linkEntry = getListItemChildren(objectItems, [1, 1]);
		linkEntry?.forEach(link => {
					// Using name to get the link because ids are different after editing the link
					if (link.name.replace(regxString, "") === `Link_${linkEntity.getDisplayName()}`) {
						const convertedLinkEntityItem: ILayersPanelItem = convertLinkItem(linkEntity);
						updateLayersPanelItem(link, convertedLinkEntityItem, true);
					}
				});
	};

	const updateSubinkEntity = (sublinkEntity: SublinkEntity) => {
		const linkEntry = getListItemChildren(objectItems, [1, 1]);

		linkEntry?.forEach(link => {
				if (sublinkEntity.linkId === link.entityId) {
					link.children?.forEach(sublink => {
						if (sublink.entityId === sublinkEntity.getModelId()) {
							const convertedSublink: ILayersPanelItem = convertSublinkItem(sublinkEntity);
							updateLayersPanelItem(sublink, convertedSublink, true);

							// Check the link triangle icon colour
							const convertedLink: ILayersPanelItem = convertLinkItem(sublinkEntity.getLink()!);							
							link.isError = convertedLink.isError;
							link.isWarning = convertedLink.isWarning;
							checkPathsTriangleIcon();
						}
					});
				}
			});
	};

	const updateNodeEntity = (nodeEntity: NodeEntity) => {
		const sublinkEntity = nodeEntity.getSublink();
		const linkEntry = getListItemChildren(objectItems, [1, 1]);

		linkEntry?.forEach(link => {
				if (sublinkEntity!.linkId === link.entityId) {
					link.children?.forEach(sublink => {
						if (sublinkEntity!.getModelId() === sublink.entityId) {
							sublink.children?.forEach(node => {
								if (node.entityId === nodeEntity.getModelId()) {
									const convertedNode: ILayersPanelItem = convertNodeItem(nodeEntity);
									updateLayersPanelItem(node, convertedNode);
									
									// Check the sublink triangle icon colour
									const convertedSublink: ILayersPanelItem = convertSublinkItem(sublinkEntity!);
									sublink.isError = convertedSublink.isError;
									sublink.isWarning = convertedSublink.isWarning;
									// Check the link triangle icon colour
									const convertedLink: ILayersPanelItem = convertLinkItem(sublinkEntity!.getLink()!);	
									link.isError = convertedLink.isError;
									link.isWarning = convertedLink.isWarning;
									checkPathsTriangleIcon();
								}
							});
						}
					});
				}
			});
	};

	/**
	 * Delete the bay from the layers panel
	 * @param bayEntity
	 */
	const deleteBayEntity = (bayEntity: BayEntity) => {
		const areaEntry = getListItemChildren(objectItems, [1, 0, 1]);

		areaEntry?.forEach(areaSubtype => {
				if (bayEntity.areaId) {
					const areaOfBay = map.getMapLookup().getEntity(bayEntity.areaId, AreaEntity);
					if (areaSubtype.name === areaLocationTypeOptions[areaOfBay.locType]) {
						areaSubtype.children?.forEach(area => {
							if (area.entityId === bayEntity.areaId) {
								area.children = area.children?.filter(bay => {
									return !bay.isNew || bay.entityId !== bayEntity.getModelId();
								});

								// Check the the area triangle icon colour
								const convertedArea: ILayersPanelItem = convertAreaItem(areaOfBay, map.getMapLookup());
								area.isError = convertedArea.isError;
								area.isWarning = convertedArea.isWarning;
								// Check the the areaSubtype triangle icon colour
								areaSubtype.isError = areaSubtype.children?.some(area => area.isError);
								areaSubtype.isWarning = areaSubtype.children?.some(area => area.isWarning);
							}
						});
					}
				}
			});

		checkAreasTriangleIcon(true);
	};

	/**
	 * Delete the area from the layers panel
	 * @param areaEntity
	 */
	const deleteAreaEntity = (areaEntity: AreaEntity) => {
		const areaEntry = getListItemChildren(objectItems, [1, 0]);

		areaEntry?.forEach(areaType => {
				if (areaType.name === areaTypeOptions[areaEntity.areaType]) {
					// Autonomous subtype
					if (areaEntity.areaType === "AREAAUTONOMOUS") {
						areaType.children?.forEach(areaSubtype => {
							if (areaSubtype.name === areaLocationTypeOptions[areaEntity.locType]) {
								areaSubtype.children = areaSubtype.children?.filter(area => {
									return !area.isNew || !area.name.includes(areaEntity.areaName);
								});
							}

							// Check the areaSubtype triangle icon colour
							areaSubtype.isError = areaSubtype.children?.some(area => area.isError);
							areaSubtype.isWarning = areaSubtype.children?.some(area => area.isWarning);
						});
					// The other subtypes
					} else {
						areaType.children = areaType.children?.filter(area => {
							if (areaEntity.areaType === 'AREAAHS' && area.name.includes(areaEntity.areaName)) {
								return false;
							}
							return !area.isNew || !area.name.includes(areaEntity.areaName);
						});
					}
				}

				// Check the areaType triangle icon colour
				areaType.isError = areaType.children?.some(item => item.isError);
				areaType.isWarning = areaType.children?.some(item => item.isWarning);
			});

		// delete areaSubtype if no any AreaEntity
		if (areaEntity.areaType === "AREAAUTONOMOUS") {
			const autonomousArea = getListItemChildren(objectItems, [1, 0, 1]);
			autonomousArea?.forEach((areaSubtype, index) => {
					if (areaSubtype.children?.length === 0) {
						autonomousArea.splice(index, 1);
					}
				});
		}

		// delete areaType if no any AreaEntity
		const areas = getListItemChildren(objectItems, [1, 0]);
		areas?.forEach((areaType, index) => {
				if (areaType.children?.length === 0) {
					areas.splice(index, 1);
				}
			});

		checkAreasTriangleIcon();
	};
	/**
	 * Delete the bay from the layers panel
	 * @param linkEntity
	 */
	const deleteLinkEntity = (linkEntity: LinkEntity) => {
		const regxString = /\[EDIT\] |\[NEW\] /;

		const linkEntry = getListItemChildren(objectItems, [1, 1]);

		const newValues = linkEntry?.filter(item => {
			return item.name.replace(regxString, "") !== `Link_${linkEntity.linkId}`;
		});

		if (newValues == undefined) {
			return;
		}

		objectItems[1]!.children![1].children = newValues;

		checkPathsTriangleIcon();
	};
	/**
	 * onMapObjectDelete event listener
	 * @param entity
	 */
	const onMapObjectDelete = (entity: Model) => {
		switch (entity.getModelDisplayName()) {
			case 'Bay':
				deleteBayEntity(entity as BayEntity);
				break;
			case 'Area':
				deleteAreaEntity(entity as AreaEntity);
				break;
			case 'Link':
				deleteLinkEntity(entity as LinkEntity);
				break;
		}
		performSearch(search.searchTerm, errorToggle, warningToggle);
		setErrorCount(() => map.getMapLookup().getMapErrorCount());
		setWarningCount(() => map.getMapLookup().getMapWarningCount());
	};

	/**
	 * Determines if the given layers panel item is among the hierarchy to expand.
	 * @param item - ILayersPanelItem
	 */
	const isAmongHierarchyToExpand = (item: ILayersPanelItem): boolean => {
		return hierarchyToExpand.some(x => x === layersPanelItemId(item));
	};

	const handleError = () => {
		const option = !errorToggle === true ? 'activate' : 'deactivate';
		setCustomTag('layers-panel', `${option}-error-filter`);
		setErrorToggle(errorToggleValue => !errorToggleValue);
		performSearch(search.searchTerm, !errorToggle, warningToggle);
	};

	const handleWarning = () => {
		const option = !errorToggle === true ? 'activate' : 'deactivate';
		setCustomTag('layers-panel', `${option}-warning-filter`);
		setWarningToggle(prevWarningToggle => !prevWarningToggle);
		performSearch(search.searchTerm, errorToggle, !warningToggle);
	};

	return (
		<>
			<div className="search-bar">
				<h5>Map Objects</h5>
				<SearchForm
					className="small"
					model={search}
					onChange={value => handleSearchChange(value)}
					clickToClear
					onClickToClear={() => handleSearchChange('')}
				/>
			</div>
			<div className="section-divider" />
			<div className="tree">
				{
					searchState.filteredObjects.length > 0
						? searchState.filteredObjects.map((item, index) => (
							<LayersPanelItem
								objectItem={item}
								forceExpand={searchState.isSearched ? true : isAmongHierarchyToExpand(item)}
								isSearched={searchState.isSearched}
								hierarchyToExpand={hierarchyToExpand}
								itemEntityIdToSetActive={entityIdForSelectedMapObject}
								itemEntityIdToSetInactive={inactiveEntityId}
								map={map}
								key={item.name + index.toString()}
								id={item.name + index.toString()}
								isViewMenuShownInLayersPanel={isViewMenuShownInLayersPanel}
							/>
						)) : <span className="no-results">No search results found</span>
				}
			</div>
			<div className="map-check-errors">
				<div className="label icon-left">
					<div
						className={classNames('error-button-styling', errorToggle ? 'button-styling-active' : '')}
						onClick={handleError}
					>
						{errorCount === 0
						? (
							<>
								<span className="dot-error" />
								<span className="error-layers-panel">
									({errorCount}) Errors
								</span>
							</>
						)
						: (
							<>
								<span className="dot-error error" />
								<span className="error-layers-panel error">
									({errorCount}) Errors
								</span>
							</>
						)}
					</div>
				</div>
				<div className="label icon-right">
					<div
						className={classNames('warning-button-styling', warningToggle ? 'button-styling-active' : '')}
						onClick={handleWarning}
					>
						{warningCount === 0
						? (
							<>
								<span className="dot-error" />
								<span className="error-layers-panel">
									({warningCount}) Warnings
								</span>
							</>
						)
						: (
							<>
								<span className="dot-error warning" />
								<span className="error-layers-panel warning">
									({warningCount}) Warnings
								</span>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};
