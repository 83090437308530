import MapEventHandler from '../MapEventHandler';
import { ToolbarEvent } from '../../MapToolbar/Toolbar';
import { setCustomTag } from '../Helpers/MapUtils';

interface EventSettings {
	// Prevent the default functionality
	preventDefault?: boolean;

	// Should the keybinding still work when an input element is focussed
	enableInputEvent?: boolean;

	handler: (event: KeyboardEvent, eventHandler: MapEventHandler) => void;
}

const IS_MAC = navigator['userAgentData']?.platform.includes('mac') ?? navigator.platform.includes('Mac');

// Used to accept legacy map data files (prior to v2.2.7.0). Enable by default in selenium tests
// Value is assigned to isAllowLegacyVersion on serverside
export const isSelenium = () => !!navigator.webdriver;

type UndoRedoEvent = Partial<ToolbarEvent & 'undo' | 'redo'>;

export const isCtrlKeyHeld = (event: KeyboardEvent | MouseEvent) => IS_MAC ? event.metaKey : event.ctrlKey;

export const isShiftKeyHeld = (event: KeyboardEvent | MouseEvent) => event.shiftKey;

const GLOBAL_EVENTS: { [key in string]: EventSettings | undefined } = {
	s: { handler: selectActiveTool('selector') },
	a: { handler: selectActiveTool('area') },
	b: { handler: selectActiveTool('bay') },
	p: { handler: selectActiveTool('clothoid') },
	ctrl_p: { handler: selectActiveTool('path'), preventDefault: true },
	// ctrl_z: { handler: undoRedo('undo'), preventDefault: true },
	// ctrl_shift_z: { handler: undoRedo('redo'), preventDefault: true },
	m: { handler: selectActiveTool('ruler') },
	c: { handler: selectActiveTool('connection') },
	ctrl_s: { handler: save, preventDefault: true, enableInputEvent: true },
	de: { handler: toggleDebugMode, preventDefault: true, enableInputEvent: false },
};

function undoRedo(action: UndoRedoEvent) {
	setCustomTag('keyboard-shortcuts', action);
	return (event: KeyboardEvent, eventHandler: MapEventHandler) => {
		if (action === 'undo') {
			eventHandler.onUndo();
		} else {
			eventHandler.onRedo();
		}
	};
}

const KeyDownMap: Set<string> = new Set<string>();

export function keyLifted(event: KeyboardEvent) {
	KeyDownMap.delete(event.key.toLowerCase());
}

export default function checkForGlobalEvents(event: KeyboardEvent, eventHandler: MapEventHandler) {
	const isCommandKeyHeld = IS_MAC ? event.metaKey : event.ctrlKey;
	const isSecondaryKeyHeld = IS_MAC ? event.ctrlKey : false;
	const isShiftHeld = event.shiftKey;
	const isAltKeyHeld = event.altKey;
	let key = event.key.toLowerCase();
	const isModifierKeyHeld = isCommandKeyHeld || isSecondaryKeyHeld || isShiftHeld || isAltKeyHeld;

	KeyDownMap.add(key);

	const eventName = [
		isCommandKeyHeld ? 'ctrl' : undefined,
		isSecondaryKeyHeld ? 'control' : undefined,
		isAltKeyHeld ? 'alt' : undefined,
		isShiftHeld ? 'shift' : undefined,
		isModifierKeyHeld ? key : Array.from(KeyDownMap).join(''),
	].filter(x => x !== undefined).join('_');

	const customEvent = GLOBAL_EVENTS[eventName];
	if (!customEvent) {
		if (key !== 'd') { // don't clear the KeyDownMap if key is 'd', as next key could be 'e' for enabling/disabling debug mode
			KeyDownMap.clear(); // without this, pressing a function key (e.g. F12), was causing the the map to ignore any further keyboard shortcuts
		}
		return;
	}

	const inputFocussed = !!event.target && event.target instanceof HTMLInputElement;
	const isEventInputEnabled = customEvent?.enableInputEvent === true;
	if (inputFocussed && !isEventInputEnabled) {
		return;
	}

	customEvent?.handler(event, eventHandler);

	if (customEvent?.preventDefault ?? false) {
		event.stopPropagation();
		event.preventDefault();
	}
}

function selectActiveTool(tool: ToolbarEvent) {
	setCustomTag('keyboard-shortcuts', tool);
	return (event: KeyboardEvent, eventHandler: MapEventHandler) => {
		eventHandler.setActiveTool(tool);
	};
}

function save(event: KeyboardEvent, eventHandler: MapEventHandler) {
	eventHandler.getController().getTracker().saveChanges();
}

function toggleDebugMode(event: KeyboardEvent, eventHandler: MapEventHandler) {
	console.log('Enabling/disabling debug mode')
	eventHandler.setDebugMode(!eventHandler.isDebugMode());
}