import {WaypointList} from "./Waypoint";
import MapStore from "../../../MapStore";
import {SUBLINK_ID_MAX} from "../../../../../../Constants";
import {LinkEntity, NodeEntity} from "../../../../../../Models/Entities";
import {RealWorldCoordinates} from "../../../Helpers/Coordinates";
import {nodetask} from "../../../../../../Models/Enums";
import PathValidator from "../../../MapValidators/PathValidator";
import PathManager from "./PathManager";
import DrivingArea from "../../../MapObjects/Area/DrivingArea";
import MapValidator from "../../../MapValidators/MapValidator";
import {store} from "../../../../../../Models/Store";
import geoJsonToPoints from "../../../Helpers/GeoJSON";
import {Point} from "pixi.js";

const MAX_PARKING_WAYPOINTS = 1;
const MAX_HAULING_WAYPOINTS = 3;
const MAX_REVERSE_WAYPOINTS = 3;

export default class PathValidationHelper {
	private waypoints: WaypointList;
	private store: MapStore;

	private pathValidator: PathValidator;

	private linkIdAvailable: number;
	private sublinkIdAvailable: number;
	private nodeIdAvailable: number;

	private availableLinkIds: number | undefined = undefined;
	private availableSublinkIds: number[] = [];
	private availableNodeIds: number[] = [];

	constructor(waypoints: WaypointList, manager: PathManager) {
		this.waypoints = waypoints;
		this.store = manager.mapStore;
		this.pathValidator = new PathValidator(manager.mapEventHandler.getController());

		// Get the allowed number of entities we are allowed to generate. and cache the result
		this.getAllowedIdCount();
	}

	public setAvailableIds(originalLink: LinkEntity) {
		this.availableLinkIds = originalLink.linkId;
		this.availableSublinkIds = originalLink.sublinkss.map(x => x.sublinkId);

		// @ts-ignore
		this.availableNodeIds = originalLink.sublinkss.flatMap(x => x.nodess).map(x => x.nodeId);
	}

	private async getAllowedIdCount() {
		const mapParams = this.store.getMapParameters();

		const maxLinkId = mapParams.staticLinkIdMax;
		const maxSublinkId =  SUBLINK_ID_MAX;
		const maxNodeId = mapParams.nodeIdMax;

		const linkIdAvailable = maxLinkId - this.store.getAllEntities('LinkEntity').length;
		const sublinkIdAvailable = maxSublinkId - this.store.getAllEntities('SublinkEntity').length;
		const nodeIdAvailable = maxNodeId - this.store.getAllEntities('NodeEntity').length;

		this.linkIdAvailable = linkIdAvailable;
		this.sublinkIdAvailable = sublinkIdAvailable;
		this.nodeIdAvailable = nodeIdAvailable;
	}

	private isAllowedLinkEntityCount(link: LinkEntity): string | undefined {
		if (this.linkIdAvailable <= this.getRequiredLinkIdCount(link)) { // There is always one link
			return 'link';
		}

		if (this.sublinkIdAvailable <= this.getRequiredSublinkIdCount(link)) {
			return 'sublink';
		}

		if (this.nodeIdAvailable <= this.getRequiredNodeIdCount(link)) {
			return 'node';
		}

		return undefined;
	}

	public isLocationInAhsMapBounds(coords: RealWorldCoordinates) {
		const {
			ahsAreaStartingPointX,
			ahsAreaStartingPointY,
			ahsAreaWidthMax,
			ahsAreaLengthMax} = this.store.getMapParameters();

		const { northing, easting } = coords;

		// Return true if the location is within the AHS map bounds
		return northing > ahsAreaStartingPointY
			&& northing < ahsAreaStartingPointY + ahsAreaLengthMax
			&& easting > ahsAreaStartingPointX
			&& easting < ahsAreaStartingPointX + ahsAreaWidthMax;
	}

	public isAllowedToAddWaypoint(task: nodetask): boolean {
		const innerWaypoints = this.waypoints.iter.slice(1, -1);
		const waypointCount = innerWaypoints.filter(x => x.task === task).length;

		if (task === 'HAULING') {
			return waypointCount < MAX_HAULING_WAYPOINTS;
		} else if (task === 'REVERSEPOINT') {
			return waypointCount < MAX_REVERSE_WAYPOINTS;
		} else if (task === 'PARKING') {
			return waypointCount < MAX_PARKING_WAYPOINTS;
		}

		return true;
	}

	/**
	 * Assign ids to the link, sublinks and nodes. It fetches the next available ids from the store in bulk.
	 * While this is happening, we also set the state of the link, sublinks and nodes to NEW_OBJECT. This is because
	 * if we are assigning new ids, the object is considered new.
	 *
	 * @param link the link to assign ids to
	 */
	public assignIdsForLink(link: LinkEntity) {
		const isConfirmed = this.availableLinkIds !== undefined;

		if (!isConfirmed) {
			link.linkId = this.getAvailableLinkId(link);
		}

		link.state = isConfirmed ? 'MODIFIED' :'NEW_OBJECT';

		const sublinkIds = this.getAvailableSublinkIds(link);
		const nodeIds = this.getAvailableNodeIds(link);

		const sublinkIdOld = this.availableSublinkIds.length;
		const nodeIdOld = this.availableNodeIds.length;

		try {
			link.sublinkss.forEach((sublink, i) => {
				const sublinkIsConfirmed = i < sublinkIdOld;

				sublink.sublinkId = sublinkIds.shift()!;
				sublink.state = sublinkIsConfirmed ? 'MODIFIED' : 'NEW_OBJECT';

				sublink.nodess.forEach((node, j) => {
					node.nodeId = nodeIds.shift()!;

					const nodeIsConfirmed = j < nodeIdOld;
					node.state = nodeIsConfirmed ? 'MODIFIED' : 'NEW_OBJECT';

					node.linkIdNumber = link.linkId;
					node.sublinkIdNumber = sublink.sublinkId;
				});
			});
		} catch (e) {
			// Should be unlikely to reach here (getting the list of ids will throw an error if there are no more ids)
			console.error('Error assigning ids to link', e);
		}
	}

	private getAvailableLinkId(link: LinkEntity) {
		return this.availableLinkIds ?? this.store.getNextAvailableLinkId(1)[0];
	}

	private getAvailableSublinkIds(link: LinkEntity) {
		return [
			...this.availableSublinkIds,
			...this.store.getNextAvailableSublinkId(this.getRequiredSublinkIdCount(link))
		].sort((a, b) => a - b);
	}

	private getAvailableNodeIds(link: LinkEntity) {
		return [
			...this.availableNodeIds,
			...this.store.getNextAvailableNodeId(this.getRequiredNodeIdCount(link))
		].sort((a, b) => a - b);
	}

	private getRequiredLinkIdCount(link: LinkEntity) {
		return this.linkIdAvailable !== undefined ? 0 : 1;
	}

	private getRequiredSublinkIdCount(link: LinkEntity) {
		return Math.max(link.sublinkss.length - this.availableSublinkIds.length, 0);
	}

	private getRequiredNodeIdCount(link: LinkEntity) {
		return Math.max(
			link.sublinkss.reduce((acc, x) => acc + x.nodess.length, 0) - this.availableNodeIds.length,
			0
		);
	}

	public isAllowedConnectivityPair(node: NodeEntity): boolean {
		const link = node.getLink();
		if (!link) {
			return false;
		}

		const connectivities: string[] = [];

		this.waypoints.getNextConnections()
			.map(x => this.store.getEntity(x, LinkEntity))
			.filter(x => x.getModelId() !== link.getModelId())
			.forEach(x => {
				x.linkFroms.forEach(y => {
					connectivities.push(y.linkFromId);
				});
			});
		this.waypoints.getPreviousConnections()
			.map(x => this.store.getEntity(x, LinkEntity))
			.filter(x => x.getModelId() !== link.getModelId())
			.forEach(x => {
				x.linkTos.forEach(y => {
					connectivities.push(y.linkToId);
				});
			});

		const isStart = node.isStartNodeOfLink();
		if (isStart) {
			link.linkFroms.forEach(y => {
				connectivities.push(y.linkFromId);
			});
		} else {
			link.linkTos.forEach(y => {
				connectivities.push(y.linkToId);
			});
		}



		return (new Set(connectivities).size) === connectivities.length;
	}

	/**
	 * Method to limit the number of driveable areas that need to be checked for an intersection with the driving zones.
	 * Comparing all points of a driving zone with all points of a driveable area is expensive. So this method first
	 * checks if a point of a driving zone is inside a driveable area. If it is, it returns the driveable area.
	 * If it is not, it returns undefined. In the case it returns undefined, this driving zone is guaranteed to be a
	 * driving zone error
	 *
	 * @param link to get the possible driveable areas for
	 * @returns A dictionary of sublink index to the possible driveable area that it needs to be checked against
	 */
	public getDriveableAreasToCheck(link: LinkEntity) {
		const sublinkToDrivingAreas: Record<number, DrivingArea> = {};
		const sublinkPoints = link.sublinkss.map(x => (geoJsonToPoints(x.drivingZone, store.renderer) as Point[])[0]);

		this.store.getDrivingAreas().forEach(area => {
			const driveableAreaId = this.store.getMapObjectId(area.id, 'driving_area');
			const drivingArea = store.renderer.getObjectById(driveableAreaId) as DrivingArea;
			sublinkPoints?.forEach((sublinkPoint, i) => {
				const boundingPoints = MapValidator.getBoundingPoints(drivingArea, sublinkPoint);
				if (!!boundingPoints) {
					sublinkToDrivingAreas[i] = drivingArea;
				}
			});
		});

		return sublinkToDrivingAreas;
	}

	/**
	 * Perform all the checks to see if the path is valid
	 * @param link
	 * @param disableDrivingZoneCheck Disable checking driving zone errors when we are not displaying the driving zones
	 * @returns two lists, first is a list of errors, and the second is a list of warnings
	 */
	public isPathValid(link?: LinkEntity, disableDrivingZoneCheck: boolean = false): { errors: string[], warnings: string[] } {
		const errors: string[] = [];
		const warnings: string[] = [];

		if (!this.waypoints.isFullPath() || link === undefined) {
			errors.push('Not enough waypoints');
			return { errors, warnings };
		}

		const checkIdError = this.isAllowedLinkEntityCount(link);
		if (checkIdError !== undefined) {
			errors.push(`Not enough available ids for ${checkIdError}`);
		}

		if (this.waypoints.iter.some(x => !this.isLocationInAhsMapBounds(x.coordinates))) {
			errors.push('Waypoint is not within the AHS map bounds');
		}

		if (!disableDrivingZoneCheck) {
			const errorsAndWarnings = this.pathValidator.validateDrivingZonesFromLink(link, this.getDriveableAreasToCheck(link));
			errorsAndWarnings.forEach((x, i) => {
				errors.push(...x.errors);
				warnings.push(...x.warnings);

				link.sublinkss[i].addErrors(x.errors);
				link.sublinkss[i].addWarnings(x.warnings);
			});
		}

		// For now we are only checking if the path is full (will need to add more checks)
		return {
			errors,
			warnings
		};
	}


}