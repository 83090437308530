import { LeafletMouseEvent } from 'leaflet';
import type { LeafletCoordinates, RealWorldCoordinates } from '../Helpers/Coordinates';
import {
	LinkEntity, LinkFromLinkTo, NodeEntity, SublinkEntity,
} from '../../../../Models/Entities';
import alertToast from '../../../../Util/ToastifyUtils';
import Path, { IPathOptions } from '../MapObjects/Path/Path';
import {
	action, autorun, IReactionDisposer, observable, reaction, runInAction,
} from 'mobx';
import NodeGraphic from '../MapObjects/Node/NodeGraphic';
import { Link } from 'Views/MapComponents';
import MapStateHandler from './MapStateHandler';
import LinkConnectivityEditHelper from '../MapStateHandlerHelpers/LinkConnectivityEditHelper';
import SnapWaypointsOperationsHelper, { ISnapParams } from '../MapStateHandlerHelpers/SnapWaypointOperationsHelper';
import { calcDistanceBetweenNodes, offsetAlongHeadingRealWorld, trc_disable } from '../Helpers/MapUtils';
import PathToolHelper, { HoverState, linkReferencePath } from '../MapStateHandlerHelpers/PathToolHelper';
import PathValidator from '../MapValidators/PathValidator';
import { store } from '../../../../Models/Store';
import PathToolIdHelper from '../MapStateHandlerHelpers/PathToolIdHelper';
import { nodetask } from 'Models/Enums';
import PathRequestHelper, { IPathRequestResponse, pathRequestError } from '../MapStateHandlerHelpers/PathRequestHelper';
import type { IPathResponseData } from '../MapStateHandlerHelpers/PathRequestHelper';
import { ToolbarEvent } from 'Views/MapComponents/MapToolbar/Toolbar';
import { PATH_ERROR_NODE_LIMIT, PATH_ERROR_UNKNOWN } from 'Constants';
import LinkOperationsHelper from '../MapStateHandlerHelpers/LinkOperationsHelper';
import { IClothoidParams } from 'Views/MapComponents/MapProperties/PropertiesSidePanel';
import { AnotherLinkNodeErrorResult } from '../ServerValidation';

// TODO: de-couple helpers and put into helper class. Only core state handler logic should be here

export type LinkSegmentDirection = "forward" | "reverse";

export interface Waypoint {
	node: NodeEntity;
	nodeGraphic?: NodeGraphic;
	// true if Task is Reverse
	isReverse?: boolean;
	// Set to true when reverseWaypointCount > 2
	// Used  to enforce limit so that only the first 3 reverse points are editable
	// Only used for waypoints with task Reverse
	isReadOnly?: boolean;
}

export interface IClothoidStateHandlerAttrs {
	isClothoidValid: boolean;
	isDrawMode: boolean;
	hasError: boolean;
	isOppositeDirections: boolean;
	clothoidPathObjectId?: string;
	waypoints: Waypoint[];
	fromGraphic?: NodeGraphic;
	_waypointCreation: boolean;
	clothoidLinkEntity: LinkEntity;
	autorunDisposer: IReactionDisposer;
	reactionDisposer: IReactionDisposer;
	_isStartWaypointSnapped: boolean;
	_isEndWaypointSnapped: boolean;
	_targetStartLinkIdNum: number | undefined;
	_targetEndLinkIdNum: number | undefined;
	hover: HoverState;
	hoverGraphic?: NodeGraphic;
	hoverWaypoint?: NodeEntity;
	lastWaypoint: boolean;
	currentSegmentDirection?: LinkSegmentDirection;
	isWaiting: boolean;
	linksToRestore: LinkEntity[] | undefined;
	nodeErrorsToRemove: AnotherLinkNodeErrorResult[];
}

export interface IMidWaypointCount {
	haulingMidWaypoints: number;
	reverseMidWaypoints: number;
	parkingMidWaypoints: number;
}

export interface IClothoidRequestUpdate {
	entity?: NodeEntity;
}

/* Define the max waypoints with a given task */
export const MAX_PARKING_WAYPOINTS = 1;
export const MAX_HAULING_WAYPOINTS = 3;
export const MAX_REVERSE_WAYPOINTS = 3;

export default class ClothoidStateHandler extends MapStateHandler {
	// Whether or not the a clothoid could be produced
	@observable
	protected isClothoidValid = false;

	// Key state varliable. True for Draw mode. False for Edit mode
	@observable
	protected isDrawMode = true;

	@observable
	protected isWaiting = false;

	// debouce for resetting of isWaiting flag
	private lastUpdate: number = 0;
	private timeoutId: NodeJS.Timeout | undefined;

	// Whether or not the path has errors on it
	protected hasError: boolean = false;

	// Separate flag for driving zone errors so that confirm isn't disabled for these errors (HITMAT-854)
	// TODO: Check this flag upon confirmation of path to re-render error (isError of DrivingZone obj must be true)
	private hasDrivingZoneError: boolean = false;


	// This flag is set to true where snapping would result in start and end
	// waypoints having opposite directions. It's used to block clothoid generation
	// in such cases
	protected isOppositeDirections: boolean = false;

	// ID of current Path map object. Assigned in createAndRenderPath and used for implicit state
	// TODO: Stop using for implicit state
	protected clothoidPathObjectId?: string;

	protected waypoints: Waypoint[];
	protected fromGraphic?: NodeGraphic;

	// Whether or not waypoints can continue to be created
	private _waypointCreation: boolean = true;

	// Current link entity being processed by this handler
	protected clothoidLinkEntity: LinkEntity;

	protected tempLinkFroms: LinkFromLinkTo[] = [];
	protected tempLinkTos: LinkFromLinkTo[] = [];

	private autorunDisposer: IReactionDisposer;
	private reactionDisposer: IReactionDisposer;

	// TODO: change to one variable and move to edit handler
	// Handles case where start waypoint is snapped (edit nmode)
	private _isStartWaypointSnapped: boolean = false;
	// Handles case where start waypoint is snapped (edit nmode)
	private _isEndWaypointSnapped: boolean = false;

	private _targetStartLinkIdNum: number | undefined;
	private _targetEndLinkIdNum: number | undefined;

	// Used for Edit Clothoid on map
	protected hover: HoverState = HoverState.DEFAULT;
	protected hoverGraphic?: NodeGraphic;
	protected hoverWaypoint: NodeEntity | undefined = undefined;

	// TODO: find different solution that does not require this flag
	// flag that indicates last waypoint placement
	// this overcomes issue where isDrawMode is set to false BEFORE getDirectionFromWaypoints is called
	// to enable direction to be set correct via getDirectionFromWaypoints
	protected lastWaypoint = false;

	protected currentSegmentDirection: LinkSegmentDirection | undefined;

	protected affectedLinks: LinkEntity[] = [];

	protected linksToRestore: LinkEntity[] | undefined;

	protected nodeErrorsToRemove: AnotherLinkNodeErrorResult[] = [];
	protected nodeErrorsToAddBack: AnotherLinkNodeErrorResult[] = [];

	// private trc = trc_disable;

	// start getters and setters needed for HIT-103
	protected get startWaypoint() {
		return this.waypoints[0].node;
	}

	protected get currentStartWaypoint() {
		return this.waypoints[this.waypoints.length - 2].node;
	}

	protected get endWaypoint() {
		return this.waypoints[this.waypoints.length - 1].node;
	}

	protected get startWaypointGraphic() {
		return this.waypoints[0].nodeGraphic;
	}

	protected set startWaypointGraphic(nodeGraphic: NodeGraphic | undefined) {
		this.waypoints[0].nodeGraphic = nodeGraphic;
	}

	protected get endWaypointGraphic() {
		return this.waypoints[this.waypoints.length - 1].nodeGraphic;
	}

	protected set endWaypointGraphic(nodeGraphic: NodeGraphic | undefined) {
		this.waypoints[this.waypoints.length - 1].nodeGraphic = nodeGraphic;
	}
	// end gettiers and setters needed for HIT-103

	protected get isStartWaypointSnapped() {
		return this._isStartWaypointSnapped;
	}

	protected set isStartWaypointSnapped(isSnapped: boolean) {
		if (this._isStartWaypointSnapped !== isSnapped) {
			this.trc(`isStartWaypointSnapped From ${this._isStartWaypointSnapped} to ${isSnapped}`);
		}
		this._isStartWaypointSnapped = isSnapped;
	}

	protected get isEndWaypointSnapped() {
		return this._isEndWaypointSnapped;
	}

	protected set isEndWaypointSnapped(isSnapped: boolean) {
		if (this._isEndWaypointSnapped !== isSnapped) {
			this.trc(`isEndWaypointSnapped From ${this._isEndWaypointSnapped} to ${isSnapped}`);
		}
		this._isEndWaypointSnapped = isSnapped;
	}

	protected get targetStartLinkIdNum(): number | undefined {
		return this._targetStartLinkIdNum;
	}

	protected set targetStartLinkIdNum(idNumber: number | undefined) {
		this._targetStartLinkIdNum = idNumber;
	}

	protected get targetEndLinkIdNum(): number | undefined {
		return this._targetEndLinkIdNum;
	}

	protected set targetEndLinkIdNum(idNumber: number | undefined) {
		this._targetEndLinkIdNum = idNumber;
	}

	protected set waypointCreation(isCreation: boolean) { this._waypointCreation = isCreation };
	protected get waypointCreation() { return this._waypointCreation; };

	protected validator: PathValidator;

	protected snapWaypointHelper: SnapWaypointsOperationsHelper;

	protected pathIdHelper: PathToolIdHelper;

	// Edit for editing existing links
	protected originalLinkId: string | undefined;
	protected originalPathId: string | undefined;
	protected startObj: NodeGraphic | undefined;
	protected endObj: NodeGraphic | undefined;

	protected requestHandler: PathRequestHelper;

	protected getAllNodes() {
		return PathToolHelper.getAllNodesOfLink(this.clothoidLinkEntity);
	}

	protected getNodeTask(node: NodeEntity): nodetask {
		// returns HAULING by default (if no task set)
		return node.task !== undefined ? node.task : 'HAULING'; 
	}

	protected trc = trc_disable;

	/**
	 * Use to set attributes needed in Edit handler from the Create handler
	 * e.g. create path via Create handler -> press Enter -> transition to edit
	 * IMPORTANT: 
	 * @param attrs 
	 */
	protected assignClothoidAttributes(attrs: IClothoidStateHandlerAttrs) {
		if (attrs.isClothoidValid !== undefined)
			this.isClothoidValid = attrs.isClothoidValid === true ? true : false;
		if (attrs.hasError !== undefined)
			this.hasError = attrs.hasError;
		if (attrs.isOppositeDirections !== undefined)
			this.isOppositeDirections = attrs.isOppositeDirections;
		if (attrs.clothoidPathObjectId !== undefined)
			this.clothoidPathObjectId = attrs.clothoidPathObjectId;
		if (attrs.waypoints !== undefined)
			this.waypoints = attrs.waypoints;
		if (attrs.clothoidLinkEntity !== undefined)
			this.clothoidLinkEntity = attrs.clothoidLinkEntity;
		if (attrs._isStartWaypointSnapped !== undefined)
			this._isStartWaypointSnapped = attrs._isStartWaypointSnapped;
		if (attrs._isEndWaypointSnapped !== undefined)
			this._isEndWaypointSnapped = attrs._isEndWaypointSnapped;
		if (attrs._targetStartLinkIdNum !== undefined)
			this._targetStartLinkIdNum = attrs._targetStartLinkIdNum;
		if (attrs._targetEndLinkIdNum !== undefined)
			this._targetEndLinkIdNum = attrs._targetEndLinkIdNum;
		if (attrs.hover !== undefined)
			this.hover = attrs.hover;
		if (attrs.hoverGraphic !== undefined)
			this.hoverGraphic = attrs.hoverGraphic;
		if (attrs.hoverWaypoint !== undefined)
			this.hoverWaypoint = attrs.hoverWaypoint;

		if (attrs.lastWaypoint !== undefined)
			this.lastWaypoint = attrs.lastWaypoint;
		if (attrs.currentSegmentDirection !== undefined)
			this.currentSegmentDirection = attrs.currentSegmentDirection;
		if (attrs.linksToRestore !== undefined) {
			this.linksToRestore = attrs.linksToRestore;
		}
		if (attrs.nodeErrorsToRemove !== undefined) {
			this.nodeErrorsToRemove = attrs.nodeErrorsToRemove;
		}
	}

	protected initHelpers() {
		PathToolHelper.areaInfoCache = undefined;
		this.requestHandler = new PathRequestHelper(this.getController());
		this.snapWaypointHelper = new SnapWaypointsOperationsHelper(this.getController());
		this.pathIdHelper = new PathToolIdHelper(this.getController());
		this.validator = new PathValidator(this.getController());
	}

	protected initEvents(directionToDisplay?: string) {
		// Set the direction property to editable
		if (this.isDrawMode) {
			this.getEventHandler().emit('setDirectionPropertyReadonly', false);
		}

		this.getEventHandler().emit('onSetHCMPGToggleButtonReadOnly', false);

		this.autorunDisposer = autorun(() => {
			// TODO: this needs to be customised and simplified for new state handlers
			// console.log(`***isDrawMode: ${this.isDrawMode} isClothoidValid ${this.isClothoidValid} isWaiting ${this.isWaiting}***`);
			this.getEventHandler().emit('onStateChange', !this.isDrawMode, directionToDisplay);
			if (this.isClothoidValid) {
				if (!this.isWaiting) {
					this.getEventHandler().emit('toggleConfirmCreation', true, this.hasError);
				}
			} else {
				this.getEventHandler().emit('toggleConfirmCreation', false);
			}
		});

		// event that's fired when pending requests are all cleared
		this.reactionDisposer = reaction(() => this.isWaiting, value => {
			this.waitEnded();
		}, { fireImmediately: false });
	}

	protected isAsyncRequestComplete() {
		return this.requestHandler.isAsyncRequestComplete();
	}

	protected isRequestInProgress() {
		return this.requestHandler.requestInProgress;
	}

	public onAsyncRequestResponse(result: IPathRequestResponse) {
		if (result.isIgnore) {
			console.log('onAsyncRequestResponse: Ignoring response');
			return;
		}
		this.trc(`onAsyncRequestResponse: Got response isPendingAsyncUpdate: ${this.requestHandler.isPendingAsyncUpdate}`);
		this.processRequestReponse(result);
		// For isPendingAsyncUpdate to be true, there must have been an update AFTER this request was sent
		if (this.requestHandler.isPendingAsyncUpdate) {
			// There is new data since, init another pending request
			// Update flag cleared in asyncClothoidPathRequest ->  asyncSendRequest
			this.asyncClothoidPathRequest();
		}
	}

	onInit(initialState: unknown) {}

	protected waitEnded() {}

	// TODO: edithandler only?
	isConfirmable() {
		if (!this.getController().isActionConfirmAllowed()) {
			return false;
		}

		const result = this.isClothoidValid && !this.hasError;
		if (!result) {
			console.log(`Path NOT Confirmable. isClothoidValid: ${this.isClothoidValid} hasError: ${this.hasError}`)
		}
		return result;
	}

	protected getDirectionFromSpeed(speed?: number): LinkSegmentDirection {
		return PathToolHelper.getDirectionFromSpeed(speed);
	}


	printWaypoints(s: string | undefined) {
		// const infoStr = !!s ? `${s} ` : '';
		// this.waypoints.forEach((w, i) => {
		// 	console.log(`${infoStr}${i} task: ${w.node.task} direction: ${w.node.direction} ${w.node.id} isReverse: ${w.isReverse}`);
		// })
		// return;
	}

	/**
	 * Restore driving zones of affected links (e.g. on cancel of edit)
	 */
	protected restoreLinks() {
		if (!!this.linksToRestore) {
			PathToolHelper.reRenderTempDrivingZones(this.getLookup(), this.linksToRestore);
			this.linksToRestore = undefined;
		}
	}

	/**
	 * Process TSD errors after canceling drawing/editing action.
	 * Clear temp TSD errors and/or add existing TSD errors back.
	 */
	processTSDErrorsForCancel() {
		if (!this.originalLinkId) {
			// Case 1
			// action: create and snap a new link to a non-edited link (without TSD errors originally) -> cancel it
			this.processNonEditedTSDErrors([]);
		} else if ((this.isStartWaypointSnapped && !!this.targetEndLinkIdNum) ||
			(this.isEndWaypointSnapped && !!this.targetStartLinkIdNum)) {
			// Case 2: an existing link connect or not connect to another link
			// action: edit the existing link and snap to a non-edited link -> cancel it
			// Remove temp TSD errors (and add TSD errors back to the non-edited link if there is any)
			this.processNonEditedTSDErrors([]);
			if (this.nodeErrorsToAddBack.length > 0) {
				this.addNodeErrorWithoutUpdateLookup(this.nodeErrorsToAddBack);
			}
		} else {
			// Case 3: an existing link snapping to another link that includes existing TSD errors
			// action: edit the existing link and unsnap the link (TSD errors are removed temporarily) -> cancel it
			// Add TSD errors back to the non-edited link
			this.addNodeErrorWithoutUpdateLookup(this.nodeErrorsToAddBack);
		}
	}

	/**
	 * Get existing LinkFroms or temp LinkFroms for TSD serverside check.
	 */
	getTempLinkFromsForTSDCheck () {
		let tempLinkFroms: LinkFromLinkTo[] = [];
		if (this.targetEndLinkIdNum) { // Creating/Editing a new link snapping to a end node of an existing link
			const fromLink = this.getLookup().getLinkByIdNumber(this.targetEndLinkIdNum);
			if (fromLink) {
				// temp LinkFroms
				const newLinkFrom = new LinkFromLinkTo({
					linkFrom: fromLink, linkFromId: fromLink?.id, linkTo: this.clothoidLinkEntity, linkToId: this.clothoidLinkEntity?.id,
				});
				tempLinkFroms = [newLinkFrom];
			}
		} else if (this.originalLinkId) { // Editing an existing link
			const originalLink: LinkEntity | undefined = this.getLookup().getEntity(this.originalLinkId ?? '', LinkEntity);
			if (!!originalLink) {
				if (this.isStartWaypointUnmodified(originalLink)) { // Start waypoint is not unsnapped
					// existing LinkFroms
					tempLinkFroms = this.tempLinkFroms;
				}
			}
		}

		return tempLinkFroms;
	}

	/**
	 * Get existing LinkTos or temp LinkTos for TSD serverside check.
	 */
	getTempLinkTosForTSDCheck () {
		let tempLinkTos: LinkFromLinkTo[] = [];
		if (this.targetStartLinkIdNum) { // Creating/Editing a new link snapping to a start node of an existing link
			const toLink = this.getLookup().getLinkByIdNumber(this.targetStartLinkIdNum);
			if (toLink) {
				// temp LinkTos
				const newLinkTo = new LinkFromLinkTo({
					linkFrom: this.clothoidLinkEntity, linkFromId: this.clothoidLinkEntity?.id, linkTo: toLink, linkToId: toLink?.id,
				});
				tempLinkTos = [newLinkTo];
			}
		} else if (this.originalLinkId) { // Editing an existing link
			const originalLink: LinkEntity | undefined = this.getLookup().getEntity(this.originalLinkId ?? '', LinkEntity);
			if (!!originalLink) {
				if (this.isEndWaypointUnmodified(originalLink)) { // End waypoint is not unsnapped
					// existing LinkTos
					tempLinkTos = this.tempLinkTos;
				}
			}
		}

		return tempLinkTos;
	}

	private TSDCheckDebugCheck() {
		if (!!this.clothoidLinkEntity.linkFroms && this.clothoidLinkEntity.linkFroms.length > 0) {
			console.warn('Already got linkFroms!');
			console.warn(this.clothoidLinkEntity.linkFroms);
		}
		if (!!this.clothoidLinkEntity.linkTos && this.clothoidLinkEntity.linkTos.length > 0) {
			console.warn('Already got linkTos!');
			console.warn(this.clothoidLinkEntity.linkTos);
		}
	}

	/**
	 * Get params used for DZ preview
	 * 
	 * Make COPY of links that will be sent to server
	 * 
	 * @returns 
	 */
	protected getDZParams() {
		const lookup = this.getLookup();
		if (!!this.endWaypoint.previousNode) {
			this.endWaypoint.previousNodeId = this.endWaypoint.previousNode.id;
		}
		this.TSDCheckDebugCheck(); // prints console warning for unexpected case
		const activeLink = LinkOperationsHelper.copyLink(this.clothoidLinkEntity);
		let affectedLinks: LinkEntity[] = [];
		if (this.targetStartLinkIdNum) {
			// End waypoint of new link is connected to Start waypoint of targetStartLinkIdNum
			// newLink -> startLink
			const startLink = this.getLookup().getLinkByIdNumber(this.targetStartLinkIdNum);
			if (startLink) {
				const startLinkCopy = LinkOperationsHelper.copyLink(startLink);
				console.log(`activeLink ${activeLink.linkFroms.length} ${activeLink.linkTos.length} startLinkCopy ${startLinkCopy.linkFroms.length} ${startLinkCopy.linkTos.length}`);
				const connectivity = LinkConnectivityEditHelper.createConnectivityNoRecalc(this.getEventHandler(), startLinkCopy, activeLink, true);
				affectedLinks.push(startLinkCopy);
			}
		} else {
			const originalLink: LinkEntity | undefined = this.getLookup().getEntity(this.originalLinkId ?? '', LinkEntity);
			if (!!originalLink) {
				// If end waypoint of EDIT link has connectivity, re-add it
				if (this.isEndWaypointUnmodified(originalLink)) {
					console.log(`Adding EXISTING linkTos`);
					originalLink.linkTos.forEach(c => {
						const linkFrom = this.getLookup().getEntity(c.linkToId, LinkEntity);
						if (!!linkFrom) {
							const linkFromCopy = LinkOperationsHelper.copyLink(linkFrom);
							const connectivity = LinkConnectivityEditHelper.createConnectivityNoRecalc(this.getEventHandler(), linkFromCopy, activeLink, true);
							affectedLinks.push(linkFromCopy);
						}
					});
				} else {
					originalLink.linkTos.forEach(x => {
						const toAdd = lookup.getEntity(x.linkToId, LinkEntity);
						// console.log(`originalLink.id ${originalLink.id} ${toAdd.linkFroms[0].linkFromId}`)
						toAdd.linkFroms = toAdd.linkFroms.filter(q => q.linkFromId !== originalLink.id);
						// console.log(toAdd);
						affectedLinks.push(toAdd);
					});
				}
			}
		}
		
		if (this.targetEndLinkIdNum) {
			// End waypoint of targetEndLinkIdNum is connected to Start waypoint of new link
			// endLink linkTo -> newLink linkFrom
			const endLink = lookup.getLinkByIdNumber(this.targetEndLinkIdNum);
			if (endLink) {
				const endLinkCopy = LinkOperationsHelper.copyLink(endLink);
				console.log(`activeLink ${activeLink.linkFroms.length} ${activeLink.linkTos.length} endLinkCopy ${endLinkCopy.linkFroms.length} ${endLinkCopy.linkTos.length}`);
				const connectivity = LinkConnectivityEditHelper.createConnectivityNoRecalc(this.getEventHandler(), activeLink, endLinkCopy, true);
				affectedLinks.push(endLinkCopy);
			}
		} else {
			const originalLink: LinkEntity | undefined = this.getLookup().getEntity(this.originalLinkId ?? '', LinkEntity);
			if (!!originalLink) {
				// If start waypoint of EDIT link has connectivity, re-add it
				if (this.isStartWaypointUnmodified(originalLink)) {
					console.log(`Adding EXISTING linkFroms`);
					originalLink.linkFroms.forEach(c => {
						const linkTo = this.getLookup().getEntity(c.linkFromId, LinkEntity);
						if (!!linkTo) {
							const linkToCopy = LinkOperationsHelper.copyLink(linkTo);
							const connectivity = LinkConnectivityEditHelper.createConnectivityNoRecalc(this.getEventHandler(), activeLink, linkToCopy, true);
							affectedLinks.push(linkToCopy);
						}
					});
				} else {
					// handle unsnap
					originalLink.linkFroms.forEach(x => {
						const toAdd = lookup.getEntity(x.linkFromId, LinkEntity);
						// console.log(`originalLink.id ${originalLink.id} ${toAdd.linkTos[0].linkFromId}`)
						toAdd.linkTos = toAdd.linkTos.filter(q => q.linkToId !== originalLink.id);
						// console.log(toAdd);
						affectedLinks.push(toAdd);
					});
				}
			}
		}

		if (!this.targetEndLinkIdNum || !this.targetStartLinkIdNum) {
			const originalLink: LinkEntity | undefined = this.getLookup().getEntity(this.originalLinkId ?? '', LinkEntity);
			if (!!originalLink) {
				affectedLinks.push(lookup.getEntity(originalLink.id, LinkEntity));
			}
		}

		// If affectedLinks contains on elements, undefined is returned
		return PathToolHelper.getRecalculateDrivingZoneParams(this.getLookup(), activeLink, affectedLinks);
	}

	// TODO: add a start function
	asyncClothoidPathRequest() {
		const ts = performance.now().toFixed(1);
		// console.log(`${ts} asyncClothoidPathRequest`);
		const segmentDirections = this.getDirectionFromWaypoints();
		const id = Date.now();
		// console.log(`asyncClothoidPathRequest id: ${id}`);

		const tempLinkFroms = this.getTempLinkFromsForTSDCheck();
		const tempLinkTos = this.getTempLinkTosForTSDCheck();
	
		/////////////////////////////
		const dzParams = this.getDZParams();

		// if no other updates are pending and req handler isn't busy, send request and reset update flag
		const isPendingAsyncUpdate = this.requestHandler.asyncSendRequest(this.waypoints,
			this.clothoidLinkEntity,
			segmentDirections,
			tempLinkFroms,
			tempLinkTos,
			dzParams
		);
		if (isPendingAsyncUpdate) {
			// console.log(`asyncClothoidPathRequest: req ${id} pending`);
			this.clearWaitTimeout();
			// this.setWaitingFlag();
		} else {
			// console.log(`asyncClothoidPathRequest: req ${id} has been sent`);
		}
		return isPendingAsyncUpdate;
	}

	private clearWaitTimeout() {
		if (this.timeoutId !== undefined) {
			// prevent debounce from incorrectly resetting flag after it's set here
			clearTimeout(this.timeoutId);
			this.timeoutId = undefined;
		}
	}

	@action
	protected setWaitingFlag() {
		this.isWaiting = true;
	}

	protected getLastRequestErrorAndClear() {
		const err = this.requestHandler.getLastError();
		this.requestHandler.clearLastError();
		return err;
	}

	protected clearWaitingFlag() {
		const resetWaitingFlag = () => runInAction(() => {
			const { isPendingAsyncUpdate } = this.requestHandler;
			this.lastUpdate = Date.now();
			// Only clear flag is there are no additional pending updates
			this.isWaiting = isPendingAsyncUpdate;
			if (!this.isWaiting) {
				console.log('wait flag cleared')
			}
			this.clearWaitTimeout();
		});

		const debounceMilliseconds = 500;
		if ((Date.now() - this.lastUpdate) > debounceMilliseconds) {
			resetWaitingFlag();
		} else if (this.timeoutId === undefined) {
			this.timeoutId = setTimeout(() => {
				resetWaitingFlag();
			}, debounceMilliseconds);
		}
	}

	/**
	 * Determines whether or not there are still has incomplete tasks
	 * @returns true if the application is busy (req in progress/pending)
	 */
	protected isBusy() {
		const { requestInProgress, isPendingAsyncUpdate } = this.requestHandler;
		const result = requestInProgress || isPendingAsyncUpdate || this.isWaiting;
		if (result) {
			console.log(`isBusy (true): requestInProgress ${requestInProgress} isPendingAsyncUpdate ${isPendingAsyncUpdate} isWaiting ${this.isWaiting}`);
		} else {
			console.log(`isBusy (false)`);
		}
		return result;
	}

	/**
	 * Synchronous request (used for simple placement of waypoint such as shortclick - no dynamic update)
	 * 
	 * @returns 
	 */
	protected async sendClothoidPathRequest() {
		const dzParams = this.getDZParams();
		const segmentDirections = this.getDirectionFromWaypoints();
		const tempLinkFroms = this.getTempLinkFromsForTSDCheck();
		const tempLinkTos = this.getTempLinkTosForTSDCheck();
		const result = await this.requestHandler._sendRequest(this.waypoints,
			this.clothoidLinkEntity,
			segmentDirections,
			tempLinkFroms,
			tempLinkTos,
			false,
			dzParams
		);
		this.processRequestReponse(result);
		return result;
	}

	/**
	 * Checks whether result of request is valid for further processing,
	 * and if so calls afterPathUpdate
	 * 
	 * @param result request response from server
	 * @returns true if afterPathUpdate is called
	 */
	private processRequestReponse(result: IPathRequestResponse) {
		// under special conditions some responses are ignored (e.g. properties panel edge case where request is fired as state handler is changed)
		if (result.isIgnore) {
			console.log('processRequestReponse: ignoring');
			return false;
		}

		if (result.isSuccess) {
			if (!!result.pathReponseData) {
				// if there's a guideline, it will be disposed automatically
				this.afterPathUpdate(result.pathReponseData);
				return true;
			} else {
				// should never reach here
				console.error('successful path response but no data set');
			}
		} else {
			console.log('Unable to generate new path');
		}
		
		return false;
	}

	protected showPathErrorToast(err: pathRequestError) {
		const msg = err === pathRequestError.NODE_LIMIT ? PATH_ERROR_NODE_LIMIT : PATH_ERROR_UNKNOWN;
		alertToast(msg, 'error');
	}

	/**
	 * Rendering of new clothoid data (from request) and handling of clientside validation
	 * Includes update of ALL associated Driving Zones via reRenderTempDrivingZones
	 * 
	 * @param result 
	 */
	@action
	private afterPathUpdate(result: IPathResponseData) {
		let isAddErrorsToEntity = true;
		if (result.validationData.isProcessed) {
			console.log("afterPathUpdate: Additional Update. Don't re-add errors");
			isAddErrorsToEntity = false;
		}
		result.validationData.isProcessed = true;
		this.isClothoidValid = true;
		this.clothoidLinkEntity = result.link; // used in createAndRenderPath
		const pathObject = this.createAndRenderPath();
		this.clothoidPathObjectId = pathObject.getId();

		// anotherLinkNodeErrors includes the TSD errors of the none-edited link
		// Remove previous TSD errors and add new TSD errors for the connected none-edited link
		this.processNonEditedTSDErrors(result.validationData.anotherLinkNodeErrors);

		// Process and render path errors returned from the server
		const isDisableConfirm = this.validator.processAndRenderClothoidErrors(pathObject, this.clothoidLinkEntity, result.validationData, isAddErrorsToEntity);
		const oldErrorState = Boolean(this.hasError);
		this.hasError = isDisableConfirm;
		// Path/driving zone errror validation and styling
		if (!this.hasError) {
			// only validate driving zone after path validation
			// this is client-side validation
			// The errors here are temp sublinks info
			const drivingZoneErrors = this.validator.validateDrivingZones(pathObject);
			this.hasDrivingZoneError = drivingZoneErrors.length > 0;
		}
		const lookup = this.getLookup();


		const { linksWithDZUpdate } = result;

		// Keep track of linksToRestore incase user cancels operation and DZ of all affected links
		// need to be reset to original state. Processing occurs in linksToRestore
		if (!!linksWithDZUpdate && linksWithDZUpdate.length > 0) {
			if (!this.linksToRestore) {
				this.linksToRestore = []; //
			}
			linksWithDZUpdate.forEach(linkUpdate => {
				// only add additional links
				if (!this.linksToRestore?.find(linkRestore => linkRestore.id === linkUpdate.id)) {
					let linkToRestore = LinkOperationsHelper.copyLink(lookup.getLinkById(linkUpdate.id));
					// restore without connectivity to new link
					linkToRestore.linkTos = linkToRestore.linkTos.filter(x => x.linkToId !== this.clothoidLinkEntity.id);
					linkToRestore.linkFroms = linkToRestore.linkFroms.filter(x => x.linkFromId !== this.clothoidLinkEntity.id);
					this.linksToRestore?.push(linkToRestore);
				}
			});
		}

		PathToolHelper.reRenderTempDrivingZones(this.getLookup(), result.linksWithDZUpdate);
		// if there are errors disable the confirm button
		const updateGlobalConfirm = oldErrorState !== this.hasError;
		if (updateGlobalConfirm) {
			this.getEventHandler().emit('toggleConfirmCreation', true, this.hasError);
		}
	}

	getNonEditedConnectedLinkTSDErrors(isDraggingStartNode: boolean) {
		if (!this.originalLinkId) {
			console.log('Creating a new link.');
			return;
		}

		const link = this.getLookup().getEntity(this.originalLinkId, LinkEntity);
		if (!link) {
			console.log('link is not found.');
			return;
		}

		// Record the node errors of non-edited link while editing an existing link, in case canceling edition
		// Used for unsnapping the link -> pressing escape key
		if (isDraggingStartNode) {
			link.linkFroms.forEach(lf => {
				const linkFrom = this.getLookup().getEntity(lf.linkFromId, LinkEntity);
				linkFrom.lastSublink()?.nodess.forEach(n => {
					if(n.getErrorCount() > 0 && n.mapObjectErrorss.some(e => e.errorMessage === 'NodeTurnbackPathStraightDistanceError')) {
						this.nodeErrorsToAddBack.push({
							errors: ['NodeTurnbackPathStraightDistanceError'],
							nodeId: n.id,
						});
					}
				});
			});
		} else {
			link.linkTos.forEach(lt => {
				const linkTo = this.getLookup().getEntity(lt.linkToId, LinkEntity);
				linkTo.firstSublink()?.nodess.forEach(n => {
					if(n.getErrorCount() > 0 && n.mapObjectErrorss.some(e => e.errorMessage === 'NodeTurnbackPathStraightDistanceError')) {
						this.nodeErrorsToAddBack.push({
							errors: ['NodeTurnbackPathStraightDistanceError'],
							nodeId: n.id,
						});
					}
				});
			});
		}
	}

	/**
	 * Update the mapObjectErrorss and the style of nodes of a none-edited link before confirming the edited link.
	 * Do not update lookup.
	 * @param anotherLinkNodeErrors
	 */
	processNonEditedTSDErrors(anotherLinkNodeErrors: AnotherLinkNodeErrorResult[]) {
		// Remove previous TSD errors
		this.removeNodeErrorWithoutUpdateLookup(this.nodeErrorsToRemove);
		// Add new TSD errors
		this.addNodeErrorWithoutUpdateLookup(anotherLinkNodeErrors);

		this.nodeErrorsToRemove = anotherLinkNodeErrors;
	}

	/**
	 * It is used for temporarily removing TSD errors from mapObjectErrorss and updating node mapObject style in the non-edited link
	 * (no updating lookup because it also affects whole map error counts and triangle icon colour in the layers panel).
	 * Lookup will be updated while running full map validation after the edited link has been confirmed.
	 * @param nodeErrorsToRemove
	 */
	removeNodeErrorWithoutUpdateLookup(nodeErrorsToRemove: AnotherLinkNodeErrorResult[]) {
		const lookup = this.getLookup();
		// Remove TSD errors
		nodeErrorsToRemove.forEach(nodeErrors => {
			const nodeEntity = lookup.getEntity(nodeErrors.nodeId, NodeEntity);
			nodeErrors.errors.forEach(errorMsg => {
				nodeEntity.removeError(errorMsg);
			});
			const hasError = nodeEntity.getErrorCount() > 0;
			const nodeMapObject = lookup.getMapObjectByEntity(nodeEntity, 'node') as NodeGraphic;
			if (!!nodeMapObject) {
				this.validator.setMapObjectError(nodeMapObject, false, hasError);
			}
		});
	}

	/**
	 * It is used for temporarily adding TSD errors to mapObjectErrorss and updating node mapObject style in the non-edited link
	 * (no updating lookup because it also affects whole map error counts and triangle icon colour in the layers panel).
	 * Lookup will be updated while running full map validation after the edited link has been confirmed.
	 * @param anotherLinkNodeErrors
	 */
	addNodeErrorWithoutUpdateLookup(anotherLinkNodeErrors: AnotherLinkNodeErrorResult[]) {
		const lookup = this.getLookup();
		// Add TSD errors
		anotherLinkNodeErrors.forEach(nodeErrors => {
			const nodeEntity = lookup.getEntity(nodeErrors.nodeId, NodeEntity);
			nodeErrors.errors.forEach(errorFlag => {
				this.validator.addNodeError(errorFlag, nodeEntity);
			});
			const hasError = nodeEntity.getErrorCount() > 0;
			const nodeMapObject = lookup.getMapObjectByEntity(nodeEntity, 'node') as NodeGraphic;
			if (!!nodeMapObject) {
				this.validator.setMapObjectError(nodeMapObject, false, hasError);
			}
		});
	}

	/**
	 * Handle updates from properties panel (invoked from from mapEventHandler)
	 * @param requestUpdateParams 
	 */
	async onRequestUpdate(requestUpdateParams?: IClothoidRequestUpdate) {
		console.log('onRequestUpdate');
		// Unsnap start or end waypoint if updating from the properties panel
		if (requestUpdateParams?.entity) {
			const _entity = requestUpdateParams.entity as NodeEntity;
			if (_entity.id === this.startWaypoint.id) {
				this.trc(`Update start waypoint from the properties panel`);
				if (this.isStartWaypointSnapped || this.targetEndLinkIdNum !== undefined) {
					// The above condition is not strictly necessary but helps for debugging
					this.trc('Unsnap startWaypoint');
					this.isStartWaypointSnapped = false;
					this.targetEndLinkIdNum = undefined;
					this.restoreLinks();
					// TODO: REVERT CONNECTIVITY
					// this.linksToRestore
				}
			} else if (_entity.id === this.endWaypoint.id) {
				this.trc(`Update end waypoint from the properties panel`);
				if (this.isEndWaypointSnapped || this.targetStartLinkIdNum !== undefined) {
					// The above condition is not strictly necessary but helps for debugging
					this.trc('Unsnap endWaypoint');
					this.isEndWaypointSnapped = false;
					this.targetStartLinkIdNum = undefined;
					this.restoreLinks();
					// TODO: REVERT CONNECTIVITY
					// this.linksToRestore
				}
			}
		}

		// remove old path
		// the isRemoved code is for instances where there's an update via properties panel
		// while in draw / waypoint creation mode. The waypoint is removed to facilitate the path request
		// and then re-added for continued editing.
		// TODO: there should be a cleaner way to handle this (e.g. at request time)
		if (this.endWaypoint.task === 'HAULING' || this.endWaypoint.task === 'PARKING') {
			const nodes = PathToolHelper.getAllNodesOfLink(this.clothoidLinkEntity).reverse();
			const index = nodes.findIndex(x => !!x.speed);
			//console.log(`onRequestUpdate: index = ${index}`);
			if (index > -1) {
				const node = nodes[index];
				//console.log(`onRequestUpdate: Speed = ${node.speed}`);
				const prevDirection = this.endWaypoint.direction; 
				const newDirection = node.speed > 0 ? 'forward' : 'reverse';
				if (prevDirection !== newDirection) {
					console.log(`!!!Direction changed to ${newDirection}`);
				}
				// TODO: investigate if speed needs to be updated
				this.updateEndwaypointDirection(newDirection);
			}
			//console.log(`onRequestUpdate: reset currentSegmentDirection`);
			this.currentSegmentDirection = undefined;
		}
	}

	async onClick(event: LeafletMouseEvent) {} // Used in DRAW mode only
	async onDoubleClick(event: LeafletMouseEvent) {} // Used in EDIT mode only / e2

	onMove(event: LeafletMouseEvent) {
	}

	async onEscapePressed(event: KeyboardEvent) {}
	async onDragStart(event: LeafletMouseEvent, originalCoordinates: LeafletCoordinates): Promise<void> {}
	async onDragEnd(event: LeafletMouseEvent): Promise<void> {}
	public onDragMove(event: LeafletMouseEvent): void | Promise<void> {}

	// start  util
	protected verifyAndUpdateTool(expectedTool: ToolbarEvent) {
		PathToolHelper.verifyAndUpdateTool(this.getController(), expectedTool);
	}

	protected checkHoverState(event: LeafletMouseEvent, nodeEntity: NodeEntity) {
		return PathToolHelper.checkHoverState(this.getRenderer(), event, nodeEntity);
	}

	/**
	 *
	 * @returns true if hovering over start waypoint
	 */
	protected whichWaypointHover(): NodeEntity | undefined {
		if (this.hover === HoverState.DEFAULT) {
			return undefined;
		}
		return this.hoverWaypoint;
	}

	protected isForwardDirection(direction: string | LinkSegmentDirection) { return PathToolHelper.isForwardDirection(direction); }

	/**
	 * Create a path along waypoint heading at a distance equal to the distance between waypoints
	 * This is used for the straight line path
	 * @param waypoint 
	 * @param isStartWaypoint
	 * @param waypointOther
	 */
	pathAlongHeading(waypoint: NodeEntity, isStartWaypoint: boolean, waypointOther: NodeEntity) { // TODO: this method needs refactor and clarification
		const distance = calcDistanceBetweenNodes(this.startWaypoint, this.endWaypoint);
		const alongLine = offsetAlongHeadingRealWorld(distance, waypointOther.heading);
		let sign = isStartWaypoint ? -1 : 1;
		const isReverse = !this.isForwardDirection(this.endWaypoint.direction);
		if (isReverse) {
			sign = -sign;
		}
		runInAction(() => {
			waypoint.northing = waypointOther.northing + (sign * alongLine.northing);
			waypoint.easting = waypointOther.easting + (sign * alongLine.easting);
			waypoint.heading = waypointOther.heading;
		});
	}

	// multiwaypoint method (TODO: combine as one method with pathAlongHeading)
	pathAlongHeadingMulti(waypointA: NodeEntity, waypointB: NodeEntity, heading: number, isForward: boolean = true) { // TODO: this method needs refactor and calrification
		
		const direction = this.getDirectionFromWaypoints().pop();

		console.log(`pathAlongHeadingMulti: using isForward ${isForward} but direction is ${direction}`);
		const distance = calcDistanceBetweenNodes(waypointA, waypointB);
		const alongLine = offsetAlongHeadingRealWorld(distance, heading);
		const plusOrMinus = isForward ? 1 : -1;
		runInAction(() => {
			waypointB.northing = waypointA.northing + (plusOrMinus * alongLine.northing);
			waypointB.easting = waypointA.easting + (plusOrMinus * alongLine.easting);
			waypointB.heading = waypointA.heading;
		});
	}
	// end util

	dispose() {
		this.autorunDisposer();
		this.reactionDisposer();

		// clear errors from properties panel
		this.getEventHandler().emit('onClearPropertiesPanelErrors');

		this.getController().setDefaultCursor();

		// HIT-680 - make sure confirm is not shown when switching tools
		this.getEventHandler().emit('toggleConfirmCreation', false);
	}

	/**
	 * Creates new link and initialises properties with it
	 * @param startWaypoint
	 * @param endWaypoint
	 * @param useExistingTaskAndDirection
	 * @param setReadOnlyTaskAndDirection
	 */
	protected createNewLinkAndResetProperties
	(
		startWaypoint?: NodeEntity,
		endWaypoint?: NodeEntity,
		useExistingTaskAndDirection?: boolean,
		setReadOnlyDirection?: boolean,
		setReadOnlyTask?: boolean,
		useExistingWaypoints?: boolean,
		directionToDisplay?: string,
	) {

		// console.log(`***createNewLinkAndResetProperties***`);
		if (useExistingWaypoints !== true) {
			this.waypoints = [
				{
					node: startWaypoint ?? new NodeEntity(),
				},
				{
					node: endWaypoint ?? new NodeEntity(),
				},
			];
	
			this.waypoints.forEach(waypoint => {
				waypoint.node.id = waypoint.node._clientId;
			});
		}

		// Set defaults
		if (useExistingTaskAndDirection !== true) {
			// TODO: check this. for removed code, HIT-390
			this.trc(`resetting direction and task`);
			this.endWaypoint.direction = 'forward';
			this.endWaypoint.task = 'HAULING';
			// this.startWaypoint.direction = 'forward';
			this.startWaypoint.task = 'HAULING'; // TODO: this is correct, but not sure if it causes other issues
		}

		if (!this.clothoidLinkEntity) {
			this.clothoidLinkEntity = new LinkEntity({
				state: 'NEW_OBJECT',
				isDefaultSpeed: false,
				sublinkss: [new SublinkEntity({
					nodess: [
						this.startWaypoint,
						this.endWaypoint,
					],
				})],
			});
		}

		// inspired by refreshLinkProperties
		this.resetClothoidProperties(!!setReadOnlyDirection, !!setReadOnlyTask, directionToDisplay)
	}

	protected resetClothoidProperties(
		setReadOnlyDirection: boolean,
		setReadOnlyTask: boolean,
		directionToDisplay?: string,
		setReadOnlyHCMPGToggleButton: boolean = false,
	) {
		// Inspired by method used in refreshLinkProperties
		// Required because task combobox isn't resetting correctly
		const clothoidParams: IClothoidParams = {
			isDirectionReadOnly: setReadOnlyDirection,
			isTaskReadOnly: setReadOnlyTask,
			direction: directionToDisplay,
			isHCMPGButtonReadonly: setReadOnlyHCMPGToggleButton,
		};
		console.log(`***resetClothoidProperties*** setReadOnlyDirection ${setReadOnlyDirection}`);
		this.getEventHandler().emit('onPropertiesPanel', 'map');
		setTimeout(() => {
			// 
			this.getEventHandler().emit('onPropertiesPanel', 'clothoid', this.clothoidLinkEntity, {
				clothoidParams: clothoidParams,
			});
		}, 1);
	}

	/**
	 * Restore waypoint to last good position (used when error occurs during path gen)
	 * 
	 * @param originalEndwaypoint last successful waypoint location / heading 
	 * @param targetWaypoint waypoint to be restored
	 */
	@action
	restoreWaypoint(originalEndwaypoint: NodeEntity, targetWaypoint: NodeEntity) {
		console.log(`${targetWaypoint.northing.toFixed(2)} to ${originalEndwaypoint.northing.toFixed(2)} | ${targetWaypoint.easting.toFixed(2)} to ${originalEndwaypoint.easting.toFixed(2)}`);
		targetWaypoint.northing = originalEndwaypoint.northing;
		targetWaypoint.easting = originalEndwaypoint.easting;
		console.log(`Setting heading from ${targetWaypoint.heading} to ${originalEndwaypoint.heading}`);
		targetWaypoint.heading = originalEndwaypoint.heading;
		targetWaypoint.task = originalEndwaypoint.task;
		targetWaypoint.direction = originalEndwaypoint.direction ?? targetWaypoint.direction;
		const lastValidationData = this.requestHandler.getLastValidationData();
		if (!!lastValidationData) {
			// the purpose of this is to re-display error information upon restore (HITmaT-1009)
			const result: IPathResponseData = {
				link: this.clothoidLinkEntity,
				validationData:  lastValidationData,
				linksWithDZUpdate: lastValidationData.linksWithDZUpdate,
			}
			console.log('restoreWaypoint: restore error information');
			this.afterPathUpdate(result);
		} else {
			console.log('restoreWaypoint: no error info. basic render.');
			this.createAndRenderPath();
		}
	}

	/**
	 * Remove whatever path has been drawn
	 */
	protected removeCurrentPathObj() {
		if (this.clothoidPathObjectId) {
			const renderer = this.getRenderer();
			renderer.removeObject(this.clothoidPathObjectId);
			renderer.rerender();
			this.clothoidPathObjectId = undefined;
			console.log(`Removed path obj ${this.clothoidPathObjectId}`);
		} else {
			console.log('Path already removed');
		}
	}

	protected isWaypointEqual(node1: NodeEntity, node2: NodeEntity, heading: number) {
		return (node1.northing.toFixed(2) === node2.northing.toFixed(2)
			&& node1.easting.toFixed(2) === node2.easting.toFixed(2)
			&& node1.heading.toFixed(2) === heading.toFixed(2))
	}

	// helper method to run createConnectivity over multiple links
	// Takes the original connectivity from sourceLink, and re-adds the connectivities to destLink
	// targetStartLinkIdNum means isLinkFroms = false
	@action
	protected createMultipleConnectivityNoRecalc(sourceLink: LinkEntity, destLink: LinkEntity, isLinkFroms: boolean, isRecalc: boolean = true) {
		const result: LinkFromLinkTo[] = [];
		const connectivity: LinkFromLinkTo[] = isLinkFroms ? sourceLink.linkFroms : sourceLink.linkTos;
		connectivity.forEach(c => {
			const link1 = isLinkFroms ? destLink : this.getLookup().getEntity(c.linkToId, LinkEntity);
			const link2 = isLinkFroms ? this.getLookup().getEntity(c.linkFromId, LinkEntity) : destLink;
			if (!!link1 && !!link2) {
				console.log(`CSH.confirmClothoidPath (createMultipleConnectivity): calling createConnectivity`);
				// debugger;
				const lflt = LinkConnectivityEditHelper.createConnectivityNoRecalc(this.getEventHandler(), link1, link2, true);
				if (!!lflt) {
					result.push(lflt);
				}
			}
		});
		return result;
	}


	/**
	 * Render properties side panel for the clothoid path
	 */
	protected confirmClothoidPath = action(async () => {
		if (!this.getController().isActionConfirmAllowed()) {
			return undefined;
		}

		if (this.clothoidPathObjectId === undefined) {
			return undefined;
		}
		// debugger;
		// Assign and validate the ids to link, sublinks and nodes
		if (!this.pathIdHelper.validateAndAssignIdsForPath(this.clothoidLinkEntity, this.originalLinkId)) {
			return undefined;
		}

		const renderer = this.getRenderer();

		const mapObject = renderer.getObjectById(this.clothoidPathObjectId);

		if (!mapObject) {
			// should never each here
			this.trc('corresponding map object not found');
			return undefined;
		}

		const newLink = mapObject.getEntity() as LinkEntity;
		this.trc(`${newLink.linkId}`);
		// Add to lookup here otherwise drivig zone generation will fail
		// this.getLookup().addLinkLookup(newLink);
		const lookup = this.getLookup();

		this.trc(`Removing path with id ${this.clothoidPathObjectId} and clear clothoidPathObjectId`);
		// Remove draft object
		renderer.removeObject(this.clothoidPathObjectId);
		this.clothoidPathObjectId = undefined;

		// forceBuild is needed otherwise path will not be built correctly when lookup is passed as param
		// Added to lookup when path is build (this is confusing and should be changed)

		// Creation of confirmed path, waypointIds not required as midwaypoints revert to normal nodes
		const opts: IPathOptions = {
			isSelected: false,
			forceBuild: true,
			allLookup: true,
		};
		const path = new Path(newLink, renderer, lookup, opts);
		this.trc(`Confirmed pathObjId ${path.getId()}.`);
		const confirmed = renderer.addObject(path);
		// new pathId is NOT assigned to clothoidPathObjectId
		PathToolHelper.renderDrivingZones(path, renderer);
		renderer.rerender();
		newLink.importVersionId = this.getController().getImportVersion().id;

		// TODO: relook at when/how drivingzone recalc is done

		let affectedLinks: LinkEntity[] = [];

		const originalLink: LinkEntity | undefined = this.getLookup().getEntity(this.originalLinkId ?? '', LinkEntity);
		if (!!originalLink?.segmentId) {
			newLink.segmentId = originalLink.segmentId;
		}
		
		if (!!originalLink && originalLink.signalSetss.length > 0) {
			alertToast(`Turn signal information deleted following the edits to link ${originalLink.linkId}`, 'info');
		}

		if (this.targetEndLinkIdNum) {
			// End waypoint of targetEndLinkIdNum is connected to Start waypoint of new link
			// endLink linkTo -> newLink linkFrom
			const endLink = lookup.getLinkByIdNumber(this.targetEndLinkIdNum);
			if (endLink) {
				const connectivity = LinkConnectivityEditHelper.createConnectivityNoRecalc(this.getEventHandler(), newLink, endLink, true);
				if (!!connectivity) {
					affectedLinks.push(endLink);
					console.log(`affected link (addedLink -> currentLink): ${endLink.id} ${endLink.linkId}`);
				}
			}
		} else {
			if (!!originalLink) {
				// Check if startWaypoint of new link has been changed, and if not re-add connectivity
				// Start waypoint of edit link may have connectivity. If waypoint unchanged add connectivity
				// endLink linkTo -> newLink linkFrom
				if (this.isStartWaypointUnmodified(originalLink)) {
					this.trc('re-adding FROM connectivity from originalLink');
					const connectivities = this.createMultipleConnectivityNoRecalc(originalLink, newLink, true);
					connectivities.forEach(c => {
						const { linkFrom } = c;
						if (!!linkFrom && affectedLinks.every(x => x.id !== linkFrom.id)) {
							affectedLinks.push(linkFrom);
							console.log(`affected link (original FROM) ${linkFrom.id} ${linkFrom.linkId}`);
						}
					});
				} else {
					// handle unsnap
					originalLink.linkFroms.forEach(x => {
						if(newLink.linkFroms.every(y => y.linkFromId !== x.linkFromId)) {
							const toAdd = lookup.getEntity(x.linkFromId, LinkEntity);
							// console.log(`originalLink.id ${originalLink.id} ${toAdd.linkTos[0].linkFromId}`)
							toAdd.linkTos = toAdd.linkTos.filter(q => q.linkToId !== originalLink.id);
							// console.log(toAdd);
							affectedLinks.push(toAdd);
						}
					});

					affectedLinks.push(lookup.getEntity(originalLink.id, LinkEntity));
				}
			}
		}

		if (this.targetStartLinkIdNum) {
			// End waypoint of new link is connected to Start waypoint of targetStartLinkIdNum
			// newLink linkTo -> startLink linkFrom
			const startLink = lookup.getLinkByIdNumber(this.targetStartLinkIdNum);
			if (startLink) {
				const connectivity = LinkConnectivityEditHelper.createConnectivityNoRecalc(this.getEventHandler(), startLink, newLink, true);
				if (!!connectivity) {
					affectedLinks.push(startLink);
					console.log(`affected link (addedLink -> currentLink): ${startLink.id} ${startLink.linkId}`);
				}
			}
		} else {
			if (!!originalLink) {
				// Check if endWaypoint of target link has been changed, and if not re-add connectivity
				if (this.isEndWaypointUnmodified(originalLink)) {
					const connectivities = this.createMultipleConnectivityNoRecalc(originalLink, newLink, false);
					connectivities.forEach(c => {
						const { linkTo } = c;
						if (!!linkTo && affectedLinks.every(x => x.id !== linkTo.id)) {
							affectedLinks.push(linkTo);
							console.log(`affected link (original TO) ${linkTo.id} ${linkTo.linkId}`);
						}
					});
				} else {
					// handle unsnap
					originalLink.linkTos.forEach(x => {
						if(newLink.linkTos.every(y => y.linkToId !== x.linkToId)) {
							const toAdd = lookup.getEntity(x.linkToId, LinkEntity);
							// console.log(`originalLink.id ${originalLink.id} ${toAdd.linkFroms[0].linkFromId}`)
							toAdd.linkFroms = toAdd.linkFroms.filter(q => q.linkFromId !== originalLink.id);
							// console.log(toAdd);
							affectedLinks.push(toAdd);
						}
					});

					affectedLinks.push(lookup.getEntity(originalLink.id, LinkEntity));
				}
			}
		}

		// TODO: this is probably wrong
		if (affectedLinks.length > 0) {
			console.log("Doing recalc");
			affectedLinks.push(newLink);
	 		await PathToolHelper.calculateDrivingZone(lookup, newLink.importVersionId, affectedLinks, undefined, 'CSH.confirmClothoidPath', undefined, false);
		}
		// }

		this.getController().setDefaultCursor();

		return newLink;
		
	});

	// returns true if endWaypoint location/heading is unchanged 
	protected isEndWaypointUnmodified(originalLink: LinkEntity) {
		const lookup = this.getLookup();
		const originalEndWaypoint = originalLink.lastNode()!;
		let originalHeading = PathToolHelper.calcEndWaypointHeading(originalLink, lookup) ?? 0;
		let isReverse = false;
		if (originalEndWaypoint.task === 'HAULING') {
			isReverse = !this.isForwardDirection(this.getDirectionFromSpeed(originalEndWaypoint.speed));
		} else {
			const prevNode = this.findPreviousNode(originalEndWaypoint);
			isReverse = !this.isForwardDirection(this.getDirectionFromSpeed(prevNode?.speed));
		}
		if (isReverse) {
			const _originalHeading = this.calcReverseHeading(originalHeading);
			this.trc(`Before isWaypointEqual, updating original heading from ${_originalHeading} to ${originalHeading}`);
			originalHeading = _originalHeading;
		}
		if (this.isWaypointEqual(this.endWaypoint, originalEndWaypoint, originalHeading)) {
			this.trc('re-adding TO connectivity from originalLink');
			return true;
		} else {
			this.trc(`endWaypoint changed, skipping re-adding TO connectivity from originalLink`);
			this.trc(`New northing/heading: ${this.endWaypoint.northing.toFixed(2)}/${this.endWaypoint.heading.toFixed(2)} Original northing/heading ${originalEndWaypoint.northing.toFixed(2)}/${originalHeading.toFixed(2)}`);
		}
		return false;
	}

	// returns true if endWaypoint location/heading is unchanged
	protected isStartWaypointUnmodified(originalLink: LinkEntity) {
		const lookup = this.getLookup();
		const originalStartWaypoint = originalLink.firstNode()!;
		let originalHeading = PathToolHelper.calcStartWaypointHeading(originalLink, lookup) ?? 0;
		const isReverse = !this.isForwardDirection(this.getDirectionFromSpeed(originalStartWaypoint.speed));
		if (isReverse) {
			const _originalHeading = this.calcReverseHeading(originalHeading);
			this.trc(`Before isWaypointEqual, updating original heading from ${_originalHeading} to ${originalHeading}`);
			originalHeading = _originalHeading;
		}
		if (this.isWaypointEqual(this.startWaypoint, originalStartWaypoint, originalHeading)) {
			this.trc('re-adding FROM connectivity from originalLink');
			return true;
		} else {
			this.trc(`startWaypoint changed, skipping re-adding FROM connectivity from originalLink`);
			this.trc(`New northing/heading: ${this.startWaypoint.northing.toFixed(2)}/${this.startWaypoint.heading.toFixed(2)} Orginal northing/heading ${originalStartWaypoint.northing.toFixed(2)}/${originalHeading.toFixed(2)}`);
		}
		return false;
	}

	/**
	 * 
	 * @returns direction of each segment
	 */
	protected getDirectionFromWaypoints(): LinkSegmentDirection[] {
		// TODO: simplify logic
		const directions: LinkSegmentDirection[] = [];

		// isOneDirection param is needed when multiwaypoint path is created
		// without reverse midpoint. Hence only one direction should be used
		const isOneDirection = this.waypoints.every(wp => wp.isReverse !== true);
		if (isOneDirection) {
			// TODO: This draw mode thing needs to support just using the isReverse
			// from the waypoint to enumerate direction
			// In draw mode only ONE direction is possible, and that is defined
			// by endWaypoint.direction (as set in properties panel)
			if (!!this.currentSegmentDirection) {
				// console.log(`getDirectionFromWaypoints: using currentSegmentDirection = ${this.currentSegmentDirection}`);
				directions.push(this.currentSegmentDirection);
			} else if (!!this.endWaypoint.direction) {
				// console.log(`getDirectionFromWaypoints: using endWaypoint.direction = ${this.endWaypoint.direction}`);
				const nodes = PathToolHelper.getAllNodesOfLink(this.clothoidLinkEntity).reverse();
				const index = nodes.findIndex(x => !!x.speed);
				if (index > -1) {
					const node = nodes[index];
					const direction = node.speed > 0 ? 'forward' : 'reverse';
					directions.push(direction as LinkSegmentDirection);
				} else {
					// Only two nodes. Not yet call sendRequest
					console.log("Placing two waypoints first time will come here.");
					directions.push(this.endWaypoint.direction as LinkSegmentDirection);
				}
			} else {
				// console.log(`currentSegmentDirection/endWaypoint.direction undefined. Assume forward`);
				directions.push('forward');
			}
		} else {
			const toDelete = (this.isDrawMode || this.lastWaypoint) ? -2 : -1;
			this.waypoints.slice(0, toDelete).forEach((wp, index) => {
				const sourceNode = wp.node;
				const destNode = this.waypoints[index + 1].node;
				if (sourceNode.task === 'HAULING') {
					directions.push(this.getDirectionFromSpeed(sourceNode.speed));
				} else if (destNode.task === 'HAULING') {
					directions.push(this.getDirectionFromSpeed(destNode.speed));
				} else if (sourceNode.task === 'REVERSEPOINT' && destNode.task === 'REVERSEPOINT') {
					const destPrevNode = this.findPreviousNode(destNode);
					if (!!destPrevNode) {
						directions.push(this.getDirectionFromSpeed(destPrevNode?.speed));
					} else {
						// this is in draw mode only
						//console.log("Set to opposite direction of previous segment");
						const lastDirection = directions[directions.length - 1] === 'forward' ? 'reverse' : 'forward';
						directions.push(lastDirection);
					}
				} else {
					this.trc(`Ambiguous case. Using prevnode speed value`, 'warn');
					const destPrevNode = this.findPreviousNode(destNode);
					directions.push(this.getDirectionFromSpeed(destPrevNode?.speed));
				}
			});
			if (this.isDrawMode || this.lastWaypoint) {
				let lastDirection = directions[directions.length - 1];
				if (this.currentStartWaypoint.task === 'REVERSEPOINT') {
					lastDirection = lastDirection === 'forward' ? 'reverse' : 'forward';
					//console.log(`Setting last direction as ${lastDirection} due to reverse point`);
				}
				//console.log(`Setting last direction as ${lastDirection} due to NO reverse point`);
				directions.push(lastDirection ?? this.endWaypoint.direction);
				this.lastWaypoint = false;
			}
		}
		// console.log(directions.join(','));
		return directions;
	}

	protected findPreviousNode(nodeEntity: NodeEntity) {
		return PathToolHelper.findPreviousNode(this.clothoidLinkEntity, nodeEntity);
	}

	protected printWaypointInfo() {
		const waypointIds = this.waypoints.map(x => x.node.id);
		const n = PathToolHelper.getAllNodesOfLink(this.clothoidLinkEntity);

		// TODO: either set heading here, OR do it on the backend.
		//this.trc('Returned Nodes:', n.filter(node => waypointIds.includes(node.id)).map(x => x.heading));
		// n.filter(node => waypointIds.includes(node.id)).forEach(returnedNode => {
		// 	console.log(`${returnedNode.nodeId} ${returnedNode.id} ${returnedNode.heading} ${returnedNode.task}`);
		// });
	}

	public preDispose() {
		const lookup = this.getLookup();
		console.log(`preDispose: Setting blockRequestId to ${lookup.currentRequestId}`);
		lookup.blockRequestId = lookup.currentRequestId;
	}

	public getLinkEntity() {
		return this.clothoidLinkEntity;
	}

	protected getMidWaypointCountOnMap(): IMidWaypointCount {
		const midWaypoints = this.waypoints.slice(1, -1);
		return {
			haulingMidWaypoints: midWaypoints.filter(x => x.node.task === 'HAULING').length,
			reverseMidWaypoints: midWaypoints.filter(x => x.node.task === 'REVERSEPOINT').length,
			parkingMidWaypoints: midWaypoints.filter(x => x.node.task === 'PARKING').length,
		};
	}

	/*
	* Set the updated values to clothoid path being generated
	* Set the direction of the end way point similar to the
	* start way point at ths stage to reflect the changes on the
	* properties panel
	* Set the direction of the end waypoint as the direction of the first waypoint
	* only if the first waypoint is snapped
	*/
	@action
	updateEndwaypointDirection(direction: string) {
		if (direction !== 'forward' && direction !== 'reverse') {
			console.error(`invalid direction ${direction}`);
			return;
		}
		this.endWaypoint.direction = direction;
		// console.log(`updateEndwaypointDirection: emit direction change to ${direction}`);
		this.getEventHandler().emit('onDirectionChange', direction);
	}

	@action
	protected snapStartwaypoint(coords: RealWorldCoordinates): ISnapParams | undefined {
		this.trc('Begin');
		// First step is to reset key variables as they hold implicit state. Only set on success.
		// TODO: set these two params in stand-alone method
		this.targetEndLinkIdNum = undefined;
		this.isStartWaypointSnapped = false;

		const snapResult = this.snapWaypointHelper.snapStartwaypointOperation(coords,
			this.endWaypoint, // TODO: this may be wrong with multiwaypoint
			this.targetStartLinkIdNum,
			this.getDirectionFromWaypoints(),
			this.isDrawMode);
			
		const {
			targetEndLinkIdNum, isOppositeDirections, direction, errorToastMessage, isSuccess, isCrusherDumpingToHauling, internalError
		} = snapResult;

		if (isSuccess) {
			if (isOppositeDirections) {
				// End waypoint was not snapped but had different direction to start waypoint
				// In this case, set the endwaypoint direction to match start waypoint
				if (!!direction) {
					// End waypoint was not snapped but had different direction to start waypoint
					// In this case, set the endwaypoint direction to match start waypoint
					if (isCrusherDumpingToHauling === true) {
						// this means calculating direction from speed will now be unreliable
						// to fix this, ensure speed sign values are correct on segment
						this.trc(`isCrusherDumpingToHauling true. Performing special operations.`);
						this.endWaypoint.task = 'HAULING';
						this.endWaypoint.heading = this.calcReverseHeading(this.endWaypoint.heading);
						this.endWaypoint.speed = 60;
						this.startWaypoint.speed = 60;
					}
					this.updateEndwaypointDirection(direction);
				} else {
					// should never reach here
					this.trc('Direction has changed but is not set!');
				}
			}
 			if (!!snapResult.params) {
				if (!direction) {
					this.trc('Direction isn\'t set!');
					return undefined;
				}
				const { snapCoords, snapHeading } = snapResult.params;
				const { northing, easting } = snapCoords;
				this.startWaypoint.northing = northing;
				this.startWaypoint.easting = easting;
				this.startWaypoint.task = 'HAULING';
				this.startWaypoint.heading = snapHeading;
				this.startWaypoint.direction = direction; // This should result in endWaypoint.direction also being updated
				this.targetEndLinkIdNum = targetEndLinkIdNum;
				this.isStartWaypointSnapped = true;
				return snapResult.params;
			}
		} else {

			if (!!errorToastMessage) {
				alertToast(errorToastMessage, 'error');
			} else {
				const reason = !!internalError ? internalError : 'unknown';
				this.trc(`Snap failed (no toast msg). Reason: ${reason}`);
			}
		}
		return undefined;
	}

	@action
	protected snapEndwaypoint(coords: RealWorldCoordinates): ISnapParams | undefined {
		const hasMidwaypoints = this.waypoints.length > 2;

		// First step is to reset key variables as they hold implicit state. Only set on success.
		// TODO: set these two params in stand-alone method
		this.targetStartLinkIdNum = undefined;
		this.isEndWaypointSnapped = false;
		
		const snapResult = this.snapWaypointHelper.snapEndwaypointOperation(coords,
			this.startWaypoint,
			this.endWaypoint,
			this.targetEndLinkIdNum,
			this.getDirectionFromWaypoints(),
			hasMidwaypoints,
			this.isDrawMode,
			);
		const { targetStartLinkIdNum, isOppositeDirections, direction, errorToastMessage, isSuccess, internalError } = snapResult;
		if (targetStartLinkIdNum !== undefined) {
			// Updated on both success and failure (if set)
			 // TODO: check this logic
		}
		if (isSuccess) {
			if (!!snapResult.params) {
				const { snapCoords, snapHeading, isSnapToBay } = snapResult.params;
				const { northing, easting } = snapCoords;
				if (!isSnapToBay) {
					this.targetStartLinkIdNum = targetStartLinkIdNum;
					this.isEndWaypointSnapped = true;
				}
				this.endWaypoint.northing = northing;
				this.endWaypoint.easting = easting;
				this.endWaypoint.heading = snapHeading;
				return snapResult.params;
			} else {
				this.trc('Unexpected result. Snap success flag set but params undefined');
			}
		} else {
			// isOppositeDirections is always an error case
			if (isOppositeDirections !== undefined) {
				if (!isOppositeDirections) {
					this.trc(`Unexpected result. isOppositeDirections false where true is expected (snap result)`);
				}
				this.isOppositeDirections = isOppositeDirections;
				// direction is only set if isOppositeDirections is true
				if (direction !== undefined) {
					// Ignore the snap and show the error toast if the directions of both the waypoints are not equal and the first waypoint is snapped
					// TODO: Check whether the point of this is to revert direction after it had been auto-changed to something invalid
					this.endWaypoint.direction = this.startWaypoint.direction;
					// this.trc(`snapEndwaypoint: emit onDirectionChange`)
					this.getEventHandler().emit('onDirectionChange', direction);
					// TODO: use updateEndWaypointDirection?
				}
			}
			if (!!errorToastMessage) {
				alertToast(errorToastMessage, 'error');
			} else {
				const error = !!internalError ? internalError : 'unknown';
				this.trc(`Snap failed (no toast msg). Reason: ${error}`);
			}
		}
		return undefined;
	}

	protected createAndRenderPath() {
		const newPath = PathToolHelper.createAndRenderPath(this.getRenderer(), this.waypoints, this.clothoidLinkEntity, this.clothoidPathObjectId);
		newPath.getChildren().forEach(child => {
			if (child.getType() === 'node') {
				(child as NodeGraphic).isEditMode = true;
			}
		});
		this.clothoidPathObjectId = newPath.getId();
		return newPath;
	}

	protected calcReverseHeading(heading: number) {
		return (heading + 180) % 360;
	}

	protected nodeGraphicById(wayPoint: NodeEntity): NodeGraphic | undefined {
		let graphic: NodeGraphic | undefined;
		if (this.clothoidPathObjectId) {
			const nodeGraphics = this.getRenderer().getObjectById(this.clothoidPathObjectId).getChildren();
			graphic = nodeGraphics.find(g => g.getEntity().id === wayPoint.id) as NodeGraphic;
		}
		return graphic;
	}

	@action
	updateClothoidState(isModifiedLink: boolean, link: LinkEntity) {
		// update the state of the clothoid link, sublinks and nodes to Created
		// If isModifiedLink is true, it means edit mode (modifying an imported link or a newly created link)
		// If isModifiedLink is false, it means draw mode
		if (isModifiedLink) {
			link.state = this.getObjectStateFromImportStatus(link.isImported);
			const sublinks: SublinkEntity[] = link.sublinkss;
			sublinks.forEach(sublink => {
				sublink.state = this.getObjectStateFromImportStatus(sublink.isImported);
				sublink.nodess.forEach(node => {
					node.state = this.getObjectStateFromImportStatus(node.isImported);
				});
			});
		}

		if (!isModifiedLink && link.state === 'IMPORTED' && !link.isImported) {
			// handle IMPORTED - worst case - isImported false meaning not imported we cannot have
			link.state = 'NEW_OBJECT';
			const sublinks: SublinkEntity[] = link.sublinkss;
			sublinks.forEach(sublink => {
				sublink.state = this.getObjectStateFromImportStatus(sublink.isImported);
				sublink.nodess.forEach(node => {
					node.state = this.getObjectStateFromImportStatus(node.isImported);
				});
			});
		}
		// TODO: verify this is no longer needed
		// if (this.getController().getSelectedToolType() === 'selector') {
		// 	this.getEventHandler().setMapEventState('selector');
		// }
		console.log(`updateClothoidState: link state ${isModifiedLink}`);
	}

	updateLinkSublinkState = (link: LinkEntity, linkFromLinkTo: LinkFromLinkTo, confirmedLinkEntityId: string) => {
		if (link.isImported || link.sublinkss.some(s => s.isImported)) {
			const oldLink = LinkOperationsHelper.copyLink(link);
			link.state = this.getObjectStateFromImportStatus(link.isImported);

			let sublink;
			if (linkFromLinkTo.linkFromId === confirmedLinkEntityId) {
				sublink = link.firstSublink();
			} else if (linkFromLinkTo.linkToId === confirmedLinkEntityId) {
				sublink = link.lastSublink();
			}

			if (sublink) {
				sublink.state = this.getObjectStateFromImportStatus(sublink.isImported);
			}
			this.getEventHandler().emit('onLinkStateUpdate', oldLink, link);
		}
	};

	/**
	 * Confirms clothoid path and prepares for drawing of next path
	 */
	onConfirmClothoid = async() => {};

	onActivateHCMPG = (isHCMPGEnabled: boolean) => {
		store.isHCMPGEnabled = isHCMPGEnabled;
	}
	
	/**
	 * Confirms clothoid path and prepares for drawing of next path
	 */
	processConfirmedPath = async(confirmedLinkEntity: LinkEntity) => {
		// prevent restore after confirm
		this.linksToRestore = undefined;

		// Necessary to prevent tracking from being disabled (see onToggleConfirmCreation)
		this.getEventHandler().emit('toggleConfirmCreation', false);

		// Connectivity (if any) added in confirmClothoidPath

		// TODO: verify if this is necessart
		// Do state transition from EDIT -> DRAW
		this.createNewLinkAndResetProperties();
		runInAction(() => {
			// So new path can be created after confirm
			this.isDrawMode = true;
			this.waypointCreation = true;
		});

		this.getEventHandler().emit('setDirectionPropertyReadonly', false);
		// Add newly generated link to the layers panel and create tracker for it
		// TODO: should be handled by createEntity
		const isModifiedLink = !!this.originalLinkId;
		// Update state of entities (important: must happen before onMapObjectUpdate event)
		this.updateClothoidState(isModifiedLink, confirmedLinkEntity);
		if (!!this.originalLinkId) {
			const linkMapObjectId = this.getLookup().getMapObjectId(this.originalLinkId, 'link');
			const pathObject = this.getRenderer().getObjectById(linkMapObjectId) as Link;
			const pathObjectId = pathObject.getId();
			// pathObject?.displayGraphic(true);
			const deleteLinkEntity = this.getLookup().getEntity(this.originalLinkId, LinkEntity);
			if (!!deleteLinkEntity) {
				// Note that EDIT also follows this code path
				// This is removing the created link from the lookup (see removeEntityToMapObject)
				// Do not allow DZ recalc here - it's incorrect
				this.getController().deleteLinkEntityOperations(deleteLinkEntity, pathObject, false);
				// this.getController().deleteEntity(deleteLinkEntity!, true);
				this.getEventHandler().emit('onTrackEditClothoid', deleteLinkEntity, confirmedLinkEntity, linkReferencePath);
				// do we need to update Froms Tos sublinks,links state here also similar to else case?
				this.originalLinkId = undefined;
				this.getEventHandler().emit('onMapObjectUpdate', confirmedLinkEntity);
				if (!!this.originalPathId && (pathObjectId !== this.originalPathId)) {
					this.getRenderer().removeObject(this.originalPathId);
					this.getRenderer().removeObject(pathObjectId);
					this.getRenderer().rerender();
					this.trc(`Removing original path (new): ${this.originalPathId}`);
					this.originalPathId = undefined;
				}
			}
		} else {
			// update db with state for connected affected links, sublinks on either side of sublinks
			const linkFromLinkTos: LinkFromLinkTo[] = [...confirmedLinkEntity.linkFroms, ...confirmedLinkEntity.linkTos];
			// adding connectivity with snap action, modify state for link, sublink.
			linkFromLinkTos.forEach(linkFromLinkTo => {				
				// Fetch link by id and update link, sublink status in case of isImported (nodes, not needed now)
				const originalFromLink: LinkEntity | undefined = this.getLookup().getEntity(linkFromLinkTo.linkFromId, LinkEntity);
				if (originalFromLink) {
					this.updateLinkSublinkState(originalFromLink, linkFromLinkTo, confirmedLinkEntity.id);
				}
				const originalToLink: LinkEntity | undefined = this.getLookup().getEntity(linkFromLinkTo.linkToId, LinkEntity);
				if (originalToLink) {
					this.updateLinkSublinkState(originalToLink, linkFromLinkTo, confirmedLinkEntity.id);
				}
			});

			this.getEventHandler().emit('onTrackCreate', confirmedLinkEntity, linkReferencePath);
			this.getEventHandler().emit('onMapObjectCreateConfirm', confirmedLinkEntity);
		}
		// Re-add to lookup (as it's getting deleted in deleteLinkEntityOperations)
		this.getLookup().addPath(confirmedLinkEntity);

		// onImportVersionStatusChanged event for import version status indicators
		this.getEventHandler().emit('onImportVersionStatusChanged', 'path', true); // TODO: is this necessary???
		this.getEventHandler().emitPathEditedEvent();

		// Check path interference
		const isCheckPathInterference = this.getLookup().isCheckPathInterference;
		if (isCheckPathInterference) {
			this.validator.validatePathsInterference();
		}
	};

	private  getObjectStateFromImportStatus(isImported: boolean) {
		return isImported ? 'MODIFIED' : 'NEW_OBJECT';
	}
}
