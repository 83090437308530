import {
	AreaEntity,
	BayEntity,
	LinkEntity,
	NodeEntity,
	SublinkEntity,
} from '../../../Models/Entities';
import MapStore from '../Map/MapStore';
import { areaLocationTypeOptions, areaTypeOptions } from '../../../Models/Enums';
import { ILayersPanelItem } from './LayersPanelItem';
import { UNDEFINED_BAYS_AREANAME } from 'Constants';

export default function buildAhsMapObjects(
	lookup: MapStore,
): ILayersPanelItem {
	// show area of each type
	// and if they have bay, also show the bay
	const allAreaObjects = categoriseAreasAndBays(
		lookup.getAllEntities(AreaEntity), lookup.getAllEntities(BayEntity), lookup);
	const errorArea = allAreaObjects.some(area => !!area.isError);

	const sortedLinks = lookup.getAllEntities(LinkEntity)
		.slice().sort((a, b) => a.linkId - b.linkId);
	const linkObjectItems = sortedLinks.map(link => convertLinkItem(link));
	const errorLink = linkObjectItems.some(link => !!link.isError);

	const ahsObjectItems: ILayersPanelItem[] = [];

	ahsObjectItems.push(
		{
			name: 'Areas',
			children: allAreaObjects,
			skipSearch: true,
			labelStyling: 'icon-area-new icon-left icon-Areas',
			mapObjectType: 'area',
			isError: errorArea,
			isNotVisibleWhenEmpty: true,
		},
	);

	ahsObjectItems.push(
		{
			name: 'Paths',
			children: linkObjectItems,
			skipSearch: true,
			labelStyling: 'icon-link icon-left',
			mapObjectType: 'link',
			isError: errorLink,
			isNotVisibleWhenEmpty: true,
		},
	);

	return {
		name: 'AHC Map',
		mapObjectType: 'ahs',
		labelStyling: 'bold-label',
		skipSearch: true,
		children: ahsObjectItems,
		isError: errorArea || errorLink,
		isNotVisibleWhenEmpty: true,
	};
}

export const dummyArea: AreaEntity = new AreaEntity({
	id: 'unknown',
	areaType: 'AREAAUTONOMOUS',
	crusherFlag: 0,
	areaName: UNDEFINED_BAYS_AREANAME,
	locType: 'INVALID',
	areaCreator: 'dummycreator',
	perimeterCount: 5,
	areaId: 6435777,
	areaVersion: '20210811081653',
	importVersionId: 'dummy-immport-version-id',
	bayss: undefined,
	polygon: '{"type":"Polygon","coordinates":[[[225132.300977,6616976.468162]]]}',
});

export function categoriseAreasAndBays(areas: AreaEntity[],
	bays: BayEntity[],
	lookup: MapStore | undefined): ILayersPanelItem[] {
	// Area types
	const ahsArea: AreaEntity[] = [];
	const _autonArea: AreaEntity[][] = [];
	const barrierArea: AreaEntity[] = [];
	const lockoutAreas: AreaEntity[] = [];
	const obstableAreas: AreaEntity[] = [];
	const exclusionAreas: AreaEntity[] = [];

	// Area subtypes
	const crusher: AreaEntity[] = [];
	const dumping: AreaEntity[] = [];
	const loading: AreaEntity[] = [];
	const parking: AreaEntity[] = [];
	const stockpile: AreaEntity[] = [];


	areas.forEach(area => {
		const type = area.areaType;
		switch (type) {
			case 'AREAAHS':
				ahsArea.push(area);
				break;
			case 'AREAAUTONOMOUS':
				const subtype = area.locType;
				switch (subtype) {
					case 'CRUSHER':
						crusher.push(area);
						break;
					case 'DUMP':
						dumping.push(area);
						break;
					case 'DIG':
						loading.push(area);
						break;
					case 'PARKING':
						parking.push(area);
						break;
					case 'STOCKPILE':
						stockpile.push(area);
						break;
					case 'INVALID':
						console.log(`Area for ${UNDEFINED_BAYS_AREANAME}. Sould be processed later.`);
						break;
					default:
						console.log(`No style for loctype ${subtype}`);
						break;
				}				
				break;
			case 'AREABARRIER':
				barrierArea.push(area);
				break;
			case 'AREALOCKOUT':
				lockoutAreas.push(area);
				break;
			case 'AREAOBSTACLE':
				obstableAreas.push(area);
				break;
			case 'AREAEXCLUSION':
				exclusionAreas.push(area);
				break;
			default:
				console.log(`No style for areaType ${type}`);
				break;
		}
	});

	_autonArea.push(crusher);
	_autonArea.push(dumping);
	_autonArea.push(loading);
	_autonArea.push(parking);
	_autonArea.push(stockpile);
	const autonArea = _autonArea.filter(s => s.length > 0);

	const _allAreas = [];

	_allAreas.push(ahsArea);
	_allAreas.push(autonArea);
	_allAreas.push(lockoutAreas);
	_allAreas.push(obstableAreas);
	_allAreas.push(barrierArea);
	_allAreas.push(exclusionAreas);

	const allAreas = _allAreas.filter(t => t.length > 0)
		.map(areaType => convertAreaTypeItem(areaType, lookup));

	const unknownBays = bays.filter(x => x.areaId === null || x.areaId === undefined);
	if (unknownBays.length > 0) {
		// If there are unknown bays, display them under a dummy area
		// titled Undefined Bays
		dummyArea.bayss = unknownBays;
		allAreas[1].children?.push(convertAreaItem(dummyArea, lookup));
	}
	return allAreas;
}

/**
 * Generate area type items
 */
function convertAreaTypeItem(areas: AreaEntity[] | AreaEntity[][], lookup: MapStore | undefined): ILayersPanelItem {
	let areaName;
	let areaTypeName;

	// Autonomous area includes subtypes
	if (areas.some(entry => Array.isArray(entry))) {
		const _areas = areas as AreaEntity[][];
		areaName = areaTypeOptions[_areas[0][0].areaType];
		areaTypeName = _areas[0][0].areaType;

	} else {
		const _areas = areas as AreaEntity[];
		areaName = areaTypeOptions[_areas[0].areaType];
		areaTypeName = _areas[0].areaType;
	}

	let iconType = 'icon-area-new';

	switch (areaTypeName) {
		case 'AREABARRIER':
			iconType = 'icon-area-dashed';
			break;
		case 'AREAAHS':
			iconType = 'icon-area-dashed';
			break;
		default:
			break;
	}

	const iconColor = `icon-${areaName}`;
	const labelStyling = `${iconType} icon-left ${iconColor}`;

	let areasObjectItem;
	// Autonomous area includes subtypes
	if (areas.some(entry => Array.isArray(entry))) {
		areasObjectItem = (areas as AreaEntity[][]).map(areaSubtype => convertAreaSubtypeItem(areaSubtype, lookup))
			.sort((a, b) => a.name.localeCompare(b.name));
	} else {
		areasObjectItem = (areas as AreaEntity[]).map(area => convertAreaItem(area, lookup))
			.sort((a, b) => a.name.localeCompare(b.name));
	}

	let error, warning;
	if (!!areasObjectItem) {
		error = areasObjectItem.some(area => !!area.isError);
		warning = areasObjectItem.some(area => !!area.isWarning);
	}

	return {
		name: areaName,
		labelStyling: labelStyling,
		children: areasObjectItem,
		skipSearch: true,
		mapObjectType: 'area',
		isError: error,
		isWarning: warning,
	};
}

/**
 * Generate area subtype items for autonomous area category: crusher, dumping, loading, parking, and stockpile
 */
function convertAreaSubtypeItem(areas: AreaEntity[], lookup: MapStore | undefined): ILayersPanelItem {
	const areaLocationTypeName = areaLocationTypeOptions[areas[0].locType];

	const labelStyling = "";
	const areasObjectItem = areas.map(area => convertAreaItem(area, lookup))
		.sort((a, b) => a.name.replace('[EDIT] ', '').localeCompare(b.name.replace('[EDIT] ', '')));
	const error = areasObjectItem.some(area => !!area.isError);
	const warning = areasObjectItem.some(area => !!area.isWarning);

	return {
		name: areaLocationTypeName,
		labelStyling: labelStyling,
		children: areasObjectItem,
		skipSearch: true,
		mapObjectType: 'area',
		isError: error,
		isWarning: warning,
	};
}

function getErrorFlag (entity: AreaEntity | BayEntity | LinkEntity | SublinkEntity | NodeEntity) {
	return entity.mapObjectErrorss.length > 0;
};

function getWarningFlag (entity: AreaEntity | BayEntity | LinkEntity | SublinkEntity | NodeEntity) {
	return entity.mapObjectWarningss.length > 0;
};

const NEW_MAP_OBJECT_COLOR = 'new-map-object-color';
const ERROR_MAP_OBJECT_COLOR = 'error-map-object-color';
const WARNING_MAP_OBJECT_COLOR = 'warning-map-object-color';

/**
 * Convert Area entity and underlying bay entities into a IObjectSideBarItem
 */
export function convertAreaItem(area: AreaEntity, lookup: MapStore | undefined): ILayersPanelItem {
	const areaName = `${area.areaName}`;

	let bayObjectItems = lookup?.getBaysByAreaId(area.id ?? 'unknown')?.map(bay => convertBayItem(bay)) ?? [];
	const hasBays = !!area.bayss && area.bayss.length > 0;
	if (hasBays && !lookup) {
		bayObjectItems = area.bayss.map(bay => convertBayItem(bay));
	}

	bayObjectItems = bayObjectItems.sort((a, b) => a.name.localeCompare(b.name));

	const isNotUndefinedBaysArea = areaName !== UNDEFINED_BAYS_AREANAME;
	const isUnpublishedArea = (area.state === 'NEW_OBJECT' || area.state === 'MODIFIED') && isNotUndefinedBaysArea;
	const isImportedModifiedArea = (area.state === 'MODIFIED' || area.state === 'PUBLISHED_MODIFIED') && isNotUndefinedBaysArea;
	const isNewArea = !area.isImported && isNotUndefinedBaysArea;
	const errorFlag: boolean = getErrorFlag(area);
	const warningFlag: boolean = getWarningFlag(area);

	const childrenHasError = bayObjectItems.some(bay => !!bay.isError);
	const error = childrenHasError || errorFlag;

	const childrenHasWarning = bayObjectItems.some(bay => !!bay.isWarning);
	const warning = childrenHasWarning || warningFlag;

	const areaListStyling = `areaitem-${area.areaType}`;
	const noErrorNoWarning = isUnpublishedArea ? NEW_MAP_OBJECT_COLOR : '';
	const labelStyling = errorFlag ? ERROR_MAP_OBJECT_COLOR : warningFlag ? WARNING_MAP_OBJECT_COLOR : noErrorNoWarning;

	return {
		name: isNewArea ? `[NEW] ${areaName}` : isImportedModifiedArea ? `[EDIT] ${areaName}` : areaName,
		entityId: area.id,
		labelStyling: `${areaListStyling} ${labelStyling}`,
		children: bayObjectItems,
		mapObjectType: 'area',
		isNew: isNewArea,
		isError: error,
		isWarning: warning,
	};
}

export function getBayName(bay: BayEntity): string {
	let bayType = bay.bayType.charAt(0).toUpperCase() + bay.bayType.slice(1).toLowerCase();
	switch (bay.bayType) {
		case 'DUMPPADDOCK':
			bayType = 'Dumping_Paddock';
			break;
		case 'DUMPOVEREDGE':
			bayType = 'Dumping_OTE';
			break;
		case 'DUMPCRUSHER':
			bayType = 'Dumping_Crusher';
			break;
	}
	return `${bayType}_${bay.bayId}`;
}

export function convertBayItem(bay: BayEntity): ILayersPanelItem {
	let bayIconClass = '';
	switch (bay.spotDir) {
		case 'DRIVETHROUGH':
			bayIconClass = 'icon-drive-through';
			break;
		case 'BACKIN':
			bayIconClass = 'icon-back-in';
			break;
		default:
			console.error(`Spotdir ${bay.spotDir} not found. Id: ${bay.id}`);
			break;
	}

	let bayIconStyle = '';
	switch (bay.bayType) {
		case 'LOADING':
			bayIconStyle = 'bay-loading';
			break;
		case 'PARKING':
			bayIconStyle = 'bay-parking';
			break;
		case 'FUELLING':
			bayIconStyle = 'bay-fuelling';
			break;
		case 'DUMPPADDOCK':
			bayIconStyle = 'bay-paddock-dumping';
			break;
		case 'DUMPOVEREDGE':
			bayIconStyle = 'bay-overedge-dumping';
			break;
		case 'DUMPCRUSHER':
			bayIconStyle = 'bay-crusher-dumping';
			break;
		default:
			console.error(`Unknown bay type ${bay.bayType}`);
	}
	const isNewUnpublishedBay = bay.state === 'NEW_OBJECT';
	const isNewBay = !bay.isImported;
	const bayName = getBayName(bay);
	const errorFlag: boolean = getErrorFlag(bay);
	const warningFlag: boolean = getWarningFlag(bay);

	const bayStyling = `bay icon-left ${bayIconClass} ${bayIconStyle}`;
	const noErrorNoWarning = isNewUnpublishedBay ? NEW_MAP_OBJECT_COLOR : '';
	const labelStyling = errorFlag ? ERROR_MAP_OBJECT_COLOR : warningFlag ? WARNING_MAP_OBJECT_COLOR : noErrorNoWarning;

	return {
		name: isNewBay ? `[NEW] ${bayName}` : bayName,
		entityId: bay.id ?? bay._clientId,
		labelStyling: `${bayStyling} ${labelStyling}`,
		mapObjectType: 'bay',
		isNew: isNewBay,
		isError: errorFlag,
		isWarning: warningFlag,
	};
}

export function getLinkName(linkId: number) {
	return `Link_${linkId}`;
}

/**
 * Convert Link entity and underlying sublink and node entities into a IObjectSideBarItem
 */
export function convertLinkItem(link: LinkEntity): ILayersPanelItem {
	const linkName = getLinkName(link.linkId);
	const sublinksObjectItem = link.sublinkss.map(sublink => convertSublinkItem(sublink))
		.sort((a, b) => a.name.localeCompare(b.name));

	const isUnpublishedLink = link.state === 'NEW_OBJECT' || link.state === 'MODIFIED';
	const isImportedModifiedLink = link.state === 'MODIFIED' || link.state === 'PUBLISHED_MODIFIED';
	const isNewLink = !link.isImported;
	const errorFlag: boolean = getErrorFlag(link);
	const warningFlag: boolean = getWarningFlag(link);
	const noErrorNoWarning = isUnpublishedLink ? NEW_MAP_OBJECT_COLOR : '';
	const labelStyling = errorFlag ? ERROR_MAP_OBJECT_COLOR : warningFlag ? WARNING_MAP_OBJECT_COLOR : noErrorNoWarning;

	const childrenHasError = link.sublinkss.some(sublink => sublink.mapObjectErrorss.length > 0
		|| sublink.nodess.some(node => node.mapObjectErrorss.length > 0));
	const error = childrenHasError || errorFlag;

	const childrenHasWarning = link.sublinkss.some(sublink => sublink.mapObjectWarningss.length > 0
		|| sublink.nodess.some(node => node.mapObjectWarningss.length > 0));
	const warning = childrenHasWarning || warningFlag;

	return {
		name: isNewLink ? `[NEW] ${linkName}` : isImportedModifiedLink ? `[EDIT] ${linkName}` : linkName,
		entityId: link.id ?? link._clientId,
		labelStyling: labelStyling,
		children: sublinksObjectItem,
		mapObjectType: 'link',
		isNew: isNewLink,
		isError: error,
		isWarning: warning,
	};
}

export function getSublinkName(sublinkId: number) {
	return `Sublink_${sublinkId}`;
}

export function convertSublinkItem(sublink: SublinkEntity): ILayersPanelItem {
	const sublinkName = getSublinkName(sublink.sublinkId);
	const nodesObjectItem = sublink.nodess.map(node => convertNodeItem(node))
		.sort((a, b) => a.name.localeCompare(b.name));

	const isUnpublishedSublink = sublink.state === 'NEW_OBJECT' || sublink.state === 'MODIFIED';
	const isImportedModifiedSublink = sublink.state === 'MODIFIED' || sublink.state === 'PUBLISHED_MODIFIED';
	const isNewSublink = !sublink.isImported;
	const errorFlag: boolean = getErrorFlag(sublink);
	const warningFlag: boolean = getWarningFlag(sublink);
	const noErrorNoWarning = isUnpublishedSublink ? NEW_MAP_OBJECT_COLOR : '';
	const labelStyling = errorFlag ? ERROR_MAP_OBJECT_COLOR : warningFlag ? WARNING_MAP_OBJECT_COLOR : noErrorNoWarning;

	const childrenHasError = sublink.nodess.some(nodes => nodes.mapObjectErrorss.length > 0);
	const error = childrenHasError || errorFlag;

	const childrenHasWarning = sublink.nodess.some(nodes => nodes.mapObjectWarningss.length > 0);
	const warning = childrenHasWarning || warningFlag;

	return {
		name: isNewSublink ? `[NEW] ${sublinkName}` : isImportedModifiedSublink ? `[EDIT] ${sublinkName}` : sublinkName,
		entityId: sublink.id ?? sublink._clientId,
		children: nodesObjectItem,
		labelStyling: labelStyling,
		mapObjectType: 'sublink',
		isNew: isNewSublink,
		isError: error,
		isWarning: warning,
	};
}

export function getNodeName(nodeId: number) {
	return `Node_${nodeId}`;
}

export function convertNodeItem(node: NodeEntity): ILayersPanelItem {
	const nodeName = getNodeName(node.nodeId);

	const isUnpublishedNode = node.state === 'NEW_OBJECT' || node.state === 'MODIFIED';
	const isImportedModifiedNode = node.state === 'MODIFIED' || node.state === 'PUBLISHED_MODIFIED';
	const isNewNode = !node.isImported || node.state === 'NEW_OBJECT' || node.state === 'PUBLISHED_NEW';
	const errorFlag: boolean = getErrorFlag(node);
	const warningFlag: boolean = getWarningFlag(node);
	const noErrorNoWarning = isUnpublishedNode ? NEW_MAP_OBJECT_COLOR : '';
	const labelStyling = errorFlag ? ERROR_MAP_OBJECT_COLOR : warningFlag ? WARNING_MAP_OBJECT_COLOR : noErrorNoWarning;

	return {
		name: isNewNode ? `[NEW] ${nodeName}` : isImportedModifiedNode ? `[EDIT] ${nodeName}` : nodeName,
		entityId: node.id,
		// eslint-disable-next-line no-nested-ternary
		labelStyling: labelStyling,
		mapObjectType: 'node',
		isNew: isNewNode,
		isError: errorFlag,
		isWarning: warningFlag,
	};
}
