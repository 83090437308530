import {
	AreaEntity, BayEntity, ImportVersionEntity, LinkEntity, LinkFromLinkTo, NodeEntity, SignalSetEntity, SublinkEntity,
} from '../../../Models/Entities';
import MapRenderer, { CONTAINER_Z_INDEX } from './MapRenderer';
import MapEventHandler from './MapEventHandler';
import MapStore from './MapStore';
import Path from './MapObjects/Path/Path';
import Area from './MapObjects/Area/Area';
import Link from './MapObjects/Link/Link';
import DrivingArea from './MapObjects/Area/DrivingArea';
import MapObject, { MapObjectType } from './MapObjects/MapObject';
import { Model } from '../../../Models/Model';
import Bay from './MapObjects/Bay/Bay';
import Beacon from './MapObjects/Beacon/Beacon';
import Location from './MapObjects/Location/Location';
import Segment from './MapObjects/Segment/Segment';
import {
	Coordinates,
	isPixiCoordinates,
	LeafletCoordinates,
	PixiCoordinates,
	realWorldCoordinates,
	RealWorldCoordinates,
} from './Helpers/Coordinates';
import rotate from '../../../Assets/images/rotate.svg';
import rotate_path from '../../../Assets/images/rotate_path.svg';
import * as PIXI from 'pixi.js';
import { ToolbarEvent } from '../MapToolbar/Toolbar';
import { getBayName } from '../LayersPanel/AhsMapObjects';
import LinkConnectivityEditHelper from './MapStateHandlerHelpers/LinkConnectivityEditHelper';
import { SAVE_CHANGES_INTERVAL_SECONDS } from '../../../Constants';
import PathToolHelper from './MapStateHandlerHelpers/PathToolHelper';
import PathSelectHandler from './MapStateHandlers/PathSelectHandler';
import { store } from '../../../Models/Store';
import { calcDistanceBetweenNodes, calcHeading, offsetAlongHeadingRealWorld, trc_caller, trc_disable } from './Helpers/MapUtils';
import Signal from './MapObjects/Signal/Signal';
import TurnSignalHelper from './MapStateHandlerHelpers/TurnSignalHelper';
import { getAffectedLinks } from './Helpers/DrivingZone';
import AhsArea from './MapObjects/Area/AhsArea';
import ChangeTracker from "../ChangeTracker/ChangeTracker";
import UndoRedoTracker, {ActionGroupType} from "../UndoRedo/UndoRedoTracker";

const ROTATE_CURSOR = `url(${rotate}) 12 12, auto`;
const ROTATE_PATH_CURSOR = `url(${rotate_path}) 12 12, auto`;

/**
 * Entity types that can be selected
 */
export type MapType = MapObjectType | 'map'
	| 'clothoid'
	| 'node'
	| 'link'
	| 'bay'
	| 'sublink'
	| 'area'
	| 'location'
	| 'beacon'
	| 'segment'
	| 'ruler'
	| 'connection';

export type MapObjectErrorType = BayEntity | AreaEntity | LinkEntity | SublinkEntity | NodeEntity;
interface IEntityInfo {
	userMessage: string;
	mapObjectType: MapObjectType;
}

export interface ImportVersionStatus {
	bayEdited: boolean;
	bayPublished: boolean;
	areaEdited: boolean;
	areaPublished: boolean;
	pathEdited: boolean;
	pathPublished: boolean;
}

/**
 * Controller class which is responsible for
 *  - Initialising the MapRenderer and MapEventHandler
 *  - Constructing MapLookup table
 *  - Parsing map objects to renderer
 *  - Initiating rendering
 *  - Attaching events to MapEventHandler
 */
export default class MapController {
	private readonly renderer: MapRenderer;
	private readonly eventHandler: MapEventHandler;

	private readonly version: ImportVersionEntity;
	private readonly mapLookup: MapStore;

	private readonly tracker1: ChangeTracker;
	private readonly tracker: UndoRedoTracker;


	private selectedTool: ToolbarEvent;
	// initialImportVersionStatus is the initial status after importing/loading a map
	private initialImportVersionStatus: ImportVersionStatus;
	// These three variables are used for recording the IDs of the first editing undo/redo actions
	private backToInitialAreaActionId: string | undefined;
	private backToInitialBayActionId: string | undefined;
	private backToInitialPathActionId: string | undefined;

	private highlightedObjectId?: string;
	private highlightedEntityId?: string;
	private autoSaveChangesIntervalId: NodeJS.Timeout | undefined;

	private _isAutoSaveDisabled = false;

	private trc = trc_disable;

	private currentActionErrorCount = 0;
	public isDisplayConfirmButton = false;
	public isActionConfirmAllowed() {
		return this.currentActionErrorCount === 0;
	}

	constructor(version: ImportVersionEntity) {
		this.version = version;
		this.mapLookup = new MapStore(version, version.maptoolparam!);

		// Add global references to these objects for debugging purposes
		document['lookup'] = this.mapLookup;
		document['controller'] = this;
		store.mapController = this;

		this.renderer = new MapRenderer(this.version, this);
		document['renderer'] = this.renderer;
		this.eventHandler = new MapEventHandler(this);
		this.tracker1 = new ChangeTracker(this);
		this.tracker = new UndoRedoTracker(this.mapLookup, this);
		this.selectedTool = 'selector';
		this.setInitialImportVersionStatus();
	}

	public rerenderLink(id: number) {
		const link = this.getMapLookup().getLinkByIdNumber(id);
		this.removeAndReAddPath(link!, true);
	}

	/**
	 * Set Initial Import Version Status
	 */
	public setInitialImportVersionStatus() {
		this.initialImportVersionStatus = {
			bayEdited: this.version.bayEdited,
			bayPublished: this.version.bayPublished,
			areaEdited: this.version.areaEdited,
			areaPublished: this.version.areaPublished,
			pathEdited: this.version.pathEdited,
			pathPublished: this.version.pathPublished,
		};
	}

	public get isAutoSaveDisabled() {
		return this._isAutoSaveDisabled;
	}

	public set isAutoSaveDisabled(isDisabled: boolean) {
		this._isAutoSaveDisabled = isDisabled;
	}

	/**
	 * Get Initial Import Version Status
	 */
	public getInitialImportVersionStatus() {
		return this.initialImportVersionStatus;
	}

	/* ************************************
	 * The first area/bay/path status changes
	 ************************************ */

	public getBackToInitialAreaActionId() {
		return this.backToInitialAreaActionId;
	}

	public setBackToInitialAreaActionId(id: string | undefined) {
		this.backToInitialAreaActionId = id;
	}

	public getBackToInitialBayActionId() {
		return this.backToInitialBayActionId;
	}

	public setBackToInitialBayActionId(id: string | undefined) {
		this.backToInitialBayActionId = id;
	}

	public getBackToInitialPathActionId() {
		return this.backToInitialPathActionId;
	}

	public setBackToInitialPathActionId(id: string | undefined) {
		this.backToInitialPathActionId = id;
	}

	public setConfirmButtonStatus(displayed: boolean, enabled: boolean) {
		// If we are transitioning to a confirmable action, reset the count
		if (this.isDisplayConfirmButton !== displayed) {
			this.currentActionErrorCount = 0;
		}

		this.isDisplayConfirmButton = displayed;

		if (!displayed) {
			return;
		}

		// If enabling the button, it means there is one less error
		// If disabling the button, it means there is one more error
		if (enabled) {
			if (this.currentActionErrorCount != 0) {
				this.currentActionErrorCount -= 1;
			}
		} else {
			this.currentActionErrorCount += 1;
		}
	}

	/**
	 * start Auto Save Interval
	 */
	public startAutoSaveInterval() {
		if (!!this.autoSaveChangesIntervalId) {
			return;
		}
		this.autoSaveChangesIntervalId = setInterval(() => {
			// Check for FMS here or within saveChanges
			if (!this.isAutoSaveDisabled) {
				console.log('Saving map');
				const isAutoSave = true;
				this.tracker.saveChanges(isAutoSave);
			} else {
				console.log('Not saving map (autoSave disabled)');
			}
		}, SAVE_CHANGES_INTERVAL_SECONDS * 1000);
	}

	/**
	 * reset Auto Save Interval
	 */
	public resetAutoSaveInterval() {
		if (!!this.autoSaveChangesIntervalId) {
			clearInterval(this.autoSaveChangesIntervalId);
			this.autoSaveChangesIntervalId = undefined;
		}
	}

	public waitForMapSaveToComplete = (maxWaitTime = 10000) => {
		return new Promise<void>((resolve, reject) => {
			const tracker = this.getTracker();
			let elapsedTime = 0;
			const interval = 50;
			const intervalId = setInterval(() => {
				try {
					if (!tracker.savingInProgress) {
						clearInterval(intervalId);
						resolve();
					} else {
						elapsedTime += interval;
						if (elapsedTime >= maxWaitTime) {
							clearInterval(intervalId);
							reject(new Error('Maximum wait time exceeded'));
						}
					}
				} catch (error) {
					clearInterval(intervalId);
					reject(error);
				}
			}, interval);
		});
	};

	public mountMap() {
		this.trc('Begin mountMap');
		console.time('mountMap');
		// base init of leaflet/pixi
		this.renderer.initRenderer();

		this.eventHandler.startListening();

		this.initialBuildMapObjects();

		// final init of pixi and start render
		this.renderer.mountPixiAndStartRendering();

		this.focusMap();
		console.timeEnd('mountMap');
	}

	public unmountMap() {
		store.isInit = false;
		console.log('unmountMap: Setting isInit to false')
		this.eventHandler.stopListening();
		this.renderer.deInitLeaflet();
	}

	/**
	 * Visually highligh a map object by entityId
	 * @param entityId
	 * @param mapType
	 */
	public highlightObjectByEntityId(entityId: string, mapType: string) {
		const objectId = this.getMapLookup().getMapObjectId(entityId, mapType as MapType);
		this.unhighlightObject(false);
		this.highlightedObjectId = objectId;
		this.highlightedEntityId = entityId;
		const mapObject = this.renderer.getObjectById(objectId);
		if (mapObject) {
			const includeChildren = mapObject.getType() === 'sublink'; // for driving zones
			mapObject.setHighlighted(true, includeChildren);
		}

		this.renderer.rerender();
	}

	/**
	 * Unhighlighted map object (if highlighted)
	 * IMPORTANT: To avoid severe performance hit, do note re-render unless there are actual changes
	 */
	public unhighlightObject(rerender: boolean = true) {
		let isRerendered = false;
		if (!!this.highlightedObjectId) {
			const mapObject = this.renderer.getObjectById(this.highlightedObjectId);
			if (mapObject) {
				const includeChildren = mapObject.getType() === 'sublink'; // for driving zones
				mapObject.setHighlighted(false, includeChildren);
			}
			this.highlightedObjectId = undefined;
			this.highlightedEntityId = undefined;
			if (rerender) {
				this.renderer.rerender();
				isRerendered = true;
			}
		}

		if (isRerendered !== rerender) {
			// Previous bevahiour was rerender even if no unhighlight but this caused performance hit
			console.log('unhighlightObject: Nothing to unhighlight. Ignoring.');
		}
	}

	public getHighlightedEntityId() {
		return this.highlightedEntityId;
	}

	/**
	 * Sets the cursor of the map. Not passing in a value will set the cursor back to default
	 *
	 * @param cursor string to set it to
	 */
	public setCursor(cursor?: string) {
		this.renderer.getRootContainer().cursor = cursor ?? '';
	}

	public setSelectedToolType(tool: ToolbarEvent) {
		this.selectedTool = tool;
	}

	public getSelectedToolType() {
		return this.selectedTool;
	}

	public setDefaultCursor() {
		this.setCursor('');
	}

	public setRotateCursor(isPath: boolean = false) {
		const whichCursor = isPath ? ROTATE_PATH_CURSOR : ROTATE_CURSOR;
		this.setCursor(whichCursor);
	}

	/**
	 * Check if there is an object at the given coordinates. Note that this only checks the hit areas of each object
	 *
	 * @param coords to check; This can be any coordinate type.
	 * @param containers to check; This will be checked in order. If parameter not provided it will check all containers
	 */
	public getMapObjectAtCoordinates(coords: Coordinates, containers?: MapObjectType[]): MapObject<any> | undefined {
		let entity: MapObject<any> | undefined;
		const containersToCheck = !!containers ? containers : Object.keys(CONTAINER_Z_INDEX);

		containersToCheck.find(key => {
			const objectContainer = this.renderer.getContainer(key);

			objectContainer.interactive = true;
			objectContainer.interactiveChildren = true;

			entity = this.getMapObjectInContainer(coords, objectContainer);

			// For performance reasons, interactivity is turned off for the container when not needed
			objectContainer.interactive = false;
			objectContainer.interactiveChildren = false;

			return !!entity;
		});

		return entity;
	}

	public getMapObjectInContainer(coords: Coordinates, container: PIXI.Container): MapObject<any> | undefined {
		const hitObject = this.hitTestContainer(coords, container);
		return hitObject ? this.renderer.getObjectById(hitObject.name ?? '') : undefined;
	}

	public hitTestContainer(coords: Coordinates, container: PIXI.Container): PIXI.DisplayObject | undefined {
		// Check the type of coordinates and cast to the correct type
		const localCoords = isPixiCoordinates(coords) ? coords as PixiCoordinates
			: this.renderer.project(coords as (RealWorldCoordinates | LeafletCoordinates));
		return this.renderer.hitTest(localCoords, container);
		// const globalCoords = this.renderer.getRootContainer().toGlobal(localCoords);
		// return new PIXI.EventBoundary(container).hitTest(globalCoords.x, globalCoords.y);
	}

	getTracker() {
		return this.tracker;
	}

	getTracker1() {
		return this.tracker1;
	}

	public getLeafletMap() {
		return this.renderer.getMap();
	}

	public getMapLookup() {
		return this.mapLookup;
	}

	public getMapRenderer() {
		return this.renderer;
	}

	public getEventHandler() {
		return this.eventHandler;
	}

	public getImportVersion() {
		return this.version;
	}

	public getHighlightedMapObject(): MapObject<any> | undefined {
		return !this.highlightedObjectId
			? undefined
			: this.renderer.getObjectById(this.highlightedObjectId);
	}

	/**
	 * Add objects to the renderer that need to be displayed
	 * for the currently loaded map
	 */
	public initialBuildMapObjects() {
		// Parse the map objects
		this.trc('Begin initialBuildMapObjects');
		console.time('initialBuildMapObjects');
		// Create DrivingArea before Path because new Sublink (within Path) will build
		// a DringZone mapObjectId and DrivingArea mapObject lookup
		this.version.drivingAreass
			.forEach(drivingArea => this.renderer.addObject(new DrivingArea(drivingArea, this.renderer, this.mapLookup)));

		// Used to hide FMS objects on initial load
		const isInitialLoad = true;

		// initialisation of links now in EditMap to prevent renderloop / memory issues
		console.time('AddLinks');
		this.version.linkss.forEach(link => {
			this.renderer.addObject(new Path(link, this.renderer, this.mapLookup));
		});
		console.timeEnd('AddLinks');

		this.version.bayss.forEach(bay => this.renderer.addObject(new Bay(bay, this)));
		this.version.areass.forEach(area => {
			this.renderer.addObject(new Area(area, this.renderer, this.mapLookup));

			// Create a location if it is an autonomous area
			if (area.isFmsLocation()) {
				this.renderer.addObject(new Location(area, this, this.mapLookup, isInitialLoad));
			}
		});

		// AHS area
		this.renderer.addObject(new AhsArea(this.version.maptoolparam!, this.renderer));

		// FMS

		this.version.beacons.forEach(beacon => {
			this.renderer.addObject(new Beacon(this.renderer, beacon, this.mapLookup, isInitialLoad));
		});
		this.version.segments.forEach(segment => {
			this.renderer.addObject(new Segment(segment, this.renderer, this.mapLookup, isInitialLoad));
		});
		console.timeEnd('initialBuildMapObjects');
	}

	/**
	 * Add a new object to renderer and map lookup table
	 * @param entity
	 * @param mapObject
	 */
	public addMapObject(entity: Model, mapObject: MapObject<unknown>, addToRerender?: boolean) {
		// add to renderer
		this.renderer.addObject(mapObject, !!addToRerender);

		// add to map lookup table
		this.mapLookup.addEntityToMapObject(entity.id ?? entity._clientId, mapObject);
	}

	/**
	 * Remove an object from renderer and map lookup table
	 * @param entity
	 * @param mapObject
	 * @param skipRemovalFromRenderer
	 */
	public removeMapObject(entity: Model, mapObject: MapObject<unknown>, skipRemovalFromRenderer?: boolean) {
		// remove from renderer
		if (!skipRemovalFromRenderer) {
			mapObject.removeTooltip();
			this.renderer.removeObject(mapObject.getId());
		}

		// remove from map lookup table
		this.mapLookup.removeEntityToMapObject(entity.id ?? entity._clientId, mapObject.getType());
	}

	public focusMap() {
		const element = document.getElementById('leaflet-container');
		if (!!element) {
			element.focus();
		}
	}

	// TODO: From this point onwards should all go in a separate module, maybe

	/**
	 * Used in undo/redo
	 * @param entity
	 * @param isUserAction whether or not this is a user action (e.g. delete) as opposed to undo/redo operation
	 */
	public deleteEntity(entity: Model, isUserAction?: boolean, opType?: ActionGroupType) {
		const entityInfo = this.getEntityInfo(entity);
		if (!!entityInfo) {
			if (entity instanceof LinkFromLinkTo) {
				MapController.deleteConnectivityEntity(entity, this, entityInfo.mapObjectType, isUserAction, opType);
			} else if (!!entityInfo) {
				if (entity instanceof SignalSetEntity && !!entity.linkId) {
					// remove signal from link for undo/redo
					const link = this.getMapLookup().getEntity(entity.linkId, LinkEntity);
					link.removeTurnSignal(entity);
				}
				if (entityInfo.mapObjectType === 'sublink') {
					// for debugging
					const sublink = entity as SublinkEntity;
					console.log(`deleteEntity (sublink): ${sublink.id} ${sublink.sublinkId}`);
					const link = this.getMapLookup().getEntity(sublink.linkId!, LinkEntity);
				}
				this.deleteEntityObjectOperations(entity, entityInfo.mapObjectType);
				const isUndoRedoOperation = !isUserAction;
				this.deleteEntityEventOperations(entity, isUndoRedoOperation);
			}
		} else {
			console.error('delete entity ignored');
		}
		return entityInfo?.userMessage ?? '';
	}

	/**
	 * Complete creation of new entity by creating map objects and updating layers panel
	 *
	 * @param entity
	 * @param userCreatedMapObject
	 * @returns
	 */
	public createEntity(entity: Model, userCreatedMapObject?: MapObject<unknown>, opType?: ActionGroupType) {
		if (!entity.id) {
			console.log(`Setting entityId to ${entity._clientId}`);
			entity.id = entity._clientId;
		}
		const entityInfo = this.getEntityInfo(entity);
		if (!!entityInfo) {
			if (entity instanceof LinkFromLinkTo) {
				console.log('createEntity: reAddConnectivityEntity');
				LinkConnectivityEditHelper.reAddConnectivityEntity(entity, this, opType);
				this.getEventHandler().setActiveTool('selector');
				// line below was commented as this step is already done i undo/redo of mapeventhandler
				// controller.getEventHandler().emit('onPropertiesPanel', 'map');
			} else {
				if (entity instanceof SignalSetEntity && !!entity.linkId) {
					// add signal to link back for undo/redo
					const link = this.getMapLookup().getEntity(entity.linkId, LinkEntity);
					link.addTurnSignal(entity);
				}
				if (entityInfo.mapObjectType == 'sublink') {
					const sublink = entity as SublinkEntity;
					console.log(`createEntity (sublink): ${sublink.id} ${sublink.sublinkId}`);
					const link = this.getMapLookup().getEntity(sublink.linkId!, LinkEntity);
				}
				// NOTE: this is actually an async operation and should probably be treated accordingly
				// Changing it may cause other issues so leaving as is
				this.createEntityObjectOperations(entity, entityInfo.mapObjectType, userCreatedMapObject, opType);
				const isUndoRedoOperation = !userCreatedMapObject;
				this.createEntityEventOperations(entity, isUndoRedoOperation, opType);
			}
		} else {
			console.error('delete entity ignored');
		}
		return entityInfo?.userMessage ?? '';
	}

	// TODO: make the methods below more generic

	/**
	 * Map object text and map object type associated with entity
	 * @param entity
	 * @returns
	 */
	public getEntityInfo(entity: Model): IEntityInfo | undefined {
		const lookup = this.getMapLookup();
		if (entity instanceof AreaEntity) {
			const { areaName } = entity;
			return {
				userMessage: `${areaName}`,
				mapObjectType: 'area',
			};
		}
		if (entity instanceof BayEntity) {
			const bayName = getBayName(entity);
			return {
				userMessage: `${bayName}`,
				mapObjectType: 'bay',
			};
		}
		if (entity instanceof LinkEntity) {
			return {
				userMessage: `Link_${entity.linkId}`,
				mapObjectType: 'link',
			};
		}
		if (entity instanceof LinkFromLinkTo) {
			const startLink = this.getMapLookup().getEntity(entity.linkFromId, LinkEntity);
			const endLink = this.getMapLookup().getEntity(entity.linkToId, LinkEntity);
			return {
				userMessage: `Connectivity between Link_${startLink?.linkId} and Link_${endLink?.linkId}`,
				mapObjectType: 'link',
			};
		}
		if (entity instanceof SublinkEntity) {
			// console.log(`Break Sublink ${entity.sublinkId}`)
			return {
				userMessage: `Link operation`,
				mapObjectType: 'sublink',
			};
		}
		if (entity instanceof SignalSetEntity) {
			return {
				userMessage: `Signal starts from ${entity.signalStart} and the length is ${entity.signalEnd - entity.signalStart}`,
				mapObjectType: 'signal',
			};
		}
		return undefined;
	}

	private createEntityMapObject(entity: Model, mapObjectType: MapObjectType): Bay | Area | Path | Signal | undefined {
		const controller = this;
		const lookup = controller.getMapLookup();
		const renderer = controller.getMapRenderer();
		switch (mapObjectType) {
			case 'area':
				return new Area(entity as AreaEntity, renderer, lookup);
			case 'bay':
				return new Bay(entity as BayEntity, controller);
			case 'link':
				return undefined;
			case 'sublink':
				console.log('createEntityMapObject: sublink');
				return undefined;
			case 'signal':
				return TurnSignalHelper.createSignalMapObject(entity as SignalSetEntity, controller);
			default:
				return undefined;
		}
	}

	async createLinkEntityOperation(linkEntity: LinkEntity, opType?: ActionGroupType) {
		const controller = this;
		console.log('createLinkEntityOperation');

		const lookup = this.getMapLookup();
		const renderer = this.getMapRenderer();
		// Fix data for break/join
		linkEntity.sublinkss.forEach((sl, i) => {
			sl.linkId = linkEntity.id;
			sl.link = linkEntity;

			sl.nodess.forEach(node => node.setSublink(sl));
		});
		const firstSublink = linkEntity.firstSublink();
		const sublinkId = firstSublink?.sublinkId;
		// Break the sublinks between fromLink and toLink
		linkEntity.sublinkss[0].previousSublinkId = undefined;
		// Update references
		linkEntity.sublinkss = linkEntity.getSublinks();
		console.log(`got first sl ${sublinkId}`);

		const pathObject = new Path(linkEntity, renderer, lookup,
			{
				isSelected: false, forceBuild: true, allLookup: true,
			});
		// console.error('map object creation failed (entity type not found)');

		controller.getMapRenderer().addObject(pathObject);
		// ClothoidToolHandler.renderDrivingZones(pathObject, controller.getMapRenderer());
		controller.getMapRenderer().rerender();
		// NOTE: this is an async operation
		if (linkEntity.linkFroms.length > 0 || linkEntity.linkTos.length > 0) {
			// This method also recalculates the driving zones
			await LinkConnectivityEditHelper.addConnectivityToAssociatedLinks(linkEntity, this.getMapLookup(), opType);
		} else if (!opType || (opType !== 'break_link' && opType !== 'join_link')) {
			// block calculateDrivingZone for undo/redo break/join link
			// because linkEntity has not been created in the database yet
			// and use PropertyUpdateAction to update sublink driving zone instead of recalculating it
			// ClothoidToolHandler.renderDrivingZones(pathObject, controller.getMapRenderer());
			const importVersionId = this.getImportVersion().id;
			await PathToolHelper.calculateDrivingZone(lookup, importVersionId, [linkEntity], undefined, "MC.createLinkEntityOperation");
		}
	}

	/**
	 *
	 * @param entity
	 * @param controller
	 * @param mapObjectType
	 * @param userCreatedMapObject
	 * @returns
	 */
	private async createEntityObjectOperations(entity: Model, mapObjectType: MapObjectType, userCreatedMapObject?: MapObject<unknown>, opType?: ActionGroupType) {
		const id = entity.getModelId();
		const controller = this;
		console.log(`createEntityObjectOperations: entityID ${id}`);
		// Step 1: add to renderer and map lookup
		const isUndoRedoAction = !userCreatedMapObject;
		const mapObject = isUndoRedoAction ? this.createEntityMapObject(entity, mapObjectType) : userCreatedMapObject;
		if (!mapObject) {
			if (mapObjectType === 'link') {
				await this.createLinkEntityOperation(entity as LinkEntity, opType);
			}
			return;
		}

		const isLink = mapObjectType === 'link'; // special case
		// Step 2: Add to map lookup
		if (isLink) {
			// NOTE: this will only be reached if userCreatedMapObject is set
			const pathObject = mapObject as Path;
			controller.getMapRenderer().addObject(pathObject);
			PathToolHelper.renderDrivingZones(pathObject, controller.getMapRenderer());
		} else {
			// only add to re-render if it's a new map object
			const isAddToRenderer = isUndoRedoAction;
			controller.addMapObject(entity, mapObject, isAddToRenderer);
			// TODO: this type of case should eventually be handled in addEntityToMapObject
			if (entity instanceof AreaEntity) {
				const areaErrorCount = entity.mapObjectErrorss.length;

				// eslint-disable-next-line max-len
				this.getEventHandler().emit('onMapObjectUpdate', entity);
				this.getMapLookup().createEntity(entity);
			} else if (entity instanceof BayEntity) {
				const mapErrorCount = this.getMapLookup().getMapErrorCount();
				const bayErrorCount = entity.mapObjectErrorss.length;
				// eslint-disable-next-line max-len
				this.getEventHandler().emit('onMapObjectUpdate', entity);
				this.getMapLookup().createEntity(entity);
			}
		}

		controller.getMapRenderer().rerender();
	}

	private createEntityEventOperations(entity: Model, isUndoRedoAction: boolean, opType?: ActionGroupType) {
		const controller = this;
		// Step 3: Add to layers panel
		let isStandardCreate = true;
		if (isStandardCreate) {
			// If breaking link includes breaking sublink, no need to add the new sublink to layers panel
			// because the sublink has been added when adding the new link label
			if (!(entity instanceof SublinkEntity) || !opType || opType !== 'break_link') {
				controller.getEventHandler().emit('onMapObjectCreateConfirm', entity);
			}
		}
		// Step 4 (optional): Set map state to selector if it's an undoRedo action
		if (isUndoRedoAction) {
			controller.getEventHandler().setActiveTool('selector');
		}
	}

	/**
	 * Delete a connectivity entity
	 * @param controller
	 * @param entity
	 * @returns
	 */
	// eslint-disable-next-line max-len
	private static deleteConnectivityEntity(entity: Model, controller: MapController, mapObjectType: MapObjectType, isUserAction?: boolean, opType?: ActionGroupType) {
		const mapParams = controller.getMapLookup().getMapParameters();
		if (entity instanceof LinkFromLinkTo) {
			console.log('deleteConnectivityEntity');
			console.log('delete', entity.getModelId());
			const startLink = controller.getMapLookup().getEntity(entity.linkFromId, LinkEntity);
			const endLink = controller.getMapLookup().getEntity(entity.linkToId, LinkEntity);
			if (startLink && endLink) {
				// step 1: remove the connectivity from the links
				startLink.removeNextLink(endLink);
				// step 2: re-render the map
				controller.getMapRenderer().rerender();
				// step 3: update the driving zones for the affected links
				const affectedLinks = getAffectedLinks(endLink, startLink, mapParams);
				if (affectedLinks.length > 0 && (!opType || (opType !== 'break_link' && opType !== 'join_link'))) {
					// block calculateDrivingZone for undo/redo break/join link
					// because linkEntity has not been created in the database yet
					// and use PropertyUpdateAction to update sublink driving zone instead of recalculating it
					PathToolHelper
					.calculateDrivingZone(controller.getMapLookup(), controller.getImportVersion().id, affectedLinks, undefined, "MC.deleteConnectivityEntity");
				}
			}
		}
		if (!!isUserAction) {
			// so that original behaviour doesn't change (e.g. on pressing delete key)
			controller.getEventHandler().setMapEventState('selector');
		} else {
			controller.getEventHandler().setActiveTool('selector');
		}
	}

	public deleteLinkEntityOperations(linkEntity: LinkEntity, linkMapObject: Link, isRecalcDZ: boolean = true) {
		// Start HACK
		// This handles and edge case where the link being undone is selected.
		// In such cases, the dispose method of LinkEditHandler isn't aware that the link
		// is deleted and attempts to call the hideConnectivity method on objects that don't exist.
		// To resolve this, we check if the LinkEditHandler is the active state handler AND if the link
		// ID matches the one being deleted. In this case, set the deleted flag to true - which avoids
		// calling the hideConnectivity method in dispose()
		const controller = this;
		const stateHandler = this.getEventHandler().getStateHandler();
		if (!!stateHandler && stateHandler instanceof PathSelectHandler) {
			if (stateHandler.getLinkEntity().getModelId() === linkEntity.getModelId()) {
				stateHandler.setIsLinkDeleted(true);
			}
		}
		// End hack
		console.log(`deleteLinkEntityOperations: isRecalcDZ = ${isRecalcDZ}`);
		// NOTE: breakAssociatedConnectivity is an async operations
		LinkConnectivityEditHelper.removeConnectivityFromAssociatedLinksUsingUUID(linkEntity, this.getMapLookup(), isRecalcDZ);
		const pathObject = linkMapObject.getParent();
		if (!pathObject) {
			console.error('path not found');
			return;
		}
		this.removeMapObject(linkEntity, linkMapObject, true);
		this.getMapRenderer().removeObject(pathObject.getId());
		controller.getMapRenderer().rerender();
	}

	removeAndReAddPath(linkEntity: LinkEntity, isRerenderDrivingZone: boolean = true) {
		const lookup = this.getMapLookup();
		const renderer = this.getMapRenderer();
		// Update original path in renderer
		const originalLinkObjectId = lookup.getMapObjectIdByEntity(linkEntity, 'link');
		const linkMapObject = renderer.getObjectById(originalLinkObjectId);
		renderer.removeObject(linkMapObject.getParent()?.getId() ?? '');
		const updatedPath = new Path(linkEntity, renderer, lookup);
		renderer.addObject(updatedPath);
		if (isRerenderDrivingZone) {
			PathToolHelper.renderDrivingZones(updatedPath, renderer);
		}
		renderer.rerender();
	}

	/**
	 * Delete a confirmed entity
	 * @param controller
	 * @param entity
	 * @returns
	 */
	private deleteEntityObjectOperations(entity: Model, mapObjectType: MapObjectType) {
		const id = entity.getModelId();
		const controller = this;
		// TODO: change to id
		console.log(`deleteEntityObjectOperations: entityID ${id}`);
		let mapObjectID = controller.getMapLookup().getMapObjectId(id, mapObjectType);
		let mapObject = controller.getMapRenderer().getObjectById(mapObjectID);
		if (mapObjectType === 'link') {
			this.deleteLinkEntityOperations(entity as LinkEntity, mapObject as Link);
			return;
		}
		if (!mapObject) {
			console.error('deleteEntityObjectOperations: Object not found');
			return;
		}

		// Step 1: Remove from renderer and lookup
		// TODO: ideally links should be removed this way as well
		controller.removeMapObject(entity, mapObject);
		if (entity instanceof BayEntity) {
			this.getEventHandler().emit('onMapObjectDelete', entity);
		}
		if (entity instanceof AreaEntity) {
			// TODO: check logic
			const getBaysList = this.getMapLookup().getBaysByAreaId(entity.id);
			// getBaysList?.forEach(bay => {
				// runInAction(() => {
				// 	bay.areaId = undefined;
				// 	bay.areaname = UNDEFINED_BAYS_AREANAME;
				// });
				// setTimeout(() => {
				// 	setBayTypeBeforeConfirm(entity, controller);
				// });
				// controller.getMapLookup().createEntity(bay);
			// });
			this.getEventHandler().emit('onMapObjectDelete', entity);
		}
		controller.getMapRenderer().rerender();
	}

	/**
	 *
	 * @param entity
	* @param isUndoRedoOperation
	 */
	private deleteEntityEventOperations(entity: Model, isUndoRedoOperation?: boolean) {
		const controller = this;
		// Step 2: Remove from layers panel
		let isStandardDelete = true;
		if (isStandardDelete) {
			controller.getEventHandler().emit('onMapObjectDelete', entity);
		}
		// Step 3 (optional): Set map state to selector
		if (!isUndoRedoOperation && entity instanceof SignalSetEntity) {
			return;
		} else if (!isUndoRedoOperation) {
			// so that original behaviour doesn't change (e.g. on pressing delete key)
			controller.getEventHandler().setMapEventState('selector');//
		} else {
			controller.getEventHandler().setActiveTool('selector');
		}
	}

	/**
	 * Get signal points to create a turn signal mapObject.
	 */
	public getSignalPoints(linkNodes: NodeEntity[], linkPoints: PixiCoordinates[], distSignalStartToLinkStart: number, signalLength: number) {
		const signalPoints: PixiCoordinates[] = [];
		let distFromPreviousLinkNodeToLinkStart = 0; // This is used for a signal falling between two nodes.
		let dist = 0; // The distance from the start node of the link to a node of the link
		let pos = 0;

		// find the start node of the signal
		for (let i = 0; i < linkNodes.length; i++) {
			pos = i + 1;
			const p1 = linkNodes[i];
			if (i < linkNodes.length - 1) {
				const p2 = linkNodes[i + 1];
				const _dist = calcDistanceBetweenNodes(p1, p2);
				if (dist + _dist > distSignalStartToLinkStart) {
					const signalStart = this.getSignalPoint(p1, p2, distSignalStartToLinkStart - dist);
					signalPoints.push(signalStart);
					distFromPreviousLinkNodeToLinkStart = dist;
					dist += _dist;
					break;
				}
				dist += _dist;
			}
		}

		// find the nodes along with the link and the end node of the signal
		let distFromNextLinkNodeToSignalStartPoint = dist - distSignalStartToLinkStart;
		if (signalLength < distFromNextLinkNodeToSignalStartPoint) {
			const distance = (distSignalStartToLinkStart + signalLength) - distFromPreviousLinkNodeToLinkStart;
			const signalEnd = this.getSignalPoint(linkNodes[pos - 1], linkNodes[pos], distance);
			signalPoints.push(signalEnd);

		} else {
			let dist = distFromNextLinkNodeToSignalStartPoint; // The distance from the start point of the signal to a node of the link
			for (let i = pos; i < linkNodes.length; i++) {
				signalPoints.push(linkPoints[i]);
				const p1 = linkNodes[i];
				if (i < linkNodes.length - 1) {
					const p2 = linkNodes[i + 1];
					const _dist = calcDistanceBetweenNodes(p1, p2);
					if (dist + _dist > signalLength) {
						const signalEnd = this.getSignalPoint(p1, p2, signalLength - dist);
						signalPoints.push(signalEnd);
						dist += _dist;
						break;
					}
					dist += _dist;
				}
			}
		}

		return signalPoints;
	}

	/**
	 * Get a signal start or end point between two nodes of a link.
	 */
	public getSignalPoint(p1: NodeEntity, p2: NodeEntity, distance: number): PixiCoordinates {
		const fromPoint = { northing: p1.northing, easting: p1.easting };
		const toPoint = { northing: p2.northing, easting: p2.easting };
		const headingDegrees = calcHeading(fromPoint, toPoint);
		const offset = offsetAlongHeadingRealWorld(distance, headingDegrees);
		const northing = p1.northing + offset.northing;
		const easting = p1.easting + offset.easting;
		return this.getMapRenderer().project(realWorldCoordinates(northing, easting));
	}
}